import React, { ChangeEvent, ReactElement, useState } from 'react'
import { Form, Input, InputProps, LabelProps, SemanticShorthandItem } from 'semantic-ui-react'

import { ChangeHandler } from './Hooks'

type MinMax = {
  min?: number
  max?: number
}

const validate = (valueAsString: string, {min, max}: MinMax, checkMin: boolean) => {
  if (min && min >= 0 && valueAsString.substring(0,1) === '-') {
    valueAsString = '';
  }
  if (valueAsString === '' || valueAsString === '-') {
    // empty, skip validation
    return {error: false, value: valueAsString};
  }

  let value = parseInt(valueAsString.replace(/,/g,''));
  if (isNaN(value)) {
    return {error: true, value: valueAsString};
  }

  if (checkMin && min && value < min) {
    value = min
  }
  if (max && value > max) {
    value = max
  }

  return {error: false, value};
}

type NumberFieldProps = {
  onChange?: ChangeHandler
  innerLabel?: SemanticShorthandItem<LabelProps>
  innerStyle?: Record<string, any>
  label?: string | ReactElement
  required?: boolean
} & MinMax & InputProps

export const NumberField: React.FC<NumberFieldProps> = (props) => {
  const {onChange, min, max, required, innerLabel, innerStyle, label, value, ...rest} = props;
  const [error, setError] = useState<boolean>(false)
  
  const handleChange: ChangeHandler = (e, target) => {
    const {error, value} = validate(target.value as string, {min, max}, false);
    setError(error)
    if (onChange) {
      onChange(e, {name: target.name, value});
    }
  }

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    // different handler because we only checkMin on blur
    const target = e.target;
    const {error, value} = validate(target.value, {min, max}, true);
    setError(error);
    if (onChange) {
      onChange(e, {name: target.name, value});
    }
  }

  return <Form.Field error={error} required={required}>
    {label && <label>{label}</label>}
    <Input
      type='text'
      value={value}
      label={innerLabel}
      style={innerStyle}
      {...rest}
      onBlur={handleBlur}
      onChange={handleChange} />
  </Form.Field>
}
