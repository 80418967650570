import { PersonContainer } from 'Context/Person'
import React, {useContext} from 'react'
import { Grid } from 'semantic-ui-react'
import { Panel } from 'Shared/Panel'
import { Admin } from 'Shared/Roles'

import BackgroundChecker from './BackgroundChecker'
import ChangePassword from './ChangePassword'
import Openpath from './Openpath'
import RoleForm from './RoleForm'

const Security = () => {
  const {person} = useContext(PersonContainer);;
  const {user} = person;

  return <>
    <Grid columns={2} stackable>
      <Grid.Column>
        <Panel icon='unlock' heading='Change or Set a Password'>
          <ChangePassword user={user}/>
        </Panel>
      </Grid.Column>
      {user && 
        <Admin>
          <Grid.Column>
            <Panel icon='lock' heading='User Role'>
              <RoleForm user={user}/>
            </Panel>
          </Grid.Column>
          <Grid.Column>
            <Panel icon='lock' heading='Open Path'>
              <Openpath/>
            </Panel>
          </Grid.Column>
          <Grid.Column>
            <Panel icon='lock' heading='Background Check'>
              <BackgroundChecker/>
            </Panel>
          </Grid.Column>
        </Admin>}
    </Grid>
  </>
};

export default Security;
