import API from 'Api'
import React, { useCallback } from 'react'
import { Form, Message } from 'semantic-ui-react'
import { RoleOptions } from 'Shared/RoleOptions'

import { ChangeHandler, useFields, useRequest } from './Hooks'
import { Household, HouseholdPersonRole, Person } from './Models'

export const EditRole = ({role, household, person}: {role: HouseholdPersonRole, household: Household, person: Person}) => {
  const [loading, error, run] = useRequest(undefined);
  const {fields, handleChange} = useFields({
    role
  });

  const handleChangeAndSave: ChangeHandler = useCallback((e, changeData) => {
    if (changeData.value) {
      handleChange(e, changeData);
    }

    run(API.updateHouseholdPerson(household.id, {
      household_id: household.id,
      person_id: person.id,
      role: changeData.value,
    }))
  }, [handleChange, household, person, run]);

  return (
    <Form error>
      <Message error>{error}</Message>
      <Form.Select
        compact
        name='role'
        value={fields.role}
        disabled={loading}
        loading={loading}
        onChange={handleChangeAndSave}
        options={RoleOptions}
      />
    </Form>
  );
}

