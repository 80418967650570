import { Form, Segment } from 'semantic-ui-react'
import { ChangeHandler } from 'Shared/Hooks';
import { Markdown } from 'Shared/Markdown';
import Tooltip from 'Shared/Tooltip'

export const ProgramFormDescription = ({description, handleChange}: {description: string, handleChange: ChangeHandler}) => (
  <Form.Group widths='equal'>
    <Form.Field required>
      <label>Description <Tooltip wide content="You can use [links](https://markdown.com), *italics*, **bold** text, and ### headings." /></label>
      <Form.TextArea
        rows={5}
        name='description'
        required
        value={description}
        onChange={handleChange}
      />
    </Form.Field>
    <Form.Field>
      <label>Description Preview</label>
      <Segment style={{marginTop:0}}>
        {description && <Markdown className="prog-description" text={description}/>}
      </Segment>
    </Form.Field>
  </Form.Group>
)
