import API from 'Api'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Form, Icon, Loader, Message, Table } from 'semantic-ui-react'
import { useRequest } from 'Shared/Hooks';
import { OpenpathUser } from 'Shared/Models';
import TableHeaders from 'Shared/TableHeaders';


const Openpath = () => {
  const [loading, error, run, {users}] = useRequest<{users:OpenpathUser[]}>({users: []}, {loading: true})
  const [active, setActive] = useState<boolean>(false);

  useEffect(()=>{
    run(API.getOpenpathUsers())
  }, [run])

  if (loading) {
    return <Loader active inline='centered' />
  }

  if (error) {
    return <Message negative>{error}</Message>
  }

  if (!users) {
    return <Message negative>No Users Found</Message>
  }

  const filtered = users.filter(u => {
    return !active || u.status === 'A';
  });

  return <>
    <Form.Checkbox
      onChange={()=>{setActive(!active)}}
      name='active'
      checked={active}
      label='Only Show Active Accounts'
    />
    <Table compact>
      <TableHeaders headers={['Name', 'Groups', 'Status', 'Remote Access', '']}/>
      <Table.Body>
        { filtered.map(ou => {
          return <UserRow key={ou.id} ou={ou}/>
        }) }
      </Table.Body>
    </Table>
  </>
}

export default Openpath;

const UserRow = ({ou}: {ou: OpenpathUser}) => (
  <tr>
    {ou.user && ou.user.id
      ? <td><Link to={`/person/${ou.user.person_id}`}>{ou.first} {ou.last} ({ou.user.openpath_id})</Link></td>
      : <td>{ou.first} {ou.last}</td>
    }
    <td>{ou.groups}</td>
    <td>{ou.status}</td> 
    <td>{ou.remote && <Icon name='check'/>}</td>
    <td>
      <a title="Edit on Openpath" rel="noopener noreferrer" href={ou.admin_link} target="_blank">
        <Button size='mini' icon='pencil'/>
      </a>
    </td>
  </tr>
)
