import { Label, LabelProps } from 'semantic-ui-react'
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';

import { ProgramStatus } from './Models';


export const ProgramStatuses: Record<ProgramStatus, {color: SemanticCOLORS, label: string}> = {
  [ProgramStatus.Draft]: {color: 'orange', label: 'Draft'},
  [ProgramStatus.Public]: {color: 'green', label: 'Public'},
  [ProgramStatus.Canceled]: {color: 'red', label: 'Canceled'},
  [ProgramStatus.Reserved]: {color: 'grey', label: 'Reserved'},
  [ProgramStatus.Deleted]: {color: 'red', label: 'Deleted'},
  [ProgramStatus.RequestReserved]: {color: 'pink', label: 'Request-Reserved'},
  [ProgramStatus.RequestPublic]: {color: 'pink', label: 'Request-Public'}
};

export const ProgramStatusOptions = Object.keys(ProgramStatuses).map((key) => {
  const keynum = Number(key) as ProgramStatus
  return {
    key: keynum,
    value: keynum,
    text: ProgramStatuses[keynum].label,
  };
});

type ProgramStatusLabelProps = LabelProps & {
  status: ProgramStatus,
  short?: boolean,
  hideActive?: boolean
}

export const ProgramStatusLabel: React.FC<ProgramStatusLabelProps> = ({status, short, hideActive, ...rest}) => {
  if (hideActive && status === ProgramStatus.Public) {
    return <></>;
  }
  const found = ProgramStatuses[status];
  const label = found.label || 'Error';
  const color = found.color || 'red';
  return <Label {...rest} color={color} title={short ? label : ''}>{short ? label.charAt(0) : label}</Label>
}
