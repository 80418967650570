import API from 'Api'
import React, { useCallback, useEffect } from 'react'
import { Button, Form, Label, Loader, Message } from 'semantic-ui-react'
import { useRequest } from 'Shared/Hooks';
import { User } from 'Shared/Models';

type SlackUser = {
  id: string
}

const SlackStatus: React.FC<{user: User}> = ({user}) => {
  const [loading, error, run, slackUser] = useRequest<SlackUser>({} as SlackUser, {loading: true})

  useEffect(()=>{
    if (user.id) {
      run(API.getSlackUser(user.id));
    }
  }, [run, user])

  if (loading) {
    return <Loader style={{left:'1em',verticalAlign:'top', top:'0.25em'}} size='tiny' inline active={loading}/>
  }

  if (error) {
    return <div>Slack error: <Label size='tiny' color='red'>{error}</Label></div>
  }

  if (slackUser && slackUser.id !== '') {
    return <>Slack Active (ID: {slackUser.id})</> 
  }

  return <>
    <SlackInviteForm user={user}/>
    {user.slack_invite !== '0001-01-01T00:00:00Z' && <>Invited on {user.slack_invite.substring(0,10)}</>}
  </>
}

const SlackInviteForm: React.FC<{user: User}>  = ({user}) => {
  const [loading, error, run, result] = useRequest({invited: false})

  const handleSubmit = useCallback(() => {
    run(API.inviteToSlack(user.id))
  }, [run, user.id])

  if (result.invited) {
    return <>Invited!</>
  }

  return <Form error onSubmit={handleSubmit} loading={loading}>
    <Message error>{error}</Message>
    <Button size='tiny'>Invite to Slack</Button>
  </Form> 
}

export default SlackStatus;
