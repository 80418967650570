import './gallery.css'

import React, {useEffect, useState} from 'react'
import defaultImg from'Shared/alder_cones.png';
import { Program, ProgramImage } from 'Shared/Models';

const nextIndex = (images: ProgramImage[], index: number) => {
  return images && index === images.length - 1 ? 0 : index + 1;
}

export const Images = ({program: {images}}: {program: Program}) => {
  const [active, setActive] = useState(0);
  const [next, setNext] = useState(0);

  useEffect(()=>{
    const interval = setInterval(() => {
      setActive(nextIndex(images, active));
      setNext(nextIndex(images, active));
    }, 4000);

    return () => {
      clearInterval(interval)
    }
  }, [active, images])

  return <div className='program_images' style={{background:'#eee'}}>
    {!images || images.length === 0 ? 
      <div className='active' >
        <img src={defaultImg} title='Alder Cones' alt='Alder Cones'/>
      </div>
      : 
      images.map((img, index) => {
        const className = index === active ? 'active' 
          : index === next 
            ? 'next' 
            : '';
        return <div key={img.id} className={className} >
          <img src={img.url} title={img.title} alt={img.title}/>
        </div>
      })}
  </div>
}
