import API from 'Api'
import React, { useCallback, useEffect } from 'react'
import { Button, Divider, Form, List, Loader, Message } from 'semantic-ui-react'
import { useFields, useRequest } from 'Shared/Hooks'
import { Tag } from 'Shared/Models'

const InterestTags: React.FC = () => {
  const [loading, error, run, tags] = useRequest<Tag[]>([], {loading: true})

  const load = useCallback(() => {
    run(API.getInterestTags())
  }, [run])

  useEffect(load, [load]);

  if (loading) {
    return <Loader active inline='centered' />
  }

  if (error) {
    return <Message negative>{error}</Message>
  }

  return <>
    <CreateInterestTag onSuccess={load}/>
    <Divider/>
    <List>
      { tags.map(t => (
        <List.Item key={t.id}>
          <b>{t.label}</b>: {t.description}
        </List.Item>
      )) }
    </List>
  </>
}

export default InterestTags;

type CreateTagProps = {
  onSuccess?: ()=>void
}

const CreateInterestTag: React.FC<CreateTagProps> = ({onSuccess}) => {
  const [loading, error, run] = useRequest<{}>({})
  const {fields, handleChange} = useFields({
    label: '',
    description: ''
  })

  const handleSubmit = useCallback(() => {
    run(API.createInterestTag(fields), () => {
      if (onSuccess) {
        onSuccess();
      }
    })
  }, [run, fields, onSuccess])

  const { label, description } = fields;

  return <Form error name="create-tag" loading={loading} onSubmit={handleSubmit}>
    <Message error>{error}</Message>
    <Form.Group inline>
      <Form.Input
        style={{maxWidth: '14em'}}
        required
        name='label'
        placeholder='Tag'
        value={label}
        onChange={handleChange}
      />
      <Form.Input
        style={{maxWidth: '25em'}}
        required
        name='description'
        placeholder='Short Description'
        value={description}
        onChange={handleChange}
      />
      <Button primary type="submit">Save Tag</Button>
    </Form.Group>
  </Form>
}


