import React from 'react'
import { Form, Segment } from 'semantic-ui-react'
import { useFields } from 'Shared/Hooks'
import { NumberField } from 'Shared/Number'

const CreatePayment = () => {
  const {fields, handleChange} = useFields({
    extra: '',
    amount: 100,
    isSpaceRental: true
  });

  const {extra, amount, isSpaceRental} = fields;

  const url = `https://aldercommons.org/pay?description=${encodeURIComponent(isSpaceRental ? 'Space Rental' : 'Sale')}&extra=${encodeURIComponent(extra)}&amount=${amount}`;

  return <Segment>
    <Form>
      <Form.Checkbox
        label='This payment is for Event or Stuff Rental'
        name='isSpaceRental'
        checked={isSpaceRental}
        onChange={handleChange}/>
      <Form.Input
        type='text'
        label='Description'
        required
        name='extra'
        placeholder='What are they paying for?'
        value={extra}
        onChange={handleChange} />
      <NumberField
        label='Amount'
        name='amount'
        innerLabel={{basic: true, content:'$'}}
        required
        min={5}
        value={amount}
        onChange={handleChange}/>

      <a href={url}>{url}</a>
    </Form>
  </Segment>
}

export default CreatePayment;
