import React from 'react'
import { Table } from 'semantic-ui-react'

type TableHeadersProps = {
  headers: any[]
}

const TableHeaders: React.FC<TableHeadersProps> = ({headers}) => {
  return (
    <Table.Header>
      <Table.Row>
        {headers.map((header) => {
          var name = '';
          var props = {};
          if (typeof header == 'object') {
            name = header.name;
            props = header;
          } else {
            name = header;
          }
          return <Table.HeaderCell {...props} key={name}>
            {name}
          </Table.HeaderCell>
        })}
      </Table.Row>
    </Table.Header>
  );
}

export default TableHeaders;
