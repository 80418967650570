import API, { ProgramSearch } from "Api";
import QueryString from "qs";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { getBase, getEnd, getStart } from "Routes/Programs/Calendar";
import { Loader, Message } from "semantic-ui-react";
import { useRequest } from "Shared/Hooks";
import { ProgramCardGroup } from "Shared/ProgramCardGroup";

export const ProgramCards = ({ embedEffect }: { embedEffect?: () => void }) => {
  const { search } = useLocation();
  const [heading, setHeading] = useState("");
  const [limit, setLimit] = useState(0);
  const [loading, error, run, programs] = useRequest([]);

  useEffect(() => {
    if (embedEffect) {
      embedEffect();
    }
  }, [embedEffect]);

  useEffect(() => {
    const base = getBase();
    const query: ProgramSearch = {
      after: getStart(true, base).format(),
      before: getEnd(true, base, true).format(),
      hydrate: "true",
      participants: "true",
    };

    const { tags, heading, limit } = QueryString.parse(search, {
      ignoreQueryPrefix: true,
    });
    if (tags) {
      query.tags = Array.isArray(tags)
        ? (tags as string[])
        : ([tags] as string[]);
    }
    if (limit) {
      setLimit(parseInt(limit as string));
    }
    if (heading) {
      setHeading(heading as string);
    }

    run(API.getPrograms(query));
  }, [run, search]);

  if (loading) {
    return <Loader active inline />;
  }

  if (error) {
    return <Message warning content={error} />;
  }

  return (
    <div style={{ padding: "1px" }}>
      {heading && <h3>{heading}</h3>}
      {programs.length === 0 && (
        <Message warning content="No upcoming programs of this type" />
      )}
      <ProgramCardGroup
        style={{ marginTop: 0 }}
        list={limit ? programs.slice(0, limit) : programs}
        embedEffect={embedEffect}
      />
    </div>
  );
};
