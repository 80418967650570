import { Link } from "react-router-dom";
import { LinkOrA } from "Routes/Programs/Calendar";
import { Button, Card, Header, Label, Loader } from "semantic-ui-react";
import defaultImg from "Shared/alder_cones.png";
import { ProgramDate } from "Shared/Date";

import { Program, Registration, RegistrationType } from "./Models";
import { Panel } from "./Panel";

type ProgramCardGroupProps = {
  style?: React.CSSProperties;
  title?: string;
  list: Program[];
  embedEffect?: () => void;
};

export const ProgramCardGroup: React.FC<ProgramCardGroupProps> = ({
  style,
  title,
  list,
  embedEffect,
}) =>
  list.length === 0 ? (
    <></>
  ) : (
    <>
      {title && <Header as="h4">{title}</Header>}
      <Card.Group style={style} itemsPerRow={3} stackable>
        {list.map((p) => (
          <ProgramCard key={p.id} program={p} embedEffect={embedEffect} />
        ))}
      </Card.Group>
    </>
  );

type RegistrationCardGroupProps = {
  title: string;
  loading: boolean;
  list: Registration[];
  embedEffect?: () => void;
};

export const RegistrationCardGroup: React.FC<RegistrationCardGroupProps> = ({
  title,
  loading,
  list,
  embedEffect,
}) => (
  <Panel icon="calendar check outline" heading={title}>
    {loading ? (
      <Loader inline="centered" active />
    ) : (
      <>
        {list.length > 0 ? (
          <Card.Group style={{ marginTop: 0 }} itemsPerRow={3} stackable>
            {list.map((r) => (
              <ProgramCard
                key={r.program.id}
                program={r.program}
                registration={r}
                embedEffect={embedEffect}
              />
            ))}
          </Card.Group>
        ) : (
          "None"
        )}
      </>
    )}
  </Panel>
);

type ProgramCardProps = {
  program: Program;
  registration?: Registration;
  embedEffect?: () => void;
};

const ProgramCard: React.FC<ProgramCardProps> = ({
  program,
  registration,
  embedEffect,
}) => {
  const i = (program.images || []).find((i) => {
    return i.id === program.og_image;
  }) || { url: defaultImg, title: "no image to display" };

  return (
    <Card>
      <div className="image">
        <img onLoad={embedEffect} src={i.url} alt={i.title} />
      </div>
      <Card.Content>
        <Card.Header>
          <LinkOrA p={program} targetBlank={embedEffect !== undefined}>
            {program.title}
          </LinkOrA>
        </Card.Header>
        <Card.Description>
          <ProgramDate program={program} />
          {program.registration_type === RegistrationType.MembersOnly && (
            <Label
              size="small"
              style={{ marginTop: "1em" }}
              content="Members Only"
            />
          )}
          {program.capacity > 0 && program.capacity <= program.participants && (
            <Label
              size="small"
              style={{ marginTop: "1em" }}
              icon="exclamation circle"
              color="orange"
              content="At Capacity"
            />
          )}
          {registration && registration.status === 1 && (
            <Label
              size="small"
              style={{ marginTop: "1em" }}
              icon="exclamation circle"
              color="orange"
              content="Canceled Registration"
            />
          )}
          {registration && registration.id > 0 && (
            <Button
              size="tiny"
              as={Link}
              style={{ marginTop: "1em" }}
              to={`/registrations/${registration.id}`}
            >
              Edit Registration
            </Button>
          )}
        </Card.Description>
      </Card.Content>
    </Card>
  );
};
