export const links = {
  zoom: 'https://us02web.zoom.us/j/5038939366?pwd=OWJFa1h4amVNS201NW1OTno3RXlOQT09',
}

export const labels = {
  zoom: 'Join on Zoom',
}

export const locations = {
  zoom: 'on Zoom',
  ac: 'at Alder Commons'
}
