import React, { useEffect, useState } from 'react'
import { Form } from 'semantic-ui-react'
import { ChangeHandler } from 'Shared/Hooks';
import { Program } from 'Shared/Models';
import { NumberField } from 'Shared/Number';
import { Panel } from 'Shared/Panel';
import { Staff } from 'Shared/Roles';
import Tooltip from 'Shared/Tooltip';

export const ProgramFormFacilitator = ({fields, handleChange}: {fields: Program, handleChange: ChangeHandler}) => {
  const {host_text, facilitator_compensation_plan, facilitator_compensation, facilitator_reimbursement, facilitator_paid} = fields;
  const [hasComp, setHasComp] = useState(Boolean(facilitator_compensation_plan || facilitator_compensation || facilitator_reimbursement || facilitator_paid))

  // clear comp fields if compensation is disabled
  useEffect(()=>{
    if (!hasComp) {
      handleChange(null, {
        name: 'facilitator_compensation_plan',
        value: ''
      })

      handleChange(null, {
        name: 'facilitator_compensation',
        value: 0
      })

      handleChange(null, {
        name: 'facilitator_reimbursement',
        value: 0
      })

      handleChange(null, {
        name: 'facilitator_paid',
        value: false
      })
    }
  }, [handleChange, hasComp])

  return <Panel heading='Facilitator / Host'>
  
    <Form.Field>
      <label>Host <Tooltip content="Who is the event host? Supports [markdown](https://go.com)." /></label>
      <Form.Input
        name='host_text'
        value={host_text}
        onChange={handleChange}
      />
    </Form.Field>

    <Staff>
      <Form.Checkbox
        label='Host will be compensated'
        checked={hasComp}
        onChange={(e, {checked})=>{
          setHasComp(!!checked);
        }}/>

      {hasComp && 
        <Form.Group widths='equal'>
          <NumberField
            label='Facilitator Minimum'
            innerLabel={{basic: true, content:'$'}}
            name='facilitator_compensation'
            value={facilitator_compensation}
            onChange={handleChange}
          />

          <NumberField
            label='Reimbursable Expenses'
            innerLabel={{basic: true, content:'$'}}
            name='facilitator_reimbursement'
            value={facilitator_reimbursement}
            onChange={handleChange}
          />

          <Form.Field>
            <label>Compensation Notes <Tooltip content="Leave this blank unless there is an unusual arrangement." /></label>
            <Form.TextArea
              name='facilitator_compensation_plan'
              value={facilitator_compensation_plan}
              onChange={handleChange}
            />
          </Form.Field>

          <Form.Field>
            <label>Paid</label>
            <Form.Checkbox
              toggle
              name='facilitator_paid'
              checked={facilitator_paid}
              onChange={handleChange}
            />
          </Form.Field>
        </Form.Group>}
    </Staff>
  </Panel>
}
