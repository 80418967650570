import API from 'Api'
import React, { useCallback } from 'react'
import { Button, DropdownItemProps, Form, Message } from 'semantic-ui-react'
import { useFields, useRequest } from 'Shared/Hooks'
import { User, UserRole } from 'Shared/Models'

type RoleFormProps = {
  user: User
}

const RoleForm: React.FC<RoleFormProps> = ({user}) => {
  const [loading, error, run, success] = useRequest(false)
  const {fields, handleChange} = useFields({
    role: user.role ? user.role : UserRole.Default
  })

  const handleSubmit = useCallback(() => {
    run(API.updateRole(user.id, fields))
  }, [run, user, fields])

  if (success) {
    return <Message positive>Role updated!</Message>
  }

  return (
    <Form error name="changeRole" loading={loading} onSubmit={handleSubmit} style={{maxWidth: '20em'}}>
      <Message error>{error}</Message>
      <Form.Select
        name='role'
        label='Role'
        value={fields.role}
        options={roleOptions}
        onChange={handleChange} />
      <Button primary type="submit">Update Role</Button>
    </Form>
  );
}

export default RoleForm;

const roleOptions: DropdownItemProps[] = [
  {
    key: 'Default',
    text: 'Default',
    value: UserRole.Default,
  },
  {
    key: 'Staff',
    text: 'Staff',
    value: UserRole.Staff,
  },
  {
    key: 'Admin',
    text: 'Admin',
    value: UserRole.Admin,
  },
  {
    key: 'Board',
    text: 'Board',
    value: UserRole.Board,
  }
];
