import API from 'Api'
import moment from 'moment-timezone'
import React, { useCallback, useEffect } from 'react'
import { Button, Loader, Message, Table } from 'semantic-ui-react'
import { useRequest } from 'Shared/Hooks'
import { Program, Task } from 'Shared/Models'
import TableHeaders from 'Shared/TableHeaders'
import { TaskDetails } from 'Shared/TaskDetails'
import { TaskStatusLabel } from 'Shared/TaskStatus'

export const ProgramTasks = ({program}: {program: Program}) => {
  const [loading, error, run, tasks] = useRequest<Task[]>([])

  const handleMore = () => {
    load(tasks[tasks.length-1].id);
  }

  const load = useCallback((id?: number) => {
    run(API.getTasks({id, program_id: program.id}));
  }, [run, program])

  useEffect(load, [load]);
  
  const headers = ['ID', 'Status', 'Date', 'Task', 'Details']

  return <>
    {error && <Message negative>{error}</Message>}
    <Table compact>
      <TableHeaders headers={headers}/>
      <Table.Body>
        { tasks.map(t => (
          <Table.Row key={t.id}>
            <Table.Cell collapsing content={t.id}/>
            <Table.Cell collapsing>
              <TaskStatusLabel task={t}/>
            </Table.Cell>
            <Table.Cell collapsing>
              {moment.utc(t.updated_at).format()}
            </Table.Cell>
            <Table.Cell>
              <Identify task={t}/>
            </Table.Cell>
            <Table.Cell collapsing>
              <TaskDetails task={t}/>
            </Table.Cell>
          </Table.Row>
        )) }
        {loading && <Table.Row key='loading' cells={[{colSpan:headers.length,content:<Loader active inline='centered'/>}]}/>}
      </Table.Body>
      <Table.Footer>
        {tasks.length === 50 && <Table.Row cells={[
          <Table.HeaderCell colSpan={headers.length} textAlign='center' content={
            <Button onClick={handleMore} content='More'/>
          }/>
        ]}/>}
      </Table.Footer>
    </Table>
  </>
}

const Identify = ({task}: {task: Task}) => {
  switch (task.name) {
  case 'email_registrant':
    return <>Emailed {task.data.email}</>
  default:
    return <>{task.name}</>
  }
}
