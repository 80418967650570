import { PersonContainer } from "Context/Person"
import { useContext } from "react"
import { Link } from "react-router-dom"
import { Grid } from "semantic-ui-react"
import { LibibPatron } from "Shared/LibibPatron"
import { MailerliteMemberStatus, MailerliteProgramUpdateStatus, MailerliteStatus } from "Shared/MailerliteStatus"
import { Person } from "Shared/Models"
import { OpenpathStatus } from "Shared/OpenpathStatus"
import { Panel } from "Shared/Panel"
import PhotoConsent from "Shared/PhotoConsent"
import { Admin } from "Shared/Roles"
import SlackStatus from "Shared/SlackStatus"

import { BackgroundCheckStatus } from "./BackgroundChecker"
import EditForm from "./EditForm"

export const Edit = () => {
  const {person} = useContext(PersonContainer);;

  return <>
    <Grid columns={2} stackable>
      <Grid.Column>
        <Panel icon='info' heading='Personal Info'>
          <EditForm/>
        </Panel>
      </Grid.Column>

      <Grid.Column>
        {person.user && person.user.email && <Newsletters person={person} /> }
        <Admin>
          <PersonAdmin person={person}/>
        </Admin>
      </Grid.Column>
    </Grid>
  </>
}


const Newsletters = ({person}: {person: Person}) => {
  const toggleRowStyle = {
    marginBottom: '0.5em'
  };

  return <Panel icon='mail' heading='Email Subscriptions'>
    <div style={toggleRowStyle}><MailerliteStatus user={person.user}/></div>
    <div style={toggleRowStyle}><MailerliteProgramUpdateStatus user={person.user}/></div>
    {person.is_member && <div style={toggleRowStyle}><MailerliteMemberStatus user={person.user}/></div> }
  </Panel>
}

const toggleRowStyle = {
  marginBottom: '0.5em'
};

const PersonAdmin = ({person}: {person: Person}) => {
  return <Panel icon='lock' heading='Admin'>
    <div style={toggleRowStyle}>
      <Link to={`/person/${person.id}/security`}>Background</Link>&nbsp;
      <BackgroundCheckStatus person={person}/>
    </div>
    <div style={toggleRowStyle}><PhotoConsent person={person}/></div>
    {person.user && person.user.id > 0 && <>
      <div style={toggleRowStyle}><OpenpathStatus user={person.user}/></div>
      <div style={toggleRowStyle}><SlackStatus user={person.user}/></div>
      <div style={toggleRowStyle}><LibibPatron user={person.user}/></div>
    </>}
  </Panel>
}
