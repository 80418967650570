import API from "Api";
import { UserContainer } from "Context/User";
import moment from "moment";
import { useCallback, useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { RegistrationStatusLabel, StewardIcon } from "Routes/Program/ProgramRegistrations";
import { CancellationModal } from "Routes/Program/ProgramView";
import { Button, Header, Message } from "semantic-ui-react";
import { ProgramDate } from "Shared/Date";
import Dollars from "Shared/Dollars";
import { useRequest } from "Shared/Hooks";
import { Payment, Program, Registration, RegistrationStatus, RegistrationType } from "Shared/Models";
import { refundAmount } from "Shared/PaymentTable";
import { Staff, staffCheck } from "Shared/Roles";
import SimplePage from "Shared/SimplePage";

const EditRegistration = () => {
  const {id, code, nonce} = useParams<{id: string, code: string, nonce: string}>();
  const {user} = useContext(UserContainer);
  const [loading, error, run, reg] = useRequest<Registration>({
    program: {} as Program,
    payment: {} as Payment,
  } as Registration, {loading: true});

  const load = useCallback(()=>{
    run(API.getRegistration(Number(id), code, nonce))
  }, [run, id, code, nonce])

  useEffect(load, [load]);

  // render conditionals
  const isInFuture = reg && reg.program && moment.utc(reg.program.end_date).add(7, 'hours').isAfter(moment.utc());
  const hasPayment = reg && reg.payment && reg.payment.amount > 0;
  const hasRefund = reg && reg.payment && reg.payment.refunds && reg.payment.refunds.length > 0;
  const isNotDonation = reg && reg.program && (reg.program.registration_type === RegistrationType.PaymentRequired || reg.program.registration_type === RegistrationType.PaymentOptional || reg.program.registration_type === RegistrationType.MembersDiscounted);
  const isStaffOrAdmin = staffCheck(user);
  const canCancel = reg && reg.status === 0 && (isInFuture || isStaffOrAdmin);
  const canActivate = reg && reg.status !== 0 && isStaffOrAdmin;
  const beforeRefundBlock = reg && reg.program && moment.utc(reg.program.start_date).add(7, 'hours').add(-7, 'days').isAfter(moment.utc());
  const canRefund = hasPayment && !hasRefund && ((isNotDonation && beforeRefundBlock)  || isStaffOrAdmin);

  return (
    <SimplePage icon='edit outline' title={'Edit Registration'} loading={loading} error={error}>
      { reg && 
        <>
          <Header as='h4'>
            <Link to={`/program/${reg.program.slug}`}>
              {reg.program.title}
            </Link>
          </Header>
          <ProgramDate program={reg.program}/>

          {reg.status === RegistrationStatus.Canceled && 
            <>
              <Header as='h4' content='Registration Status'/>
              <div style={{marginBottom: '1em'}}>
                <RegistrationStatusLabel status={reg.status}/>
              </div>
            </> }

          <Header as='h4'>
            {reg.participants_full && reg.participants_full.length > 1 ? 'Participants' : 'Registrant'}
            {reg.steward && <> <StewardIcon/></>}
          </Header>
          <div>
            {reg.participants_full ? reg.participants_full.map(p=>(
              <span key={p.id} style={{marginRight: '1em'}}>{p.first} {p.last}</span>
            )) : 'No Participants?!'}
          </div>

          {hasPayment && <>
            <Header as='h4' content={isNotDonation ? 'Payment' : 'Donation'}/>
            <p>
              <Dollars amount={reg.payment.amount/100}/>
              {hasRefund && 
                <> &nbsp; (<Dollars amount={refundAmount(reg.payment.refunds)/100}/> refunded)</>
              }
            </p>
          </>}

          <div>
            {canCancel && <CancelRegButtons code={code} nonce={nonce} registration={reg} hasPayment={hasPayment} canRefund={canRefund} onSuccess={load}/> }
            {canActivate && <ActivateRegButton registration={reg} onSuccess={load}/> }
            <Staff>
              <ToggleSteward registration={reg} onSuccess={load}/>
            </Staff>
          </div>
        </>
      }
    </SimplePage>
  );
}

const CancelRegButtons = ({registration, onSuccess, code, nonce, hasPayment, canRefund}: {registration: Registration, onSuccess: ()=>void, code: string, nonce: string, hasPayment: boolean, canRefund: boolean}) => {
  const [loading, error, run] = useRequest(false)

  const cancel = useCallback(() => {
    run(API.updateRegistrationStatus(registration.id, 1, code, nonce), onSuccess)
  }, [run, registration, code, nonce, onSuccess])

  const cancelWithRefund = useCallback(() => {
    run(API.updateRegistrationStatus(registration.id, 1, code, nonce, true), onSuccess)
  }, [run, registration, code, nonce, onSuccess])

  if (error) {
    return <Message negative content={error}/>
  }

  return <>
    {hasPayment && !canRefund && 
      <p>This registration is no longer <CancellationModal triggerText='eligible for a refund'/>.</p>}
    <Button
      negative
      disabled={loading}
      size='tiny'
      style={{marginTop:'1em'}}
      content={'Cancel Registration'}
      onClick={cancel} />
    {canRefund && <Button
      disabled={loading}
      size='tiny'
      style={{marginTop:'1em'}}
      content={'Cancel Registration with Refund'}
      onClick={cancelWithRefund} />}
  </>
}

const ActivateRegButton = ({registration, onSuccess}: {registration: Registration, onSuccess: ()=>void}) => {
  const [loading, error, run] = useRequest(false)
  const activate = useCallback(() => {
    run(API.updateRegistrationStatus(registration.id, 0), onSuccess)
  }, [run, registration, onSuccess])

  if (error) {
    return <Message negative content={error}/>
  }

  return <Button positive disabled={loading} size='tiny' style={{marginTop:'1em'}} content='Activate Registration' onClick={activate} />
}

const ToggleSteward = ({registration, onSuccess}: {registration: Registration, onSuccess: ()=>void}) => {
  const [loading, error, run] = useRequest(false)
  const updateSteward = useCallback(() => {
    run(API.updateRegistrationSteward(registration.id, !registration.steward), onSuccess)
  }, [run, registration, onSuccess])

  if (error) {
    return <Message negative content={error}/>
  }

  return <Button disabled={loading} size='tiny' title='Toggle Steward' icon={<StewardIcon/>} onClick={updateSteward} />
}

export default EditRegistration;
