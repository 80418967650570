import API from "Api";
import { HouseholdContainer } from "Context/Household";
import { useCallback, useContext, useEffect } from "react";
import { Form, Loader, Message } from "semantic-ui-react";
import { useRequest } from "Shared/Hooks";
import { Person, Signature, User } from "Shared/Models";

export const HouseholdPersonSignatures = ({person}: {person: Person}) => {
  const {household} = useContext(HouseholdContainer);
  const [loading, error, run, sigs] = useRequest<Signature[]>([])

  useEffect(()=>{
    if (person.user && person.user.id) {
      run(API.getSignaturesForUser(household.id, person.user.id))
    }
  }, [run, household.id, person.user])

  if (loading) {
    return <Loader inline active/>
  }

  return <>
    {error && <Message negative>{error}</Message>}
    {sigs.length === 0 && 'No signatures'}
    {sigs.length < 3 && sigs.length > 0 && 'Missing signatures'}
    {sigs.length === 3 && 'All signatures complete'}
    {sigs.length < 3 && person.user && person.user.id > 0 && <InviteToSign user={person.user}/>}
  </>
}

const InviteToSign = ({user}: {user: User}) => {
  const {household} = useContext(HouseholdContainer);
  const [loading, error, run, invited] = useRequest<{success: boolean}>({success: false})

  const handleSubmit = useCallback(()=>{
    run(API.inviteToSign(household.id, user.id))
  }, [user, household, run])

  if (invited.success) {
    return <Message size='mini' positive content='Invitation sent'/>
  }

  return <Form error loading={loading} onSubmit={handleSubmit}>
    <Message error content={error}/>
    <Form.Button size='mini' content='Invite to sign Membership docs'/>
  </Form>
}
