import { UserContainer } from 'Context/User'
import React, { ComponentType, useContext } from 'react'
import { Redirect, RouteProps, useLocation } from 'react-router'
import { Route } from 'react-router-dom'
import { Container, Dimmer, Loader } from 'semantic-ui-react'
import { User, UserStatus } from 'Shared/Models'
import { staffCheck } from 'Shared/Roles'
import SimplePage from 'Shared/SimplePage'

export const PrivateRoute = (props: Omit<LimitedRouteProps, 'check'>) => (
  <LimitedRoute {...props} check={(user)=>(user.id > 0)} />
);

export const MemberRoute = (props: Omit<LimitedRouteProps, 'check'>) => (
  <LimitedRoute {...props} check={(user)=>{
    if (staffCheck(user)) {
      return true;
    }
    if (user && user.id > 0 && user.status === UserStatus.Active && user.person.is_member) {
      return true;
    }
    return false;
  }} />
);

export const AdminRoute = (props: Omit<LimitedRouteProps, 'check'>) => (
  <LimitedRoute {...props} check={(user)=>(user.role === 2)} />
);

// Staff OR Admin
export const StaffRoute = (props: Omit<LimitedRouteProps, 'check'>) => (
  <LimitedRoute {...props} check={staffCheck} />
);

type LimitedRouteProps = {
  check: (user: User) => boolean,
  component?: ComponentType<any>,
  render?: () => JSX.Element,
  path: string,
} & RouteProps

export const LimitedRoute: React.FC<LimitedRouteProps> = ({ check, component, render, path, ...routeProps }) => {
  const location = useLocation();
  const {user, userLoading} = useContext(UserContainer)
  return (
    <Route {...routeProps} render={() => {
      if (userLoading) {
        return <BigLoader/>
      }

      // if they fail the check, attempt to log them in
      if (!check(user)) {
        if (user.id > 0) {
          return <NoMatch/>
        } else {
          return (
            <Redirect to={{
              pathname: '/login',
              state: { from: location }
            }} />
          );
        }
      }

      if (render) {
        return render();
      }

      const C = component as React.ComponentType;
      return <C/>
    }} />
  )
}

export const NoMatch = () => (
  <SimplePage icon='ban' title='Page Not Found'>
    <p>The page you are trying to view does not exist!</p>
  </SimplePage>
)

const BigLoader = () => (
  <Container>
    <Dimmer active inverted>
      <Loader size="big"/>
    </Dimmer>
  </Container>
)
