import { capitalize } from 'lodash'
import React from 'react'
import { Label, SemanticCOLORS } from 'semantic-ui-react'

import { Subscription, SubscriptionStatus } from './Models'
import { NotStaff, Staff } from './Roles'

type SubscriptionStatusLabelProps = {
  sub: Subscription
  style?: Record<string, any>
}

export const SubscriptionStatusLabel: React.FC<SubscriptionStatusLabelProps>= ({sub, style}) => {
  if (!sub || !sub.status) {
    return <Label style={style} size='tiny' color='red' content='Autopay Not Configured'/>
  }

  return <>
    <Staff>
      <Label style={style} size='tiny' color={colorMap[sub.status]} content={`Autopay ${capitalize(sub.status)}`}
        as='a' href={`https://dashboard.stripe.com/subscriptions/${sub.id}`} />
    </Staff>
    <NotStaff>
      <Label style={style} size='tiny' color={colorMap[sub.status]} content={`Autopay ${capitalize(sub.status)}`}/>
    </NotStaff>
  </>
}

const colorMap: Record<SubscriptionStatus, SemanticCOLORS> = {
  [SubscriptionStatus.Active]: 'green',
  [SubscriptionStatus.PastDue]: 'yellow',
  [SubscriptionStatus.Unpaid]: 'yellow',
  [SubscriptionStatus.Canceled]: 'red',
  [SubscriptionStatus.Incomplete]: 'yellow',
  [SubscriptionStatus.IncompleteExpired]: 'yellow',
  [SubscriptionStatus.Trialing]: 'yellow',
}

