import { Person } from "./Models";

export const personName = (person: Person) => {
  if (!person) {
    return 'Uknown Person'
  }
  const nickname = person.nickname ? ` "${person.nickname}" `: ' ';
  return `${person.first}${nickname}${person.last}`;
}

export const PersonName = ({person}: {person: Person}) => {
  return <>{personName(person)}</>
}
