import API from "Api";
import { SyntheticEvent, useCallback, useRef, useState } from "react";
import ReactCrop, { centerCrop, Crop, makeAspectCrop } from "react-image-crop";
import { Redirect, useParams } from "react-router";
import { Form, Grid, Message } from "semantic-ui-react";
import { useRequest } from "Shared/Hooks";
import { ProfileImage } from "Shared/Models";
import { Panel } from "Shared/Panel";
import SimplePage from "Shared/SimplePage"

export const MemberProfileEditImage = () => {
  const {id} = useParams<{id: string}>()
  const personID = parseInt(id, 10)
  const [redirect, setRedirect] = useState('');
  if (redirect) {
    return <Redirect to={redirect}/>
  }

  return <SimplePage icon='photo' title={'Upload Profile Image'}>
    <ProfileImageForm personID={personID} onSuccess={()=>{
      setRedirect('/members/' + personID)
    }}/>
  </SimplePage>
}

type ProfileImageFormProps = {
  personID: number
  onSuccess?: () => void
}

const finalWidth = 500;
const finalHeight = 500;
const aspectRatio = finalWidth / finalHeight;

const ProfileImageForm: React.FC<ProfileImageFormProps> = ({personID, onSuccess}) => {
  const [loading, error, run, , setError] = useRequest({} as ProfileImage);

  const [result, setResult] = useState('')
  const [src, setSrc] = useState('');

  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    width: 100,
    height: 100,
    x: 0,
    y: 0,
  })

  const fileRef = useRef<HTMLInputElement | null>(null)
  const imgRef = useRef<HTMLImageElement | null>(null)

  const handleFileChange = () => {
    if (fileRef.current && fileRef.current.files) {
      setSrc(URL.createObjectURL(fileRef.current.files[0]));
    }
  }

  const handleCropComplete = (crop: Crop, percentCrop: Crop) => {
    setCrop(percentCrop)

    if (fileRef.current && fileRef.current.files && fileRef.current.files[0]) {
      const { type } = fileRef.current.files[0];
      getCroppedImg(imgRef.current, percentCrop, type).then((blob: Blob | unknown) => {
        setResult(URL.createObjectURL(blob as Blob));
      });
    }
  }

  const handleLoad = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    imgRef.current = e.currentTarget;
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
          height: 90
        },
        aspectRatio,
        width,
        height
      ),
      width,
      height
    )
  
    handleCropComplete({} as Crop, crop)
  }

  const handleCrop = (crop: Crop, percentCrop: Crop) => {
    setCrop(percentCrop);
  }

  const handleSubmit = useCallback(() => {
    if (!fileRef.current || !fileRef.current.files) {
      return setError('No file selected for upload.');
    }

    const { name, type } = fileRef.current.files[0];

    run(getCroppedImg(imgRef.current, crop, type)
      .then(blob => {
        return API.createProfileImage(personID, blob as Blob, name)
      }), 
    () => {
      if (onSuccess) {
        onSuccess();
      }
    }
    )
  }, [run, personID, onSuccess, crop, setError]);

  return <Form error name="profile_image" loading={loading} onSubmit={handleSubmit}>
    <Message error>{error}</Message>
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column>
          <Panel heading='Chose an image'>
            <Form.Field required>
              <label>Image formats: .png or .jpg</label>
              <input type='file' accept="image/png,image/jpeg" onChange={handleFileChange} ref={fileRef}/>
            </Form.Field>
            {src && <ReactCrop
              aspect={aspectRatio}
              crop={crop}
              keepSelection={true}
              onChange={handleCrop} 
              onComplete={handleCropComplete}>
              <img alt='crop' src={src} onLoad={handleLoad}/>
            </ReactCrop>}
          </Panel>
        </Grid.Column>
        <Grid.Column>
          <Panel heading='Crop Preview'>
            { result ? <>
              <img style={{maxWidth:'100%'}} alt='Crop Result' src={result}/>
              <Form.Button primary>Upload</Form.Button>
            </> : 'No image selected yet...'}
          </Panel>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Form>
}

function getCroppedImg(image: unknown, crop: Crop, type: string) {
  const img = image as HTMLImageElement;
  const canvas = document.createElement('canvas');
  const browserScaleFactor = finalWidth / img.width;
  const scaleX = img.naturalWidth / img.width;
  const scaleY = img.naturalHeight / img.height;
  canvas.width = finalWidth;
  canvas.height = finalHeight;
  const cropWidth = (crop.width/100) * img.width || 100;
  const cropHeight = (crop.height/100) * img.height || 100;
  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(
      img,
      (crop.x/100 || 0) * img.width * scaleX,
      (crop.y/100 || 0) * img.height * scaleY,
      cropWidth * scaleX,
      cropHeight * scaleY,
      0,
      0,
      cropWidth * browserScaleFactor * (img.width / cropWidth),
      cropHeight * browserScaleFactor * (img.width / cropWidth),
    );
  }

  return new Promise((resolve, reject) => {
    canvas.toBlob(resolve, type);
  });
}
