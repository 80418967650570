
import API from 'Api'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Button, Form, Loader, Message, Segment } from 'semantic-ui-react'
import { useFields, useRequest } from 'Shared/Hooks'
import { Markdown } from 'Shared/Markdown'
import { FileType, Signature, User } from 'Shared/Models'
import { Panel } from 'Shared/Panel'
import { personName } from 'Shared/PersonName'

import { filesByLang, lang } from './agreements'

type SignDocumentsProps = {
  household_id: number,
  onSuccess: (u: User)=>void
  code?: string
  nonce?: string
}

const SignDocuments: React.FC<SignDocumentsProps>= ({household_id, onSuccess, code, nonce}) => {
  const {fields, handleChange} = useFields({
    [FileType.Waiver]: false,
    [FileType.MutualAgreements]: false,
    [FileType.TermsOfUse]: false,
  })
  const [loading, error, run] = useRequest<Signature>({} as Signature);
  const [loadingInit, errorInit, runInit, sigResponse] = useRequest({
    user: {} as User,
    signatures: [] as Signature[]
  }, {loading: true});
  const [lang, setLang] = useState<lang>('en')

  const {user} = sigResponse;

  const handleSubmit = useCallback(() => {
    run(API.createSignature(household_id, code, nonce), ()=> onSuccess(user));
  }, [run, household_id, code, nonce, onSuccess, user])

  useEffect(()=>{
    runInit(API.getSignature(household_id, code, nonce))
  }, [runInit, household_id, code, nonce])

  const files = filesByLang[lang];
  const agreesToAll = files.every(({type}) => fields[type]);

  if (loadingInit) {
    return <Loader inline active/>
  }
  if (errorInit) {
    return <Message negative>{errorInit}</Message>
  }
  if (sigResponse && sigResponse.signatures && sigResponse.signatures.length === 3) {
    return <>
      <Message positive>You've already signed your Membership documents!</Message>
      <Button onClick={()=> onSuccess(user)}>Next</Button>
    </>
  }

  return <>
    <Message info>You must agree to our Mutual Agreements, Terms of Use, and Waiver, before we activate your Membership.</Message>
    <Form error name="documents" loading={loading} onSubmit={handleSubmit}>
      <Message error>{error}</Message>
      <Form.Checkbox label='Prefiero los documentos en Español' onChange={()=>setLang(lang === 'en' ? 'es' : 'en')}/>

      {files.map(({title, type, body}) => (
        <Fragment key={title}>
          <Panel heading={title} footer>
            <Markdown text={body}/>
          </Panel>
          <Segment size='mini' attached='bottom' style={{background: fields[type] ? '#e7f9de' : '#ffdabf'}}>
            <Form.Checkbox
              label={`${lang === 'en' ? 'I agree to the ' : 'Estoy de acuerdo con '}"${title}"`}
              onChange={handleChange}
              name={String(type)} 
              checked={fields[type]}/>
          </Segment>
        </Fragment>
      ))}
      
      <Button disabled={!agreesToAll} primary>{lang === 'en' ? `I, ${personName(user.person)}, agree to all Membership Documents` : `Yo, ${personName(user.person)}, Estoy de acuerdo con todos los documentos de membresía`}</Button>
    </Form>
  </>
}

export default SignDocuments;
