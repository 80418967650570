import API from 'Api'
import { UserContainer } from 'Context/User'
import qs from 'qs'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { Redirect } from 'react-router-dom'
import { Message } from 'semantic-ui-react'
import { useRequest } from 'Shared/Hooks';
import { User } from 'Shared/Models'
import SimplePage from 'Shared/SimplePage'

const Verify = () => {
  const { code } = useParams<{code: string}>();
  const location = useLocation();
  const [loading, error, run, result] = useRequest<User>({} as User)
  const [redirect, setRedirect] = useState('')
  const [doRedirect, setDoRedirect] = useState(false)
  const [verifying, setVerifying] = useState(false)
  const { user, userLoading, setUser } = useContext(UserContainer)

  useEffect(()=>{
    const { redirect } = qs.parse(location.search,  { ignoreQueryPrefix: true });
    if (redirect) {
      setRedirect(redirect as string);
    }
  }, [location])

  useEffect(() => {
    if (!userLoading) {
      // wait until defailt whoami returns before attempting reset

      if (user.id && !verifying) {
        // they're already logged in as someone, probably they need to go to onboarding
        setDoRedirect(true);
      } else if (!verifying) {
        setVerifying(true);
        run(API.verify({code}), (user) => {
          setUser(user);
          setTimeout(() => {
            setDoRedirect(true);
          }, 2500);
        })
      }
    }
  }, [run, user, setUser, setRedirect, code, userLoading, verifying])

  if (doRedirect) {
    return <Redirect to={{
      pathname: redirect,
      state: {
        justVerified: true
      }
    }}/>
  }

  return (
    <SimplePage title='Account Verification' centered loading={loading} error={error}>
      {result && result.id && <Message positive>Success! You have verified your email! Redirecting...</Message>}
    </SimplePage>
  );
}

export default Verify;
