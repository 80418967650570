import { Link } from "react-router-dom";
import { Button, Label } from "semantic-ui-react";
import { Tag } from "Shared/Models";

type ProfileTagsProps = {
  tags: Tag[]
  noLink?: boolean
} 

export const ProfileTags: React.FC<ProfileTagsProps> = ({tags, noLink = false}) => (
  <>
    {tags.map(tag => {
      return <ProfileTag key={tag.id} tag={tag} noLink={noLink}/>
    })}
  </>
)

const ProfileTag = ({tag, noLink}: {tag: Tag, noLink: boolean}) => {
  if (noLink) {
    return <Label
      size='mini'
      style={{
        marginBottom: '0.25em'
      }}
      content={tag.label}
    />
  }
  return <Link to={`/members/?tags=${tag.id}`}>
    <Button
      size='mini'
      style={{
        marginBottom: '0.5em'
      }}
      content={tag.label}
    />
  </Link>
}
