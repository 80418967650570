import API from "Api";
import { PersonContainer } from "Context/Person";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { MemberCodes } from "Routes/Admin/Codes";
import { ProfileChecker } from "Routes/Members/ProfileChecker";
import { Grid, List, Message } from "semantic-ui-react";
import { useRequest } from "Shared/Hooks";
import { MemberResources } from "Shared/MemberResources";
import { HouseholdPerson, Person, UserStatus } from "Shared/Models";
import { Panel } from "Shared/Panel";
import { SingleLinePlaceholder } from "Shared/Placeholders";
import { ProfileFields } from "Shared/ProfileField";

const Dashboard = () => {
  const { person } = useContext(PersonContainer);

  return (
    <>
      {(!person.user || !person.user.id) && (
        <Message color="orange">
          Some functions aren't available until this person has an email address
          tied to their profile. You can add an email from the edit tab.
        </Message>
      )}
      {person.user &&
        person.user.status === UserStatus.Unverified &&
        person.user.id > 0 && (
        <Message color="orange">
          This account doesn't have a verified email address. Use "Reset
          Password" functionality to send a new verification email.
        </Message>
      )}

      {person.is_member && <ProfileChecker person={person} />}

      <Grid columns={2} stackable>
        <Grid.Column>
          <ProfileFields person={person} />
          <HouseholdLinks person={person} />
        </Grid.Column>
        <Grid.Column>
          {person.is_member && (
            <>
              <MemberResources />
              <MemberCodesPanel />
            </>
          )}
        </Grid.Column>
      </Grid>
    </>
  );
};

export default Dashboard;

const HouseholdLinks = ({ person }: { person: Person }) => {
  const [loading, error, run, householdPeople] = useRequest<HouseholdPerson[]>(
    []
  );

  useEffect(() => {
    if (person.id) {
      run(API.getPersonHouseholds(person.id));
    }
  }, [run, person]);

  return (
    <Panel
      icon="star"
      heading={"My Membership" + (householdPeople.length > 1 ? "s" : "")}
    >
      {loading && <SingleLinePlaceholder />}
      {error && <Message warning>{error}</Message>}
      {householdPeople.length === 0 ? (
        <p>
          <a href="mailto:hello@aldercommons.org">Contact us</a> if you'd like
          to become a Member.
        </p>
      ) : (
        <List>
          {householdPeople.map(({ household }) => (
            <List.Item key={household.id}>
              <List.Icon name={household.is_org ? "building" : "home"} />
              <Link to={`/household/${household.id}`}>{household.name}</Link>
            </List.Item>
          ))}
        </List>
      )}
    </Panel>
  );
};

const MemberCodesPanel = () => (
  <Panel icon="unlock alternate" heading="Lock Combinations" noSegment>
    <MemberCodes attached="bottom" />
  </Panel>
);
