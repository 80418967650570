import API from "Api";
import { useEffect } from "react";
import { useParams } from "react-router";
import { useRequest } from "Shared/Hooks";
import { Profile } from "Shared/Models";
import SimplePage from "Shared/SimplePage"

import { ProfileForm } from "./ProfileForm";

export const MemberProfileEdit = () => {
  const {id} = useParams<{id: string}>()
  const personID = parseInt(id, 10)

  const [loading, error, run, profile] = useRequest<Profile|false>(false)

  useEffect(()=>{
    run(API.getProfile(personID))
  }, [run, personID])

  if (!profile) {
    return <SimplePage icon='user' title='...' loading={loading} error={error}/>
  }

  return <SimplePage icon='user' title={profile.name} loading={loading} error={error}>
    <ProfileForm profile={profile}/>
  </SimplePage>
}
