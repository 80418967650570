import API from 'Api'
import React, { useCallback } from 'react'
import { Button, Form, Message } from 'semantic-ui-react'
import { useFields, useRequest } from 'Shared/Hooks'
import { Household } from 'Shared/Models'
import { HouseholdPerson } from 'Shared/Models'
import { PeopleChooser } from 'Shared/PeopleChooser'

type AddToGroupProps = {
  household: Household
  onSuccess?: ()=>void
}
export const AddToGroup: React.FC<AddToGroupProps> = ({household, onSuccess}) => {
  const [loading, error, run] = useRequest({} as HouseholdPerson)
  const {fields, handleChange, setFields} = useFields({
    person_id: undefined
  })
    
  const handleSubmit = useCallback(() => {
    if (!fields.person_id) {
      return
    }

    run(API.attachHouseholdPerson(household.id, fields.person_id), () => {
      setFields({person_id: undefined})
      if (onSuccess) {
        onSuccess();
      }
    })
  }, [run, household, fields, onSuccess, setFields])

  const { person_id } = fields;
  return <Form error name="add-to-group" loading={loading} onSubmit={handleSubmit}>
    <Message error>{error}</Message>
    <PeopleChooser
      name='person_id'
      icon='search'
      placeholder='Person Name'
      value={person_id}
      style={{maxWidth: '14em'}}
      onChange={handleChange}
    />
    <Button type="submit">Add Existing Person to Group</Button>
  </Form>
}
