import { Form, SelectProps } from 'semantic-ui-react'
import { Reservable } from 'Shared/Models';

export const ReservableSelect = (props: Omit<SelectProps, 'options'>) => {
  return <Form.Select
    options={Object.keys(ReservablesWithCapactiy).map((key) => {
      return {
        key,
        value: key,
        text: ReservablesWithCapactiy[key as Reservable]
      }
    })}
    {...props}
    multiple /> 
}

export const Reservables: Record<Reservable, string> = {
  'kitchen': 'Kitchen',
  'woodshop': 'Woodshop',
  'lounge': 'Lounge',
  'meeting_room': 'Meeting Room',
  'library': 'Library',
  'coworking': 'Coworking',
  'parking_lot': 'Parking Lot',
  'backyard': 'Backyard',
  'play_room': 'Play Room'
};

export const ReservablesWithCapactiy: Record<Reservable, string> = {
  'kitchen': 'Kitchen (7-max)',
  'woodshop': 'Woodshop (20-max)',
  'lounge': 'Lounge (30-max)',
  'meeting_room': 'Meeting Room (6-max)',
  'library': 'Library (20-max)',
  'coworking': 'Coworking (20-max)',
  'parking_lot': 'Parking Lot (150-max)',
  'backyard': 'Backyard (100-max)',
  'play_room': 'Play Room (20-max)'
};

