import API from "Api";
import { PersonContainer } from "Context/Person";
import moment from "moment";
import { useCallback, useContext } from "react";
import { Form, Message } from "semantic-ui-react"
import { useFields, useRequest } from "Shared/Hooks"
import { NumberField } from "Shared/Number";

export const DonationForm = () => {
  const {person} = useContext(PersonContainer);
  const {user} = person;
  const {fields, handleChange} = useFields({
    user_id: user.id,
    received: new Date().toISOString().split('T')[0], // today
    amount: 0,
    memo: null
  })

  const [loading, error, run, result] = useRequest({success: false});

  const handleSubmit = useCallback(() => {
    run(API.createDonationRecord({
      ...fields,
      received: moment.utc(fields.received, 'YYYY-MM-DD').format()
    }))
  }, [run, fields]);

  const {received, amount, memo} = fields;

  if (result && result.success) {
    return <Message success>Non-Stripe Donation Saved</Message>
  }

  return <Form loading={loading} error onSubmit={handleSubmit}>
    <Message error>{error}</Message>
    <Form.Input 
      required
      type='date'
      label='Received'
      value={received}
      name='received'
      style={{maxWidth: '12em'}}
      onChange={handleChange}/>
    <NumberField
      required
      label='Amount'
      name='amount'
      value={amount}
      style={{maxWidth: '12em'}}
      onChange={handleChange}/>
    <Form.Select 
      required
      label='Source'
      options={DonationOptions}
      value={memo || ''}
      name='memo'
      onChange={handleChange}/>

    <Form.Button content='Save'/>
  </Form>
}

const DonationOptions = [
  'Cash Donation',
  'Paypal Donation',
  'Venmo Donation',
  'Check Donation',
].map((type) => {
  return {
    key: type,
    value: type,
    text: type
  };
});
