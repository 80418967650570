import React from 'react'
import { Link } from 'react-router-dom'
import { Label, LabelProps } from 'semantic-ui-react'

import Age from './Age'
import { Person } from './Models';
import { PersonName } from './PersonName';

export default function PersonLabel({person, link, labelProps}: {person: Person, link?: boolean, labelProps?: LabelProps}) {
  if (!person) {
    return <></>;
  }

  const label = <Label {...labelProps} size="tiny">
    <PersonName person={person}/>
    <Label.Detail><Age person={person}/></Label.Detail>
  </Label>;

  if (link) {
    return <Link to={`/person/${person.id}`}>{label}</Link>
  } else {
    return label;
  }
};
