import API from 'Api'
import React, { FormEvent, useEffect, useState } from 'react'
import { Button, Checkbox, CheckboxProps, Label, Loader, Modal } from 'semantic-ui-react';

import { useRequest } from './Hooks';
import { Person } from './Models';


const PhotoConsent = ({person, reload}: {person: Person, reload?: boolean}) => {
  const [value, setValue] = useState(person.photo_consent);

  const init = () => {
    if (!reload) {
      return Promise.resolve(value)
    } else {
      return API.getPerson(person.id).then((p: Person)=>{
        setValue(p.photo_consent);
        return p.photo_consent;
      })
    }
  }

  const consent = () => {
    return API.updatePersonPhotoConsent({id: person.id, photo_consent: 2}).then(()=>{
      setValue(2);
    });
  }

  const unconsent = () => {
    return API.updatePersonPhotoConsent({id: person.id, photo_consent: 1}).then(()=>{
      setValue(1);
    })
  }

  return <PhotoToggler
    init={init}
    onCheck={consent}
    onUncheck={unconsent}
    reload={reload}
  />
}

export default PhotoConsent;

type PhotoTogglerProps = {
  label?: string
  init: () => Promise<number>
  onCheck: () => Promise<any>
  onUncheck: () => Promise<any>
  reload?: boolean
}

// variation on Toggler:
const PhotoToggler: React.FC<PhotoTogglerProps> = ({ init, onCheck, onUncheck, reload}) => {
  const [checked, setChecked] = useState<boolean|undefined>(undefined)
  const [loading, error, run] = useRequest({}, {loading: !!reload});

  useEffect(()=>{
    run(init(), (value) => {
      setChecked(value === 0 ? undefined : value === 2 ? true : false)
    })
  }, [run, init])

  const handleChange = (e: FormEvent<HTMLInputElement>, {checked}: CheckboxProps) => {
    if (checked) {
      run(onCheck(), ()=>{
        setChecked(true)
      })
    } else {
      run(onUncheck(), ()=>{
        setChecked(false)
      })
    }
  }

  return <>
    <Checkbox
      label={checked === undefined ? 'Unknown Photo Consent' : checked ? 'Accepted Photo Consent' : 'Rejected Photo Consent'}
      checked={checked}
      indeterminate={checked === undefined}
      disabled={loading}
      onChange={handleChange}/>
    <Loader style={{left:'0.5em'}} size='tiny' inline active={loading}/>
    {error && <Label size='tiny' color='red'>{error}</Label>}
  </>
}

export const PhotoTermsModal = ({trigger = <Button type='button' size='tiny'>View photo use agreement</Button>}: {trigger?: React.ReactNode}) => (
  <Modal closeIcon='close' trigger={trigger}>
    <Modal.Header>Photo Consent Agreement</Modal.Header>
    <Modal.Content>
      We may take photos and videos of you (and others) while you are participating in our services, and we would like your permission to use photos and videos of you on our website and in our marketing, advertising, promotional, and other content and materials (both online and offline). We will not identify you (by name), but anyone who views the photo or video may be able to identify you. Additionally, if you let us use photos and videos of you, you acknowledge that we will not (and do not have to) pay or compensate you in any way. Please check the box if you give us permission to use photos and videos of you.
    </Modal.Content>
  </Modal>
)
