import API from 'Api'
import React, {  useCallback, useRef } from 'react'
import { Button, Form, Message } from 'semantic-ui-react'
import { FileStatusOptions } from 'Shared/FileStatuses';
import { FileTypeOptions } from 'Shared/FileTypes'
import { useFields, useRequest } from 'Shared/Hooks';
import { HouseholdFile } from 'Shared/Models';

type FileFormProps = {
  groupID: number
  onSuccess?: ()=>void
}

const FileForm : React.FC<FileFormProps>= ({groupID, onSuccess}) => {
  const fileRef = useRef<HTMLInputElement | null>(null)
  const [loading, error, run, , setError] = useRequest(false);
  const {fields, handleChange, setFields} = useFields({} as HouseholdFile)

  const handleSubmit = useCallback(() => {
    if (!(fileRef.current && fileRef.current.files)) {
      setError('No file selected for upload.');
      return
    }

    run(API.createHouseholdFile(groupID, fileRef.current.files[0], fields), () => {
      setFields({} as HouseholdFile)
      if (fileRef.current) {
        fileRef.current.value = '';
      }
      if (onSuccess) {
        onSuccess();
      }
    })
  }, [fileRef, onSuccess, groupID, fields, run, setError, setFields])

  
  const { type, notes, status } = fields;

  return (
    <Form error name="upload" style={{marginBottom: '1em'}} loading={loading} onSubmit={handleSubmit}>
      <Message error>{error}</Message>
      <Form.Field inline required>
        <label>File to Upload</label>
        <input type='file' ref={fileRef}/>
      </Form.Field>
      <Form.Select
        style={{maxWidth: '14em'}}
        name='type'
        label='Type'
        inline
        value={type}
        required
        onChange={handleChange}
        options={FileTypeOptions}
      />
      <Form.Select
        style={{maxWidth: '14em'}}
        name='status'
        label='Status'
        inline
        value={status}
        required
        onChange={handleChange}
        options={FileStatusOptions}
      />
      <Form.TextArea
        name="notes"
        type="text"
        label="Notes"
        rows={1}
        value={notes}
        onChange={handleChange} />

      <Button primary type="submit">Upload</Button>
    </Form>
  );
}

export default FileForm;
