import API from "Api";
import { UserContainer } from "Context/User";
import { useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { Form, Message } from "semantic-ui-react";
import { useFields, useRequest } from "Shared/Hooks";
import { Profile, User } from "Shared/Models";
import { personName } from "Shared/PersonName";
import SimplePage from "Shared/SimplePage"

import { useProfile } from "./useProfile";

export const MemberProfileContact = () => {
  const {profile, profileLoading, profileError} = useProfile()

  return <SimplePage icon='mail' title={`Contact ${profile.name}`} loading={profileLoading} error={profileError}>
    <ContactForm profile={profile}/>
  </SimplePage>
}

const defaultMessage = (profile: Profile, user: User) => `Hi ${profile.name},

I wanted to reach out to...

Thanks,
— ${user.person.first}`

const ContactForm = ({profile}: {profile: Profile}) => {
  const {user} = useContext(UserContainer)

  const [loading, error, run, result] = useRequest({success: false});
  const {fields, handleChange} = useFields({
    message: defaultMessage(profile, user)
  })

  const handleSubmit = useCallback(()=>{
    run(API.forwardMessage(profile.person_id, fields.message))
  }, [run, fields, profile]);

  if (result.success) {
    return <Message positive>Message sent! Go back to the <Link to='/members'>Member Directory</Link>.</Message>
  }

  return <Form name='contact' error loading={loading} onSubmit={handleSubmit}>
    <Message info>
      When you submit this form, we will forward your message to <Link to={`/members/${profile.person_id}`}>{profile.name}</Link>.
      Your email address will be shared with them, so that they can reply directly via email. <b>Do not use this to advertise!</b>
    </Message>
    <Message error>{error}</Message>
    <Form.Field>
      <label>From</label>
      {`${personName(user.person)} <${user.email}>`}
    </Form.Field>
    <Form.TextArea
      label='Message'
      rows={10}
      value={fields.message}
      onchange={handleChange}
    />
    <Form.Button primary content='Send'/>
  </Form>
}
