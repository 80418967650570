import React, {useEffect, useState} from 'react'
import { Checkbox, CheckboxProps, Label, Loader } from 'semantic-ui-react'

import { useRequest } from './Hooks'

// onCheck and onUncheck should be promises 
// that set/unset check after a succesful API call
type TogglerProps = {
  label?: string
  init: () => Promise<boolean>
  onCheck: () => Promise<boolean>
  onUncheck: () => Promise<boolean>
}

export const Toggler: React.FC<TogglerProps>= ({ label, init, onCheck, onUncheck }) => {
  const [checked, setChecked] = useState(false)
  const [loading, error, run] = useRequest<boolean>(false, {loading: true});

  useEffect(()=>{
    run(init(), setChecked)
  }, [run, init]);

  const handleChange = (e: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    if (data.checked) {
      run(onCheck(), ()=>{
        setChecked(true)
      })
    } else {
      run(onUncheck(), ()=>{
        setChecked(false)
      })
    }
  }

  return <>
    <Checkbox label={label} toggle disabled={loading} checked={checked} onChange={handleChange}/>
    <Loader style={{left:'0.5em'}} size='tiny' inline active={loading}/>
    {error && <Label size='tiny' color='red'>{error}</Label>}
  </>
}
