import API from 'Api'
import { UserContainer } from 'Context/User'
import _ from 'lodash'
import React, { FormEvent, useCallback, useContext, useRef, useState } from 'react'
import { Button, Form, Icon, Message, Segment } from 'semantic-ui-react'
import { Date as CustomDate } from 'Shared/Date'
import { ChangeHandler, useFields, useRequest } from 'Shared/Hooks'
import { Person, Program, Registration, RegistrationType, User } from 'Shared/Models'
import { NumberField } from 'Shared/Number'
import PaymentMethod from 'Shared/PaymentMethod'
import { PeopleChooser } from 'Shared/PeopleChooser'
import { Anonymous, IsMember, LoggedIn } from 'Shared/Roles'
import { SlidingScaleAmount } from 'Shared/SlidingScaleAmount'
import Tooltip from 'Shared/Tooltip'


const makeParticipant = (user: User) => user && user.person ? {0: user.person.id} : {0: undefined}

const defaultDonation = 20;

type RegistrationFields = {
  user: User,
  program_id: number,
  participants: {[key: number]: number|undefined},
  guests: number,
  amount: number,
  newsletter: boolean,
}

const ProgramRegister = ({bypass, program, onReg}: {bypass: boolean, program: Program, onReg: (r: Registration)=>void}) => {
  const pm = useRef<{getPaymentMethod: ()=> Promise<{payment_method_id: string}>}>(null)
  const {user} = useContext(UserContainer);
  const [donate, setDonate] = useState(false);
  const [adds, setAdds] = useState(false);

  const isMember = IsMember(user);
  const isPaid = program.registration_type === RegistrationType.PaymentRequired || program.registration_type === RegistrationType.MembersDiscounted;

  const [loading, error, run] = useRequest<Registration>({} as Registration)
  const {fields, handleChange, setFields} = useFields<RegistrationFields>({
    user: Object.assign({
      person: {} as Person
    } as User, {id: user.id}),
    program_id: program.id,
    participants: makeParticipant(user),
    guests: 0,
    amount: getDefaultPrice(program, isMember),
    newsletter: false,
  });

  const { amount, participants, guests, newsletter } = fields;
  const count = (_.uniq(Object.values(participants).filter(Boolean)).length || 1) + (guests || 0);
  const remainingSeats = program.capacity ? program.capacity - program.participants : Infinity;
  const overFull = program.capacity > 0 && count > remainingSeats && !bypass
  const fifteenMinutesAgo = new Date().getTime() - (15  *  60  *  1000);
  const isAfterProgramStart = new Date(program.start_date).getTime() < fifteenMinutesAgo;

  // disbale donation amount if toggled off
  const toggleDonate = useCallback(()=>{
    if (donate) {
      setDonate(false);
      setFields({...fields, amount: 0});
    } else {
      setDonate(true);
      setFields({...fields, amount: defaultDonation});
    }
  }, [donate, fields, setFields])

  const removePerson = useCallback((idx: number) => (e: FormEvent) => {
    e.preventDefault();
    setFields(f => {
      delete f.participants[idx];
      return {...f};
    })
  }, [setFields]);

  const addPerson = useCallback((e: FormEvent) => {
    e.preventDefault();
    const newKey = 1 + Number(_(fields.participants).keys().sort().last());
    setFields({...fields, 
      participants: {...fields.participants, [newKey]: undefined}
    });
  }, [setFields, fields]);


  const handlePersonChange = useCallback((idx: number): ChangeHandler => (e, {value}) => {
    setFields(f => {
      f.participants[idx] = value as number;
      return {...f};
    })
  }, [setFields]);

  const doRegister = useCallback((payment_method_id?: string) => {
    const regBody = Object.assign({}, fields, {
      guests: fields.guests || 0, // force number for empty string
      amount: amount * count,
      participants: _.uniq(Object.values(fields.participants).filter(Boolean)),
      payment_method_id,
      extra: isPaid ? program.title : '',
      description: isPaid ? 'Program Registration' : 'Suggested Donation'
    })

    return API.createRegistration(regBody)
  }, [fields, amount, count, program, isPaid])

  const handleSubmit = useCallback(() => {
    if (!amount) {
      return run(doRegister(), onReg);
    }

    if (pm.current) {
      run(pm.current.getPaymentMethod()
        .then(({payment_method_id}) => {
          return doRegister(payment_method_id);
        }), onReg)
    }
  }, [run, onReg, amount, doRegister])

  return (
    <Form error name="register" loading={loading} onSubmit={handleSubmit} style={{maxWidth: '30em'}}>
      <Message error>{error}</Message>
      {overFull && <Message negative>Only {remainingSeats} spots are avaiable.</Message>}

      <LoggedIn>
        <Form.Field>
          <label>Participants</label>
          {Object.keys(participants).map(key=>{
            return <PeopleChooser
              key={key}
              style={{maxWidth: '14em'}}
              value={participants[Number(key)]}
              onChange={handlePersonChange(Number(key))}
              onDelete={Number(key) > 0 ? removePerson(Number(key)) : undefined}
            />
          })}
          {remainingSeats > 1 && 
            <Button compact size='mini' onClick={addPerson}>
              <Icon name='plus'/> Add a another registrant
            </Button>}
        </Form.Field>
      </LoggedIn>

      <Anonymous>
        <Form.Input
          style={{maxWidth: '20em'}}
          name='user.email'
          type='email'
          required
          label='Email'
          value={fields.user.email}
          onChange={handleChange}
        />

        <Form.Checkbox
          label='I want to receive the Alder Commons newsletter'
          name='newsletter'
          checked={newsletter}
          onChange={handleChange}/>

        <Form.Group widths='equal'> 
          <Form.Input
            style={{maxWidth: '14em'}}
            name='user.person.first'
            required
            label='First Name'
            value={fields.user.person.first}
            onChange={handleChange}
          />

          <Form.Input
            style={{maxWidth: '14em'}}
            name='user.person.last'
            required
            label='Last Name'
            value={fields.user.person.last}
            onChange={handleChange}
          />
        </Form.Group>
      </Anonymous>

      {remainingSeats > 1 && 
        <>
          {!adds ? 
            <Form.Checkbox 
              label={
                user.person && user.person.is_member ? 
                  "I am also bringing guests that aren't part of my Membership" : 
                  "I am also bringing guests"
              } onClick={()=>{setAdds(true)}}/>
            :
            <NumberField
              label='Number of Guests'
              style={{maxWidth:'7em'}}
              name='guests'
              value={guests}
              min={0}
              onChange={handleChange}
            />
          }
        </>}

      {isPaid &&
        <>
          <SlidingScaleAmount
            label={<>Registration Fee (per person) <Tooltip content="The range of our sliding scale is large to reflect wealth inequalities. Please pay what you can afford. The lowest price is intended for households earning less than $30k/year." /></>}
            minPrice={program.min_price}
            showMemberPricing={program.registration_type === RegistrationType.MembersDiscounted}
            isMember={isMember}
            memberPrice={program.member_price}
            amount={amount}
            onChange={handleChange}/>
          {amount > 0 && <PaymentMethod ref={pm}/>}
        </>}

      {!isPaid &&
        <Segment>
          <Form.Checkbox
            label='Make a donation to Alder Commons'
            checked={donate}
            onChange={() => {toggleDonate()}}/>
          {donate &&
            <>
              <NumberField
                label='Donation Amount'
                style={{maxWidth: '10em'}}
                innerLabel={{basic: true, content:'$'}}
                name='amount'
                value={amount}
                onChange={handleChange}/>
              <PaymentMethod ref={pm}/>
            </>
          }
        </Segment>}

      { isAfterProgramStart && <Message negative>This course already started on <CustomDate full ts={program.start_date}/>, are you sure you still want to register?</Message>}
      <Button primary disabled={!!overFull} type="submit">
        { !amount ? 'Register' : `Register and ${isPaid ? `Pay $${amount * count}` : `Donate $${amount}`}`  }
      </Button>
    </Form>
  )
};

const getDefaultPrice = (program: Program, isMember: boolean) => {
  if (program.registration_type === RegistrationType.PaymentRequired) {
    return program.min_price * 2;
  }

  if (program.registration_type === RegistrationType.MembersDiscounted) {
    return isMember ? program.member_price : program.min_price * 2;
  }

  return 0;
}

export default ProgramRegister
