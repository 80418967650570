import { RegistrationType } from "Shared/Models";

export const RegistrationTypes: Record<RegistrationType, string> = {
  [RegistrationType.None]: 'None (or third-party)',
  [RegistrationType.DonationOptional]: 'Donation Optional',
  [RegistrationType.PaymentOptional]: 'Payment Optional',
  [RegistrationType.PaymentRequired]: 'Payment Required',
  [RegistrationType.MembersOnly]: 'Members-Only',
  [RegistrationType.MembersDiscounted]: 'Members-Discounted',
};

export const RegistrationTypeOptions = [0,1,4,2,3,5].map((key) => {
  return {
    key: key,
    value: Number(key),
    text: RegistrationTypes[key as RegistrationType],
    disabled: key === 4
  };
});
