import React from "react";
import { Icon, IconProps } from "semantic-ui-react";

import { FileStatus } from "./Models";

export const FileStatuses: Record<FileStatus, IconProps> = {
  [FileStatus.Uploaded]: {
    title: 'Uploaded',
    name: 'question circle outline',
    color: 'orange',
  },
  [FileStatus.Approved]: {
    title: 'Approved',
    name: 'checkmark',
    color: 'green',
  },
  [FileStatus.Invalid]: {
    title: 'Invalid',
    name: 'close',
    color: 'red'
  },
}

export const FileStatusOptions = Object.keys(FileStatuses).map(Number).map((type: FileStatus) => {
  return {
    key: type,
    value: type,
    text: FileStatuses[type].title,
    content: FileStatuses[type].title
  };
});

export const FileStatusLabel = ({status}: {status: FileStatus}) => {
  return <Icon size='small' {...FileStatuses[status]}/>
}
