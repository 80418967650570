import { Placeholder } from "semantic-ui-react"

export const ListPlaceholder = () => (
  <Placeholder style={{marginBottom:'1em'}}>
    <Placeholder.Line length='short'/><Placeholder.Line length='short'/><Placeholder.Line length='short'/><Placeholder.Line length='short'/>
  </Placeholder>
)

export const SingleLinePlaceholder = () => (
  <Placeholder><Placeholder.Line length='short'/></Placeholder>
)

export const TwoLinePlaceholder = () => (
  <Placeholder><Placeholder.Line length='short'/><Placeholder.Line length='short'/></Placeholder>
);
