import _ from 'lodash';
import { Profile, Signature, User } from 'Shared/Models';

export type ProgramSearch = {
  before: string
  after: string
  draft?: string
  trashed?: string
  private?: string
  requested?: string
  participants?: string
  hydrate?: string
  tags?: string[]
}

export default class API {
  // Config
  static getConfig = () => (
    _get('/api/config'));

  // session handlers
  static whoami = () => (
    _get('/api/session'));
  static logout = () => (
    _delete('/api/session'));
  static login = (body: Record<string, any>) => (
    _post('/api/session', body));

  // User stuff
  static getUsers = () => (
    _get('/api/user') as Promise<User[]>);
  static getUser = (uid: number) => (
    _get(`/api/user/${uid}`));
  static reset = (body: Record<string, any>) => (
    _post('/api/user/reset', body));
  static updatePassword = (uid: number, body: Record<string, any>) => (
    _put(`/api/user/${uid}/pass`, body));
  static updateRole = (uid: number, body: Record<string, any>) => (
    _put(`/api/user/${uid}/role`, body));
  static updateUser = (uid: number, body: Record<string, any>) => (
    _put(`/api/user/${uid}`, body));
  static verify = (body: Record<string, any>) => (
    _post('/api/user/verify', body));
  static getUserPrograms = (uid: number) => (
    _get(`/api/user/${uid}/programs`));

  // Checkr candidate
  static createCheckrInvite = (uid: number) => (
    _post(`/api/checkr/${uid}/invite`));
  static getCheckrInvites = (uid: number) => (
    _get(`/api/checkr/${uid}/invite`));
  static getCheckrReports = (uid: number) => (
    _get(`/api/checkr/${uid}/report`));
  static createReportReview = (body: Record<string, any>) => (
    _post(`/api/report-review/`, body));

  // Household/Groups Endpoints
  static getHousehold = (id: number) => (
    _get(`/api/household/${id}`));
  static getGroups = () => (
    _get(`/api/household`));
  static createHousehold = (body: Record<string, any>) => (
    _post('/api/household', body));
  static updateHousehold = (body: Record<string, any>) => (
    _put('/api/household', body));

  // HouseholdPerson
  static getHouseholdPerson = (hid: number, pid: number) => (
    _get(`/api/household/${hid}/person/${pid}`));
  static attachHouseholdPerson = (hid: number, pid: number) => (
    _post(`/api/household/${hid}/person/${pid}`));
  static createHouseholdPerson = (hid: number, body: Record<string, any>) => (
    _post(`/api/household/${hid}/person`, body));
  static updateHouseholdPerson = (hid: number, body: Record<string, any>) => (
    _put(`/api/household/${hid}/person`, body));

  // HouseholdMembership
  static getHouseholdMembership = (hid: number) => (
    _get(`/api/household/${hid}/membership`));
  static createHouseholdMembership = (hid: number, body: Record<string, any>, minStatus: number) => {
    if (minStatus > body.status) {
      body = Object.assign({}, body, { status: minStatus });
    }
    return _post(`/api/household/${hid}/membership`, body);
  }
  static checkHouseholdMembership = (hid: number) => {
    return _get(`/api/household/${hid}/membership/check`);
  }
  static activateHouseholdMembership = (hid: number) => {
    return _put(`/api/household/${hid}/membership/activate`);
  }
  static updateHouseholdMembership = (hid: number, body: Record<string, any>, minStatus?: number) => {
    if (minStatus && minStatus > body.status) {
      body = Object.assign({}, body, { status: minStatus });
    }
    return _put(`/api/household/${hid}/membership`, body);
  }
  static cancelHouseholdMembership = (hid: number) => {
    return _delete(`/api/household/${hid}/membership`);
  }

  // HouseholdFiles
  static getHouseholdFiles = (hid: number) => (
    _get(`/api/household/${hid}/file`));
  static getHouseholdFile = (hid: number, id: number) => (
    _get(`/api/household/${hid}/file/${id}`));
  static updateHouseholdFile = (hid: number, body: Record<string, any>) => (
    _put(`/api/household/${hid}/file`, body)
  )
  static createHouseholdFile = (hid: number, file: File, body: Record<string, any>) => {
    return _upload('household_file', file, file.name, body)
      .then(({ file_name }) => {
        body.name = file_name;
        body.household_id = hid;
        return _post(`/api/household/${hid}/file`, body);
      });
  }

  // Signatures
  static getSignature = (household_id: number, code?: string, nonce?: string): Promise<{
    user: User,
    signatures: Signature[],
  }> => {
    let path = `/api/household/${household_id}/signature`;
    if (code && nonce) {
      path = path + `/${code}/${nonce}`
    }
    return _get(path);
  }
  static createSignature = (household_id: number, code?: string, nonce?: string) => {
    let path = `/api/household/${household_id}/signature`;
    if (code && nonce) {
      path = path + `/${code}/${nonce}`
    }
    return _post(path);
  }
  static getSignaturesForUser = (household_id: number, uid: number): Promise<Signature[]> => (
    _get(`/api/household/${household_id}/signatures-for-user/${uid}`));
  static inviteToSign = (household_id: number, uid: number): Promise<{success: boolean}> => (
    _post(`/api/household/${household_id}/signatures-for-user/${uid}`));

  // Person
  static getPeople = () => (
    _get(`/api/person`));
  static getPerson = (id: number) => (
    _get(`/api/person/${id}`));
  static createPerson = (body: Record<string, any>) => (
    _post(`/api/person/${body.id}`, body));
  static updatePerson = (body: Record<string, any>) => (
    _put(`/api/person/${body.id}`, body));
  static updatePersonPhotoConsent = (body: Record<string, any>) => (
    _put(`/api/person/${body.id}/photo_consent`, body));
  static getPersonUser = (id: number) => (
    _get(`/api/person/${id}/user`));
  static getPersonHouseholds = (id: number) => (
    _get(`/api/person/${id}/households`));
  static getPersonRegistrations = (id: number) => (
    _get(`/api/person/${id}/registrations`));
  static getPeopleAdmin = () => (
    _get(`/api/admin/person`));
  static deduplicatePerson = (body: Record<string, any>) => (
    _put(`/api/admin/deduplicate`, body));


  // Profiles
  static updateProfile = (body: Partial<Profile>) => (
    _put(`/api/profile`, body));
  static getProfile = (id: number) => (
    _get(`/api/person/${id}/profile`));
  static getProfiles = () => (
    _get(`/api/profile`));
  static forwardMessage = (personID: number, message: string) => (
    _post(`/api/person/${personID}/forward-message`, { message }));
  
  // profile image
  static createProfileImage = (personID: number, file: Blob, fileName: string) => {
    return _upload('profile', file, fileName, {})
      .then(({ file_name }) => {
        return _post(`/api/person/${personID}/profile-image`, {
          path: file_name
        });
      });
  }

  // Payments
  static createDonation = (body: Record<string, any>) => (
    _post(`/api/payment/donate`, body));
  static createPayment = (body: Record<string, any>) => (
    _post(`/api/payment/payment`, body));
  static createAutopay = (body: Record<string, any>) => (
    _post(`/api/payment/autopay`, body));
  static saveCard = (body: Record<string, any>) => (
    _post(`/api/payment/card`, body));
  static getCard = (pm_id: string) => (
    _get(`/api/payment/card/${pm_id}`));
  static getSubscription = (sub_id: string) => (
    _get(`/api/payment/sub/${sub_id}`));
  static sendReceipt = (body: Record<string, any>) => (
    _post(`/api/payment/receipt`, body));
  static getCustomer = (uid: number) => (
    _get(`/api/payment/customer/${uid}`));
  static getPayments = (uid: number) => (
    _get(`/api/payment/list/${uid}`));

  // donation records
  static createDonationRecord = (body: Record<string, any>) => (
    _post(`/api/donation`, body));
  static updateDonationRecord = (body: Record<string, any>) => (
    _put(`/api/donation/${body.id}`, body));

  // Programs
  static getPrograms = (params: ProgramSearch) => {
    const most = _.omit(params, 'tags');
    const qs = new URLSearchParams(most);
    if (params.tags) {
      params.tags.forEach((t) => qs.append('tags', t));
    }
    return _get(`/api/program?${qs}`);
  }
  static getProgram = (id: number|string) => (
    _get(`/api/program/${id}`));
  static createProgram = (prog: Record<string, any>) => (
    _post(`/api/program`, prog));
  static cloneProgram = (id: number) => (
    _post(`/api/program/${id}/clone`));
  static announceProgram = (id: number) => (
    _post(`/api/program/${id}/announce`));
  static updateProgram = (prog: Record<string, any>) => (
    _put(`/api/program/${prog.id}`, prog));
  static programSummary = () => (
    _post(`/api/admin/program/summary`));
  static programUpdate = () => (
    _post(`/api/admin/program/update`));
  static syncPrograms = () => (
    _post(`/api/admin/program/sync`));
  static clearPrograms = () => (
    _delete(`/api/admin/program/clear`));

  static emailProgramParticipants = (body: Record<string, any>) => (
    _post(`/api/program/${body.program_id}/email`, body))

  // Program Images
  static createProgramImage = (pid: number, file: Blob, fileName: string, body: Record<string, any>) => {
    return _upload('program', file, fileName, body)
      .then(({ file_name }) => {
        body.path = file_name;
        body.program_id = pid;
        return _post(`/api/program/${pid}/image`, body);
      });
  }
  static updateProgramImage = (pid: number, body: Record<string, any>) => (
    _put(`/api/program/${pid}/image`, body));
  static deleteProgramImage = (pid: number, id: number) => (
    _delete(`/api/program/${pid}/image/${id}`));

  // Registrations
  static createRegistration = (body: Record<string, any>) => (
    _post(`/api/registration`, body));
  static getRegistration = (id: number, code: string, nonce: string) => {
    const path = code && nonce ? `/api/registration/${id}/${code}/${nonce}` : `/api/registration/${id}`;
    return _get(path)
  };
  static updateRegistrationStatus = (id: number, status: number, code?: string, nonce?: string, refund?: boolean) => {
    const path = code && nonce ? `/api/registration/${id}/status/${code}/${nonce}` : `/api/registration/${id}/status`;
    return _put(path, { status, refund })
  }
  static updateRegistrationSteward = (id: number, steward: boolean) => {
    return _put(`/api/registration/${id}/steward`, { steward })
  }
  static updateRegistrationUser = (id: number, user_id: number) => (
    _put(`/api/registration/${id}/user`, { user_id }));
  static getRegistrations = (id: number) => (
    _get(`/api/program/${id}/registrations`));
  static getMyRegistration = (id: number) => (
    _get(`/api/program/${id}/my-registration`));

  // Waitlister
  static getWaitlisters = (id: number) => (
    _get(`/api/program/${id}/waitlisters`));
  static getMyWaitlister = (id: number) => (
    _get(`/api/program/${id}/my-waitlister`));
  static createWaitlister = (body: Record<string, any>) => (
    _post(`/api/waitlister`, body));
  static inviteWaitlister = (body: Record<string, any>) => (
    _put(`/api/waitlister/invite`, body));
  static deleteWaitlister = (body: Record<string, any>) => (
    _delete(`/api/waitlister`, body));

  // Membership verification
  static checkMember = ({ code }: { code: string }) => (
    _get(`/api/check/${code}`))

  // Tags
  static getTags = () => (
    _get(`/api/tag`));
  static createTag = (body: Record<string, any>) => (
    _post('/api/tag', body));

  // Interest Tags
  static getInterestTags = () => (
    _get(`/api/interest-tag`));
  static createInterestTag = (body: Record<string, any>) => (
    _post('/api/interest-tag', body));

  // Docs
  static getDocs = () => (
    _get(`/api/doc`));
  static getActiveDocs = () => (
    _get(`/api/doc/active`));
  static createDoc = (file: File, body: Record<string, any>) => (
    _upload('members', file, file.name, body)
      .then(({ file_name }) => {
        body.src = file_name;
        return _post(`/api/doc`, body);
      }))
  static updateDoc = (body: Record<string, any>) => (
    _put(`/api/doc`, body));

  // tasks
  static getTasks = (params: Record<string, any>) => {
    const qs = new URLSearchParams(params);
    return _get(`/api/task?${qs}`);
  }

  // admin openpath
  static getOpenpathUsers = () => (
    _get(`/api/openpath/users`));
  static getOpenpathUser = (acUID: number) => (
    _get(`/api/openpath/user/${acUID}`));
  static getOpenpathUserGroups = (acUID: number) => (
    _get(`/api/openpath/user/${acUID}/groups`));
  static getOpenpathUserCredentials = (acUID: number) => (
    _get(`/api/openpath/user/${acUID}/credentials`));
  static createOpenpathUser = (acUID: number) => (
    _post(`/api/openpath/user/${acUID}`));
  static removeOpenpathUser = (acUID: number) => (
    _delete(`/api/openpath/user/${acUID}`));
  static addOpenpathFob = (acUID: number, fob: string) => (
    _post(`/api/openpath/user/${acUID}/fob/${fob}`));
  static deleteOpenpathCredential = (acUID: number, credentialID: number) => (
    _delete(`/api/openpath/user/${acUID}/credential/${credentialID}`));

  // admin libib
  static getLibibPatron = (acUID: number) => (
    _get(`/api/libib/user/${acUID}`));
  static createLibibPatron = (acUID: number) => (
    _post(`/api/libib/user/${acUID}`));

  // admin memberships
  static getMemberships = () => (
    _get(`/api/membership`));
  static createMembershipInvite = (body: Record<string, any>) => (
    _post(`/api/membership/invite`, body));
  static hideMembershipInvite = (email: string) => (
    _delete(`/api/membership/invite/${email}`));
  static getPendingInvites = () => (
    _get(`/api/membership/invites`));

  // admin google groups
  static getGoogleGroupUsers = () => (
    _get(`/api/google_groups`));
  static getGoogleGroupUser = (acUID: number) => (
    _get(`/api/google_groups/${acUID}`));
  static createGoogleGroupUser = (acUID: number) => (
    _post(`/api/google_groups/${acUID}`));
  static removeGoogleGroupUser = (acUID: number) => (
    _delete(`/api/google_groups/${acUID}`));

  // Admin Slack
  static getSlackUser = (acUID: number) => (
    _get(`/api/slack/${acUID}`));
  static inviteToSlack = (acUID: number) => (
    _post(`/api/slack/${acUID}`));
  static linkSlackUsers = () => (
    _get(`/api/slack-link`));

  // Admin mailerlite
  static getMailerliteSubscriber = (acUID: number) => (
    _get(`/api/mailerlite/${acUID}`));
  static addMailerliteSubscriber = (acUID: number, group: number) => (
    _post(`/api/mailerlite/${acUID}/${group}`));
  static removeMailerliteSubscriber = (acUID: number, group: number) => (
    _delete(`/api/mailerlite/${acUID}/${group}`));

  // Admin reports
  static generateCheckrReport = () => (
    _get(`/api/report/checkr`))
  static generateSlackReport = () => (
    _get(`/api/report/slack`))
  static generateOpenpathReport = () => (
    _get(`/api/report/openpath`))
  static pastDueReport = () => (
    _get(`/api/report/past-due`));

  // Admin stats
  static getStats = () => (
    _get(`/api/stats`)
  )
}

// internal utils
function _get(url: string, body?: Record<string, any>) {
  return _fetch('GET', url, body);
}

function _post(url: string, body?: Record<string, any>) {
  return _fetch('POST', url, body);
}

function _delete(url: string, body?: Record<string, any>) {
  return _fetch('DELETE', url, body);
}

function _put(endpoint: string, body?: Record<string, any>) {
  return _fetch('PUT', endpoint, body);
}

function _upload(dir: string, file: Blob, fileName: string, body: Record<string, any>) {
  let formData = new FormData();
  formData.append('dir', dir);
  formData.append('file', file, fileName);
  formData.append('type', body.type === undefined ? 'upload' : body.type);

  const opts = {
    method: 'POST',
    body: formData,
    headers: {
      // CSRF prevention
      // https://www.owasp.org/index.php/Cross-Site_Request_Forgery_(CSRF)_Prevention_Cheat_Sheet#Use_of_Custom_Request_Headers
      'X-Requested-With': 'XMLHttpRequest'
    }
  };

  return _massageOutput(
    fetch('/api/upload', opts)
      .then(resp => {
        if (resp.status === 413) {
          return Promise.reject('Upload is too large');
        } else {
          return resp;
        }
      })
  )
}

function _fetch(method: string, url: string, body?: Record<string, any>) {
  return _massageOutput(fetch(url, {
    method: method,
    body: JSON.stringify(body),
    headers: {
      // CSRF prevention
      // https://www.owasp.org/index.php/Cross-Site_Request_Forgery_(CSRF)_Prevention_Cheat_Sheet#Use_of_Custom_Request_Headers
      'X-Requested-With': 'XMLHttpRequest'
    }
  }));
}

function _massageOutput(p: Promise<{ json: () => Promise<any> }>) {
  return p
    .then(resp => resp.json())
    .then((result: any) => {
      if (result && result.error) {
        return Promise.reject(result.error);
      }
      return Promise.resolve(result);
    })
    .catch(error => {
      return Promise.reject(error.toString());
    });
}
