import { FileType } from "Shared/Models";

export type lang = "en" | "es";
type agreement = { title: string; type: FileType; body: string };

export const filesByLang: Record<lang, agreement[]> = {
  en: [
    {
      title: "Mutual Agreements",
      type: FileType.MutualAgreements,
      body: `Alder Commons (or “AC”) is a community-based organization. Our Members* are active contributors and participants in the community. By becoming a Member, you become a co-creator of "the commons": the shared values, space, knowledge, and practices of the community. 

_*Alder Commons is not a Membership Corporation, and Members do not have official voting or governing duties._

These agreements help ensure that each individual's needs are met, along with the needs of the community as a whole. 

### Community

What you can expect from us:

* Work to uphold the community's [Core Values](https://aldercommons.org/philosophy/core-values/)
    * Self-Determination
    * Collective Liberation
    * Community Support
* Prioritizing the safety and well-being of the community, including vulnerable groups
* Outreach to residents of the wider community who might want to join AC, and communication with other organizations who might want to partner with AC 

What we expect from you:

* Commitment to work towards upholding AC's Core Values 
* Advocate for yourself and others when able
* Be kind and respectful to yourself and to others
* Take responsibility and initiative for creating a fun, happy, safe, and supportive community

### Space

What you can expect from us:

* Safe and welcoming environment, including precautionary measures on-site to address safety concerns regarding COVID-19
* Amenities (ex: kitchen, wifi, printer, tools, books, etc.)
* Reservable rooms

What we expect from you:

* Put away supplies after you use them, and clean up a mess if you make one
* If something breaks, let the AC Staff know
* When reserving rooms, be mindful of the rest of the community's need for those spaces
* Respect certification processes for certain tools and permissions (e.g. Shop Steward Certification, etc.)

### Programs

What you can expect from us:

* Classes, workshops, clubs, and events that align with our Core Values
* Open-to-the-public events during evenings and weekends
* Access to online community

What we expect from you:

* Participation in programs (at least every once in a while), such as taking a class, attending a workshop or event, or joining a club
* Occasionally hosting a class/workshop/club

### Support

What you can expect from us:

* Guidance and hands-on support for locating resources in the space
* Clearly communicated feedback, as well as updates on community practices
* Regularly scheduled All-Members Meetings to address community dynamics that need adjusting 

What we expect from you:

* Willingness to engage in conflict resolution and restorative justice practices
* Participation in the creation and implementation of community practices 
* Provide feedback to help us improve the functioning of the organization and the community
* Willingness to be an informal mentor or friend to other Members

## Some logistics...

### Safety

What you can expect from us:

* During Member Hours (8:30am-4:30pm weekdays, subject to change), all adults in the building will be background checked, or will be accompanied by a background-checked adult
* Alarm system, security cameras, and community-based deterrents against abuse and theft

What we expect from you:

* All adults (18+) spending time in the building during Member Hours must complete a background check or be accompanied by a background-checked adult while in the space
* Alcohol will not be consumed or sold on site
* During Member Hours, accompany your non-Member visitors from the time they arrive to the time they leave 
* If someone's actions make you feel uncomfortable or unsafe (physically, mentally, or otherwise), and you do not feel comfortable directly addressing the issue with the person, tell AC Staff immediately


### Money

What you can expect from us:

* Maintain finances in a way that keeps Membership affordable and accessible
* Communicate clearly about any additional costs beyond Membership fees (ex: additional materials costs for a particular workshop, or space rental) 
* Accept multiple forms of payment, including credit/debit cards, checks, and cash
* Flexibility on payment times in the case of financial hardship

What we expect from you:

* Use of the sliding scale fees as an opportunity to give what is a meaningful and appropriate amount for you, _not_ to take advantage of the flexibility to pay less than you are able
* Monthly payments made on the same day of the month that your Membership started (ex: June 12th, July 12th, Aug 12th, etc.)
* Use your online accounts to adjust your monthly Membership fees as needed


### Cancellations

What you can expect from us:

* Multiple ways of providing anonymous feedback to us: 
    * Online feedback forms
    * In-person suggestion box
* AC is committed to restorative justice and will strive to work with any person or persons for mutual healing. In the event that a mutual agreement can't be reached, AC Staff & Board will do what they deem is in the best interest for the health of the community. We maintain the right to end someone's Membership either temporarily or permanently, at the discretion of the Board. 
* Memberships that have two months of unpaid invoices will be automatically canceled on the third month; they can be reactivated by Staff after payments have been made
* Upon request to AC staff, canceled Memberships that were up-to-date on their payments can be prorated from the day of cancellation (ex: If you normally pay your monthly fee on June 12th, and cancel on June 20th, we can refund the days between June 20th - July 12th).

What we expect from you:

* Use your online accounts to cancel your Membership
* Provide feedback on how your experience was as a Member, and ways in which the organization could improve

---

By signing below, you acknowledge that you understand the above mutual agreements, and agree to uphold them to the best of your ability.`,
    },
    {
      title: "Terms of Use",
      type: FileType.TermsOfUse,
      body: `
**Welcome to the Alder Commons community!** Please take some time to read this entire document carefully. If you have any questions, please let us know.

### 1. Purpose of this Agreement

The purpose of this agreement is to establish the terms of Membership with Alder Commons, an Oregon nonprofit public benefit corporation (“**we**” or “**us**” or “**our**” or “**Alder Commons**”). This agreement explains the terms of your Membership with Alder Commons, your access to our building and premises located at 4212 NE Prescott Street in Portland, Oregon, and your use of our office space, work stations, internet access, workshop, event space, play areas, knowledge resources, and other services (collectively, “**Services**”).

We recognize that you may be entering into this agreement yourself or as the parent or legal guardian of your child (or both). All references to “**you**” and “**your**” in this agreement apply to both you and your child (unless the context indicates otherwise). If you are entering into this agreement as part of a household, all members of your household (who will be a part of your Membership) must sign this agreement.

### 2. Your Acceptance of this Agreement

Using any of the Services offered by Alder Commons means you accept the terms of this agreement. Please be aware that we may change this agreement at any time. If we make any material changes, we will notify you (using the contact information that you provide to us) of the nature of the changes and the date on which the changes will go into effect. By continuing to use our Services following notice of any change, you acknowledge that you have read, understand, and agree to this agreement as changed.

### 3. Your Use of Services

When using our Services, here are some things you <span style="text-decoration:underline;">must</span> do:

1. comply with this agreement, our stated rules and policies, and all applicable laws;
2. use our Services as they are intended to be used, in a way that does not result in an unreasonable or unnecessary danger to you or anyone else; and
3. respect the rights of all other Members, visitors, workers, volunteers, and others who are using the Services or are otherwise present at Alder Commons.

Similarly, when you use our Services, here are some things you <span style="text-decoration:underline;">must not</span> do:

1. anything that restricts or limits any other Member's use or enjoyment of our Services;
2. any action that results (or is likely to result) in any damage, injury, or loss to Alder Commons, its employees, volunteers, or any other Member;
3. access any area or portion of our premises which Members are not authorized to access;
4. access (or attempt to access) our computer systems, networks, servers, or other systems (whether by hacking or otherwise) which you are not authorized to access; and
5. produce, share, distribute, or otherwise access any illegal, inappropriate, or obscene materials using our Services.
4. Confidentiality and Privacy

We respect your privacy, and you must respect the privacy of our officers, directors, employees, and volunteers, and other Members and users of our Services. During your use of our Services, you may receive, be exposed to, or be given access to certain private, non-public data and information related to us or another Member (collectively, “**Confidential Information**”). Unless and until such data or information is made public, or is no longer considered Confidential Information, you shall not disclose or use any Confidential Information for any unauthorized purpose, except as required by law. Your obligations under this section continue even after you are no longer a Member.

### 5. Limitation on Damages

Alder Commons is not responsible for lost, stolen, or damaged goods.

**General Limitation.** Neither you nor we are liable to the other for (a) any damages that the breaching party could not reasonably have foreseen as of the effective date of this agreement or (b) any punitive damages.

**Maximum Damages.** In any event, we are not liable to you for damages or losses in excess of the amount you have paid us in Membership fees for the six months preceding the issue giving rise to your claim.

### 6. Termination

**Termination by Us.** We may terminate this agreement upon written notice to you if you violate any terms of this agreement (or if we otherwise determine - in our sole discretion - that your continued Membership with Alder Commons presents an unreasonable or unacceptable threat or danger to any of our staff or other Members or is otherwise inconsistent with our mission and core values).

**Termination by You.** You may terminate this agreement at any time and for any reason by canceling your account or notifying us of your intent to terminate.

**Effect of Termination.** Upon termination of this agreement (by you or us and for whatever reason), you are no longer permitted to use or access our Services as a Member. If you cancel your Membership in the middle of a payment period, we will issue a partial refund for Membership fees based on the amount of time remaining in the payment period.

### 7. Insurance

Your personal property is not covered by any of our insurance policies. Accordingly, if you use or store any of your personal property at Alder Commons, we strongly recommend that you obtain proper insurance to cover any damage or loss.

### 8. Your Indemnification Obligations

In the event any action, lawsuit, claim, investigation, or proceeding is brought by a third party against Alder Commons (or any of our officers, directors, employees, volunteers, or agents) that arises out of, or is related to you or the Services provided under this agreement (each, a “**Nonparty Claim**”), you shall indemnify Alder Commons (and our officers, directors, employees, volunteers, and agents) against all amounts awarded in or paid in settlement of any Nonparty Claim, together with all reasonable out-of-pocket expense (including court filing fees, court costs, arbitration fees, witness fees, and attorneys' and other professionals' fees and disbursements) incurred in defending a Nonparty Claim or in any related investigation or negotiation. Essentially, if you cause Alder Commons to be sued for something you did, you agree to pay all costs incurred by Alder Commons in defending such matter, and any resulting damages or judgments. 

### 9. Dispute Resolution

Due to the time and costs associated with conventional litigation, not to mention the strain it puts on the public judicial system, the parties agree to resolve all disputes via alternative dispute resolution. Any dispute arising under this Agreement shall first be submitted for non-binding mediation by a mediator mutually agreed to by the parties. The mediator will work with the parties to find a mutually agreeable resolution to the dispute. In the event mediation is not successful, the dispute will be resolved by binding arbitration in Portland, Oregon, by an arbitrator and in accordance with the rules then in effect of the Arbitration Service of Portland, Inc. (or other arbitration service the parties agree to in writing). The arbitrator (private judge) will have the authority to make a final and binding decision regarding the dispute. Judgment may be entered on the arbitrator's award in any court having jurisdiction. Each party hereby waives any and all right to a trial by jury in any claim.

### 10. Miscellaneous Provisions

This agreement shall be governed by, construed and enforced in accordance with the laws of the State of Oregon (exclusive of any choice of law provisions). If any suit or action is filed by any party to enforce this agreement or otherwise with respect to the subject matter of the agreement, venue shall be in the federal or state courts located in Multnomah County, Oregon; and the parties consent to the jurisdiction of the court or courts located in Oregon and to service of process by registered mail, return receipt requested, or in any other manner provided by law. If any provision of this agreement shall prove to be invalid, void or illegal, it shall in no way affect, impair or invalidate any other provision hereof. No waiver of any provision of this agreement is effective unless it is (a) in writing and (b) signed by the waiving party. The parties acknowledge that any delay or failure to enforce a party's rights under this agreement is not intended to be a waiver of such rights. The terms contained in sections 4, 5, 8, 9 and 10 shall survive termination of this agreement.

### 11. Communications

If you need to contact us, you can call us at (503) 893-9366, email us at hello@aldercommons.org, or write us a letter and send it to 4212 NE Prescott St. Portland, OR 97218. You may also ask to speak to Staff whenever you are at Alder Commons.

If we need to contact you, we will use the contact information that you provided to us, or we will talk to you in person when you are at Alder Commons. You agree to notify us of any changes to the contact information that you provided to us (even if the changes are temporary). If we are unable to contact you for more than 30 days, we may cancel your Membership.

---

By signing below, you acknowledge that you (a) have read this entire document and (b) agree to all of its terms.`,
    },
    {
      title: "Assumption of Risk and Waiver of Liability",
      type: FileType.Waiver,
      body: `Due to the risk of injury or other harm involved, we could not provide certain services to you (and others) if you (and others) did not sign this Assumption of Risk and Waiver of Liability. If you do not agree to the terms in this document, **do not use our services**.

By signing this document, you acknowledge and agree that:

1. you will follow our instructions, rules, and policies related to your use of our services;
2. engaging in the type of experimental play and adventurous activities that we offer involves risks of serious injury or harm; 
3. using power tools, hand tools, and other equipment can be dangerous (even if used properly) and can cause serious injury or harm; 
4. climbing onto and jumping off of structures and objects can be dangerous and can result in serious injury or harm; 
5. infectious diseases (such as COVID-19 and other viruses) can be highly contagious and can result in significant injury or harm;
6. by using our services, you are likely increasing your risk of injury or harm; 
7. you voluntarily assume all of these (and other) risks (whether known or unknown) related to your use of our services.

Additionally, by signing this document:

1. you hereby **waive (i.e. give up)** your rights (if any) to bring a legal (or other) claim against us (or our directors, officers, employees, volunteers, representatives, and agents) related to your use of our services; 
2. you hereby **release (i.e. relieve)** us (and our directors, officers, employees, volunteers, representatives, and agents) from any responsibility or liability related to your use of our services; and
3. you shall defend and indemnify us (and our directors, officers, employees, volunteers, representatives, and agents) from any legal (or other) claim (by anyone other than you) against us (or any of our directors, officers, employees, volunteers, representatives, or agents) related to your use of our services.

Just to be clear, this means that, if you use our services and you are injured or harmed in any way (or you injure or harm someone else), we are not responsible or liable in any way and you cannot bring any legal (or other) claim against us, and you will defend (i.e. pay our legal fees) and indemnify (i.e. pay any award, judgment, or settlement amount) if someone else brings a legal (or other) claim against us related to your use of our services. 

---

Finally, by signing this document, you acknowledge that:

1. you have read and understood this entire document;
2. you have consulted with your own legal counsel (or knowingly waived your right to do so); and
3. you agree to all of the terms in this document.

**Note to People Under 18**: If you are under the age of 18, your parent or legal guardian must agree to this document, as well.

**Note to Parents and Legal Guardians**: If you are signing this document as the parent or legal guardian of a child under 18, all references in this document to "you" apply to both you and your child.`,
    },
  ],
  es: [
    {
      title: "Acuerdos Mutuos",
      type: FileType.MutualAgreements,
      body: `Alder Commons (o “AC”) es una organización basada en comunidad. Nuestros Miembros* contribuyen y participan activamente en la comunidad. Al hacerte Miembro, te conviertes en cocreador de los “commons” o bienes comunes: Los espacios, valores, conocimientos y prácticas que compartimos en comunidad.

_*Alder Commons no es una Corporación de Membresía, y sus Miembros no tienen deberes de gobernación ni de voto oficial._

Estos acuerdos nos ayudan a satisfacer las necesidades individuales de cada miembro, así como las necesidades grupales de la comunidad. 

### Nuestra comunidad

Qué puedes esperar de nosotros:

* Trabajamos para mantener los [valores fundamentales](https://aldercommons.org/philosophy/core-values/) de nuestra comunidad
    * Liberación colectiva
    * Autodeterminación
    * Apoyo comunitario
* Priorizamos la seguridad y el bienestar de la comunidad, incluyendo los grupos más vulnerables
* Nos comunicamos con los residentes de la comunidad general que quieran unirse a AC, y con otras organizaciones que quieran asociarse a AC.

Qué esperamos de tí:

* Comprometerte a trabajar en mantener los valores fundamentales de AC
* Abogar por tí mismo, y por otros cuando seas capaz 
* Ser respetuoso y bondadoso, contigo mismo y con los demás
* Ser responsable y tomar iniciativa para crear una comunidad en la que los miembros están seguros y felices, son apoyados, y se divierten 

### Nuestro Espacio

Qué puedes esperar de nosotros: 

* Un ambiente seguro, cálido e incluyente, con medidas preventivas para protegernos del Coronavirus. 
* Uso de las instalaciones (cocina, internet, impresora, herramientas, biblioteca, etc) 
* Salones reservables

Qué esperamos de tí:

* Guardar los materiales después de usarlos, limpiar cuando ensucias
* Comunicar al personal de AC si algo se rompe 
* Tener en cuenta las necesidades del resto de la comunidad cuando reservas los salones. 
* Respetar los procesos de certificación y permisos (para el uso de ciertas herramientas del Taller, etc)


### Nuestros Programas 

Qué puedes esperar de nosotros: 

* Clases, talleres, clubes, y eventos que se alinean con nuestros valores fundamentales
* Eventos abiertos al público general en las noches y fines de semana
* Acceso a la comunidad en línea

Qué esperamos de tí: 

* Tu participación (al menos de vez en cuando) en nuestros programas: tomando una clase, asistiendo a un taller o evento, uniéndote a un club.
* El estar dispuesto a ofrecer una clase, taller, o club de vez en cuando 


### Nuestro Apoyo

Qué puedes esperar de nosotros:

* Apoyo práctico para poder encontrar los recursos disponibles en el edificio 
* Comunicación clara y actualizada regularmente sobre tu participación así como sobre las prácticas de la comunidad.
* Reuniones para Miembros programadas regularmente sobre cambios o ajustes en las dinámicas de la comunidad.

Qué esperamos de tí:

* Estar dispuesto a participar en prácticas de resolución de conflictos y justicia restaurativa.
* Estar dispuesto a participar en la creación e implementación de las prácticas de la comunidad.
* Estar dispuesto a comunicarte para apoyar el funcionamiento de la organización y la comunidad cuando sea necesario.
* Estar dispuesto a ser amigo y mentor de otros miembros.

## Algunos asuntos logísticos

### La Seguridad

Qué puedes esperar de nosotros:

* Durante el horario sólo para los miembros (8:30am a 4:30pm de lunes a viernes, sujeto a cambios), todos los adultos presentes deberán haber pasado una verificación de antecedentes, o permanecer acompañados por un adulto que lo haya pasado. 
* Sistema de alarma, cámaras de seguridad, e impedimentos basados en la comunidad, en contra de cualquier abuso o robo.

Qué esperamos de tí:

* Todos los adultos (18 años de edad o mayor) presentes durante el horario sólo para los miembros, deben pasar una verificación de antecedentes, o de lo contrario permanecer acompañados por alguien que lo haya pasado.
* No se venderá ni consumirá alcohol en nuestras instalaciones
* Acompaña a todo visitante que no sea Miembro, durante el horario sólo para los miembros, desde que llegan hasta que se van. 
* Si alguien hace algo que te hace sentir incómodo o inseguro mental, físicamente o de cualquier otro modo, y te incomoda hablarlo directamente con esa persona, dile a alguien del personal de AC inmediatamente.

### El Dinero

Qué puedes esperar de nosotros: 

* Mantenimiento de los fondos para que la Membresía siga siendo accesible
* Comunicación clara con respecto a cualquier costo adicional más allá de la Membresía (por ejemplo, costo de materiales adicionales para un taller en particular, o renta de un espacio)
* Aceptamos diferentes formas de pago incluyendo cheques, tarjetas de crédito, o efectivo
* Flexibilidad en cuanto al tiempo de pago en casos de dificultad financiera

Qué esperamos de tí:

* Uso de la escala de tarifas, no para tomar ventaja y pagar menos de lo que puedes, sino como una oportunidad para contribuir un monto apropiado de acuerdo a tus recursos.
* Pago de tu cuota mensual el mismo día del mes en que comenzó tu Membresía. (Por ejemplo, el 12 de junio, 12 de julio, 12 de agosto, etc) 
* Utiliza tu cuenta en línea si necesitas ajustar el monto de tu tarifa de Membresía según sea necesario 


### En caso de cancelación 

Qué puedes esperar de nosotros: 

* Varias maneras de comunicar tus comentarios de forma anónima
    * Formularios en línea
    * Buzón de sugerencias
* En AC estamos comprometidos a la justicia restaurativa y a intentar constantemente el logro de acuerdos mutuamente beneficiosos. En caso de no poderse llegar a un acuerdo, el personal y la junta directiva de AC tomarán las decisiones que consideren mejor para la salud de la comunidad. Mantenemos el derecho de cancelar una Membresía temporal o permanentemente, según la discreción de la junta directiva. 
* Las membresías que no se han pagado en dos meses serán canceladas automáticamente el tercer mes; podrán ser reactivadas por el personal de AC después de haberse realizado el pago. 
* A pedido al personal de AC, las Membresías canceladas se pueden estar calculado por prorrateo desde la fecha de cancelación (por ejemplo, si normalmente pagas tu cuota el 12 de junio, y la cancelas 3l 20, se te reembolsará el pago de los días que quedan entre el 20 de junio y el 12 de julio).

Qué esperamos de tí:

* Utiliza tu cuenta en línea si planeas finalizar tu Membresía. 
* Danos tus comentarios de cómo fue tu experiencia como Miembro, y de cómo piensas que podríamos mejorar como organización. 

---

Al firmar abajo, indicas que comprendes los acuerdos listados aquí, y estás dispuest@ a mantenerlos tanto como puedas`,
    },
    {
      title: "Condiciones de Uso",
      type: FileType.TermsOfUse,
      body: `**¡Bienvenidos a la comunidad Alder Commons!** Por favor tómese el tiempo que necesite para leer cuidadosamente este documento en su totalidad. Si tiene alguna pregunta, no dude en contactarnos. 

### 1. Propósito de este contrato

El propósito de este contrato es establecer los términos de membresía de Alder Commons, una corporación sin fines de lucro de beneficio público localizada en Oregón (“**Nosotros**” o “**nos**” o “**nuestros**” o “**Alder Commons**” en lo sucesivo). Este contrato explica los términos de su membresía con Alder Commons; su acceso a nuestro edificio e instalaciones ubicadas en 4212 NE Prescott Street en Portland, Oregón; y su uso de nuestro espacio de oficinas, estaciones de trabajo, acceso a internet, talleres, espacio para eventos, áreas de juego, recursos de conocimiento, y otros servicios (en conjunto, “**Servicios**”).

Reconocemos que puede estar firmando este acuerdo como usted mismo, como el padre o tutor legal de su hijo(a), o como ambos. Todas las referencias a “**usted**” y “**su**” en este contrato se aplican tanto para usted como para su hijo(a) (al menos que el contexto indique lo contrario). Si usted está firmando este contrato como parte de un hogar, todos los miembros de dicho hogar (que serán parte de su membresía) también deben de firmar este contrato.

### 2. Su aceptación de este contrato

El uso de cualquiera de los Servicios ofrecidos por Alder Commons significa que usted acepta los términos de este contrato. Por favor esté consciente de que podemos realizar cambios a este contrato en cualquier momento. Si realizamos algún cambio significativo, le notificaremos (usando la información de contacto que usted nos haya proporcionado) la naturaleza de dichos cambios y la fecha en la cual éstos entrarían en vigor. Al continuar usando nuestros Servicios tras la notificación de cualquier cambio, usted reconoce que ha leído, entiende, y acepta este contrato modificado.

### 3. Su uso de los Servicios

Al utilizar nuestros Servicios, aquí mencionamos algunas cosas que <span style="text-decoration:underline;">debe hacer</span>:

1. cumplir con este contrato, con nuestras reglas y políticas establecidas, y con todas las leyes aplicables;
2. utilizar nuestros Servicios como están destinados a ser usados, en una manera que no resulte en un peligro irrazonable o innecesario para usted o alguien más; y
3. respetar los derechos de todos los demás Miembros, visitantes, trabajadores, voluntarios, y demás personas que estén utilizando los Servicios o que de otra manera estén presentes en Alder Commons.

Asimismo, cuando utilice nuestros Servicios, aquí enumeramos algunas cosas que <span style="text-decoration:underline;">no debe hacer</span>:

1. ninguna cosa que restrinja o limite el uso o disfrute de nuestros Servicios a cualquier otro miembro;
2. ninguna acción que resulte (o pueda resultar) en algún daño, lesión, o pérdida para Alder Commons, sus empleados, voluntarios, o cualquier otro Miembro; 
3. acceder a ningún área o parte de nuestras instalaciones a la que los Miembros no estén autorizados a ingresar;
4. acceder (o intentar acceder) a nuestros sistemas de cómputo, redes, servidores, u otros sistemas (ya sea hackeando o de otra manera) a los que no esté autorizado a acceder; ni
5. producir, compartir, distribuir, o acceder a cualquier material ilegal, inapropiado, u obsceno.

### 4. Confidencialidad y privacidad

Respetamos su privacidad, y usted debe respetar la privacidad de nuestros funcionarios, directores, empleados, y voluntarios, así como la de otros Miembros y usuarios de nuestros servicios. Durante el uso de nuestros Servicios, usted puede recibir, estar expuesto, o tener acceso a cierto tipo de datos e información privados y no públicos relacionados con nosotros u otro(s) Miembro(s) (en conjunto, “**Información Confidencial**”). A menos y hasta que dichos datos y/o información se hagan públicos, o ya no se consideren como Información Confidencial, usted no utilizará, revelará, ni divulgará ningún tipo de Información Confidencial para ningún propósito no autorizado, excepto cuando lo requiera la ley. Sus obligaciones en virtud de esta sección continúan vigentes incluso si usted dejara de ser Miembro.

### 5. Limitaciones de daños

Alder Commons no es responsable de bienes perdidos, robados o dañados.

**Limitación General.** Ni usted ni nosotros somos responsables ante el otro por (a)ningún daño que la parte infractora no haya podido de manera razonable prever a la fecha de entrada en vigor de este contrato o (b)ningún daño punitivo.

**Daños Máximos. Bajo ninguna circunstancia** somos responsables ante usted por daños o pérdidas que excedan el monto total que usted nos haya pagado por el costo de su membresía durante seis meses antes del evento que haya dado origen a su demanda.


### 6. Rescisión

Rescisión por nuestra parte. Podemos rescindir este contrato mediante un aviso por escrito si usted viola cualquiera de los términos de este contrato (o si a nuestra entera discreción determinamos que prolongar su membresía con Alder Commons pudiese representar una amenaza o peligro irrazonable o inaceptable para cualquier integrante de nuestro personal u otros Miembros, o fuese de alguna manera inconsistente con nuestra misión y valores fundamentales).

Rescisión **por su parte.** Usted puede rescindir este contrato en cualquier momento y por cualquier razón cancelando su cuenta o notificándonos su intención de rescindir.

**Efecto de rescisión.** Tras la rescisión de este contrato (por su parte o por la nuestra, y por cualquier motivo), usted ya no será acreedor de usar o acceder a nuestros Servicios como Miembro. Si cancela su membresía en medio de un período de pago, le emitiremos un reembolso parcial de la cuota de su membresía basado en el tiempo restante del período de pago.


### 7. Seguro

Sus pertenencias personales no están cubiertas por ninguna de nuestras pólizas de seguro. Por consiguiente, si usted usa o almacena cualquiera de sus pertenencias personales en Alder Commons, le recomendamos encarecidamente que obtenga un seguro adecuado que cubra cualquier daño o pérdida.


### 8. Sus obligaciones de indemnización

En caso de que cualquier acción, demanda, reclamación, investigación, o procedimiento sea iniciado por una tercera parte en contra de Alder Commons (o contra cualquiera de nuestros funcionarios, directores, empleados, voluntarios, o agentes) que surja de, o que esté relacionado con usted o con los Servicios provistos derivados de este contrato (cada uno, un “Tercero Interesado”), usted deberá indemnizar a Alder Commons (y a nuestros funcionarios, directores, empleados, voluntarios, y agentes) contra cualquiera de las cantidades que sean condenadas o pagadas en acuerdo ante cualquier tercero interesado junto con todos los gastos corrientes (incluyendo las cuotas para la presentación judicial, las costas procesales, los costos de arbitraje, los honorarios de testigos, así como los honorarios y desembolsos de abogados y otros profesionales) generados en la defensa de un tercero interesado o en cualquier investigación o negociación relacionada. Esencialmente: si usted ocasiona que Alder Commons sea demandado por algo que usted hizo, usted está aceptando pagar todos los costos ocasionados a Alder Commons en la defensa de dichos asuntos y de cualquier daño o sentencias resultantes.


### 9. Resolución de Disputas

Debido al tiempo y los costos asociados con el litigio convencional, sin mencionar la presión que pone sobre el sistema judicial público, las partes acuerdan resolver todas sus disputas mediante una resolución de conflictos alternativa. Cualquier controversia que surja bajo la entrada en vigor del presente contrato, deberá ser sometida en primera instancia a una mediación no vinculante a cargo de un mediador mutuamente acordado por las partes. El mediador trabajará con las partes para encontrar una solución mutuamente aceptable a la disputa. En caso de que la mediación no tenga éxito, la controversia se resolverá mediante arbitraje vinculante en Portland, Oregón, por un árbitro y de acuerdo con las reglas vigentes en ese momento del Servicio de Arbitraje de Portland, Inc. (u otro servicio de arbitraje que las partes acuerden por escrito). El árbitro (juez privado) tendrá la autoridad de tomar una decisión final y vinculante respecto a la disputa. El laudo podrá dictarse en cualquier tribunal que tenga jurisdicción. Por la presente, cada parte renuncia a todo derecho a un juicio con un jurado por cualquier reclamación.


### 10. Disposiciones varias 

Este contrato se regirá, interpretará, y aplicará de acuerdo con las leyes del Estado de Oregón (excluyendo cualquier elección de disposiciones legales). Si cualquier demanda o acción es presentada por cualquiera de las partes para hacer cumplir este contrato o de otra manera con respecto al asunto del contrato, la jurisdicción será en los tribunales federales o estatales localizados en el Condado de Multnomah, Oregón; y las partes dan su consentimiento a la jurisdicción del tribunal o tribunales localizados en Oregón y a la notificación del proceso por correo certificado, solicitud de acuse de recibo, o de cualquier otra manera que disponga la ley. Si alguna disposición de este acuerdo resultara ser inválida, nula o ilegal, de ninguna manera afectará, alterará, o invalidará en modo alguno a cualquier otra disposición del presente contrato. Ninguna renuncia a cualquier disposición de este contrato es efectiva a menos que esté (a) escrita y (b) firmada por la parte renunciante. Las partes reconocen que cualquier retraso o falta en hacer cumplir los derechos de las partes bajo este contrato, no pretende ser una renuncia a tales derechos. Los términos contenidos en las secciones 4, 5, 8, 9 y 10 sobrevivirán a la rescisión de este contrato.


### 11. Comunicaciones

Si necesita ponerse en contacto con nosotros, puede llamarnos al (503) 893-9366, enviarnos un correo electrónico a [hello@aldercommons.org,](mailto:hello@aldercommons.org) o escribirnos una carta y enviarla a la dirección 4212 NE Prescott St. Portland, OR 97218. También puede solicitar hablar con el personal siempre que esté en las instalaciones de Alder Commons.

Si necesitáramos comunicarnos con usted, usaremos la información de contacto que nos ha proporcionado, o hablaremos con usted en persona cuando se encuentre en las instalaciones de Alder Commons. Usted acepta notificarnos sobre cualquier cambio en la información de contacto que nos ha proporcionado (incluso si los cambios son temporales). Si no podemos comunicarnos con usted por más de 30 días, podemos cancelar su membresía.

---

Al firmar a continuación, usted reconoce que (a) ha leído cuidadosamente este documento en su totalidad y (b) acepta todos sus términos.`,
    },
    {
      title: "Aceptación De Riesgo Y Renuncia De Responsabilidad",
      type: FileType.Waiver,
      body: `Debido al riesgo de lesiones u otros daños involucrados, no podríamos brindarle(s) ciertos servicios a usted (y otros) si usted (y otros) no firmaran esta Aceptación de Riesgo y Renuncia de Responsabilidad. Si usted no está de acuerdo con los términos establecidos en este documento, por favor, **no utilice nuestros servicios.**

Al firmar este documento, usted reconoce y acepta que: 

1. Usted seguirá nuestras instrucciones, reglas, y políticas relacionadas con el uso de nuestros servicios;
2. participar en los tipos de juegos experimentales y actividades de aventura que ofrecemos, implica riesgos de lesiones o daños graves; 
3. usar herramientas eléctricas, herramientas manuales, y otros equipos, puede ser peligroso (incluso si son usadas correctamente) y puede causar lesiones o daños graves;
4. subirse a y saltar de estructuras y objetos puede ser peligroso y puede provocar lesiones o daños graves;
5. enfermedades infecciosas (como Covid-19 y otros virus) pueden ser altamente contagiosas y pueden provocar lesiones o daños significativos;
6. al usar nuestros servicios, es probable que usted aumente su riesgo de lesiones o daño;
7. usted asume voluntariamente todos estos (y otros) riesgos (ya sean conocidos o desconocidos) relacionados al uso de nuestros servicios.

Adicionalmente, al firmar este documento:

1. Usted **renuncia** a sus derechos (si corresponde) de presentar alguna demanda legal ( o de otro tipo) en contra de nosotros (o de nuestros directores, funcionarios, empleados, voluntarios, representantes, y agentes) relacionada con el uso de nuestros servicios;
2. usted nos **libera** (y a nuestros directores, funcionarios, empleados, voluntarios, representantes, y agentes) de cualquier responsabilidad u obligación relacionada con el uso de nuestros servicios; y
3. usted deberá defendernos e indemnizarnos (y a nuestros directores, funcionarios, empleados, voluntarios, representantes, y agentes) de cualquier demanda legal (o de otro tipo) (realizada por cualquier otra persona que no sea usted) contra nosotros (o cualquiera de nuestros directores, funcionarios, empleados, voluntarios, representantes, o agentes) relacionada a su uso de nuestros servicios.

Para aclarar, el párrafo anterior significa, que si usted usa nuestros servicios y se lesiona o daña de alguna manera (o si lesiona o causa daño a alguna persona), nosotros no somos responsables de ninguna manera y usted no puede presentar ninguna demanda legal (u de otro tipo) en contra de nosotros; si alguien más presentara una demanda legal (u de otro tipo) en contra de nosotros por el uso que usted hizo de nuestros servicios, usted se compromete a defendernos (es decir, que pagará todos nuestros gastos legales) e indemnizará (es decir, pagará cualquier indemnización, sentencia, o monto de liquidación).

---

Por último, al firmar este documento, usted reconoce que:

1. Ha leído y entendido este documento en su totalidad;
2. ha consultado a su asesor legal (o ha renunciado deliberadamente a su derecho de hacerlo); y
3. acepta todos los términos de este documento.

**Nota para las personas menores de 18 años:** Si usted es menor de 18 años, uno de sus padres o tutor legal también debe de firmar este documento.

**Nota para los Padres y Tutores Legales:** Si usted está firmando este documento como padre o tutor legal de un menor de 18 años, todas las referencias en este documento a “usted” aplican tanto para usted como para su(s) hijo(a)(as)(os).`,
    },
  ],
};
