import API from 'Api'
import { HouseholdContainer } from 'Context/Household'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Form, Message } from 'semantic-ui-react'
import Dollars, { usd } from 'Shared/Dollars'
import { useFields, useRequest } from 'Shared/Hooks'
import { FrequencyOptions } from 'Shared/MembershipFrequency'
import { NumberField } from 'Shared/Number'
import Tooltip from 'Shared/Tooltip'
import getSuggestedPrice, { minimumFee } from 'SlidingScale';

import { countByAge } from './MembershipPeopleForm'


const MembershipFeeForm = () => {
  const {household, membership, load} = useContext(HouseholdContainer);
  const [loading, error, run] = useRequest({});
  const [suggestedPrice, setSuggestedPrice] = useState(0);
  const {fields, handleChange} = useFields(Object.assign({}, membership));
  
  const {adults, youth, days, income, amount} = fields;

  useEffect(()=>{
    const {adults, youth} = countByAge(household.people);
    handleChange(null, {name:'adults', value: adults});
    handleChange(null, {name:'youth', value: youth});
  }, [household, handleChange])


  useEffect(()=>{  
    const newPrice = getSuggestedPrice(adults, youth, days, income, household.is_org);
    if (newPrice !== suggestedPrice) {
      setSuggestedPrice(newPrice);
      if (suggestedPrice === amount || !amount) {
        handleChange(null, {name:'amount', value: newPrice});
      }
    }
  }, [suggestedPrice, household.is_org, adults, youth, days, income, amount, handleChange])

  const handleSubmit = () => {
    const validate = () => {
      if (fields.amount < minimumFee(household.is_org)) {
        return Promise.reject("You must enter a membership fee of at least " + usd.format(minimumFee(household.is_org)));
      }
  
      return Promise.resolve();
    }
  
    let promise = validate()
      .then(()=>{
        return API.updateHouseholdMembership(household.id, fields, 4);
      });

    run(promise, load);
  }

  const { subscription_id } =  membership;
  if (subscription_id) {
    return <Message>This account already has autopay configured. Please <a href="mailto:hello@aldercommons.org">contact us</a> if you need to make changes!</Message>
  }

  return <>
    <p>We use income data to calculate suggested fees and to track our impact. This information is kept confidential, but if you don't feel comfortable sharing this information with us, you can set it to $0 before saving this form!</p>
    <Form error name="fee" loading={loading} onSubmit={handleSubmit}>
      <Message error>{error}</Message>
      <NumberField
        required
        innerLabel={{basic: true, content:'$'}}
        style={{maxWidth: '10em'}}
        label={!household.is_org ? 'Annual Household Income' : 'Annual Organizational Budget'} 
        name="income"
        min={0}
        value={income}
        onChange={handleChange} />

      <Form.Select
        style={{maxWidth: '20em'}}
        name="days"
        label="Frequency"
        required
        value={days}
        onChange={handleChange} 
        options={FrequencyOptions}/>

      <Form.Field>
        <label>Suggested Monthly Membership Fee <Tooltip>
            Membership Fees are <i>not</i> tax-deductible.
        </Tooltip></label>
        <Dollars amount={suggestedPrice}/>
      </Form.Field>

      { (suggestedPrice || amount > 0) && 
        <NumberField
          label={<>
            Your Monthly Membership Fee <Tooltip>
              <Dollars amount={suggestedPrice}/> is our suggested fee. You can offer a different amount but must meet our minimum of {usd.format(minimumFee(household.is_org))}.
            </Tooltip>
          </>} 
          style={{maxWidth: '10em'}}
          innerLabel={{basic: true, content:'$'}}
          name="amount"
          min={0}
          required
          value={amount}
          onChange={handleChange}
        />}

      <Button primary disabled={!suggestedPrice && !amount} type="submit">Save</Button>
    </Form>
  </>
}

export default MembershipFeeForm;
