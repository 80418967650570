import React from 'react'
import ResponsiveTabs, { ResponsiveTabsProps } from 'Shared/ResponsiveTabs'

import Codes from './Codes'
import CreatePayment from './CreatePayment'
import CreatePerson from './CreatePerson'
import Dedupe from './Dedupe'
import { Docs } from './Docs'
import Donors from './Donors'
import InterestTags from './InterestTags'
import Openpath from './Openpath'
import Stats from './Stats'
import Tags from './Tags'
import { Tasks } from './Tasks'

const Misc = (props: ResponsiveTabsProps) => (
  <ResponsiveTabs {...props} menuProps={{secondary: true}} items={[
    {admin: true, path: 'stats', name: "Stats", component: Stats},
    {admin: true, path: 'docs', name: "Docs", component: Docs},
    {admin: true, path: 'openpath', name: "Openpath", component: Openpath},
    {admin: true, path: 'donors', name: "Donors", component: Donors},
    {admin: true, path: 'tags', name: "Tags", component: Tags},
    {admin: true, path: 'interest-tags', name: "Interest Tags", component: InterestTags},
    {admin: true, path: 'tasks', name: "Tasks", component: Tasks},
    {staff: true, path: 'codes', name: "Codes", component: Codes},
    {admin: true, path: 'create-payment-link', name: 'Payment Links', component: CreatePayment},
    {admin: true, path: 'dedupe', name: 'Dedupe', component: Dedupe},
    {admin: true, path: 'person', name: 'Create Person', component: CreatePerson}
  ]}/>
);

export default Misc;
