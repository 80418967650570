import API from 'Api'
import { PersonContainer } from 'Context/Person'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Divider, Form, Label, Loader, Message } from 'semantic-ui-react'
import { isAdult } from 'Shared/Age'
import { AltStatus, AltStatusOptions, CheckrStatus } from 'Shared/BackgroundStatus'
import { useFields, useRequest } from 'Shared/Hooks'
import { Person, Report, User } from 'Shared/Models'

import { ReportReview } from './ReportReview'

const BackgroundChecker = () => {
  const {person} = useContext(PersonContainer);
  const {user} = person;

  const [loading, error, run] = useRequest<any>({});

  const [reports, setReports] = useState([])
  const [invitationURL, setInvitationURL] = useState('')
  const [candidateID, setCandidateID] = useState(user && user.candidate_id)

  const loadReports = useCallback(()=> {
    if (user && user.candidate_id) {
      run(API.getCheckrReports(user.id)
        .then(reports => {
          setReports(reports)
          if (!reports || reports.length === 0) {
            return API.getCheckrInvites(user.id)
          }
        })
        .then(invites=>{
          if (invites && invites.length > 0) {
            setInvitationURL(invites[0].invitation_url);
          }
        })
      )
    }
  }, [run, user])

  useEffect(loadReports, [loadReports]);

  const handleSubmit = () => {
    run(API.createCheckrInvite(user.id), ({candidate_id, invitation_url}: {candidate_id: string, invitation_url: string})=>{
      setCandidateID(candidate_id)
      setInvitationURL(invitation_url);
    });
  }

  if (!user) {
    return <Message negative>This person doesn't have a user account.</Message>
  }

  return <>
    <AltStatusForm user={user}/>
    <Divider/>
    {candidateID && <p>
      <a href={"https://dashboard.checkr.com/candidates/" + candidateID}>View Candidate on Checkr</a>
    </p>}
    {error && <Message negative>{error}</Message>}
    {loading && <Loader active inline/>}
    {!loading && reports.length > 0 && <Reports reports={reports} loadReports={loadReports}/>}
    {!loading && reports.length === 0 && <p>No checkr reports to show, yet.</p>}

    {invitationURL && <p>The following link can be shared with a candidate to <a rel="noopener noreferrer" href={invitationURL} target="_blank">submit identifying information</a> and begin the background check.</p>}
    {!invitationURL && reports.length === 0 && !error && 
      <Form error name="invite" loading={loading} onSubmit={handleSubmit}>
        <Button primary type="submit">Start a background check for {person.first}</Button>
      </Form>}
  </>
}

export default BackgroundChecker;

export const BackgroundCheckStatus = ({person}: {person: Person}) => {
  const {user} = person;
  const [loading, error, run, reports] = useRequest([]);
  const loadReports = useCallback(()=>{
    if (user && user.candidate_id) {
      run(API.getCheckrReports(user.id))
    }
  }, [run, user])

  useEffect(loadReports, [loadReports]);

  if (!isAdult(person)) {
    return <>Under 18</>
  }
  if (!user) {
    return <>Need email to run background check</>
  }
  if (user.background_check_alt) {
    return <AltStatus status={user.background_check_alt}/>
  }
  if (loading) {
    return <Loader inline active size='tiny'/>
  }
  if (error) {
    return <Label size='tiny' color='red' content={error}/>
  }
  if (!user.candidate_id) {
    return <Label size='tiny' color='red' content='Not Invited'/>
  }
  if (reports.length === 0) {
    return <Label size='tiny' color='orange' content='Invite Sent'/>
  }
  return <Reports reports={reports} loadReports={loadReports}/>
}

const Reports = ({reports, loadReports}: {reports: Report[], loadReports: ()=>void}) => (
  <>
    {reports.filter(Boolean).map(r => (
      <CheckrReport key={r.id} report={r} loadReports={loadReports}/>
    ))}
  </>
)

const AltStatusForm = ({user}: {user: User}) => {
  const [loading, error, run] = useRequest({})
  const {fields, handleChange} = useFields(Object.assign({}, user))

  const handleSubmit = () => {
    run(API.updateUser(fields.id, fields))
  }

  const { background_check_alt } = fields;

  return (
    <Form onSubmit={handleSubmit} error>
      <Message error>{error}</Message>
      <Form.Group inline>
        <Form.Select
          style={{minWidth:'10em'}}
          name='background_check_alt'
          value={background_check_alt}
          disabled={loading}
          loading={loading}
          onChange={handleChange}
          options={AltStatusOptions}
        />
        <Form.Button>Update Alt Bg Check Status</Form.Button>
      </Form.Group>
    </Form>
  );
}

const CheckrReport = ({report, loadReports}: {report: Report, loadReports: ()=>void}) => {
  return <div>
    <CheckrStatus status={report.status}/>
    {report.status === "consider" && 
      <> <ReportReview report={report} loadReports={loadReports}/></>}
  </div>
}

