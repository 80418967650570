import API from 'Api'
import Fuse from 'fuse.js'
import moment from 'moment';
import React, {  useEffect, useState } from 'react'
import { Form, Loader, Message } from 'semantic-ui-react'
import { useFields, useRequest } from 'Shared/Hooks';
import { Program } from 'Shared/Models';
import { ProgramCardGroup } from 'Shared/ProgramCardGroup';
import SimplePage from 'Shared/SimplePage'

const Search = () => {
  const [loading, error, run, programs] = useRequest([])
  const {fields, handleChange} = useFields({search: ''})
  const [fuse, setFuse] = useState<Fuse<Program>|undefined>();

  // load programs for the next year
  useEffect(()=> {
    run(API.getPrograms({
      before: moment.tz('America/Los_Angeles').utc(true).add(1, 'year').format(),
      after: moment.tz('America/Los_Angeles').utc(true).format(),
      hydrate: 'true'
    }), programs => {
      setFuse(new Fuse(programs, {
        threshold: 0.3,
        keys: ['title', 'description', 'host_text']
      }));
    });
  }, [run]);

  // parse query params
  useEffect(()=> {
    let url = new URL(String(window.location));
    if (url.searchParams.get('s')) {
      handleChange(null, {name:'search', value: url.searchParams.get('s') || ''})
    }
  }, [handleChange]);
  
  // update query params
  useEffect(() => {
    let url = new URL(window.location.origin + window.location.pathname);
    if (fields.search) {
      url.searchParams.append("s", fields.search);
    }
    window.history.replaceState(null, '', url);
  }, [fields])

  // filter!
  const result = fields.search  && fuse ? fuse.search(fields.search).map(({item}) => (item)) : programs;

  return (
    <SimplePage icon='search' title='Search'>
      <Form>
        <Form.Input
          name="search"
          placeholder="Search programs by name, host, topic, etc."
          className='calendar-search'
          value={fields.search}
          onChange={handleChange}
          style={{maxWidth:'25em', marginBottom: '0.5em'}} />
      </Form>
      {error && <Message negative>{error}</Message>}
      {loading && <Loader active inline/>}
      {result.length === 0 ? 
        <Message>No upcoming programs matched your search.</Message> : 
        <ProgramCardGroup list={result} />}
    </SimplePage>
  );
}

export default Search;
