import API from 'Api'
import qs from 'qs'
import React, { useCallback, useEffect } from 'react'
import { useLocation } from 'react-router'
import { Button, Form, Message } from 'semantic-ui-react'
import { useFields, useRequest } from 'Shared/Hooks'
import { User } from 'Shared/Models'

const ResetForm: React.FC = () => {
  const location = useLocation();
  const [loading, error, run, newUser] = useRequest({} as User);
  const {fields, handleChange} = useFields({
    email: ''
  })

  useEffect(()=>{
    if (location) {
      const { email } = qs.parse(location.search,  { ignoreQueryPrefix: true });
      if (email) {
        handleChange(null, {name: 'email', value: decodeURIComponent(email as string)});
      }
    }
  }, [location, handleChange])
  
  const handleSubmit = useCallback(() => {
    run(API.reset(fields))
  }, [run, fields]);

  const { email } = fields;

  if (newUser && newUser.id) {
    return <Message positive>Check your email for a link to reset your password.</Message>
  }

  return (
    <Form error name="reset" loading={loading} onSubmit={handleSubmit}>
      <Message error>{error}</Message>
      <Form.Input
        name="email"
        type="email"
        label="Email"
        required
        value={email}
        onChange={handleChange} />
      <Button primary fluid type="submit">Reset My Password</Button>
    </Form>
  );
}

export default ResetForm;
