import { UserContainer } from 'Context/User'
import React, { useContext } from 'react'
import { Loader } from 'semantic-ui-react'

import { User, UserRole, UserStatus } from './Models';


export const IsAdmin = (user: User) => (
  user && user.role === UserRole.Admin
);

export const Admin: React.FC<React.PropsWithChildren> = ({children}) => (
  <Access children={children} check={IsAdmin}/>
);

// Staff OR Admin
export const staffCheck = (user: User) => (
  (user.role === UserRole.Staff || user.role === UserRole.Admin)
);

export const Staff: React.FC<React.PropsWithChildren> = ({children}) => (
  <Access children={children} check={staffCheck}/>
);

export const NotStaff: React.FC<React.PropsWithChildren> = ({children}) => (
  <Access children={children} check={(user)=>(
    user.role < UserRole.Staff
  )}/>
);

export const LoggedIn: React.FC<React.PropsWithChildren> = ({children}) => (
  <Access children={children} check={(user)=>(
    user.id > 0 && user.status === UserStatus.Active
  )}/>
);

// HasUser might not have their email verified
export const HasUser: React.FC<React.PropsWithChildren> = ({children}) => (
  <Access children={children} check={(user)=>(
    user.id > 0
  )}/>
);

export const IsMember = (user: User) => {
  if (user.role === UserRole.Admin || user.role === UserRole.Staff) {
    return true;
  }
  if (user.id > 0 && user.status === UserStatus.Active && user.person.is_member) {
    return true;
  }
  return false;
}

export const Member: React.FC<React.PropsWithChildren> = ({children}) => (
  <Access children={children} check={IsMember}/>
);

export const Anonymous: React.FC<React.PropsWithChildren> = ({children}) => (
  <Access children={children} check={(user)=>(!user || !user.id)}/>
);

type AccessProps = React.PropsWithChildren<{
  check: (u: User) => boolean
}>

const Access: React.FC<AccessProps> = ({children, check}) => {
  const {user, userLoading} = useContext(UserContainer);
  if (!children) {
    return <></>;
  }

  if (userLoading) {
    return <Loader active/>
  }

  return check(user) ? <>{children}</> : <></>;
}
