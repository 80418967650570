import API from "Api";
import { HouseholdContainer } from "Context/Household";
import { UserContainer } from "Context/User";
import React, { useContext, useRef } from "react";
import { Button, Form, List, Message } from "semantic-ui-react";
import { isAdult } from "Shared/Age";
import Dollars from "Shared/Dollars";
import { useRequest } from "Shared/Hooks";
import { Household, HouseholdPersonRole } from "Shared/Models";
import PaymentMethod from "Shared/PaymentMethod";
import Tooltip from "Shared/Tooltip";

const minimumOnboardingFee = 15;
const minimumOrgOnboardingFee = 50;
const backgroundCheckFee = 26;

const getBackgroundCheckableAdults = (household: Household) => {
  return household.people.filter(({ role, person }) => {
    return (
      role !== HouseholdPersonRole.Inactive &&
      isAdult(person) && // eligible
      person.user &&
      person.user.background_check_alt === 0 &&
      person.user.candidate_id === ""
    ); // doesn't already have a background check
  });
};

const MembershipBackgroundCheckForm = () => {
  const { household, membership, load } = useContext(HouseholdContainer);
  const { user } = useContext(UserContainer);
  const pm = useRef<{getPaymentMethod: () => Promise<{ payment_method_id: string }>}>(null);

  const [loading, error, run] = useRequest({});
  const adultsToCheck = getBackgroundCheckableAdults(household);
  const amount = household.is_org
    ? Math.max(minimumOrgOnboardingFee, backgroundCheckFee * adultsToCheck.length)
    : Math.max(minimumOnboardingFee, backgroundCheckFee * adultsToCheck.length);

  const handleSubmit = () => {
    run(
      handlePayment()
        .then(() => {
          return initiateBackgroundChecks();
        })
        .then(() => {
          return API.updateHouseholdMembership(household.id, membership, 7);
        }),
      load
    );
  };

  const handlePayment = () => {
    if (!pm.current) {
      return Promise.reject("Please enter your payment information.");
    }

    return pm.current
      .getPaymentMethod()
      .then(({ payment_method_id }) => {
        return API.createPayment({
          user: {
            id: user.id,
          },
          amount,
          payment_method_id,
          description: "Onboarding Fees",
        });
      })
      .then((result) => {
        if (result && result.success) {
          return Promise.resolve();
        } else {
          return Promise.reject(
            "We encountered a mysterious error... Please contact us at hello@aldercommons.org."
          );
        }
      });
  };

  const initiateBackgroundChecks = () => {
    return Promise.all(
      getBackgroundCheckableAdults(household).map((hp) =>
        API.createCheckrInvite(hp.person.user.id)
      )
    );
  };

  return (
    <>
      {adultsToCheck.length === 0 ? (
        <NoCheckrDescription />
      ) : (
        <>
          <CheckrDescription />
          <List>
            {adultsToCheck.map(({ person }) => (
              <List.Item
                icon="user"
                key={person.id}
                content={`${person.first} ${person.last}`}
              />
            ))}
          </List>
        </>
      )}

      <Message>
        There is a minimum onboarding charge of{" "}
        <Dollars
          amount={
            household.is_org ? minimumOrgOnboardingFee : minimumOnboardingFee
          }
        />
        . Onboarding costs $26 per adult, so you may be charged beyond the
        minimum below.
      </Message>

      {error && (
        <Message negative>
          {typeof error === "object" ? JSON.stringify(error) : error}
        </Message>
      )}

      <Form name="background-checks" loading={loading} onSubmit={handleSubmit}>
        <Form.Field>
          <label>Onboarding Fees</label>
          <Dollars amount={amount} />
        </Form.Field>

        <PaymentMethod ref={pm} />

        <Button primary type="submit">
          Pay <Dollars amount={amount} />{" "}
          {adultsToCheck.length > 0 ? "and Initiate Background Checks" : ""}
        </Button>
      </Form>
    </>
  );
};

const CheckrDescription = () => (
  <>
    <p>
      All adult Members of Alder Commons need to pass a background check that we
      run through{" "}
      <a href="https://checkr.com" target="_blank" rel="noopener noreferrer">
        Checkr
      </a>
      . These checks often complete the same day they are submitted, but may
      take longer depending on your residency history and other factors. If you
      don't have a Social Security Number, which is required for automated
      background checks, please{" "}
      <a href="mailto:karl@aldercommons.org">contact us</a>.
    </p>
    <p>
      Checkr will email background check invitations to the following Members{" "}
      <Tooltip content="You may have adult members of your household missing from this list if we already initiated a background check for them." />
    </p>
  </>
);

const NoCheckrDescription = () => (
  <>
    <p>
      We charge an onboarding fee to help us cover the cost of bringing on new
      members, as well as to ensure that we have a valid card on file for your
      household.
    </p>
    <p>
      <strong>If you've already paid for onboarding fees, do not 
      submit this form again!</strong>
    </p>
  </>
);
export default MembershipBackgroundCheckForm;
