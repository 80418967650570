import API from 'Api'
import React, { useEffect } from 'react'
import { Redirect } from 'react-router'
import { Button, Form, Message, Segment } from 'semantic-ui-react'
import { useFields, useRequest } from 'Shared/Hooks'
import { Person, User } from 'Shared/Models'
import PersonFields from 'Shared/PersonFields'

const CreatePerson = () => {
  const [loading, error, run, success] = useRequest<{id: number} | false>(false);
  const {fields, handleChange} = useFields<{person: Person}>({person: {
    user: {} as User
  } as Person})

  useEffect(()=>{
    if (error) {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }, [error])

  const handleSubmit = () => {
    run(API.createPerson(fields.person));
  }

  if (success) {
    return <Redirect to={{
      pathname: `/person/${success.id}`,
      state: { reload: true }
    }}/>
  }

  return <Segment>
    <Form error name="Person" loading={loading} onSubmit={handleSubmit}>
      <Message error>{error}</Message>
      <PersonFields onChange={handleChange} person={fields.person} phoneRequired={false}/>
      <Button primary type="submit">Save</Button>
    </Form>
  </Segment>;
}


export default CreatePerson;
