import React, { useEffect } from 'react'
import { Divider, Form, Label } from 'semantic-ui-react'
import Dollars from 'Shared/Dollars'
import { ChangeHandler } from 'Shared/Hooks'
import { Program, RegistrationType } from 'Shared/Models'
import { NumberField } from 'Shared/Number'
import { Panel } from 'Shared/Panel'
import Tooltip from 'Shared/Tooltip'

import { locations } from './options'
import { RegistrationTypeOptions } from './RegistrationTypes'

const absenteeFactor = 0.8; // how many empty seats can we still run the program, assuming all registrants pay minimum
const absoluteMinimumFee = 5;

export const ProgramFormRegistration = ({fields, handleChange}: {fields: Program, handleChange: ChangeHandler}) => {
  const {registration_type, location, min_price, member_price, capacity, waitlist, registration_message, reminder} = fields;

  const reccomendedFeeMinimum = Math.max(absoluteMinimumFee, Math.ceil((fields.facilitator_compensation * 2 + fields.location_fee + fields.facilitator_reimbursement) / Math.floor(capacity * absenteeFactor)));

  useEffect(()=>{
    if (registration_type === RegistrationType.None) {
      handleChange(null, {name:'capacity', value: 0})
    }
  }, [registration_type, handleChange])

  return <Panel heading='Registration'>
    <Form.Group widths='equal'>
      <Form.Field>
        <label>
          Registration Type <Tooltip wide="very" content={<>
            <p><Label size='tiny' content='None'/> means the Link Label and Link URL will be turned into a button visibile directly on the event page.</p>
            <p><Label size='tiny' content='Donation Optional'/> means we will keep track of registrations, and anyone can register.</p>
            <p><Label size='tiny' content='Payment Optional'/>  means members can register for free, and sliding scale for others can go to zero.</p>
            <p><Label size='tiny' content='Payment Required'/> means members can register for free, and others must meet a minimum payment.</p>
            <p><Label size='tiny' content='Members-Only'/> means only active members can register.</p>
            <p><Label size='tiny' content='Members-Discounted'/> means members can pay a different lower price, including potentially $0.</p>
          </>}/>
        </label>
        <Form.Select 
          name='registration_type' 
          value={registration_type}
          options={RegistrationTypeOptions}
          onChange={handleChange}
        />
      </Form.Field>

      { registration_type !== RegistrationType.None &&
        <Form.Field>
          <label>Capacity <Tooltip content='Use 0 for unlimited capacity.'/></label>
          <NumberField
            name='capacity'
            value={capacity}
            onChange={handleChange}
          />
        </Form.Field>}

      { registration_type !== RegistrationType.None &&
        <Form.Field>
          <label>Enable Waitlist</label>
          <Form.Checkbox
            toggle
            name='waitlist'
            checked={waitlist}
            onChange={handleChange}/>
        </Form.Field>}

      { registration_type !== RegistrationType.None &&
        <Form.Field>
          <label>Enable Auto-Reminder</label>
          <Form.Checkbox
            toggle
            name='reminder'
            checked={reminder}
            onChange={handleChange}/>
        </Form.Field>}
    </Form.Group>

    <Form.Group widths='equal'>
      { (registration_type === RegistrationType.PaymentRequired || registration_type === RegistrationType.PaymentOptional || registration_type === RegistrationType.MembersDiscounted) &&
          <Form.Field>
            <label>Base Payment <Tooltip content='The sliding scale will also include, 1.5x and 2x the base.'/></label>
            <NumberField
              min={absoluteMinimumFee}
              innerLabel={{basic: true, content:'$'}}
              name='min_price'
              value={min_price}
              onChange={handleChange}
            />
            Mininum: <Dollars amount={reccomendedFeeMinimum}/> <Tooltip content={`Minimum fee assuming ${absenteeFactor * 100}% fill rate, with all registrants paying the minimum amount. $${absoluteMinimumFee} absolute minimum. Note: this doesn't factor in member discount registrations.`}/>
          </Form.Field>}

      { (registration_type === RegistrationType.MembersDiscounted) &&
        <Form.Field>
          <label>Member Fee <Tooltip content='Fee for members, can be $0!'/></label>
          <NumberField
            min={0}
            innerLabel={{basic: true, content:'$'}}
            name='member_price'
            value={member_price}
            onChange={handleChange}
          />
        </Form.Field>}
    </Form.Group>

    { registration_type !== RegistrationType.None &&
      <>
        <Form.Group widths='equal'>
          <Form.Field>
            <label>Additional Information for Registrants <Tooltip content="This is where we can remind about materials, 'homework', or share 'for registrants only' information." /></label>
            <Form.TextArea
              rows={3}
              name='registration_message'
              value={registration_message}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Registration Email Sample <Tooltip content="The title, time, link, etc. don't reflect this program's registration email, this preview is just to show you where the additional info shows up." /></label>
            Dear Karl,
            <br/><br/>
            Thank you for registering for Fortnite Club.
            <br/><br/>
            This program occurs Wednesday, February 3, 2021 from 1:30pm-2:30pm PT.
            <br/><br/>
            {registration_message && <>{registration_message}<br/><br/></>}
            You can click the following link to Join on Zoom: https://zoom.us/1234567
            <br/><br/>
            With gratitude,<br/>
            Alder Commons Organizing Team
          </Form.Field>
        </Form.Group>
      </>
    }

    {/* we only need to customize the online program links for external links */}
    {registration_type === RegistrationType.None && location !== locations.zoom && <>
      <CustomLink fields={fields} handleChange={handleChange} />
    </>}
  </Panel>
}


const CustomLink = ({fields: {link, link_label}, handleChange}: {fields: Program, handleChange: ChangeHandler}) => {
  return <>
    <Divider/>
    <p>Link fields should only be modified for <b>third-party registration</b>, like another organization's event hosted on eventbrite.</p>
    <Form.Field>
      <label>Link Label <Tooltip content="Visit the Website, Register on Eventbrite, etc." /></label>
      <Form.Input
        placeholder='Register on Eventbrite'
        name='link_label'
        value={link_label}
        onChange={handleChange}/>
    </Form.Field>

    <Form.Field>
      <label>Link URL <Tooltip content="URL the zoom meeting, external eventbrite, etc." /></label>
      <Form.Input
        placeholder='https://someother.site'
        name='link'
        value={link}
        onChange={handleChange}/>
    </Form.Field>
  </>
}
