import API from 'Api'
import { PersonContainer } from 'Context/Person'
import moment from 'moment'
import React, { useCallback, useContext, useEffect } from 'react'
import { Grid, List, Message } from 'semantic-ui-react'
import Dollars from 'Shared/Dollars'
import { useRequest } from 'Shared/Hooks'
import { Customer, Sub } from 'Shared/Models'
import { Panel } from 'Shared/Panel'
import PaymentCard from 'Shared/PaymentCard'
import { ListPlaceholder } from 'Shared/Placeholders'
import { Admin } from 'Shared/Roles'

import { DonationForm } from './DonationForm'
import PaymentHistory from './PaymentHistory'

const Billing = () => {
  const [loading, error, run, customer] = useRequest<Customer>({} as Customer)
  const {person} = useContext(PersonContainer);
  const {user} = person;

  const loadCustomer = useCallback(()=>{
    run(API.getCustomer(user.id))
  }, [run, user])

  useEffect(loadCustomer, [loadCustomer]);

  return <>
    {error && <Message error>{error}</Message>}
    <Admin>
      {customer && <p>
        <a href={`https://dashboard.stripe.com/customers/${customer.id}`} rel="noreferrer" target="_blank">Go to Stripe Dashboard</a>
      </p>}
    </Admin>
    <Grid columns={2} stackable>
      <Grid.Column width={6}>
        <Panel icon='payment' heading='Payment Method'>
          {loading && <ListPlaceholder/>}
          {!loading && <PaymentCard cardholder_id={user.id} customer={customer} onSuccess={loadCustomer} />}
        </Panel>

        <Panel icon='repeat' heading='Recurring Charges'>
          {loading ? <ListPlaceholder/> : <>
            {customer && customer.subscriptions && customer.subscriptions.data && customer.subscriptions.data.length > 0 ?
              <Subs subs={customer.subscriptions.data} /> : 'None'}
          </>}
        </Panel>
        <Admin>
          <Panel icon='dollar' heading='Add Non-Stripe Donation'>
            <DonationForm/>
          </Panel>
        </Admin>
      </Grid.Column>
      <Grid.Column width={10}>
        <Panel icon='history' heading='Payment History'>
          <PaymentHistory/>
        </Panel>
      </Grid.Column>
    </Grid>
  </>
}

export default Billing;

const Subs = ({subs} : {subs: Sub[]}) => {
  return <List>
    {subs.map(sub => {
      return <List.Item key={sub.id}>{sub.plan.nickname} - <Dollars amount={sub.quantity}/><br/><i>Next charge on {moment.unix(sub.current_period_end).format('LL')}</i></List.Item>
    })}
  </List>
}
