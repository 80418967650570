import API from 'Api'
import { PersonContainer } from 'Context/Person'
import moment from 'moment-timezone'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Message } from 'semantic-ui-react'
import { getNextOrLast } from 'Shared/Date'
import { useRequest } from 'Shared/Hooks'
import { Registration } from 'Shared/Models'
import { RegistrationCardGroup } from 'Shared/ProgramCardGroup'

const Registrations = () => {
  const {person} = useContext(PersonContainer);
  
  const [loading, error, run] = useRequest<Registration[]>([])
  const [upcoming, setUpcoming] = useState<Registration[]>([])
  const [past, setPast] = useState<Registration[]>([])

  // load func is run again if folks cancel
  const load = useCallback(()=>{
    run(API.getPersonRegistrations(person.id), registrations => {
      registrations.sort((a,b)=>{
        return getNextOrLast(a.program) > getNextOrLast(b.program) ? 1 : -1
      });

      setUpcoming(registrations.filter(r=>{
        return moment(getNextOrLast(r.program)).isAfter(moment.tz('America/Los_Angeles').utc(true));
      }))

      setPast(registrations.filter(r=>{
        return moment(getNextOrLast(r.program)).isBefore(moment.tz('America/Los_Angeles').utc(true));
      }).reverse());
    })
  }, [run, person])

  useEffect(load, [load])

  return <>
    {error && <Message error>{error}</Message>}
    <RegistrationCardGroup loading={loading} title='Upcoming Registrations' list={upcoming}/>
    <RegistrationCardGroup loading={loading} title='Past Registrations' list={past}/>
  </>;
}

export default Registrations;
