import API from 'Api'
import React, {useCallback} from 'react'
import { Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import { Toggler } from 'Shared/Toggler';

import { User } from './Models';

export const OpenpathStatus = ({user}: {user: User}) => {
  const init = useCallback(() => {
    return API.getOpenpathUser(user.id)
      .then(({data})=>{
        return Promise.resolve(data && data.status === 'A')
      })
  }, [user])

  const createUser = () => {
    return API.createOpenpathUser(user.id)
  }

  const removeUser = () => {
    return API.removeOpenpathUser(user.id)
  }

  return <>
    <Toggler
      label="openpath"
      init={init}
      onCheck={createUser}
      onUncheck={removeUser}
    />
    {user.person_id && <>
      &nbsp;&nbsp;
      <Link to={`/person/${user.person_id}/security`}>
        <Icon size='small' name='info circle'/>
      </Link>
    </>}
  </>
}
