import { UserContainer } from 'Context/User'
import React, {useContext} from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import Nav from '../Nav/Nav'
import Admin from './Admin/Admin'
import CheckMember from './CheckMember/CheckMember'
import { MemberRoute, NoMatch, PrivateRoute, StaffRoute } from './Helpers'
import Household from './Household/Household'
import LogIn from './LogIn/LogIn'
import MemberDirectory from './Members/MemberDirectory'
import ProfileSection from './Members/ProfileSection'
import Membership from './Membership/Membership'
import Person from './Person/Person'
import {ProgramFormWrapped} from './Program/ProgramForm'
import ProgramRouter from './Program/ProgramRouter'
import CalendarPage from './Programs/Calendar'
import Search from './Programs/Search'
import EditRegistration from './Registrations/Edit'
import Reset from './Reset/Reset'
import { Sign } from './Sign/Sign'
import Verify from './Verify/Verify'

const Routes = () => {
  const {user} = useContext(UserContainer)

  return (
    <div id="wrapper">
      <Nav/>
      <Switch>
        <Route exact path="/" component={CalendarPage} />
        <Route exact path="/search" component={Search} />

        <MemberRoute exact path={`/members`} component={MemberDirectory}/>
        <MemberRoute path={`/members/:id`} component={ProfileSection}/>

        <Route exact path="/reset" component={Reset} />
        <Route exact path="/login" component={LogIn} />
        <Route exact path="/verify/:code" component={Verify} />
        <Route path="/membership" component={Membership} />

        <Route path="/program" component={ProgramRouter}/>
        <MemberRoute exact path={`/new-program`} component={ProgramFormWrapped}/>
        <Redirect from='/programs' to='/' />

        <Route path="/registrations/:id/:code?/:nonce?" component={EditRegistration}/>
        <Route path="/sign/:household_id/:code?/:nonce?" component={Sign}/>

        <PrivateRoute path="/household/:id" component={Household} />
        <PrivateRoute path="/person/:person_id" component={Person} />

        <StaffRoute path="/admin" component={Admin}/>

        {/* vanity URLs for account pages */}
        <PrivateRoute path="/account" render={()=>(
          <Redirect to={`/person/${user.person_id}`} />
        )} />
        <PrivateRoute path="/security" render={()=>(
          <Redirect to={`/person/${user.person_id}/security`} />
        )} />
        <PrivateRoute path="/billing" render={()=>(
          <Redirect to={`/person/${user.person_id}/billing`} />
        )} />
        <PrivateRoute path="/registrations" render={()=>(
          <Redirect to={`/person/${user.person_id}/registrations`} />
        )} />

        <Route path="/check" component={CheckMember}/>
        <Route component={NoMatch} />
      </Switch>
    </div>
  )
}

export default Routes;
