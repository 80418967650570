import API from 'Api'
import { UserContainer } from 'Context/User'
import React, { useContext, useEffect, useRef } from 'react'
import { Button, Form, Message, Radio } from 'semantic-ui-react'
import { useFields, useRequest } from 'Shared/Hooks'
import PaymentMethod from 'Shared/PaymentMethod'
import { DonorSlidingScale } from 'Shared/SlidingScaleAmount'

export const Donate = ({embedEffect}: {embedEffect: ()=>void}) => {
  const pm = useRef<{getPaymentMethod: ()=> Promise<{payment_method_id: string}>}>(null)
  const userContext = useContext(UserContainer)
  const [loading, error, run, result] = useRequest({success: false})
  const {fields, handleChange} = useFields({
    user: {
      email: userContext.user.email || '',
      person: {
        first: userContext.user.person ? userContext.user.person.first : '',
        last: userContext.user.person ? userContext.user.person.last : '',
      }
    },
    amount: 20,
    monthly: true,
    description: 'Donation'
  });

  useEffect(()=> {
    if (embedEffect) {
      embedEffect();
    }
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (pm.current) {
      run(pm.current.getPaymentMethod()
        .then(({payment_method_id}) => {
          const body = Object.assign(fields, {payment_method_id})
          return API.createDonation(body)
        })
      );
    }
  }

  const { user, amount, monthly } = fields;

  if (result && result.success) {
    return <Message positive style={{maxWidth: '30em'}}>We've successfully processed your donation. Thank you for supporting our work!</Message>
  }

  return (
    <Form error name="donate" loading={loading} onSubmit={handleSubmit} style={{maxWidth: '30em'}}>
      <Message error>{error}</Message>

      <Form.Input
        style={{maxWidth: '20em'}}
        name='user.email'
        type='email'
        required
        label='Email'
        value={user.email}
        onChange={handleChange}
      />

      <Form.Group widths='equal'> 
        <Form.Input
          style={{maxWidth: '14em'}}
          name='user.person.first'
          required
          label='First Name'
          value={user.person.first}
          onChange={handleChange}
        />

        <Form.Input
          style={{maxWidth: '14em'}}
          name='user.person.last'
          required
          label='Last Name'
          value={user.person.last}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group> 
        <DonorSlidingScale
          style={{minWidth:'17em'}}
          label='Donation'
          name='amount'
          min={5}
          presets={[10,20,50,100]}
          defaultAmount={amount}
          onChange={handleChange}/>

        <Form.Field>
          <label>Monthly</label>
          <Radio 
            style={{marginTop: '0.5em'}}
            toggle
            name='monthly'
            checked={monthly} 
            onChange={handleChange} />
        </Form.Field>
      </Form.Group>

      { amount > 900 ? 
        <p>Please consider sending a check for large donations to save on processing fees.</p> :
        ''
      }

      { monthly ? 
        <p>Monthly donations help us plan for the future.</p> : 
        <p>One-time donations are also welcome!</p>
      }

      <PaymentMethod ref={pm}/>

      <Button primary type="submit">
        { monthly ? `Donate $${amount} Monthly` : `Donate $${amount} Now` }
      </Button>
    </Form>
  )
}
