import API from 'Api'
import moment from 'moment-timezone'
import React, { useCallback, useEffect } from 'react'
import { Button, Form, Loader, Message, Table } from 'semantic-ui-react'
import { useRequest } from 'Shared/Hooks'
import { Task } from 'Shared/Models'
import { Panel } from 'Shared/Panel'
import { TaskDetails } from 'Shared/TaskDetails'
import { TaskStatusLabel } from 'Shared/TaskStatus'

export const Tasks = () => {
  const [loading, error, run, tasks] = useRequest<Task[]>([])

  const handleNext = () => {
    load(tasks[tasks.length-1].id);
  }

  const load = useCallback((id?: number) => {
    run(API.getTasks({id}));
  }, [run])

  useEffect(load, [load]);
  
  const headers = ['ID', 'Status', 'Date', 'Task', 'Details']

  return <>
    <Panel icon='calendar' heading='Program Tasks'>
      <DailySummary/>
      <WeeklyUpdateEmail/>
      <SyncGcal/>
      <ClearGcal/>
    </Panel>

    <Panel icon='user' heading='People Tasks'>
      <LinkSlackUsers/>
      <SlackReport/>
      <OpenpathReport/>
      <CheckrReport/>
    </Panel>

    <Panel icon='star' heading='Membership Tasks'>
      <PastDueReport/>
    </Panel>

    {error && <Message negative>{error}</Message>}
    <Table compact headerRow={headers}>
      <Table.Body>
        { tasks.map(t => (
          <Table.Row key={t.id}>
            <Table.Cell collapsing content={t.id}/>
            <Table.Cell collapsing>
              <TaskStatusLabel task={t}/>
            </Table.Cell>
            <Table.Cell collapsing>
              {moment.utc(t.updated_at).format()}
            </Table.Cell>
            <Table.Cell>
              <Identify task={t}/>
            </Table.Cell>
            <Table.Cell collapsing>
              <TaskDetails task={t}/>
            </Table.Cell>
          </Table.Row>
        )) }
        {loading && <Table.Row cells={[{colSpan:headers.length,content:<Loader active inline='centered'/>}]}/>}
      </Table.Body>
      <Table.Footer>
        {tasks.length === 50 && 
          <Table.Row cells={[
            <Table.HeaderCell colSpan={headers.length} textAlign='center' 
              content={<Button onClick={handleNext} content='Next'/>}
            />
          ]}/>}
      </Table.Footer>
    </Table>
  </>
}

const Identify: React.FC<{task: Task}> = ({task}) => {
  switch (task.name) {
  case 'email_registrant':
    return <>{`Emailed ${task.data.email} about program ${task.program_id.Int64}`}</>
  default:
    return <>{task.name}</>
  }
}


const DailySummary = () => {
  const [loading, error, run, result] = useRequest(undefined)

  const handleSubmit = ()=> {
    run(API.programSummary())
  }

  if (result) {
    return <Message>{JSON.stringify(result)}</Message>
  }

  return <Form error name="daily-summary" loading={loading} onSubmit={handleSubmit} style={{display:'inline-block'}}>
    <Message error>{error}</Message>
    <Button type="submit">Slack Daily Summary</Button>
  </Form>
}

const WeeklyUpdateEmail = () => {
  const [loading, error, run, result] = useRequest(undefined)

  const handleSubmit = ()=> {
    run(API.programUpdate())
  }

  if (result) {
    return <Message>{JSON.stringify(result)}</Message>
  }

  return <Form error name="weekly-update" loading={loading} onSubmit={handleSubmit} style={{display:'inline-block'}}>
    <Message error>{error}</Message>
    <Button type="submit">Weekly Update Campaign</Button>
  </Form>
}


const SyncGcal = () => {
  const [loading, error, run, result] = useRequest(undefined)

  const handleSubmit = ()=> {
    run(API.syncPrograms())
  }

  if (result) {
    return <Message>{JSON.stringify(result)}</Message>
  }

  return <Form error name="sync-gcal" loading={loading} onSubmit={handleSubmit} style={{display:'inline-block'}}>
    <Message error>{error}</Message>
    <Button type="submit">Sync Gcal</Button>
  </Form>
}

const ClearGcal = () => {
  const [loading, error, run, result] = useRequest(undefined)

  const handleSubmit = ()=> {
    run(API.clearPrograms())
  }

  if (result) {
    return <Message>{JSON.stringify(result)}</Message>
  }

  return <Form error name="clear-gcal" loading={loading} onSubmit={handleSubmit} style={{display:'inline-block'}}>
    <Message error>{error}</Message>
    <Button type="submit">Clear Gcal</Button>
  </Form>
}

const SlackReport = () => {
  const [loading, error, run, result] = useRequest(undefined)

  const handleSubmit = ()=> {
    run(API.generateSlackReport())
  }

  if (result) {
    return <Message>{JSON.stringify(result)}</Message>
  }

  return <Form error name="slack-report" loading={loading} onSubmit={handleSubmit} style={{display:'inline-block'}}>
    <Message error>{error}</Message>
    <Button type="submit">Slack Report</Button>
  </Form>
}

const OpenpathReport = () => {
  const [loading, error, run, result] = useRequest(undefined)

  const handleSubmit = ()=> {
    run(API.generateOpenpathReport())
  }

  if (result) {
    return <Message>{JSON.stringify(result)}</Message>
  }

  return <Form error name="openpath-report" loading={loading} onSubmit={handleSubmit} style={{display:'inline-block'}}>
    <Message error>{error}</Message>
    <Button type="submit">Openpath Report</Button>
  </Form>
}

const CheckrReport = () => {
  const [loading, error, run, result] = useRequest(undefined)

  const handleSubmit = ()=> {
    run(API.generateCheckrReport())
  }

  if (result) {
    return <Message>{JSON.stringify(result)}</Message>
  }

  return <Form error name="checkr-report" loading={loading} onSubmit={handleSubmit} style={{display:'inline-block'}}>
    <Message error>{error}</Message>
    <Button type="submit">Checkr Report</Button>
  </Form>
}


const LinkSlackUsers = () => {
  const [loading, error, run, result] = useRequest(undefined)

  const handleSubmit = ()=> {
    run(API.linkSlackUsers())
  }

  if (result) {
    return <Message>{JSON.stringify(result)}</Message>
  }

  return <Form error name="link-slack" loading={loading} onSubmit={handleSubmit} style={{display:'inline-block'}}>
    <Message error>{error}</Message>
    <Button type="submit">Link Slack Users</Button>
  </Form>
}

const PastDueReport = () => {
  const [loading, error, run, result] = useRequest(undefined)

  const handleSubmit = ()=> {
    run(API.pastDueReport())
  }

  if (result) {
    return <Message>{JSON.stringify(result)}</Message>
  }

  return <Form error name="past-due-report" loading={loading} onSubmit={handleSubmit} style={{display:'inline-block'}}>
    <Message error>{error}</Message>
    <Button type="submit">Past Due Memberships Report</Button>
  </Form>
}
