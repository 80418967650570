import { PersonContainer, WithPerson } from 'Context/Person'
import { UserContainer } from 'Context/User'
import React, { useContext } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { Message } from 'semantic-ui-react'
import { personName } from 'Shared/PersonName'
import ResponsiveTabs, { TabProps } from 'Shared/ResponsiveTabs'
import SimplePage from 'Shared/SimplePage'

import Billing from './Billing'
import Dashboard from './Dashboard'
import { Edit } from './Edit'
import MyPrograms from './MyPrograms'
import Security from './Security'

const PersonSection = () => {
  const {person_id} = useParams<{person_id: string}>();
  return (
    <WithPerson id={Number(person_id)}>
      <PersonInner/>
    </WithPerson>
  );
}

export default PersonSection;

const PersonInner = () => {
  const {person, personError, personLoading} = useContext(PersonContainer);
  const {user} = useContext(UserContainer);

  // make menu and sub-routes
  const subMenu = [
    {path: 'dash', name:'Dashboard', component: Dashboard},
    {path: 'registrations', name:'My Programs', component: MyPrograms},
    person.user && person.user.id ? {path: 'billing', name: 'Billing', component: Billing} : false,
    person.user && person.user.id ? {path: 'security', name: 'Security', component: Security} : false,
    {path: 'edit', name: 'Edit', component: Edit}
  ].filter(x=>x);

  return (
    <SimplePage icon='user' profile={person.profile} title={personName(person)} loading={personLoading} error={personError}>
      {user && user.person_id === person.id && !person.is_member && user.can_onboard  &&
        <Message>Your Membership is not active yet. Check your <Link to='/membership'>Membership onboarding</Link>.</Message>}
      {!personError && <ResponsiveTabs items={subMenu as TabProps[]}/>}
    </SimplePage>
  );
}
