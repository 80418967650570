import _ from "lodash";
import React, { FormEvent, useState } from "react";
import { Options } from "rrule";
import { CheckboxProps, Form } from "semantic-ui-react";
import { ChangeHandler } from "Shared/Hooks";

import { WkDay } from "./ProgramFormSchedule";

const isChecked = (list: Options["byweekday"] | undefined, item: number) => {
  if (!_.isArray(list)) {
    return false;
  }
  return list.includes(item);
};

const defaultState = (
  defaultValues?: WkDay[]
): { days: Record<string, boolean> } => {
  const flattened = defaultValues && defaultValues.map((d) => d.weekday);

  return {
    days: {
      Mo: isChecked(flattened, 0),
      Tu: isChecked(flattened, 1),
      We: isChecked(flattened, 2),
      Th: isChecked(flattened, 3),
      Fr: isChecked(flattened, 4),
      Sa: isChecked(flattened, 5),
      Su: isChecked(flattened, 6),
    },
  };
};

const dayValue: Record<string, number> = {
  Mo: 0,
  Tu: 1,
  We: 2,
  Th: 3,
  Fr: 4,
  Sa: 5,
  Su: 6,
};

const asArray = (daysMap: Record<string, boolean>) => {
  return Object.keys(daysMap)
    .filter((d) => {
      return daysMap[d] !== false;
    })
    .map((d) => {
      return dayValue[d];
    });
};

export const Weekdays = ({
  name: parentName,
  defaultValues,
  onChange,
}: {
  name: string;
  defaultValues?: WkDay[];
  onChange: ChangeHandler;
}) => {
  const [state, setState] = useState(defaultState(defaultValues));

  const handleChange = (
    e: FormEvent<HTMLInputElement>,
    { name, checked }: CheckboxProps
  ) => {
    setState((state) => {
      if (name) {
        state.days[name] = !!checked;
        const result = {
          name: parentName,
          value: asArray(state.days),
        };
        onChange(null, result);
      }
      return state;
    });
  };

  const { days } = state;
  return (
    <Form.Field>
      <label>Weekday</label>
      <Form.Group>
        {Object.keys(days).map((day) => (
          <Form.Checkbox
            inline
            key={day}
            label={day}
            name={day}
            checked={days[day]}
            value={dayValue[day]}
            onChange={handleChange}
          />
        ))}
      </Form.Group>
    </Form.Field>
  );
};
