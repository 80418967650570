import React, {CSSProperties, ReactNode, useCallback, useEffect, useState} from 'react'
import { Button, Form, Icon, Input, Radio } from 'semantic-ui-react'
import Dollars from 'Shared/Dollars'

import { ChangeHandler } from './Hooks';

export const SlidingScaleAmount = ({minPrice, memberPrice, showMemberPricing, isMember, label, amount, onChange, style}: {minPrice: number, memberPrice: number, showMemberPricing: boolean, isMember: boolean, label: ReactNode, amount: number, onChange: ChangeHandler, style?: CSSProperties}) => {
  const fullPrice = Math.ceil(minPrice * 2);
  const subsidized = Math.ceil(minPrice * 1.5);
  return <>
    <Form.Field style={Object.assign({minHeight:'80px'}, style)}>
      <label>{label}</label>
      <div className='ui field' style={{marginBottom:'0.25em'}}>
        <Radio
          label={`Full Price: $${fullPrice}`}
          type='radio'
          name='amount'
          value={fullPrice}
          checked={amount === fullPrice}
          onChange={onChange}/>
      </div>
      <div className='ui field' style={{marginBottom:'0.25em'}}>
        <Radio
          label={`Subsidized: $${subsidized}`}
          type='radio'
          name='amount'
          value={subsidized}
          checked={amount === subsidized}
          onChange={onChange}/>
      </div>
      <div className='ui field' style={{marginBottom:'0.25em'}}>
        <Radio
          label={`More Subsidized: $${minPrice}`}
          type='radio'
          name='amount'
          value={minPrice}
          checked={amount === minPrice}
          onChange={onChange}/>
      </div>

      {showMemberPricing && <div className='ui field' style={{marginBottom:'0.25em', background: ""}}>
        <Radio
          label={isMember ? `Members-only: $${memberPrice}` : `Become a Member for discount pricing: $${memberPrice}`}
          type='radio'
          name='amount'
          value={memberPrice}
          disabled={!isMember}
          checked={amount === memberPrice}
          onChange={onChange}/>
      </div>}
    </Form.Field>
  </>
}

export const DonorSlidingScale = ({name, defaultAmount, presets, label, min, style, onChange}: {name: string, defaultAmount: number, presets: number[], label: ReactNode, min: number, style?: CSSProperties, onChange: ChangeHandler}) => {
  const [other, setOther] = useState(false);
  const [amount, setAmount] = useState(defaultAmount);

  const toggleOther = () => {
    setOther(!other)
  }

  const cancel = () => {
    toggleOther()
    if (!presets.includes(amount)) {
      setAmount(presets[0]);
    }
  }

  const handleChange: ChangeHandler = useCallback((e, {value}) => {
    setAmount(parseFloat(value as string))
  }, []);

  useEffect(()=>{
    onChange(null, {name, value: amount});
  }, [name, amount, onChange])

  return <Form.Field style={Object.assign({minHeight:'80px'}, style)}>
    {presets && !other ?
      <>
        <label>{label}</label>
        { presets.map(amt => (
          <Button size="small" icon compact secondary={amt === amount} type="button" key={amt} onClick={handleChange.bind(this, null, {name:'amount', value: amt})}>
            <Dollars amount={amt}/>
          </Button>
        ))}
        <Button size="small" icon compact type="button" onClick={toggleOther}>
          Other
        </Button>
      </>
      :
      <>
        <label>{label}</label>
        <Input 
          style={{maxWidth: '7em'}}
          label={{basic: true, content:'$'}}
          name="amount"
          type="number"
          min={min ? min : 5}
          step={1}
          value={amount}
          onChange={handleChange} />
        { presets ? <Icon style={{marginLeft:'3em',marginTop:'0.6em'}} link name='close' onClick={cancel}/> : null }
      </>
    }
  </Form.Field>
}
