import React from "react";
import { Grid, StrictTableProps, Table } from "semantic-ui-react";
import { Panel } from "Shared/Panel";

export const MemberCodes: React.FC<StrictTableProps> = ({ attached }) => (
  <Table unstackable compact attached={attached}>
    <Table.Body>
      <Table.Row cells={["Garbage Bins", "8008"]} />
      <Table.Row cells={["PA Cabinet", "1992"]} />
      <Table.Row cells={["A/V Cart", "RISE"]} />
      <Table.Row cells={["Laundry Room", "1234"]} />
      <Table.Row cells={["Backyard Storage", "1992"]} />
      <Table.Row cells={["Outdoor Metal Shed", "2358"]} />
    </Table.Body>
  </Table>
);

const StaffCodes: React.FC<StrictTableProps> = ({ attached }) => (
  <Table unstackable compact attached={attached}>
    <Table.Body>
      <Table.Row cells={["Front Office", "6261"]} />
      <Table.Row cells={["Tech Closet", "1278"]} />
      <Table.Row cells={["Mailbox", "1321"]} />
      <Table.Row cells={["Package Box", "1321"]} />
      <Table.Row cells={["Electrical Boxes", "1321"]} />
      <Table.Row cells={["Gaming PC", "1321"]} />
      <Table.Row cells={["Woodshop", "2791"]} />
      <Table.Row cells={["Studio", "1221"]} />
      <Table.Row cells={["Nexus Phone", "1234"]} />
    </Table.Body>
  </Table>
);

const Codes = () => (
  <Grid compact columns={2} stackable>
    <Grid.Column>
      <Panel heading="Shareable" noSegment>
        <MemberCodes attached="bottom" />
      </Panel>
    </Grid.Column>
    <Grid.Column>
      <Panel heading="Staff Only" noSegment>
        <StaffCodes attached="bottom" />
      </Panel>
    </Grid.Column>
  </Grid>
);

export default Codes;
