import API from "Api";
import { HouseholdContainer } from "Context/Household";
import { UserContainer } from "Context/User";
import React, { FormEvent, useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import {
  CheckboxProps,
  Form,
  Label,
  Message,
  TextArea,
} from "semantic-ui-react";
import Dollars from "Shared/Dollars";
import { useFields, useRequest } from "Shared/Hooks";
import { FrequencyOptions } from "Shared/MembershipFrequency";
import { MembershipStatusSelect } from "Shared/MembershipStatus";
import { NumberField } from "Shared/Number";
import { Admin, IsAdmin } from "Shared/Roles";
import Tooltip from "Shared/Tooltip";
import getSuggestedPrice, { minimumFee } from "SlidingScale";

const MembershipEdit = () => {
  const { user } = useContext(UserContainer);
  const { membership, household } = useContext(HouseholdContainer);
  const [loading, error, run, success] = useRequest(false);
  const [suggestedPrice, setSuggestedPrice] = useState(0);

  const [hasExtras, setHasExtras] = useState(membership.extras > 0);
  const handleCheck = (
    e: FormEvent<HTMLInputElement>,
    { checked }: CheckboxProps
  ) => {
    setHasExtras(!!checked);
    if (checked === false) {
      handleChange(null, { name: "extras", value: 0 });
      handleChange(null, { name: "notes", value: "" });
    }
  };

  const { fields, handleChange } = useFields(
    Object.assign({}, membership, { household })
  );

  const { adults, youth, income, days, amount, extras, notes } = fields;

  const handleSubmit = () => {
    run(API.updateHouseholdMembership(household.id, fields));
  };

  useEffect(() => {
    const newPrice = getSuggestedPrice(
      adults,
      youth,
      days,
      income,
      household.is_org
    );
    if (newPrice !== suggestedPrice) {
      setSuggestedPrice(newPrice);
      if (suggestedPrice === amount || !amount) {
        handleChange(null, { name: "amount", value: newPrice });
      }
    }
  }, [
    suggestedPrice,
    household.is_org,
    adults,
    youth,
    days,
    income,
    amount,
    handleChange,
  ]);

  const minimum = minimumFee(household.is_org);

  let hasFeeChange = membership.amount + membership.extras !== amount + extras;
  if (amount < minimum && !IsAdmin(user)) {
    hasFeeChange = false;
  }

  if (success) {
    return (
      <Redirect
        to={{
          pathname: `/household/${household.id}/membership`,
          state: { reload: true },
        }}
      />
    );
  }

  return (
    <Form onSubmit={handleSubmit} error loading={loading}>
      <Message error>{error}</Message>
      <Admin>
        <Form.Group>
          <NumberField
            required
            style={{ maxWidth: "10em" }}
            label="Adults"
            name="adults"
            min={0}
            value={adults}
            onChange={handleChange}
          />
          <NumberField
            required
            style={{ maxWidth: "10em" }}
            label="Youth"
            name="youth"
            min={0}
            value={youth}
            onChange={handleChange}
          />
        </Form.Group>
      </Admin>

      <NumberField
        required
        innerLabel={{ basic: true, content: "$" }}
        style={{ maxWidth: "10em" }}
        label={
          !household.is_org
            ? "Annual Household Income"
            : "Annual Organizational Budget"
        }
        name="income"
        min={0}
        value={income}
        onChange={handleChange}
      />

      <Form.Select
        style={{ maxWidth: "20em" }}
        name="days"
        label="Usage"
        required
        value={days}
        onChange={handleChange}
        options={FrequencyOptions}
      />

      <Form.Field>
        <label>Suggested Monthly Membership Fee</label>
        <Label>
          <Dollars amount={suggestedPrice} />
        </Label>
      </Form.Field>

      <NumberField
        label={
          <>
            Monthly Membership Fee (minimum ${minimum}){" "}
            <Tooltip>
              <Dollars amount={suggestedPrice} /> is our suggested fee based on
              the info you have provided, but you can offer a different amount
              if this doesn't fit your financial situation.
            </Tooltip>
          </>
        }
        style={{ maxWidth: "10em" }}
        innerLabel={{ basic: true, content: "$" }}
        name="amount"
        min={IsAdmin(user) ? 5 : minimum}
        required
        value={amount}
        onChange={handleChange}
      />

      <Form.Field>
        <label>Extras</label>
        <Form.Checkbox
          label="I have extra needs, such as dedicated space, kitchen use, mailbox, etc."
          name="hasExtras"
          checked={hasExtras}
          onChange={handleCheck}
        />
      </Form.Field>

      {hasExtras && (
        <>
          <Message>
            If you haven't discussed your specific 'extras' needs with a staff
            member yet, you should do so before submitting this form!
          </Message>
          <Form.Field>
            <label>
              Description of Extras{" "}
              <Tooltip content="Things like renting a mailbox or permanent desk space" />
            </label>
            <TextArea
              name="notes"
              onChange={handleChange}
              rows={2}
              value={notes}
            />
          </Form.Field>

          <NumberField
            style={{ maxWidth: "14em" }}
            label="Monthly Fee for Extras"
            innerLabel={{ basic: true, content: "$" }}
            name="extras"
            min={0}
            required
            value={extras}
            onChange={handleChange}
          />
        </>
      )}

      <Admin>
        <MembershipStatusSelect
          style={{ maxWidth: "14em" }}
          label="Status"
          value={fields.status}
          onChange={handleChange}
        />
      </Admin>

      {hasFeeChange && (
        <Message color="yellow">
          Note: when you change your fees, the new rate will take effect{" "}
          <i>immediately</i>, but your payment date each month will remain the
          same. Since you are{" "}
          {membership.amount + membership.extras > amount + extras
            ? "decreasing"
            : "increasing"}{" "}
          your fees,{" "}
          <b>
            your very next payment may be{" "}
            {membership.amount + membership.extras > amount + extras
              ? "lower"
              : "higher"}{" "}
            than your new monthly fee
          </b>
          . Payments after that will return to your new fee amount (
          <Dollars amount={amount + extras} />
          ).
        </Message>
      )}

      <Form.Button primary>
        Update Membership{" "}
        {hasFeeChange ? (
          <>
            (Change fees from{" "}
            <Dollars amount={membership.amount + membership.extras} /> to{" "}
            <Dollars amount={amount + extras} />)
          </>
        ) : (
          false
        )}
      </Form.Button>
    </Form>
  );
};

export default MembershipEdit;
