import { UserContainer } from "Context/User";
import { useContext, useState } from "react";
import { Redirect, useParams } from "react-router";
import { Link } from "react-router-dom";
import SignDocuments from "Routes/Household/SignDocuments";
import { Message } from "semantic-ui-react";
import { User } from "Shared/Models";
import SimplePage from "Shared/SimplePage";

export const Sign = () => {
  const {user} = useContext(UserContainer)
  const {household_id, code, nonce} = useParams<{household_id: string, code: string, nonce: string}>();
  const [signer, setSigner] = useState<User|false>(false);

  if (signer) {
    if (user && user.id) {
      // already logged in
      return <Redirect to='/account'/>
    }

    const resetLink = "/reset?email=" + (signer.email ? encodeURIComponent(signer.email) : '');
    const loginLink = "/login?email=" + (signer.email ? encodeURIComponent(signer.email) : '');

    return <SimplePage title='Sign Membership Documents'>
      <Message positive>Thanks for signing our Membership documents!</Message>
      <p>If you haven't set a password for your account, you can <Link to={resetLink}>reset your password</Link>. Otherwise you can <Link to={loginLink}>log in</Link>.</p>
    </SimplePage>
  }

  return <SimplePage title='Sign Membership Documents'>
    <SignDocuments code={code} nonce={nonce} household_id={Number(household_id)} onSuccess={(u)=>{setSigner(u)}}/>
  </SimplePage>
}
