import { HouseholdContainer } from 'Context/Household'
import { UserContainer } from 'Context/User'
import React, { useContext, useState } from 'react'
import { useLocation } from 'react-router'
import ChangePassword from 'Routes/Person/ChangePassword'
import { Loader, Step } from 'semantic-ui-react'
import { MembershipStatus } from 'Shared/Models'
import { Panel } from 'Shared/Panel'

import MembershipAutoPayForm from './MembershipAutoPayForm'
import MembershipBackgroundCheckForm from './MembershipBackgroundCheckForm'
import MembershipDocumentsForm from './MembershipDocumentsForm'
import MembershipExtrasForm from './MembershipExtrasForm'
import MembershipFeeForm from './MembershipFeeForm'
import MembershipPeopleForm from './MembershipPeopleForm'
import MembershipUnderReview from './MembershipUnderReview'

const MembershipOnboarding = () => {
  const { membership } = useContext(HouseholdContainer);
  const [step, setStep] = useState(membership.status);

  return <>
    <Steps step={step} setStep={setStep}/>
    <StepContent step={step} />
  </>
}

export default MembershipOnboarding;

const StepContent = ({step}: {step: MembershipStatus}) => {
  const { state } = useLocation<{justVerified: boolean}>();
  const { user } = useContext(UserContainer)
  const { householdLoading } = useContext(HouseholdContainer);

  const [passwordUpdated, setPasswordUpdated] = useState(false);

  if (householdLoading) {
    return <Loader active inline='centered'/>
  }

  switch (step) {
  case 0:
    return <>
      {state && state.justVerified && !passwordUpdated && <Panel heading={`Update password for ${user.email}`}>
        <p>Since this is your first time using the web app, your account needs a password.</p>
        <ChangePassword user={user} onSuccess={()=>{
          setTimeout(()=>{setPasswordUpdated(true)}, 2000)
        }}/>
      </Panel>}
      <MembershipPeopleForm/>
    </>
  case 3:
    return <MembershipFeeForm/>
  case 4:
    return <MembershipExtrasForm/>
  case 5:
    return <MembershipDocumentsForm/>
  case 6:
    return <MembershipBackgroundCheckForm/>
  case 7:
    return <MembershipAutoPayForm/>
  case 8:
    return <MembershipUnderReview/>
  default:
    return <p>Error - unrecognized onboarding step.</p>
  }
}

type StepSetter = React.Dispatch<React.SetStateAction<number>>;

const Steps = ({step, setStep}: {step: MembershipStatus, setStep: StepSetter}) => {
  const { membership } = useContext(HouseholdContainer);
  const { status } = membership;
  return (
    <Step.Group fluid unstackable ordered size='mini'>
      <MembershipStep status={status} step={step} idx={0} setStep={setStep} title='People'/>
      <MembershipStep status={status} step={step} idx={3} setStep={setStep} title='Fees'/>
      <MembershipStep status={status} step={step} idx={4} setStep={setStep} title='Extras'/>
      <MembershipStep status={status} step={step} idx={5} setStep={setStep} title='Docs'/>
      <MembershipStep status={status} step={step} idx={6} setStep={setStep} title='Safety'/>
      <MembershipStep status={status} step={step} idx={7} setStep={setStep} title='Autopay'/>
      <MembershipStep status={status} step={step} idx={8} setStep={setStep} title='Review'/>
      <MembershipStep status={status} step={step} idx={10} setStep={setStep} title='Complete'/>
    </Step.Group>
  )
}

const MembershipStep = ({idx, status, step, title, setStep}: {idx: number, status: MembershipStatus, step: MembershipStatus, title: string, setStep: StepSetter}) => (
  <Step 
    completed={status > idx}
    disabled={idx > status}
    active={step === idx}
    onClick={()=>{setStep(idx)}}
    title={title}/>
)
