import API from "Api";
import { HouseholdContainer } from "Context/Household";
import React, { useContext } from "react";
import { Button, Form, Grid, Message } from "semantic-ui-react";
import { useFields, useRequest } from "Shared/Hooks";
import { HouseholdInDirectory } from "Shared/Models";
import { Panel } from "Shared/Panel";
import { Admin } from "Shared/Roles";

import MembershipEdit from "./MembershipEdit";

export const Edit = () => {
  return (
    <>
      <Grid columns={2} stackable>
        <Grid.Column>
          <Panel icon="star" heading="Membership Fees">
            <Message>
              If this form is too confusing, or you need to add or remove a
              person from your group, please{" "}
              <a href="mailto:hello@aldercommons.org">contact us</a>.
            </Message>
            <MembershipEdit />
          </Panel>
        </Grid.Column>
        <Grid.Column>
          <Admin>
            <Panel icon="lock" heading="Group">
              <GroupEdit />
            </Panel>
          </Admin>
        </Grid.Column>
      </Grid>
    </>
  );
};

const GroupEdit = () => {
  const { household, load } = useContext(HouseholdContainer);
  const { fields, handleChange } = useFields(Object.assign({}, household));
  const [loading, error, run] = useRequest({});

  const handleSubmit = () => {
    run(API.updateHousehold(fields), () => {
      load();
    });
  };

  const { name, is_org, in_directory } = fields;

  return (
    <>
      <Form error name="household" loading={loading} onSubmit={handleSubmit}>
        <Message error>{error}</Message>

        <Form.Input
          style={{ maxWidth: "14em" }}
          name="name"
          type="text"
          label="Group Name"
          required
          value={name}
          onChange={handleChange}
        />

        <Form.Checkbox
          name="is_org"
          label="Is Org"
          checked={is_org}
          onChange={handleChange}
        />

        <Form.Checkbox
          name="in_directory"
          label="In Directory"
          checked={in_directory === HouseholdInDirectory.Yes}
          onChange={handleChange}
        />

        <Button primary type="submit">
          Save
        </Button>
      </Form>
    </>
  );
};
