import API from 'Api';
import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import { DropdownItemProps, Form, Icon, SelectProps } from 'semantic-ui-react'

import { ChangeHandler, useRequest } from './Hooks';
import { Person } from './Models';

type PeopleChooserProps = {
  name?: string,
  value?: number,
  onChange: ChangeHandler,
  onDelete?: (e: FormEvent)=>void,
  showID?: boolean,
} & Omit<SelectProps, 'options'>

export const PeopleChooser: React.FC<PeopleChooserProps> = ({name, value, onChange, onDelete, showID, ...rest}) => {
  const [loading, error, run, people] = useRequest<Person[]>([]);
  const [options, setOptions] = useState<DropdownItemProps[]>([]);

  useEffect(()=>{
    run(API.getPeople())
  }, [run]);

  useEffect(()=>{
    setOptions(people.map(p => {
      return {
        key: p.id,
        value: p.id,
        text: p.first + ' ' + p.last + (showID ? ' (' + p.id + ')' : '')
      }
    }))
  }, [people, showID]);

  const innerChange: ChangeHandler = useCallback((e, data)=>{
    onChange(null, {name, value: data.value as number[]})
  }, [name, onChange]);

  return (
    <Form.Group style={onDelete ? {height:'3.25em',marginBottom:0} : {}}>
      <Form.Select
        {...rest}
        defaultValue={value}
        error={error}
        loading={loading}
        options={options}
        search
        onChange={innerChange} />
      { onDelete ? <Icon style={{marginTop:'0.6em'}} link name='close' onClick={onDelete}/> : null }
    </Form.Group>
  );
}
