import React from 'react'
import { Checkbox, Form } from 'semantic-ui-react'
import { DateField } from 'Shared/DateField';
import Tooltip from 'Shared/Tooltip'

import { isAdult } from './Age';
import { ChangeHandler } from './Hooks';
import { Person } from './Models';
import PhotoConsent, { PhotoTermsModal } from './PhotoConsent';
import { Admin } from './Roles';

type PersonFieldProps = {
  onChange: ChangeHandler,
  person: Person,
  strictFields?: boolean,
  phoneRequired?: boolean
}

const PersonFields = ({onChange, person, strictFields, phoneRequired=true}: PersonFieldProps) => {
  return <>
    <Form.Input
      style={{maxWidth: '16em'}}
      name="person.first"
      label="First Name"
      required
      defaultValue={person.first}
      onChange={onChange} />
    <Form.Input
      style={{maxWidth: '16em'}}
      name="person.last"
      label="Last Name"
      required
      defaultValue={person.last}
      onChange={onChange} />
    <Form.Input
      style={{maxWidth: '14em'}}
      name='person.nickname'
      label='Nickname'
      defaultValue={person.nickname}
      onChange={onChange}
    />
    <DateField
      required={!!strictFields}
      label="Date of Birth"
      name="person.dob"
      defaultValue={person.dob}
      onChange={onChange}
    />
    <Form.Field>
      <label>Pronouns <Tooltip content='Formatted like "she/her"' /></label>
      <Form.Input
        style={{maxWidth: '10em'}}
        name="person.pronouns"
        defaultValue={person.pronouns}
        onChange={onChange} />
    </Form.Field>
    <Form.Field>
      <label>Race/Ethnicity</label>
      <Form.Input
        style={{maxWidth: '10em'}}
        name="person.race_ethnicity"
        defaultValue={person.race_ethnicity}
        onChange={onChange} />
    </Form.Field>
    <div className='field'>
      <Checkbox
        style={{maxWidth: '10em'}}
        label='Identify as BIPOC'
        name="person.bipoc"
        checked={person.bipoc}
        onChange={onChange} />{' '}
      <Tooltip content='Black, Indigenous, and other People of Color' />
    </div>

    {person && person.id && <div className='field'>
      <label>Photo Use</label>
      <div style={{marginBottom: '0.5em'}}>
        <PhotoTermsModal/>
      </div>
      <PhotoConsent person={person}/>
    </div>}

    <Form.Input
      required={isAdult(person) && phoneRequired}
      style={{maxWidth: '16em'}}
      name='person.phone_number'
      label='Phone Number'
      defaultValue={person.phone_number}
      onChange={onChange}
    />
    <Admin>
      <Form.Checkbox
        label='Receive Emergency Text Alerts '
        name='person.alerts'
        checked={person.alerts}
        onChange={onChange}/>
    </Admin>
    <Form.Field>
      <label>Allergies &amp; Medical Needs</label>
      <Form.TextArea
        name="person.allergies"
        defaultValue={person.allergies}
        onChange={onChange} />
    </Form.Field>
  </>
}

export default PersonFields;
