import API from "Api";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Message } from "semantic-ui-react";
import { useRequest } from "Shared/Hooks";
import { Person, Profile, Tag } from "Shared/Models";
import { Panel } from "Shared/Panel";
import { personName } from "Shared/PersonName";

import { ProfileForm } from "./ProfileForm";

export const ProfileChecker = ({person}: {person: Person}) => {
  const [loading, error, run, profile] = useRequest<Profile|false>(false)
  const [showForm, setShowForm] = useState(false)

  useEffect(()=>{
    run(API.getProfile(person.id))
  }, [run, person.id])

  if (!loading && !error && !profile) {
    return <>
      {!showForm ?
        <Message warning><p>You don't have a profile setup for our <Link to='/members'>Member Directory</Link>.</p>
          <Button size='small' onClick={()=>{setShowForm(true)}}>Create your Profile</Button>
        </Message> : 
        <Panel heading='Create Profile'>
          <ProfileForm profile={{
            person_id: person.id,
            name: personName(person),
            pronouns: person.pronouns,
            interest_tags: [] as Tag[],
          } as Profile}/>
        </Panel>}
    </>
  }
  
  return <></>
}
