import "./directory.css";

import API from "Api";
import { UserContainer } from "Context/User";
import Fuse from "fuse.js";
import qs from "qs";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, Header, Image, Label, Segment } from "semantic-ui-react";
import { ChangeHandler, useRequest } from "Shared/Hooks";
import { Markdown } from "Shared/Markdown";
import { Household, Profile, Tag } from "Shared/Models";
import SimplePage from "Shared/SimplePage";
import TagChooser from "Shared/TagChooser";

import { ProfileChecker } from "./ProfileChecker";
import { ProfileTags } from "./ProfileTags";

const filterFromHistory = (search: string): qs.ParsedQs => {
  return qs.parse(search, { ignoreQueryPrefix: true });
};

const filterToHistory = (
  search: string,
  tags: number[],
  interests: boolean,
  bio: boolean,
  services: boolean,
  skillshare: boolean
): string => {
  let props: Record<string, any> = {
    search,
    tags: tags.join(","),
    interests,
    bio,
    services,
    skillshare,
  };

  // remove falsy values
  Object.keys(props).forEach((k) => {
    if (!props[k]) {
      delete props[k];
    }
  });

  return qs.stringify(props);
};

const MemberDirectory = () => {
  const { user } = useContext(UserContainer);
  const history = useHistory();
  const paramsFromHistory = filterFromHistory(history.location.search);

  const [loading, error, run, profiles] = useRequest<Profile[]>([]);

  // filter options
  const [search, setSearch] = useState(
    (paramsFromHistory?.search as string) || ""
  );
  const [tags, setTags] = useState<number[]>(
    ((paramsFromHistory?.tags as string) || "")
      .split(",")
      .map(Number)
      .filter(Boolean)
  );
  const [interests, setInterests] = useState(
    Boolean(paramsFromHistory?.interests) || false
  );
  const [bio, setBio] = useState(Boolean(paramsFromHistory?.bio) || false);
  const [services, setServices] = useState(
    Boolean(paramsFromHistory?.services) || false
  );
  const [skillshare, setSkillshare] = useState(
    Boolean(paramsFromHistory?.skillshare) || false
  );

  // filters and results
  const [fuse, setFuse] = useState<Fuse<Profile>>(new Fuse([]));
  const [fuseFiltered, setFuseFiltered] = useState<Profile[]>([]);

  const setTag: ChangeHandler = useCallback((e, data) => {
    setTags((data.value as Tag[]).map((t) => t.id));
  }, []);

  useEffect(() => {
    run(API.getProfiles(), (profiles) => {
      setFuse(
        new Fuse(profiles, {
          threshold: 0.4,
          keys: ["name"],
        })
      );
    });
  }, [run]);

  // update fuse filtered profiles on search change
  useEffect(() => {
    if (search) {
      const result = fuse.search(search);
      setFuseFiltered(result.map(({ item }) => item));
    } else {
      setFuseFiltered(profiles);
    }
  }, [search, fuse, profiles]);

  // set query param on filter changes
  useEffect(() => {
    history.replace({
      search: filterToHistory(
        search,
        tags,
        interests,
        bio,
        services,
        skillshare
      ),
    });
  }, [search, tags, interests, bio, services, skillshare, history]);

  const filtered = fuseFiltered
    .filter((profile) => {
      // filter by tag matching
      return (
        tags.length === 0 ||
        profile.interest_tags?.map((t) => t.id).some((id) => tags.includes(id))
      );
    })
    .filter((profile) => {
      return (
        (!services && !skillshare && !bio && !interests) || // none selected
        (bio && profile.bio !== "") || // bio and has bio
        (services && profile.services !== "") || // services and has service
        (skillshare && profile.skillshare !== "") || // skillshare and has skillshare
        (interests && profile.interest_tags?.length > 0)
      ); // interests and has interests
    });

  return (
    <SimplePage
      icon="id card outline"
      title="Member Directory"
      loading={loading}
      error={error}
    >
      <ProfileChecker person={user.person} />
      <Segment>
        <Form>
          <Form.Group>
            <Form.Field>
              <label>Search by Name</label>
              <Form.Input
                icon="search"
                value={search}
                onChange={(e, data) => setSearch(data.value)}
              />
            </Form.Field>
            <Form.Field width={8}>
              <label>Interests</label>
              <TagChooser
                interestTags
                value={tags.map((id) => ({ id } as Tag))}
                onChange={setTag}
              />
            </Form.Field>
            <Form.Field>
              <label>Show More Info</label>

              <Form.Checkbox
                style={{ marginBottom: "0.25em" }}
                toggle
                label="About Me"
                checked={bio}
                onChange={(e, data) => setBio(!!data.checked)}
              />
              <Form.Checkbox
                style={{ marginBottom: "0.25em" }}
                toggle
                label="Interests"
                checked={interests}
                onChange={(e, data) => setInterests(!!data.checked)}
              />
              <Form.Checkbox
                style={{ marginBottom: "0.25em" }}
                toggle
                label="Skillshare"
                checked={skillshare}
                onChange={(e, data) => setSkillshare(!!data.checked)}
              />
              <Form.Checkbox
                style={{ marginBottom: "0.25em" }}
                toggle
                label="Services"
                checked={services}
                onChange={(e, data) => setServices(!!data.checked)}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Segment>
      <div
        className={`cardContainer ${
          skillshare || services || bio || interests ? "extra" : ""
        }`}
      >
        {filtered.map((profile) => {
          return (
            <ProfileCard
              key={profile.person_id}
              profile={profile}
              bio={bio}
              services={services}
              skillshare={skillshare}
              interests={interests}
            />
          );
        })}
        {filtered.length === 0 && (
          <p>No members found with this search criteria...</p>
        )}
      </div>
    </SimplePage>
  );
};

export default MemberDirectory;

type ProfileCardProps = {
  profile: Profile;
  bio: boolean;
  skillshare: boolean;
  services: boolean;
  interests: boolean;
};

const ProfileCard: React.FC<ProfileCardProps> = ({
  profile,
  bio,
  skillshare,
  services,
  interests,
}) => {
  const extra = bio || skillshare || services || interests;
  const href = `/members/${profile.person_id}`;

  return (
    <>
      <div className="profileCard">
        <Link to={href}>
          <Image src={profile?.profile_image_url || "/profile.png"} circular />
        </Link>

        <Header as="h4" style={{ margin: 0, wordBreak: "break-word" }}>
          <Link to={href}>{profile.name}</Link>
        </Header>

        <p style={{ color: "#999" }}>{profile.pronouns || "-"}</p>
        <HouseholdLabels households={profile.households} />
      </div>
      {extra && (
        <Segment className="extraBits">
          {bio && profile.bio && (
            <>
              <Header as="h4" style={{ marginBottom: "0.25em" }}>
                About Me
              </Header>
              <Markdown text={profile.bio} />
            </>
          )}
          {interests && profile.interest_tags?.length > 0 && (
            <>
              <Header as="h4" style={{ marginBottom: "0.25em" }}>
                Interests
              </Header>
              <ProfileTags noLink tags={profile.interest_tags} />
            </>
          )}
          {skillshare && profile.skillshare && (
            <>
              <Header as="h4" style={{ marginBottom: "0.25em" }}>
                Skillshare
              </Header>
              <Markdown text={profile.skillshare} />
            </>
          )}
          {services && profile.services && (
            <>
              <Header as="h4" style={{ marginBottom: "0.25em" }}>
                Services
              </Header>
              <Markdown text={profile.services} />
            </>
          )}
        </Segment>
      )}
    </>
  );
};

export const HouseholdLabels = ({
  households,
}: {
  households?: Household[];
}) => {
  return (
    <>
      {households?.map((h) => {
        return (
          <Label
            style={{ marginBottom: "0.25em" }}
            key={h.id}
            content={h.name}
            icon={h.is_org ? "building" : "home"}
            size="tiny"
          />
        );
      })}
    </>
  );
};
