import API from 'Api'
import { HouseholdContainer } from 'Context/Household'
import { UserContainer } from 'Context/User'
import React, { FormEvent, useContext } from 'react'
import { Button, Form, Message } from 'semantic-ui-react'
import Dollars from 'Shared/Dollars'
import { useRequest } from 'Shared/Hooks'
import { MembershipStatus } from 'Shared/Models'

const MembershipAutoPayForm = () => {
  const {household, membership, load} = useContext(HouseholdContainer);
  const {user} = useContext(UserContainer);
  const [loading, error, run] = useRequest({});

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    run(API.updateHouseholdMembership(household.id, membership, MembershipStatus.UnderReview), load)
  }

  const notInHousehold = household.people.filter(({person})=>(person.user && person.user.id === user.id)).length === 0;
  if (notInHousehold) {
    return <Message>You must be in this household to setup Autopay.</Message>
  }
  
  const { amount, extras, subscription_id } =  membership;

  if (subscription_id) {
    return <Message>This account already has autopay configured. Please <a href="mailto:hello@aldercommons.org">contact us</a> if you need to make changes!</Message>
  }

  return (
    <>
      {error && <Message negative>{error}</Message>}
      <p>After your background checks clear, and we approve your Membership, we'll initiate an automatic monthly charge in the amount indicated below. Please <a href="mailto:hello@aldercommons.org">contact us</a> if you have any questions.</p>

      <Form error name="payment" loading={loading} onSubmit={handleSubmit}>
        <Form.Field>
          <label>Membership + Extras</label>
          <Dollars amount={amount + extras} />
        </Form.Field>

        <Button primary type="submit">I approve of these monthly payments</Button>
      </Form>
    </>
  );
}

export default MembershipAutoPayForm;
