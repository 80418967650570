import _ from "lodash";
import moment from "moment";
import React, { FormEvent, useCallback, useState } from "react";
import { RRule, RRuleSet, rrulestr } from "rrule";
import { Options } from "rrule/dist/esm/src/types";
import { CheckboxProps, Form, Grid, Segment } from "semantic-ui-react";
import { PlainTextRule } from "Shared/Date";
import { DateTimePicker } from "Shared/DateTimePicker";
import { Duration } from "Shared/Duration";
import { ChangeHandler } from "Shared/Hooks";
import { Program } from "Shared/Models";
import { NumberField } from "Shared/Number";
import { Panel } from "Shared/Panel";
import Tooltip from "Shared/Tooltip";

import { Weekdays } from "./Weekdays";
import { WeekdaysMonthly } from "./WeekdaysMonthly";

export type WkDay = { weekday: number; n: number };

const getNext = (exdates: Record<number, string>) =>
  (Number(
    Object.keys(exdates)
      .map(Number)
      .sort((a: number, b: number) => a - b)
      .pop()
  ) || 0) + 1;

// used onLoad but also for cloning
const stateFromProgram = (program: Program) => {
  let fields = {
    start_date: moment
      .utc()
      .add(7, "days")
      .hours(12)
      .minutes(0)
      .seconds(0)
      .format(),
    end_date: moment.utc().add(10, "weeks").endOf("day").format(),
    duration: 60,
    setup_duration: 0,
    cleanup_duration: 0,
    rrule: "",
  };

  // handle rrule fields
  let options: Partial<Options> = {
    freq: undefined,
    interval: 1,
    byweekday: [],
  };

  let ruleset = undefined;
  let exdates: Record<number, string> = {};

  if (program) {
    fields = _.pick(program, [
      "start_date",
      "end_date",
      "duration",
      "setup_duration",
      "cleanup_duration",
      "rrule",
    ]);
    if (program.rrule) {
      ruleset = rrulestr(program.rrule, { forceset: true }) as RRuleSet;
      options = _.pick(
        ruleset.rrules()[0].origOptions,
        "freq",
        "interval",
        "byweekday"
      );

      // parse xdates
      const xd = ruleset.exdates();
      for (let i = 0; i < xd.length; i++) {
        let m = moment.utc(xd[i]);
        if (m.isValid()) {
          exdates[i] = m.format("YYYY-MM-DD");
        }
      }
    }
  }

  // create a blank value for adding new exclusions
  exdates[getNext(exdates)] = "";

  return Object.assign({}, options, {
    ruleset,
    exdates,
    fields,
  });
};

export const ProgramFormSchedule = ({
  fields,
  onScheduleChange,
}: {
  fields: Program;
  onScheduleChange: (fields: any) => void;
}) => {
  const [state, setState] = useState(stateFromProgram(fields));
  const [hasSetupOrCleanup, setHasSetupOrCleanup] = useState(
    fields.setup_duration > 0 || fields.cleanup_duration > 0
  );

  const handleHasSetup = useCallback(
    (e: FormEvent, { checked }: CheckboxProps) => {
      setHasSetupOrCleanup(!!checked);
      if (!checked) {
        setState((state) => {
          state.fields.setup_duration = 0;
          state.fields.cleanup_duration = 0;
          onScheduleChange(state.fields);
          return state;
        });
      }
    },
    [onScheduleChange]
  );

  const handleChange: ChangeHandler = useCallback(
    (e, { name, value }) => {
      if (name === "end_date") {
        let m = moment.utc(value as string, "YYYY-MM-DD");
        if (m.isValid()) {
          value = m.endOf("day").format();
        }
      }

      let s = _.cloneDeep(state);

      if (name.startsWith("exdates")) {
        _.set(s, name, value);

        // remove falsy values
        Object.keys(s.exdates).forEach((key) => {
          if (!s.exdates[Number(key)]) {
            delete s.exdates[Number(key)];
          }
        });

        // create a blank value for adding new exclusions
        s.exdates[getNext(s.exdates)] = "";
      }

      // handle all-day duration
      if (name === "duration" && value === 1440) {
        let m = moment.utc(s.fields.start_date);
        if (m.isValid()) {
          s.fields.cleanup_duration = 0;
          s.fields.setup_duration = 0;
          s.fields.start_date = m.startOf("day").format();
        }
      }

      // handle rrule fields separately
      if (["freq", "interval", "byweekday"].includes(name)) {
        s = Object.assign(s, { [name]: value });
        // reset weekday rule on frequency change
        if (name === "freq") {
          s.byweekday = [];
          s.exdates = { 0: "" };
        }
      } else {
        s.fields = Object.assign(s.fields, { [name]: value });
      }

      // guard against useless rrule
      if (
        !s.freq ||
        !s.interval ||
        !s.fields.start_date ||
        !s.fields.end_date ||
        moment.utc(s.fields.end_date).year() < 2000 ||
        moment.utc(s.fields.start_date).year() < 2000
      ) {
        s.fields.rrule = "";
        s.ruleset = undefined;
      } else {
        // update rrule
        s.ruleset = new RRuleSet();
        s.ruleset.rrule(
          new RRule({
            freq: s.freq,
            interval: s.interval,
            byweekday:
              _.isArray(s.byweekday) && s.byweekday.length > 0
                ? s.byweekday
                : undefined,
            dtstart: moment.utc(s.fields.start_date).toDate(),
            until: moment.utc(s.fields.end_date).toDate(),
          })
        );

        // iterate and add exlusion dates
        const start = moment.utc(s.fields.start_date);
        Object.keys(s.exdates).forEach((key) => {
          if (s.exdates[Number(key)]) {
            let exdateFudged = moment
              .utc(s.exdates[Number(key)])
              .hour(start.hours())
              .minute(start.minutes())
              .toDate();
            if (s.ruleset && exdateFudged.getFullYear() > 2000) {
              s.ruleset.exdate(exdateFudged);
            }
          }
        });

        s.fields.rrule = s.ruleset.toString();
      }

      setState(s);
      onScheduleChange(s.fields);
    },
    [onScheduleChange, state]
  );

  const { freq, interval, ruleset, byweekday, exdates } = state;
  const { start_date, end_date, duration, setup_duration, cleanup_duration } =
    state.fields;
  const all = ruleset ? ruleset.all() : [];

  return (
    <Panel heading="Schedule" noSegment>
      <Grid as={Segment} attached="bottom" stackable columns={2}>
        <Grid.Column width={8}>
          <Form.Field required>
            <label>Date &amp; Time</label>
            <DateTimePicker
              name="start_date"
              value={start_date}
              onChange={handleChange}
            />
          </Form.Field>

          <Duration
            prefix="Event"
            name="duration"
            handleChange={handleChange}
            defaultValue={duration}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <Form.Field>
            <label>Setup &amp; Cleanup</label>
            <Form.Checkbox
              label="I need to reserve time for setup and cleanup"
              checked={hasSetupOrCleanup}
              onChange={handleHasSetup}
            />
          </Form.Field>
          {hasSetupOrCleanup && (
            <>
              <Duration
                prefix="Setup"
                name="setup_duration"
                handleChange={handleChange}
                defaultValue={setup_duration}
              />
              <Duration
                prefix="Cleanup"
                name="cleanup_duration"
                handleChange={handleChange}
                defaultValue={cleanup_duration}
              />
            </>
          )}
        </Grid.Column>

        <Grid.Column width={10}>
          <Form.Group style={{ marginBottom: "1em" }}>
            <Form.Select
              compact
              label="Recurrence"
              style={{ minWidth: "8em" }}
              name="freq"
              value={freq ? freq : false}
              onChange={handleChange}
              options={RecurrenceOptions}
            />
            {!freq ? (
              false
            ) : (
              <>
                <NumberField
                  style={{ maxWidth: "6em" }}
                  label="Interval"
                  name="interval"
                  min={1}
                  value={interval}
                  onChange={handleChange}
                />
                <Form.Input
                  label="Until"
                  style={{ maxWidth: "11em" }}
                  name="end_date"
                  type="date"
                  value={end_date.length > 10 && end_date.substring(0, 10)}
                  onChange={handleChange}
                />
              </>
            )}
          </Form.Group>
          {freq === RRule.WEEKLY && (
            <Weekdays
              name="byweekday"
              defaultValues={byweekday as WkDay[]}
              onChange={handleChange}
            />
          )}
          {freq === RRule.MONTHLY && (
            <WeekdaysMonthly
              name="byweekday"
              defaultValues={byweekday as WkDay[]}
              onChange={handleChange}
            />
          )}
          {!freq ? (
            false
          ) : (
            <Form.Field>
              <label>
                Excluding{" "}
                <Tooltip content="Useful for skipping dates that land on a holiday, for instance." />
              </label>
              {Object.keys(exdates).map((x) => {
                return (
                  <Form.Input
                    key={x}
                    style={{ maxWidth: "11em" }}
                    name={`exdates[${x}]`}
                    type="date"
                    value={exdates[Number(x)]}
                    onChange={handleChange}
                  />
                );
              })}
            </Form.Field>
          )}
        </Grid.Column>

        <Grid.Column width={6}>
          {ruleset && (
            <div>
              <div style={{ marginBottom: "1em" }}>
                <PlainTextRule ruleset={ruleset} />
              </div>
              <div
                style={{
                  background: "#eee",
                  overflowY: "scroll",
                  maxHeight: "12em",
                }}
              >
                <div style={{ whiteSpace: "pre", margin: "0.75em 1em" }}>
                  {all
                    .map((d) => {
                      return moment.utc(d).format("ddd MMM D, YYYY");
                    })
                    .join("\n")}
                </div>
              </div>
              <pre
                style={{ fontSize: "10px", lineHeight: 1, overflow: "auto" }}
              >
                {state.fields.rrule}
              </pre>
            </div>
          )}
        </Grid.Column>
      </Grid>
    </Panel>
  );
};

const RecurrenceOptions = [
  {
    key: false,
    value: false,
    text: "None",
  },
  {
    key: RRule.DAILY,
    value: RRule.DAILY,
    text: "Daily",
  },
  {
    key: RRule.WEEKLY,
    value: RRule.WEEKLY,
    text: "Weekly",
  },
  {
    key: RRule.MONTHLY,
    value: RRule.MONTHLY,
    text: "Monthly",
  },
];
