import _ from "lodash";
import React, { MouseEvent, useState } from "react";
import { Weekday } from "rrule";
import { Button, Form } from "semantic-ui-react";
import { ChangeHandler } from "Shared/Hooks";

import { WkDay } from "./ProgramFormSchedule";

const defaultState = (defaultValues?: WkDay[]): { days: Weekday[] } => {
  return {
    days: defaultValues
      ? defaultValues.map((val) => new Weekday(val.weekday, val.n))
      : [],
  };
};

const dayValue: Record<string, number> = {
  Monday: 0,
  Tuesday: 1,
  Wednesday: 2,
  Thursday: 3,
  Friday: 4,
  Saturday: 5,
  Sunday: 6,
};

export const WeekdaysMonthly = ({
  name: parentName,
  defaultValues,
  onChange,
}: {
  name: string;
  defaultValues?: WkDay[];
  onChange: ChangeHandler;
}) => {
  const [state, setState] = useState(defaultState(defaultValues));

  const handleChange: ChangeHandler = (e, { name, value }) => {
    setState((state) => {
      if (name) {
        // name is the array idx here
        state.days[Number(name)] = value as Weekday;
        onChange(null, {
          name: parentName,
          value: state.days,
        });
      }
      return state;
    });
  };

  const addItem = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setState((state) => {
      state.days.push(new Weekday(0, 1));

      onChange(null, {
        name: parentName,
        value: state.days,
      });

      return state;
    });
  };

  const removeItem = (i: number) => {
    return (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setState((state) => {
        state.days = state.days.filter((item, j) => i !== j);

        onChange(e, {
          name: parentName,
          value: state.days,
        });

        return state;
      });
    };
  };

  const { days } = state;
  return (
    <div style={{ marginBottom: "1em" }}>
      {days.map((d, i) => {
        return (
          <Form.Group key={"" + i}>
            <Entry onChange={handleChange} name={String(i)} d={d} />
            <Form.Button label="&nbsp;" icon="close" onClick={removeItem(i)} />
          </Form.Group>
        );
      })}
      <Button size="tiny" onClick={addItem}>
        Add Weekday Constraint
      </Button>
    </div>
  );
};

const entryState = (d: Weekday): Record<string, number> => {
  if (d && _.isNumber(d.weekday)) {
    return {
      day: d.weekday,
      iter: d.n || 1,
    };
  }

  return {
    day: 0,
    iter: 1,
  };
};

const Entry = ({
  name: parentName,
  d,
  onChange,
}: {
  name: string;
  d: Weekday;
  onChange: ChangeHandler;
}) => {
  const [state, setState] = useState(entryState(d));

  const handleChange: ChangeHandler = (e, { name, value }) => {
    setState((state) => {
      state[name] = value as number;
      onChange(e, {
        name: parentName,
        value: new Weekday(state.day, state.iter),
      });
      return state;
    });
  };

  const { day, iter } = state;
  return (
    <>
      <Form.Select
        label="Iteration"
        name="iter"
        value={iter}
        options={iterationOptions}
        onChange={handleChange}
      />
      <Form.Select
        label="Day"
        name="day"
        value={day}
        options={dayOptions}
        onChange={handleChange}
      />
    </>
  );
};

const iterationOptions = [
  {
    key: "first",
    text: "First",
    value: 1,
  },
  {
    key: "second",
    text: "Second",
    value: 2,
  },
  {
    key: "third",
    text: "Third",
    value: 3,
  },
  {
    key: "fourth",
    text: "Fourth",
    value: 4,
  },
  {
    key: "last",
    text: "Last",
    value: -1,
  },
];

const dayOptions = Object.keys(dayValue).map((d) => {
  return {
    key: d,
    text: d,
    value: dayValue[d],
  };
});
