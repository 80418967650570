const orgMinimum = 100;
const householdMinimum = 60;

export const minimumFee = (is_org: boolean) =>
  is_org ? orgMinimum : householdMinimum;

// useful math starts here
function power_law(income: number, is_org: boolean): number {
  const progressive_min = 0.75; // 0.2 means minimum is 20% of midpoint
  const progressive_max = 4; // 2 means maximum is 200% of midpoint

  // the exponent controls the steepness of progressive ramping -- this is super sensitive!
  // higher values povider greater discounts for folks lower on the scale
  const power_law_exponent = 1.1;

  // this is the value we divide the result of the exponentiation by to scale things from 0->1
  const top_of_scale = is_org ? 300000 : 160000;

  // % values from 0 to max mapped to incomes from $0 to $top_of_scale
  const scale_to_one = Math.pow(top_of_scale, power_law_exponent);
  const scaled_to_max =
    (progressive_max * Math.pow(income, power_law_exponent)) / scale_to_one;
  if (scaled_to_max < progressive_min) {
    return progressive_min;
  } else if (scaled_to_max > progressive_max) {
    return progressive_max;
  } else {
    return scaled_to_max;
  }
}

// summing of geometric series
function geometric(base: number, pow: number, series_length: number): number {
  let sum = base;
  for (let i = 1; i < series_length; i++) {
    sum += base * Math.pow(1 - pow, i);
  }

  // handle remainders
  const rem = series_length - Math.floor(series_length);
  if (rem) {
    sum += rem * base * Math.pow(1 - pow, series_length - 1);
  }

  return sum;
}

export default function getSuggestedPrice(
  adult: number,
  youth: number,
  days: number,
  income: number,
  is_org: boolean
): number {
  const base = minimumFee(is_org);
  const size_step = 0.2; // 0.4 means 40% decrease in cost/person
  const price = (days / 4) * geometric(base, size_step, adult + youth);

  // then adjust for household income
  const suggested = Math.ceil(power_law(income, is_org) * price);
  return suggested < minimumFee(is_org) ? minimumFee(is_org) : suggested;
}
