import API from 'Api'
import { UserContainer } from 'Context/User'
import React, { useContext } from 'react'
import { Button, Form, Message } from 'semantic-ui-react'

import { useFields, useRequest } from './Hooks'
import { Household, HouseholdPerson } from './Models'

const CreateGroup: React.FC<{attachUser?: boolean, onSuccess: (h: Household)=>void}>= ({attachUser, onSuccess}) => {
  const {user} = useContext(UserContainer)
  const [loading, error, run] = useRequest<Household>({} as Household);
  const {fields, handleChange} = useFields({
    name: '',
    is_org: false,
    people: [] as HouseholdPerson[]
  })

  const handleSubmit = () => {
    let body = fields;
    if (attachUser && user) {
      body.people = [{
        person_id: user.person_id
      } as HouseholdPerson];
    }

    run(API.createHousehold(body), onSuccess)
  }

  const { name, is_org } = fields;

  return <Form error name="create-group" loading={loading} onSubmit={handleSubmit} style={{maxWidth: '30em'}}>
    <Message error>{error}</Message>
    <Form.Input
      style={{maxWidth: '14em'}}
      name='name'
      required
      label='Group Name'
      value={name}
      onChange={handleChange}
    />
    <Form.Checkbox
      name='is_org'
      label='Group is an Organization'
      checked={is_org}
      onChange={handleChange}
    />
    <Button primary type="submit">Create Group</Button>
  </Form>
}

export default CreateGroup;
