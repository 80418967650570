import API from "Api";
import { HouseholdContainer } from "Context/Household";
import { useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Loader, Message } from "semantic-ui-react";
import { useRequest } from "Shared/Hooks";
import { Household, HouseholdInDirectory } from "Shared/Models";

export const GroupInDirectoryChecker = () => {
  const { household } = useContext(HouseholdContainer);

  const [loading, error, run, result] = useRequest<Household|false>(false)

  const handleChoice = useCallback((in_directory: HouseholdInDirectory) => {
    run(API.updateHousehold(Object.assign({}, household, {in_directory})))
  }, [run, household]);

  if (household.in_directory !== HouseholdInDirectory.Undecided) {
    return <></>
  }

  if (result) {
    return <Message success>Thanks!</Message>
  }

  return <>
    {error && <Message warning>{error}</Message>}
    <Message><p>Can we include your group's name, <i>{household.name}</i>, in the <Link to='/members'>Member Directory</Link>?</p>
      {loading ? <Loader inline active/> : <>
        <Button primary size='small' loading={loading} onClick={()=>{handleChoice(HouseholdInDirectory.Yes)}}>Yes</Button>
        <Button size='small' loading={loading} onClick={()=>{handleChoice(HouseholdInDirectory.No)}}>No</Button>
      </>}
    </Message>
  </>
}
