import { HouseholdPersonRole } from "./Models";

export const Roles: Record<HouseholdPersonRole, string> = {
  [HouseholdPersonRole.Dependent]: "Dependent",
  [HouseholdPersonRole.Inactive]: "Inactive",
  [HouseholdPersonRole.Admin]: "Group Admin"
}

export const RoleOptions = Object.keys(Roles).map(Number).map((role: HouseholdPersonRole) => {
  return {
    key: role,
    value: role,
    text: Roles[role],
  };
});
