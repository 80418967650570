import React from 'react'
import ResponsiveTabs, { ResponsiveTabsProps } from 'Shared/ResponsiveTabs'
import SimplePage from 'Shared/SimplePage'

import Groups from './Groups'
import Memberships from './Memberships'
import Misc from './Misc'
import People from './People'
import Programs from './Programs'

type AdminProps = ResponsiveTabsProps

const Admin: React.FC<AdminProps>= (props) => (
  <SimplePage icon='lock' title='Admin'>
    <ResponsiveTabs {...props} items={[
      {staff: true, path: 'people', name:"People", component: People},
      {staff: true, path: 'households', name:"Groups", component: Groups},
      {staff: true, path: 'memberships', name:"Memberships", component: Memberships},
      {staff: true, path: 'programs', name:"Programs", component: Programs},
      {staff: true, path: 'misc', name:"Misc", component: Misc},
    ]}/>
  </SimplePage>
);

export default Admin;
