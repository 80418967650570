import { HouseholdContainer } from "Context/Household";
import { UserContainer } from "Context/User";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Grid, Icon, List, Message, Table } from "semantic-ui-react";
import { MemberResources } from "Shared/MemberResources";
import { MembershipStatusLabel } from "Shared/MembershipStatus";
import {
  HouseholdPerson,
  HouseholdPersonRole,
  MembershipStatus,
  UserRole,
} from "Shared/Models";
import { Panel } from "Shared/Panel";
import { PersonName } from "Shared/PersonName";
import { Admin } from "Shared/Roles";

import { GroupFiles } from "./Files";
import { GroupInDirectoryChecker } from "./GroupInDirectoryChecker";
import MembershipOnboarding from "./MembershipOnboarding";
import { StripeDetails } from "./StripeDetails";

const MembershipDashboard = () => {
  const { user } = useContext(UserContainer);
  const { membership, householdLoading, householdError } =
    useContext(HouseholdContainer);

  if (householdError) {
    return <Message warning>{householdError}</Message>;
  }

  if (
    !householdLoading &&
    (membership === null || !membership.id) &&
    !user.can_onboard &&
    user.role !== UserRole.Admin
  ) {
    return (
      <Message warning>
        You must be <i>invited</i> to start onboarding as a member at Alder
        Commons. Please <a href="mailto:hello@aldercommons.org">contact us</a>.
      </Message>
    );
  }

  if (membership.status === MembershipStatus.Active) {
    return <InnerDashboard />;
  }

  if (membership.status === MembershipStatus.Canceled) {
    return (
      <p>
        Your membership was canceled - if you have questions, please{" "}
        <a href="mailto:hello@aldercommons.org?subject=Membership Review">
          contact us
        </a>
        !
      </p>
    );
  }

  return <MembershipOnboarding />;
};

export default MembershipDashboard;

export const InnerDashboard = () => {
  return (
    <>
      <GroupInDirectoryChecker />
      <Grid columns={2} stackable>
        <Grid.Column>
          <StatusPanel />
          <People />
        </Grid.Column>
        <Grid.Column>
          <MemberResources />
          <GroupFiles />
        </Grid.Column>
      </Grid>
    </>
  );
};

export const StatusPanel = ({ heading = "My Membership" }) => {
  const { membership } = useContext(HouseholdContainer);
  return (
    <Panel icon="star" heading={heading} noSegment>
      <Table attached="bottom">
        <Table.Body>
          <Table.Row>
            <Table.Cell collapsing>Status</Table.Cell>
            <Table.Cell>
              <MembershipStatusLabel size="small" status={membership.status} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Billing</Table.Cell>
            <Table.Cell>
              <StripeDetails />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Panel>
  );
};

const People = () => {
  const { household } = useContext(HouseholdContainer);

  if (!household.people || household.people.length === 0) {
    return <>Nobody to show?</>;
  }
  const inactivePeople = household.people.filter(
    ({ role }) => role === HouseholdPersonRole.Inactive
  );

  const activePeople = household.people
    .filter(({ role }) => role !== HouseholdPersonRole.Inactive)
    .sort((a, b) => (a.role < b.role ? 1 : -1));

  return (
    <Panel icon="users" heading="Members">
      <p>
        If you need to change who is in your group, please{" "}
        <a href="mailto:hello@aldercommons.org">contact us</a>.
      </p>
      {inactivePeople && (
        <List>
          {activePeople &&
            activePeople.map((hp) => (
              <PersonListItem key={hp.person.id} hp={hp} />
            ))}
          <Admin>
            {inactivePeople &&
              inactivePeople.map((hp) => (
                <PersonListItem key={hp.person.id} hp={hp} />
              ))}
          </Admin>
        </List>
      )}
    </Panel>
  );
};

const PersonListItem = ({ hp }: { hp: HouseholdPerson }) => {
  const { role, person } = hp;
  return (
    <List.Item>
      <Icon
        name="user"
        color={role === HouseholdPersonRole.Inactive ? "grey" : "black"}
      />
      <Link to={`/person/${person.id}`}>
        <PersonName person={person} />
      </Link>
    </List.Item>
  );
};
