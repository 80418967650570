import { Message } from "semantic-ui-react";

import { MyEvents } from "./MyEvents";
import Registrations from "./Registrations";

const MyPrograms = () => {
  return <>
    <Message size='tiny'>Note: If you registered <i>someone else</i> for a program, it will show up on <i>their</i> dashboard.</Message>
    <MyEvents/>
    <Registrations/>
  </>
}

export default MyPrograms;
