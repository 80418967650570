import { HouseholdContainer, WithHousehold } from 'Context/Household'
import { UserContainer } from 'Context/User'
import React, { useContext } from 'react'
import { useParams } from 'react-router'
import { MembershipStatus } from 'Shared/Models'
import ResponsiveTabs, { TabProps } from 'Shared/ResponsiveTabs'
import { IsAdmin } from 'Shared/Roles'
import SimplePage from 'Shared/SimplePage'

import { AdminMembership } from './AdminMembers'
import { Cancel } from './Cancel'
import { Edit } from './Edit'
import MembershipDashboard from './MembershipDashboard'
import AddHouseholdPerson from './People/AddHouseholdPerson'

const HouseholdSection = () => {
  const { id } = useParams<{id: string}>();
  return (
    <WithHousehold id={Number(id)}>
      <HouseholdInner/>
    </WithHousehold>
  );
}

export default HouseholdSection

const HouseholdInner = () => {
  const { user } = useContext(UserContainer);
  const { household, membership, householdLoading, householdError } = useContext(HouseholdContainer);
  const { name, is_org } = household;

  const memberOrAdmin = membership.status === MembershipStatus.Active || IsAdmin(user);

  const items = [
    {path: 'membership', exact: true,  name:'Membership', component: MembershipDashboard},
    {hidden: true, path: 'add-person', exact: true, name: 'Add Person', component: AddHouseholdPerson},
    memberOrAdmin && {path: 'edit', name:'Edit', component: Edit},
    memberOrAdmin && {path: 'cancel', name:'Cancel', component: Cancel},
    {staff: true, icon: 'lock', path: 'admin', name:'Admin', component: AdminMembership}
  ].filter(x=>x);
  
  return (
    <SimplePage icon={is_org ? 'building' : 'home'} title={name} loading={householdLoading} error={householdError}>
      <ResponsiveTabs items={items as TabProps[]}/>
    </SimplePage>
  );
}
