import React from 'react'
import { Icon, Popup, PopupProps, SemanticICONS } from 'semantic-ui-react'

const Tooltip = (props: PopupProps & {icon?: SemanticICONS}) => {
  return (
    <Popup 
      {...props}
      size='small'
      position='top center'
      trigger={
        <Icon 
          link 
          size='small' 
          color='grey' 
          name={props.icon ? props.icon : 'info circle'}/> 
      } />
  );
}

export default Tooltip;
