import moment from 'moment-timezone'
import React from 'react'
import { RRule, RRuleSet, rrulestr } from 'rrule'
import { Divider, Modal } from 'semantic-ui-react'
import { TimeRange } from 'Shared/Time';

import { Program } from './Models';

const compactFormat = "YYYY-MM-DD";
const shortFormat = "MMM D, YYYY"
const fullFormat = "YYYY-MM-DD h:mma";
const noYearFormat = "ddd, MMM D"
const baseFormat = "ddd, MMM D, YYYY";

type DateProps = {
  compact?: boolean
  short?: boolean
  full?: boolean
  noYear?: boolean
  ts: string | Date
}

export const Date: React.FC<DateProps> = ({compact, short, full, noYear, ts }) => {
  if (compact) {
    return <>{moment.utc(ts).format(compactFormat)}</>
  }
  if (short) {
    return <>{moment.utc(ts).format(shortFormat)}</>
  }
  if (full) {
    return <>{moment.utc(ts).format(fullFormat)}</>
  }
  if (noYear) {
    return <>{moment.utc(ts).format(noYearFormat)}</>
  }
  return <>{moment.utc(ts).format(baseFormat)}</>
}

const timeStyle = {
  color: '#777'
}

export const ProgramDate = ({program}: {program: Program}) => {
  // no recurrence rule!
  if (!program.rrule) {
    return <div>
      <b><Date ts={program.start_date} /></b><br/> 
      <div style={timeStyle}><TimeRange program={program}/></div>
    </div>
  }

  let ruleset = rrulestr(program.rrule, {forceset: true}) as RRuleSet;
  let opts = ruleset.rrules()[0].options

  // recurrence rule is just a range!
  if (opts.freq === RRule.DAILY && opts.interval === 1 && ruleset.exdates().length === 0) {
    return <>
      <div><b><Date ts={program.start_date} /> &mdash; <Date ts={program.end_date} short/></b></div>
      <div style={timeStyle}><TimeRange program={program}/></div>
    </>
  }

  // few sessions!
  if (ruleset.count() <= 5) {
    return <>
      <div><SessionList ruleset={ruleset}/></div>
      <div style={timeStyle}><TimeRange program={program}/></div>
    </>
  }

  return <div>
    <div><b><Date ts={getNextOrLast(program)} /></b></div>
    <div style={timeStyle}><TimeRange program={program}/></div>
    <Divider fitted style={{margin: '0.5rem 0'}}/>
    <div><PlainTextRule ruleset={ruleset}/></div>
    <SessionModal ruleset={ruleset}/>
  </div>
}

export const PlainTextRule = ({ruleset}: {ruleset: RRuleSet}) => {
  return <>
    Repeats {ruleset.rrules()[0].toText()} 
    {ruleset.exdates().length > 0 && 
      ', excluding ' + ruleset.exdates().map(x => {
        return moment.utc(x).format('MMM D')
      }).join(', ') + '.'
    }
  </>
}

export const getNextOrLast = (program: Program) => {
  if (!program.rrule) {
    return program.end_date;
  }

  const ruleset = rrulestr(program.rrule, {forceset: true});
  const now = moment.tz('America/Los_Angeles').utc(true).toDate();
  const nextDate = ruleset.after(now);

  if (nextDate) {
    return moment.utc(nextDate).format();
  } else {
    return program.end_date;
  }
}

export const SessionModal = ({ruleset}: {ruleset: RRuleSet}) => (
  <Modal size='mini' closeIcon='close'
    trigger={<button className='a'>{ruleset.count()} sessions</button>}
  >
    <Modal.Content scrolling>
      <SessionList ruleset={ruleset}/>
    </Modal.Content>
  </Modal>
)

export const SessionList = ({ruleset}: {ruleset: RRuleSet}) => {
  const now = moment.tz('America/Los_Angeles').utc(true).toDate();
  const nextDate = ruleset.after(now);

  return <>
    {ruleset.all().map(ts => (
      <div key={String(ts.getTime())}>
        {nextDate && ts.getTime() === nextDate.getTime() ? <b><Date ts={ts}/></b>: <Date ts={ts}/>}
      </div>
    ))}
  </>
}
