import './calendar.css'

import API, { ProgramSearch } from 'Api'
import { UserContainer } from 'Context/User';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { useContext, useEffect, useRef } from 'react'
import { Link, Redirect, useLocation } from 'react-router-dom'
import { stripMarkdownLinks } from 'Routes/Program/ProgramView';
import { RegistrationTypes } from 'Routes/Program/RegistrationTypes';
import { Reservables,ReservableSelect } from 'Routes/Program/ReservableSelect';
import { rrulestr } from 'rrule'
import { Button, Dimmer, Form, Grid, Header, Icon, Label, Loader, Message } from 'semantic-ui-react'
import { useFields, useMobile, useRequest } from 'Shared/Hooks';
import { Program, ProgramStatus, RegistrationType } from 'Shared/Models';
import { ProgramStatuses } from 'Shared/ProgramStatus';
import { IsMember, Member, Staff } from 'Shared/Roles'
import SimplePage from 'Shared/SimplePage'
import { Time } from 'Shared/Time'

const CalendarPage: React.FC<CalendarWrapProps> = (props) => {
  const scrollTo = (top: number) => {
    window.scrollTo({
      top,
      behavior: "smooth"
    });
  }

  return (
    <SimplePage icon='calendar alternate outline' title='Calendar'>
      <div style={{marginBottom:'3em'}}>
        <p><a target="_blank" rel="noopener noreferrer" href="https://aldercommons.org/about">Alder Commons</a> is a non-profit community center where kids and adults alike can attend workshops &amp; classes, and spend their time freely.</p>
        <p>Many of our programs below are open to the public and available for free. Please be sure to get the consent of any young people that you register for our programs!</p>
      </div>
      {/* TODO: program card highlights */}
      <CalendarWrap {...props} scrollTo={scrollTo}/>
    </SimplePage>
  );
}

export default CalendarPage;

type CalendarWrapProps = {
  embedEffect?: () => void,
  scrollTo?: (top: number) => void
}

export const CalendarWrap: React.FC<CalendarWrapProps> = ({embedEffect, scrollTo}) => {
  const location = useLocation();
  const calRef = useRef<HTMLDivElement>(null);
  const url = new URL(String(window.location));
  const mobile = useMobile(900);

  const {fields, handleChange} = useFields<Params>({
    base: getBase(),
    showDraft: !!url.searchParams.get('d'),
    showPrivate: !!url.searchParams.get('p'),
    showRequested: !!url.searchParams.get('req'),
    filterRooms: url.searchParams.getAll('r') || [],
    showMore: !!url.searchParams.get('m')
  })

  const [loading, error, run, programs] = useRequest([])

  // handle navigation and filter changes
  useEffect(()=>{
    // scroll to the top
    if (scrollTo && calRef.current) {
      scrollTo(calRef.current.offsetTop);
    }

    // update query params
    setQueryParams(fields);
    
    run(API.getPrograms(makeFilter(mobile, fields)), () => {
      if (embedEffect){
        embedEffect();
      }
    })
  }, [run, fields, location, scrollTo, embedEffect, mobile, calRef])

  // reload once per hour
  useEffect(()=>{
    const interval = setInterval(() => {
      handleChange(null, {
        name:'base', 
        value: moment.tz('America/Los_Angeles').utc(true)
      })
    }, 1000 * 60 * 60);

    return () => {
      clearInterval(interval);
    }
  })

  // handlers
  const prev = () => {
    const value = fields.base.clone().add(-3, 'week');
    handleChange(null, {name:'base', value});
  }

  const next = () => {
    const value = fields.base.clone().add(3, 'week');
    handleChange(null, {name:'base', value});
  }

  // calendar
  const { base, showDraft, showPrivate, showRequested, filterRooms, showMore } = fields;
  const indexedPrograms = indexPrograms(mobile, base, programs, showMore);

  // prompt for login if they need to see private
  const {user} = useContext(UserContainer)
  if (!IsMember(user) && showPrivate) {
    return <Redirect to={{
      pathname: '/login',
      search: '',
      state: { from: location }
    }} />
  }

  let filteredPrograms = _.clone(indexedPrograms);

  // filter by reserved room?
  if (filterRooms && filterRooms.length) {
    Object.keys(filteredPrograms).forEach(day => {
      filteredPrograms[day] = filteredPrograms[day].filter(p => {
        return _.intersection(p.reservations, filterRooms).length > 0;
      })
    })
  }

  const isEmpty = _.flatten(Object.values(filteredPrograms)).length === 0;

  return <>
    <div ref={calRef} className="accalendar-wrap">
      {error && <Message negative>{error}</Message>}

      {/* legend and filters */}
      <Grid stackable columns={3} style={{marginBottom: '0.5em'}}>
        <Grid.Row verticalAlign='bottom'>
          <Grid.Column textAlign={mobile ? 'center' : 'left'}>
            <div style={{fontSize: '0.8em', lineHeight: 1.3, border: '1px solid #ccc', borderRadius: '0.5em', display: 'inline-block', padding: '0.5em', background: 'white'}}>
              <div className='short-program highlight legend'>
                <span className="title">highlighted</span>
              </div>
              <Icon name='star'/> <a href="https://aldercommons.org/membership">members-only</a> programs<br/>
              <Icon name='dollar'/> has registration fees<br/>
              <Icon name='exclamation circle'/> full or waitlisted
              <Member>
                <br/>
                <Icon name='eye slash'/> private reservation
              </Member>
            </div>
          </Grid.Column>
          <Grid.Column textAlign='center'>
            {!embedEffect && <ActionButtons/>}
          </Grid.Column>
          <Grid.Column textAlign={mobile ? 'center' : 'right'}>
            <Staff>
              <Form.Checkbox
                toggle
                style={{width: '14em', textAlign:'left', marginBottom: '0.5em'}}
                label='Show 12 Weeks'
                name='showMore'
                checked={showMore}
                onChange={handleChange}/>
              <Form.Checkbox
                toggle
                style={{width: '14em', textAlign:'left', marginBottom: '0.5em'}}
                label='Show Draft'
                name='showDraft'
                checked={showDraft}
                onChange={handleChange}/>
              <Form.Checkbox
                toggle
                style={{width: '14em', textAlign:'left', marginBottom: '0.5em'}}
                label='Show Requested'
                name='showRequested'
                checked={showRequested}
                onChange={handleChange}/>
            </Staff>
            <Member>
              <Form.Checkbox
                toggle
                style={{width: '14em', textAlign:'left', marginBottom: '0.5em'}}
                label='Show Reservations'
                name='showPrivate'
                checked={showPrivate}
                onChange={handleChange}/>
              <ReservableSelect
                placeholder='Filter by Room'
                name='filterRooms'
                value={filterRooms}
                onChange={handleChange}/>
            </Member>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Dimmer.Dimmable style={{minHeight:'50px'}} dimmed={loading || isEmpty}>
        <Dimmer active={loading} inverted>
          <Loader/>
        </Dimmer>
        <Dimmer active={isEmpty && !loading} inverted>
          <Header as='h4'>
            No programs match your search<br/>
            {Object.keys(indexedPrograms).shift()} thru {Object.keys(indexedPrograms).pop()}
          </Header>
        </Dimmer>
        <Calendar base={base} indexedPrograms={filteredPrograms} embedEffect={embedEffect} showPrivate={showPrivate}/>
      </Dimmer.Dimmable>

      <div style={{display:'flex', justifyContent:'center', marginTop: '1em'}}>
        <Button disabled={loading} onClick={prev} icon='left arrow' content='Earlier' size='tiny' style={{marginTop: '0.5em'}}/>
        <Button disabled={loading} onClick={next} size='tiny' style={{marginTop: '0.5em'}}>
          Later <Icon name='arrow right'/>
        </Button>
      </div>
    </div>
  </>
}

const btnStyle = {
  marginBottom: '0.5em'
}

const ActionButtons = () => {
  const {user} = useContext(UserContainer)
  return <>
    <Button
      size='tiny'
      icon='search'
      as={Link}
      to='/search'
      content='Search'
      style={btnStyle}/>
    <Member>
      <Button
        size='tiny'
        icon='calendar alternate outline icon'
        as={Link}
        to={`/person/${user.person_id}/registrations`}
        content='My Programs'
        style={btnStyle}/>
      <Button
        size='tiny'
        icon='calendar plus outline'
        as={Link}
        to='/new-program'
        title='Request a Reservation or Host a Program'
        content='Reserve Space'
        style={btnStyle}/>
    </Member>
  </>
}

type CalendarProps = {
  base: moment.Moment,
  indexedPrograms: {[key: string]: Program[]},
  embedEffect?: () => void
  showPrivate: boolean
}

const Calendar = ({
  base,
  indexedPrograms,
  embedEffect,
  showPrivate,
}: CalendarProps) => {
  return <div className='accalendar'>
    <div className="day-label">Mon</div>
    <div className="day-label">Tue</div>
    <div className="day-label">Wed</div>
    <div className="day-label">Thu</div>
    <div className="day-label">Fri</div>
    <div className="day-label">Sat</div>
    <div className="day-label">Sun</div>
    {Object.keys(indexedPrograms).sort().map(day => {
      let programs = indexedPrograms[day];
      return <Day key={day} date={day} programs={programs} showPrivate={showPrivate} embedEffect={embedEffect}/>
    })}
  </div>
}

const dayClass = (date: string, programs: Program[]) => {
  const day = moment(date);
  const today = moment();

  let dayClass = ['short-day'];
  if (day.isBefore(today, 'day')) {
    dayClass.push('past');
  } else if (day.isSame(today, 'day')) {
    dayClass.push('today');
  }

  if (day.day() === 6 || day.day() === 0) {
    dayClass.push('weekend');
  }

  if (programs.length === 0) {
    dayClass.push('empty');
  }

  return dayClass.join(' ');
}

type DayProps = {
  date: string
  programs: Program[]
  showPrivate: boolean
  embedEffect?: ()=>void
}

export const Day: React.FC<DayProps> = ({date, programs, showPrivate, embedEffect}) => {
  const sortedByTime = _.sortBy(programs, [(p)=>{
    return p.start_date.substring(10);
  }]);

  return <div className={dayClass(date, programs)}>
    <div className='date-label'>{moment(date).format('MMM D')}</div>
    <h4 className="date-label-mobile">{moment(date).format('dddd, MMMM D')}</h4>
    <div className="classes">
      {sortedByTime.map(p => (
        <Prog key={p.id} p={p} showPrivate={showPrivate} embedEffect={embedEffect}/>  
      ))}
    </div>
    {showPrivate && <Link className='reserve' to={`/new-program?d=${moment(date).format('YYYY-MM-DD')}`}><Icon name='plus'/>Reserve...</Link>}
  </div>
}

type ProgProps = {
  p: Program,
  showPrivate: boolean,
  embedEffect?: () => void
}

const Prog = ({p, showPrivate, embedEffect}: ProgProps) => (
  <LinkOrA p={p} targetBlank={embedEffect !== undefined} className={programClass(p)}>
    {p.duration === 1440 ? '': 
      <span className="time"><Time m={moment.utc(p.start_date)}/>{showPrivate && <>-<Time m={moment.utc(p.start_date).add(p.duration, 'minutes')}/></>}</span>
    }
    <span className="title">{p.title}</span>&nbsp;<MiniIcon {...p}/><AtCapacityIcon capacity={p.capacity} participants={p.participants}/>

    {showPrivate && <div className='reservations'>
      {p.reservations.map(r => {
        return <Label size='mini' key={r}>{Reservables[r]}</Label>
      })}
      {p.do_not_disturb > 0 && <abbr title='Do Not Disturb' style={{fontWeight: 'bold', marginLeft: '0.1em'}}>
        DND
      </abbr>}
    </div>}
    {showPrivate && p.setup_duration > 0 && <div className='setup-cleanup'>
      Setup: {p.setup_duration} minutes
    </div>}
    {showPrivate && p.cleanup_duration > 0 && <div className='setup-cleanup'>
      Cleanup: {p.cleanup_duration} minutes
    </div>}
  </LinkOrA>
)

const AtCapacityIcon = ({capacity, participants}: {capacity: number, participants: number}) => {
  if (capacity && capacity <= participants) {
    return <Icon style={{display:'inline'}} size='small' name='exclamation circle'/>;
  }

  return <></>
}

const MiniIcon = ({registration_type, status}: {registration_type: RegistrationType, status: ProgramStatus}) => {
  if (status === ProgramStatus.Reserved) {
    return <Icon style={{display:'inline'}} size='small' name='eye slash'/>;
  }

  if (registration_type === RegistrationType.PaymentRequired || registration_type === RegistrationType.PaymentOptional) {
    return <Icon style={{display:'inline'}} size='small' name='dollar'/>;
  }

  if (registration_type === RegistrationType.MembersOnly) {
    return <Icon style={{display:'inline'}} size='small' name='star'/>;
  }

  return <></>
}

type LinkOrAProps = React.PropsWithChildren<{
  p: Program,
  className?: string
  targetBlank: boolean,
}>

export const LinkOrA = ({p, targetBlank, className, children}: LinkOrAProps) => {
  const url = `/program/${p.slug}`;
  const cleanedHost = p.host_text ? ' with ' + stripMarkdownLinks(p.host_text) : '';
  const attrs = {
    title: p.title + (p.status === 3 ? ': ' : cleanedHost)
  }

  if (targetBlank) {
    return <a className={className} rel="noopener noreferrer" target="_blank" href={url} {...attrs}>{children}</a>
  }

  return <Link className={className} to={url} {...attrs}>{children}</Link>
}

const programClass = (p: Program) => {
  const {status, registration_type, duration, tags} = p;
  let classes = ["short-program", ProgramStatuses[status].label.toLowerCase(), RegistrationTypes[registration_type].toLowerCase()];
  if (duration === 1440) {
    classes.push('all-day');
  }
  if (_.isArray(tags)) {
    classes = classes.concat(_.map(tags, 'label'));
  }
  return classes.join(' ');
}

// helpers

export const getStart = (mobile: boolean, base: Moment) => {
  if (mobile) {
    return base.clone().utc().startOf('day');
  }
  return base.clone().utc().startOf('day').startOf('isoWeek');
}

const gapSize = 3;
const bigGapSize = 12;
export const getEnd = (mobile: boolean, base: Moment, showMore: boolean) => {
  const gap = showMore ? bigGapSize : gapSize

  if (mobile) {
    return base.clone().utc().endOf('day').add(gap, 'week');
  }
  return base.clone().utc().endOf('day').add(gap, 'week').endOf('isoWeek');
}

type Params = {
  base: Moment,
  showDraft: boolean,
  showPrivate: boolean,
  showRequested: boolean,
  filterRooms: string[],
  showMore: boolean
}

const makeFilter = (mobile: boolean, {base, showDraft, showPrivate, showRequested, showMore}: Params): ProgramSearch => {
  return {
    after: getStart(mobile, base).format(),
    before: getEnd(mobile, base, showMore).format(),
    draft: String(showDraft),
    private: String(showPrivate),
    requested: String(showRequested),
    hydrate: 'true'
  }
}

const setQueryParams = (fields: Params) => {
  const {base, showDraft, showPrivate, showRequested, filterRooms, showMore} = fields;
  // flush existing parameters and replaceState with query params
  let url = new URL(window.location.origin + window.location.pathname);
  if (!base.isSame(moment.tz('America/Los_Angeles').utc(true), 'day')) {
    url.searchParams.append("base", base.format('YYYY-MM-DD'));
  }
  if (showDraft) {
    url.searchParams.append("d", String(showDraft));
  }
  if (showPrivate) {
    url.searchParams.append("p", String(showPrivate));
  }
  if (showRequested) {
    url.searchParams.append("req", String(showRequested));
  }
  if (filterRooms) {
    filterRooms.forEach(r => {
      url.searchParams.append("r", r);
    })
  }
  if (showMore) {
    url.searchParams.append("m", String(showMore));
  }
  window.history.replaceState(null, '', url);
}

const indexPrograms = (mobile: boolean, base: Moment, programs: Program[], showMore: boolean) => {
  let index = makeMap(mobile, base, showMore);

  if (programs.length > 0) {
    const start = getStart(mobile, base);
    const end = getEnd(mobile, base, showMore);

    // for each program, insert into the index
    _.forEach(programs, p => {
      if (p.rrule) {
        let ruleset = rrulestr(p.rrule, {forceset: true});

        // for each instance
        _.forEach(ruleset.between(start.toDate(), end.toDate(), true), i => {
          // clone the program but give it a new start date
          const key = moment.utc(i).format('YYYY-MM-DD');
          if (index[key]) {
            index[key].push(
              Object.assign({}, p, {start_date: moment.utc(i).format()})
            )
          }
        })
      } else {
        if (index[p.start_date.slice(0,10)]) {
          index[p.start_date.slice(0,10)].push(p);
        }
      }
    });
  }

  return index;
}

const makeMap = (mobile: boolean, base: Moment, showMore: boolean) => {
  let map: Record<string, Program[]> = {};
  let start = getStart(mobile, base);
  let end = getEnd(mobile, base, showMore);
  while (start.isBefore(end)) {
    map[start.format("YYYY-MM-DD")] = [];
    start.add(1, 'day');
  }

  return map;
}

export const getBase = () => {
  // parse query params for default state
  let base = moment.tz('America/Los_Angeles').utc(true);

  let url = new URL(String(window.location));
  if (url.searchParams.get('base')) {
    base = moment.tz(url.searchParams.get('base'), 'America/Los_Angeles').utc(true);
  }
  return base;
}
