import { Header, Icon, Segment, SemanticCOLORS, SemanticICONS } from "semantic-ui-react";

type PanelProps = React.PropsWithChildren<{
  icon?: SemanticICONS
  iconColor?: SemanticCOLORS
  heading: string
  noSegment?: boolean
  footer?: boolean
}>

export const Panel: React.FC<PanelProps> = ({icon, iconColor, heading, footer, children, noSegment=false}) => (
  <>
    <Header attached='top' as='h4'>
      {icon && <Icon name={icon} color={iconColor}/>}{heading}
    </Header>
    {noSegment ? children : 
      <Segment attached={footer ? true : 'bottom'}>
        {children}
      </Segment>}
  </>
)
