import { Button, Modal } from "semantic-ui-react"

import { Task } from "./Models"

export const TaskDetails: React.FC<{task: Task}> = ({task}) => (
  <Modal 
    closeIcon='close'
    trigger={<Button size='mini' content='Details'/>}
    content={<div style={{padding:'1em', whiteSpace: 'pre-wrap'}}>{JSON.stringify(task, null, 2)}</div>}
  />
)
