import API from 'Api'
import moment from 'moment'
import React, { useCallback, useEffect, useRef } from 'react'
import { Button, Divider, Form, Loader, Message, Table } from 'semantic-ui-react'
import { useFields, useRequest } from 'Shared/Hooks'
import { Doc, DocStatus } from 'Shared/Models'
import TableHeaders from 'Shared/TableHeaders'
import { Toggler } from 'Shared/Toggler'

export const Docs = () => {
  const [loading, error, run, docs] = useRequest<Doc[]>([])

  const load = useCallback(()=>{
    run(API.getDocs())
  }, [run])

  useEffect(load, [load]);

  if (loading) {
    return <Loader active inline='centered' />
  }

  if (error) {
    return <Message negative>{error}</Message>
  }

  return <>
    <CreateDoc onSuccess={load}/>
    <Divider/>
    <Table>
      <TableHeaders headers={['Link', 'Filename', 'Visible to Members', 'Upload Date']}/>
      <Table.Body>
        { docs && docs.map(d => (
          <Table.Row key={d.src}>
            <Table.Cell><a href={d.src}>{d.name}</a></Table.Cell>
            <Table.Cell><Renamer doc={d} onSuccess={load}/></Table.Cell>
            <Table.Cell><StatusSlider doc={d}/></Table.Cell>
            <Table.Cell><UploadDate ts={d.created_at}/></Table.Cell>
          </Table.Row>
        )) }
      </Table.Body>
    </Table>
  </>
}

const UploadDate: React.FC<{ts: string}> = ({ts}) => {
  return <>{moment.utc(ts).format()}</>
}


const CreateDoc: React.FC<{onSuccess: () => void}> = ({onSuccess}) => {
  const fileInput = useRef<HTMLInputElement | null>(null)
  const [loading, error, run] = useRequest({})
  const {fields, handleChange} = useFields({
    name: '',
    status: 1
  })
  
  const handleSubmit = () => {
    if (fileInput.current && fileInput.current.files) {
      run(API.createDoc(fileInput.current.files[0], fields), onSuccess)
    }
  }

  const { name } = fields;

  return <Form error name="create-doc" loading={loading} onSubmit={handleSubmit}>
    <Message error>{error}</Message>
    <Form.Group inline>
      <Form.Field required>
        <label>New File</label>
        <input type='file' ref={fileInput}/>
      </Form.Field>
      <Form.Input
        style={{maxWidth: '25em'}}
        required
        name='name'
        placeholder='Name'
        value={name}
        onChange={handleChange}
      />
      <Button primary type="submit">Save Document</Button>
    </Form.Group>
  </Form>
}

const Renamer: React.FC<{doc: Doc, onSuccess: ()=>void}> = ({doc, onSuccess}) => {
  const [loading, error, run] = useRequest({})
  const {fields, handleChange} = useFields(doc)

  const handleSubmit = () => {
    run(API.updateDoc(fields), onSuccess)
  }

  return <Form error name="rename-doc" loading={loading} onSubmit={handleSubmit}>
    <Message error>{error}</Message>
    <Form.Group inline style={{marginBottom: 0}} >
      <Form.Input
        name='name'
        value={fields.name}
        onChange={handleChange}/>
      <Form.Button title='Rename' icon='check'/>
    </Form.Group>
  </Form>
}

const StatusSlider: React.FC<{doc: Doc}>= ({doc}) => {
  const init = () => {
    return Promise.resolve(doc.status === DocStatus.Active);
  }

  const show = () => {
    return API.updateDoc(Object.assign({}, doc, {status: DocStatus.Active}))
  }

  const hide = () => {
    return API.updateDoc(Object.assign({}, doc, {status: DocStatus.Hidden}))
  }

  return <Toggler
    init={init}
    onCheck={show}
    onUncheck={hide}
  />
}

