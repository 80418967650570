import { Route, Switch, useRouteMatch } from "react-router";
import { NoMatch } from "Routes/Helpers";

import { MemberProfile } from "./MemberProfile";
import { MemberProfileContact } from "./MemberProfileContact";
import { MemberProfileEdit } from "./MemberProfileEdit";
import { MemberProfileEditImage } from "./MemberProfileEditImage";

const ProfileSection = () => {
  const match = useRouteMatch();
  return <Switch>
    <Route exact path={`${match.path}`}>
      <MemberProfile/>
    </Route>
    <Route exact path={`${match.path}/edit`}>
      <MemberProfileEdit/>
    </Route>
    <Route exact path={`${match.path}/edit-image`}>
      <MemberProfileEditImage/>
    </Route>
    <Route exact path={`${match.path}/contact`}>
      <MemberProfileContact/>
    </Route>
    <Route component={NoMatch} />
  </Switch>
}

export default ProfileSection;
