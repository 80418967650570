import { FileType } from "./Models";

export const FileTypes: Record<FileType, string> = {
  [FileType.Waiver]: "Waiver",
  [FileType.MutualAgreements]: "Mutual Agreements",
  [FileType.TermsOfUse]: "Terms of Use"
}

export const FileTypeOptions = Object.keys(FileTypes).map(Number).map((type: FileType) => {
  return {
    key: type,
    value: type,
    text: FileTypes[type],
    content: FileTypes[type]
  };
});
