import API from 'Api'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import { Person } from 'Shared/Models';

export const PersonContainer = React.createContext({
  person: {} as Person,
  setPerson: (p: Person) => {},
  personLoading: false,
  personError: '',
  load: ()=> {}
});

type WithPersonProps = React.PropsWithChildren<{
  id: number
}>

export const WithPerson: React.FC<WithPersonProps> = ({id, children}) => {
  const location = useLocation<{reload: boolean}>();
  const [person, setPerson] = useState<Person>({} as Person)
  const [personError, setError] = useState('')
  const [personLoading, setLoading] = useState(true)

  const load = useCallback(()=>{
    setLoading(true);
    setError('');

    Promise.all([API.getPersonUser(id), API.getPerson(id), API.getProfile(id)])
      .then(([u, p, profile]) => {
        p.user = u;
        p.profile = profile;
        setPerson(p);
      })
      .catch(error=>{
        setError(error);
      })
      .finally(()=>{
        setLoading(false)
      });
  }, [id])

  useEffect(load, [load, location.state && location.state.reload]);
  
  return (
    <PersonContainer.Provider value={{person, setPerson, personLoading, personError, load}}>
      {children}
    </PersonContainer.Provider >
  );
}
