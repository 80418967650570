import { CardElement } from '@stripe/react-stripe-js'
import { Stripe, StripeCardElement } from '@stripe/stripe-js'
import { StripeElements } from '@stripe/stripe-js'
import API from 'Api'
import { UserContainer } from 'Context/User'
import React, { useCallback, useContext, useEffect, useImperativeHandle, useState } from 'react'
import { Form, Message } from 'semantic-ui-react'
import { useRequest } from 'Shared/Hooks'
import PaymentCard from 'Shared/PaymentCard'
import StripeEnabled from 'Shared/StripeEnabled'
import { StripeFormField } from 'Shared/StripeFormField'
import Tooltip from 'Shared/Tooltip'

import { Customer, UserStatus } from './Models'
import { TwoLinePlaceholder } from './Placeholders'

const Wrapped = React.forwardRef((props, ref) =>(
  <div className="ui form" style={{marginBottom:'1em'}}>
    <Form.Field>
      <label>Payment Method <Tooltip icon="lock">Payments are processed securely via Stripe.</Tooltip></label>
      <StripeEnabled {...props} component={PaymentMethod} ref={ref} />
    </Form.Field>
  </div>
))

export default Wrapped;

const PaymentMethod = React.forwardRef<any, { stripe: Stripe, elements: StripeElements}>(({stripe, elements}, ref) => {
  const {user} = useContext(UserContainer)
  const [cardValid, setCardValid] = useState(false)
  const [cardError, setCardError] = useState('')
  const [loading, error, run, customer] = useRequest<Customer|undefined>(undefined)

  const handleCardChange = (e: any) => {
    setCardError(e.error ? e.error.message : '')
    setCardValid(e.complete)
  }

  const loadCustomer = useCallback(()=>{
    if (user && user.id > 0 && user.status === UserStatus.Active) {
      run(API.getCustomer(user.id));
    }
  }, [user, run])

  useEffect(loadCustomer, [loadCustomer])

  useImperativeHandle(ref,() => ({
    getPaymentMethod: () => {
      if (customer && customer.invoice_settings && customer.invoice_settings.default_payment_method) {
        return Promise.resolve({payment_method_id: customer.invoice_settings.default_payment_method.id});
      }

      if (!stripe || !elements) {
        return Promise.reject("Stripe didn't initialize.");
      }

      if (!cardValid) {
        return Promise.reject('Please correct your card details.');
      }

      return stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement) as StripeCardElement,
      }).then((result: any) => {
        if (result.error) {
          return Promise.reject(result.error.message);
        }
        return Promise.resolve({payment_method_id: result.paymentMethod.id});
      });
    }
  }))

  if (loading) {
    return <TwoLinePlaceholder/>
  }

  return <>
    {error && <Message negative>{error}</Message>}
    {cardError && <Message negative>{cardError}</Message>}
    {customer ? 
      <PaymentCard cardholder_id={user.id} customer={customer} onSuccess={loadCustomer} /> : 
      <StripeFormField elements={elements} onChange={handleCardChange}/>}
  </>
})
