import API from 'Api'
import { HouseholdContainer } from 'Context/Household'
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { BackgroundCheckStatus } from 'Routes/Person/BackgroundChecker'
import { Button, Card, Divider, Form, Grid, Header, Message } from 'semantic-ui-react'
import { EditRole } from 'Shared/EditRole'
import { useRequest } from 'Shared/Hooks'
import { LibibPatron } from 'Shared/LibibPatron'
import { MailerliteMemberStatus, MailerliteProgramUpdateStatus, MailerliteStatus } from 'Shared/MailerliteStatus'
import { Household, HouseholdPerson, HouseholdPersonRole, MembershipStatus, Person } from 'Shared/Models'
import { OpenpathStatus } from 'Shared/OpenpathStatus'
import { Panel } from 'Shared/Panel'
import { PersonName } from 'Shared/PersonName'
import PhotoConsent from 'Shared/PhotoConsent'
import SlackStatus from 'Shared/SlackStatus'

import { GroupFiles } from './Files'
import { HouseholdPersonSignatures } from './HouseholdPersonSignatures'
import { StatusPanel } from './MembershipDashboard'
import { AddToGroup } from './People/AddToGroup'

export const AdminMembership = () => {
  const { membership } = useContext(HouseholdContainer);
  return <>
            
    <Grid columns={2} stackable>
      <Grid.Column>
        <StatusPanel heading='Status'/>
        {membership.status === MembershipStatus.UnderReview && <ActivateMembership/>}
      </Grid.Column>
      <Grid.Column>
        <GroupFiles/>
      </Grid.Column>
    </Grid>
    <AdminMembers/>
  </>
}

const AdminMembers = () => {
  const { household, load } = useContext(HouseholdContainer);
  const { people } = household;
  if (!people) {
    return <>
      <Message>No people in this household...</Message>
      <AddPerson household={household} onSuccess={load}/>
    </>
  }

  const members = people.filter(({role}) => role !== HouseholdPersonRole.Inactive)
  const inactives = people.filter(({role}) => role === HouseholdPersonRole.Inactive)
  
  return <Panel icon='users' heading='Group Members'>
    <Card.Group itemsPerRow={3} stackable>
      {members.map(hp => (
        <PersonCard key={hp.person_id} hp={hp} household={household}/>
      ))}
      <AddPerson household={household} onSuccess={load}/>
    </Card.Group>

    {inactives && inactives.length > 0 &&
      <>
        <Header as='h4' content='Inactive People'/>
        <Card.Group itemsPerRow={3} stackable>
          {inactives.map(hp => (
            <PersonCard key={hp.person_id} hp={hp} household={household}/>
          ))}
        </Card.Group>
      </>}
  </Panel>
}

const PersonCard = ({hp, household}: {hp: HouseholdPerson, household: Household}) => {
  const {person, role} = hp;
  return <Card>
    <Card.Content>
      <Card.Header>
        <Link to={`/person/${person.id}`}><PersonName person={person}/></Link>
      </Card.Header>
      <Card.Description>
        <div style={{marginBottom:'0.5em'}}>
          <EditRole person={person} household={household} role={role}/>
        </div>
        <PersonAdmin person={person}/>
      </Card.Description>
    </Card.Content>
  </Card>
}

const AddPerson = ({household, onSuccess}: {household: Household, onSuccess: ()=> void}) => (
  <Card>
    <Card.Content>
      <Card.Header>Add a Person</Card.Header>
      <Card.Description>
        <Button as={Link} title='Add Person' to={`/household/${household.id}/add-person`} content='Create New Person in Group'/>
        <Divider/>
        <AddToGroup household={household} onSuccess={onSuccess}/>
      </Card.Description>
    </Card.Content>
  </Card>
)

const ActivateMembership = () => {
  const { household, load } = useContext(HouseholdContainer);

  const [loading, error, run] = useRequest(false)

  const handleSubmit = () => {
    run(API.activateHouseholdMembership(household.id), load)
  }

  return <Form error name="active" onSubmit={handleSubmit} loading={loading}>
    <Message error>{error}</Message>
    <Form.Button size='mini' color='green'>Activate Membership</Form.Button>
  </Form>
}

const toggleRowStyle = {
  marginBottom: '0.5em'
};

const PersonAdmin = ({person}: {person: Person}) => {
  const [checkEmail, setCheckEmail] = useState(false)
  return <>
    <div style={toggleRowStyle}>
      <Link to={`/person/${person.id}/security`}>Background</Link>&nbsp;
      <BackgroundCheckStatus person={person}/>
    </div>
    <div style={toggleRowStyle}><HouseholdPersonSignatures person={person}/></div>
    <div style={toggleRowStyle}><PhotoConsent person={person}/></div>
    {person.user && person.user.id > 0 && <>
      <div style={toggleRowStyle}><OpenpathStatus user={person.user}/></div>
      <div style={toggleRowStyle}><SlackStatus user={person.user}/></div>
      <div style={toggleRowStyle}><LibibPatron user={person.user}/></div>
      {!checkEmail && <Button size='tiny' onClick={()=>{setCheckEmail(true)}}>Check Email Lists</Button>}
      {checkEmail && <>
        <div style={toggleRowStyle}><MailerliteStatus user={person.user}/></div>
        <div style={toggleRowStyle}><MailerliteMemberStatus user={person.user}/></div>
        <div style={toggleRowStyle}><MailerliteProgramUpdateStatus user={person.user}/></div>
      </>}
    </>}
  </>
}
