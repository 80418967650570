import { UserContainer } from 'Context/User'
import React, {useContext, useEffect} from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { NoMatch } from 'Routes/Helpers'
import { CalendarWrap } from 'Routes/Programs/Calendar'
import { Loader } from 'semantic-ui-react'

import { Donate } from './Donate'
import { ProgramCards } from './ProgramCards'
import { Sale } from './Sale'

const Embeds = () => {
  const location = useLocation()
  const {userLoading} = useContext(UserContainer)
  const sendHeight = () => {
    if (window && window.top) {
      window.top.postMessage({
        location,
        height: document.body.scrollHeight
      }, "*");
    }
  }

  // send window size on browser resize
  useEffect(() => {
    window.addEventListener('resize', sendHeight);

    return () => {
      window.removeEventListener('resize', sendHeight);
    };
  });

  // send window size on render
  useEffect(()=>{
    sendHeight();

    // send window size periodically
    const int = setInterval(sendHeight, 1000);
    return () => clearInterval(int);
  });

  if (userLoading) {
    return <Loader active/>
  }

  return <Switch>
    <Route path='/embed/programs' render={()=><CalendarWrap embedEffect={sendHeight}/>}/>
    <Route path='/embed/donate' render={()=><Donate embedEffect={sendHeight}/>}/>
    <Route path='/embed/sale' render={()=><Sale min={5} embedEffect={sendHeight}/>}/>
    <Route path='/embed/program-cards' render={()=><ProgramCards embedEffect={sendHeight}/>}/>
    <Route component={NoMatch} />
  </Switch>
};

export default Embeds;
