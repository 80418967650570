import API from 'Api'
import { UserContainer } from 'Context/User'
import qs from 'qs'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import { Button, Form, Label, Message } from 'semantic-ui-react'
import Dollars from 'Shared/Dollars'
import { useFields, useRequest } from 'Shared/Hooks'
import { NumberField } from 'Shared/Number'
import PaymentMethod from 'Shared/PaymentMethod'

export const Sale = ({min, embedEffect}: {min: number, embedEffect: ()=>void}) => {
  const pm = useRef<{getPaymentMethod: ()=> Promise<{payment_method_id: string}>}>(null)
  const userContext = useContext(UserContainer)
  const location = useLocation()
  const [lockExtra, setLockExtra] = useState(false)
  const [lockAmount, setLockAmount] = useState(false)
  const [loading, error, run, result] = useRequest({success: false})

  const {fields, handleChange} = useFields({
    user: {
      email: userContext.user && userContext.user.email ? userContext.user.email : '',
      person: {
        first: userContext.user && userContext.user.person && userContext.user.person.first ? userContext.user.person.first : '',
        last: userContext.user && userContext.user.person && userContext.user.person.last ? userContext.user.person.last : '',
      }
    },
    amount: 0,
    extra: '',
    description: 'Sale',
  })

  useEffect(() => {
    const { description, extra, amount } = qs.parse(location.search,  { ignoreQueryPrefix: true });
    if (description) {
      handleChange(null, {name:'description', value: description as string});
    }
    if (extra) {
      handleChange(null, {name:'extra', value: extra as string});
      setLockExtra(true)
    }
    if (amount) {
      handleChange(null, {name:'amount', value: Number(amount)});
      setLockAmount(true)
    }
  }, [location, handleChange])

  useEffect(()=> {
    if (embedEffect) {
      embedEffect();
    }
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (pm.current) {
      run(pm.current.getPaymentMethod()
        .then(({payment_method_id}) => {
          const body = Object.assign(fields, {payment_method_id})
          return API.createPayment(body);
        })
      );
    }
  }

  const { user, amount, extra } = fields;

  if (result && result.success) {
    return <Message positive style={{maxWidth: '30em'}}>We've successfully processed your payment!</Message>
  }

  return (
    <Form name="payment" loading={loading} onSubmit={handleSubmit} style={{maxWidth: '30em'}}>
      {error ? <Message negative>{error}</Message> : ''}

      <Form.Input
        style={{maxWidth: '20em'}}
        name='user.email'
        type='email'
        required
        label='Email'
        value={user.email}
        onChange={handleChange}
      />

      <Form.Group widths='equal'> 
        <Form.Input
          style={{maxWidth: '14em'}}
          name='user.person.first'
          required
          label='First Name'
          value={user.person.first}
          onChange={handleChange}
        />

        <Form.Input
          style={{maxWidth: '14em'}}
          name='user.person.last'
          required
          label='Last Name'
          value={user.person.last}
          onChange={handleChange}
        />
      </Form.Group>

      {lockExtra ? 
        <Form.Field>
          <label>Payment For</label>
          <Label content={extra}/>
        </Form.Field> :
        <Form.Input
          style={{maxWidth: '20em'}}
          name='extra'
          type='text'
          label='Payment For'
          disabled={!lockExtra}
          value={extra}
          onChange={handleChange}
        />
      }

      {lockAmount ?
        <Form.Field>
          <label>Amount</label>
          <Dollars amount={amount}/>
        </Form.Field> :
        <NumberField
          label='Amount'
          style={{maxWidth: '7em'}}
          innerLabel={{basic: true, content:'$'}}
          name="amount"
          min={min}
          required
          value={amount}
          onChange={handleChange} />
      }

      <PaymentMethod ref={pm}/>

      <Button disabled={amount < min} primary type="submit">Submit ${amount} Payment</Button>
    </Form>
  )
}
