import { UserContainer } from 'Context/User'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Container, Grid, Header, Icon, Image, Loader, Message, SemanticICONS } from 'semantic-ui-react'

import { Profile, User } from './Models'

type SimplePageProps = React.PropsWithChildren<{
  title: string
  icon?: SemanticICONS
  profile?: Profile
  centered?: boolean
  loading?: boolean
  error?: string|null
}>

const SimplePage: React.FC<SimplePageProps> = ({title, icon, profile, centered, loading, error, children}) => {
  const {user} = useContext(UserContainer)

  return (
    <Container className="page">
      {title && !loading &&
        <Helmet>
          <title>{title}</title>
        </Helmet>}

      <Content centered={centered}>
        { !loading &&
          <Header as='h1'>
            {profile && 
              <Link to={profileLink(profile, user)} style={{display:'inline-block', position: 'relative', verticalAlign: 'middle', marginRight: '0.5em'}}>
                <Image height={100} width={100} src={profile.profile_image_url || '/profile.png'} circular style={{margin:0}}/>
              </Link>}
            {!profile && icon && <Icon name={icon}/>}{title}
          </Header>}

        {error && <Message negative>{error}</Message>}

        {loading ? <Loader active inline='centered'/> : children}
      </Content>
    </Container>
  );
}

const profileLink = (profile: Profile, user: User) => {
  if (profile.person_id === user.person.id) {
    return `/members/${profile.person_id}/edit-image`;
  }

  return `/members/${profile.person_id}`;
}

export default SimplePage;

type ContentProps = React.PropsWithChildren<{
  centered?: boolean
}>

const Content: React.FC<ContentProps> = ({centered, children}) => (
  centered ?
    <Grid centered>
      <Grid.Column mobile={16} tablet={8} computer={8}>
        {children}
      </Grid.Column>
    </Grid>
    :
    <>{children}</>
)
