import { UserContainer } from "Context/User";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Grid, List } from "semantic-ui-react";
import { Markdown } from "Shared/Markdown";
import { Panel } from "Shared/Panel";
import { ProfileField } from "Shared/ProfileField";
import { IsAdmin } from "Shared/Roles";
import SimplePage from 'Shared/SimplePage';

import { HouseholdLabels } from "./MemberDirectory";
import { ProfileTags } from './ProfileTags';
import { useProfile } from "./useProfile";

export const MemberProfile = () => {
  const {profile, profileLoading, profileError} = useProfile()
  const {user} = useContext(UserContainer)

  const canEdit = IsAdmin(user) || (profile && profile.person_id === user?.person_id)

  return <SimplePage profile={profile} title={profile.name} loading={profileLoading} error={profileError}>
    <p>
      <HouseholdLabels households={profile.households}/>
    </p>
    <Grid columns={2} stackable style={{marginBottom: '1em'}}>
      <Grid.Column>
        <Panel icon='info circle' heading='About Me'>
          <Markdown text={profile.bio || "This person hasn't filled in 'about me' yet..."}/>
        </Panel>

        {profile.interest_tags?.length > 0 && <Panel icon='paint brush' heading='Interests'>
          <ProfileTags tags={profile.interest_tags}/>
        </Panel>}
      </Grid.Column>

      <Grid.Column>
        {profile.skillshare && <Panel icon='gift' heading='Skillshare'>
          <Markdown text={profile.skillshare}/>
        </Panel>}

        {profile.services && <Panel icon='shopping bag' heading='Services'>
          <Markdown text={profile.services}/>
        </Panel>}

        {profile.person_id !== user.person_id && <Panel icon='mail' heading='Contact'>
          <List>
            {profile.slack_id && <ProfileField title='Slack' icon='slack' iconColor='olive' content={
              <a target='_blank' href={`https://aldercommons.slack.com/team/${profile.slack_id}`} rel="noreferrer">On Slack</a>
            }/>}
            <ProfileField title='Email' icon='mail' iconColor='green' content={
              <Link to={`/members/${profile.person_id}/contact`}>Via Email</Link>
            }/>
          </List>
        </Panel>}
      </Grid.Column>
    </Grid>

    {canEdit && <>
      <Link to={`/members/${profile.person_id}/edit`}>
        <Button>Edit Profile</Button>
      </Link>
      <Link to={`/members/${profile.person_id}/edit-image`}>
        <Button>Edit Profile Image</Button>
      </Link>
    </>}
  </SimplePage>
}
