import API from "Api";
import { HouseholdContainer } from "Context/Household";
import { capitalize } from "lodash";
import { useContext, useEffect } from "react";
import { Message } from "semantic-ui-react";
import { useRequest } from "Shared/Hooks";

const MembershipUnderReview = () => {
  const { household } = useContext(HouseholdContainer);
  const [loading, error, run, resp] = useRequest({ activationErrors: [] });

  useEffect(() => {
    run(API.checkHouseholdMembership(household.id));
  }, [run, household.id]);

  if (loading) {
    return <Message>Checking membership status...</Message>;
  }

  return (
    <>
      {error && <Message negative>{error}</Message>}
      <Message>
        <p>
          Your membership is currently under review. See errors below to see
          which (if any) steps are still pending.
        </p>
        <p>
          If you have questions, please{" "}
          <a href="mailto:hello@aldercommons.org?subject=Membership Review">
            contact us
          </a>
          !
        </p>
      </Message>
      {resp &&
        resp.activationErrors &&
        resp.activationErrors.length > 0 &&
        resp.activationErrors.map((e) => (
          <Message key={e} negative>
            {capitalize(e)}
          </Message>
        ))}
    </>
  );
};

export default MembershipUnderReview;
