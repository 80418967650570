import API from 'Api'
import { UserContainer } from 'Context/User'
import qs from 'qs'
import React, { useCallback, useContext, useEffect } from 'react'
import { useLocation } from 'react-router'
import { Link, Redirect } from 'react-router-dom'
import { Button, Form, Message } from 'semantic-ui-react'
import { useFields, useRequest } from 'Shared/Hooks';
import { User } from 'Shared/Models'
import PasswordField from 'Shared/PasswordField'
import SimplePage from 'Shared/SimplePage'

const LogIn = () => {
  const location = useLocation<{from: {pathname: string, search: string}}>();
  const {user, setUser} = useContext(UserContainer)
  const [loading, error, run] = useRequest<User>({} as User)
  const {fields, handleChange} = useFields({
    email: ''
  } as User)

  // get email from url param
  useEffect(()=>{
    if (location) {
      const { email } = qs.parse(location.search,  { ignoreQueryPrefix: true });
      if (email) {
        handleChange(null, {name: 'email', value: decodeURIComponent(email as string)});
      }
    }
  }, [location, handleChange])
  
  const handleSubmit = useCallback(() => {
    run(API.login(fields), user=>{
      setUser(user);
    });
  }, [run, fields, setUser])

  const resetLink = "/reset?email=" + (fields.email ? encodeURIComponent(fields.email) : '');

  if (user.id > 0 && !loading) {
    let from = { pathname: `/person/${user.person_id}` };
    if (location.state && location.state.from && (location.state.from.pathname !== '/' || location.state.from.search !== '')) {
      from = location.state.from;
    }
    return <Redirect to={from}/>;
  }

  return (
    <SimplePage icon='sign in' title='Log In' centered>
      <Form error name="login" loading={loading} onSubmit={handleSubmit}>
        <Message error>{error}</Message>
        <Form.Input
          name="email"
          type="email"
          label="Email"
          required
          value={fields.email}
          onChange={handleChange} />
        <PasswordField
          name='pass'
          onChange={handleChange} />
        <Button primary size="big" type="submit">Log In</Button>
      </Form>
      <Message><Link to={resetLink}>Reset your password</Link></Message>
    </SimplePage>
  )
}

export default LogIn;
