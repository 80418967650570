
export const usd = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

const Dollars: React.FC<{amount: number | undefined}> = ({amount}) => {
  if (!amount) {
    return <></>;
  }

  return <>{usd.format(amount)}</>
}

export default Dollars;
