import moment, { Moment } from 'moment'
import React from 'react'
import { Program } from 'Shared/Models';

type TimeRangeProps = {
  program: Program, 
  noTZ?: boolean, 
  style?: Record<string, any>
}

export const TimeRange: React.FC<TimeRangeProps> = ({program, noTZ, style}) => {
  if (program.duration === 1440) {
    return <>All Day</>;
  }
  let start = moment.utc(program.start_date);
  let end = moment(start).add(program.duration, 'minutes');
  return <span style={style}>
    <Time m={start}/>-<Time m={end}/>{noTZ ? '' : ' PT'}
  </span>
}

export const Time: React.FC<{m: Moment}> = ({m}) => {
  if (!m.minutes()) {
    return <>{m.format('ha')}</>
  } else {
    return <>{m.format('h:mma')}</>
  }
}
