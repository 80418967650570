import React from 'react'
import { DropdownItemProps, Form, Label, LabelProps, SelectProps, SemanticCOLORS } from 'semantic-ui-react'

import { MembershipStatus } from './Models'

export const MembershipStatuses: Record<MembershipStatus, {name: string, color: SemanticCOLORS}> = {
  [MembershipStatus.Inactive]: {name: "Not Started", color: "orange"},
  [MembershipStatus.PastDue]: {name: "Past Due", color: "orange"},
  [MembershipStatus.Canceled]: {name: "Canceled", color: "red"},
  [MembershipStatus.PeopleSaved]: {name: "People Saved", color: "orange"},
  [MembershipStatus.FeeSaved]: {name: "Fee Saved", color: "orange"},
  [MembershipStatus.ExtrasSaved]: {name: "Extras Saved", color: "orange"},
  [MembershipStatus.DocumentsUploaded]: {name: "Document Submission", color: "orange"},
  [MembershipStatus.BackgroundChecksStarted]: {name: "Background Checks", color: "orange"},
  [MembershipStatus.UnderReview]: {name: "Under Review", color: "yellow"},
  [MembershipStatus.Active]: {name: "Active", color: "green"}
}

type MembershipStatusLabelProps = {
  status: MembershipStatus
} & LabelProps

export const MembershipStatusLabel: React.FC<MembershipStatusLabelProps>= ({status, ...rest}) => {
  const found = MembershipStatuses[status];

  if (!found) {
    return <Label {...rest} color="red">Unrecognized Status</Label>
  }
  return <Label {...rest} color={found.color} content={found.name}/>
};

const MembershipStatusOptions = Object.keys(MembershipStatuses).map(
  (status, i) => ({
    key: status,
    value: Number(status),
    text: MembershipStatuses[Number(status) as MembershipStatus].name,
  })
);

type MembershipStatusSelectProps = {
  forFilter?: boolean
} & Omit<SelectProps, 'options'>

export const MembershipStatusSelect: React.FC<MembershipStatusSelectProps> = ({forFilter, ...rest}) => {
  let options: DropdownItemProps[] = forFilter ? [ {
    key: 'onboarding',
    value: 'onboarding',
    text: 'Onboarding'
  }, {
    key: 'any',
    value: 'any',
    text: 'All Statuses'
  }] : [];

  options = options.concat(MembershipStatusOptions)

  return <Form.Select
    {...rest}
    name='status'
    search
    options={options}
  />
}
