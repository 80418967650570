import React, { useCallback } from 'react'
import { Form, Message, Segment } from 'semantic-ui-react'
import { useFields, useRequest } from 'Shared/Hooks'
import { PeopleChooser } from 'Shared/PeopleChooser'

import API from '../../Api'

const Dedupe = () => {
  const {fields, handleChange} = useFields({
    keep: undefined,
    destroy: undefined
  });

  const [loading, error, run, success] = useRequest(false);
  const handleSubmit = useCallback(()=>{
    run(API.deduplicatePerson(fields))
  },[fields, run])

  const {keep, destroy} = fields;

  if (success) {
    return <Message positive>Duplicate cleanup successful for {`${destroy} -> ${keep}`}!</Message>
  }

  return <Segment>
    <Form error loading={loading} onSubmit={handleSubmit}>
      <Message error>{error}</Message>
      <PeopleChooser
        label='Keep'
        name='keep'
        value={keep}
        onChange={handleChange}
        showID={true}/>

      <PeopleChooser
        label='Destroy'
        name='destroy'
        value={destroy}
        onChange={handleChange}
        showID={true}/>

      <Form.Button content='Deduplicate'/>
    </Form>
  </Segment>
}

export default Dedupe;
