import API from 'Api'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import { Household, Membership } from 'Shared/Models';


export const HouseholdContainer = React.createContext({
  household: {} as Household,
  membership: {} as Membership,
  setHousehold: (h: Household) => {},
  householdLoading: false,
  householdError: '',
  load: () => {}
});

type WithHouseholdProps = React.PropsWithChildren<{
  id: number
}>

export const WithHousehold: React.FC<WithHouseholdProps> = ({id, children}) => {
  const location = useLocation<{reload: boolean}>();
  const [household, setHousehold] = useState<Household>({} as Household)
  const [membership, setMembership] = useState<Membership>({} as Membership)
  const [householdError, setError] = useState('')
  const [householdLoading, setLoading] = useState(true)

  const load = useCallback(()=>{
    setLoading(true);
    setError('');

    API.getHousehold(id)
      .then(h => {
        setHousehold(h);
        return API.getHouseholdMembership(h.id)
      })
      .then(m => {
        setMembership(m)
        setLoading(false)
      })
      .catch(error=>{
        setError(error);
        setLoading(false)
      })
  }, [id])

  // initial load
  useEffect(load, [load]);

  // force reload on some redirects and some paths
  useEffect(()=>{
    if (location.state && location.state.reload) {
      load()
    } else if (location.pathname.endsWith('edit') || location.pathname.endsWith('admin')) {
      load()
    }
  }, [load, location])
  
  return (
    <HouseholdContainer.Provider value={{household, membership, setHousehold, householdLoading, householdError, load}}>
      {children}
    </HouseholdContainer.Provider >
  );
}
