import API from 'Api'
import React, { useCallback } from 'react'
import { Button, Form, Message } from 'semantic-ui-react'
import { useFields, useRequest } from 'Shared/Hooks'
import { User } from 'Shared/Models'
import PasswordField from 'Shared/PasswordField'

type ChangePasswordProps = {
  user: User
  onSuccess?: () => void
}

const ChangePassword: React.FC<ChangePasswordProps> = ({user, onSuccess}) => {
  const [loading, error, run, success] = useRequest(false)
  const {fields, handleChange} = useFields({pass: ''})
  
  const handleSubmit = useCallback(() => {
    run(API.updatePassword(user.id, fields), onSuccess);
  }, [run, user, fields, onSuccess])

  if (success) {
    return <Message positive>We've updated your password!</Message>
  }

  if (!user) {
    return <Message negative>This person doesn't have a user account.</Message>
  }

  return (
    <Form error name="reset" loading={loading} onSubmit={handleSubmit} style={{maxWidth: '20em'}}>
      <Message error>{error}</Message>
      <PasswordField
        name='pass'
        onChange={handleChange} />
      <Button primary type="submit">Update Password</Button>
    </Form>
  );
}

export default ChangePassword;
