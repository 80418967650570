import API from 'Api'
import React, { useCallback, useEffect } from 'react'
import { List, Message } from 'semantic-ui-react'
import { useRequest } from 'Shared/Hooks'
import { Panel } from 'Shared/Panel'
import { ListPlaceholder } from 'Shared/Placeholders'

import { Doc } from './Models'

export const MemberResources = () => {
  const [loading, error, run, files] = useRequest<Doc[]>([])

  const load = useCallback(()=>{
    run(API.getActiveDocs())
  }, [run])

  useEffect(load, [load]);

  return <Panel icon='list' heading='Member Resources'>
    {error && <Message negative>{error}</Message>}
    {loading ? <ListPlaceholder/> : 
      <List>  
        {files.map(f => {
          const parts = f.src.split('.');
          const extension = parts[parts.length-1];
          return <List.Item
            key={f.src}
            icon={`file ${extension}`}
            content={<a rel="noopener noreferrer"
              href={f.src}
              target="_blank">{f.name}
            </a>}/>
        })}
        <List.Item 
          icon='talk'
          content={<a rel="noopener noreferrer"
            href='https://docs.google.com/forms/d/e/1FAIpQLSdrMrlVdzc3WZDKJD5LHT18ncb4t6_y3w_7JLznWu4dlb31ag/viewform'
            target="_blank">Membership Feedback Form
          </a>}/>
        <List.Item 
          icon='talk'
          content={<a rel="noopener noreferrer"
            href='https://docs.google.com/forms/d/e/1FAIpQLSeJp-6yd_WUnwJD9FFIqMqiCmWxd2IBRIEcujo7lSIwgDBhRA/viewform'
            target="_blank">Community Awareness Form
          </a>}/>
        <List.Item 
          icon='google drive'
          content={<a rel="noopener noreferrer"
            href='https://drive.google.com/drive/u/2/folders/17tn-vw5zuKPIYmdwPI-1BD2Ea9ssYJNN'
            target="_blank">All-Members Meeting Minutes
          </a>}/>
      </List>
    }
  </Panel>
}
