import API from 'Api'
import { HouseholdContainer } from 'Context/Household'
import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { Button, Form, Icon, Label, Message, Modal, Table } from 'semantic-ui-react'
import { age, isAdult } from 'Shared/Age'
import { useFields, useRequest } from 'Shared/Hooks'
import { HouseholdPerson, HouseholdPersonRole, Person } from 'Shared/Models'
import { Panel } from 'Shared/Panel'
import PhotoConsent, { PhotoTermsModal } from 'Shared/PhotoConsent'

import AddHouseholdPerson from './People/AddHouseholdPerson'

const MembershipPeopleForm = () => {
  const { membership, load } = useContext(HouseholdContainer);
  const { household } = useContext(HouseholdContainer);
  const location = useLocation();
  const {fields, handleChange} = useFields(Object.assign({}, membership, {household_id: household.id}, countByAge(household.people)))
  const [loading, error, run] = useRequest({})

  const handleSubmit = () => {
    const action = fields.id ? API.updateHouseholdMembership : API.createHouseholdMembership;
    run(action(household.id, fields, 3), load)
  }

  useEffect(()=>{
    const {adults, youth} = countByAge(household.people);
    handleChange(null, {name:'adults', value: adults});
    handleChange(null, {name:'youth', value: youth});
  }, [household, handleChange])

  const { youth, adults } = fields;
  const { people } = household;
  if (!people) {
    return <p>This household has no members!</p>;
  }

  const activePeople = people.filter(p => (p.role !== HouseholdPersonRole.Inactive));
  const inactivePeople = people.filter(p => (p.role === HouseholdPersonRole.Inactive));

  const missingBday =  activePeople.filter(({person}) => (age(person.dob) === '?')).length > 0;
  const missingEmail =  activePeople.filter(({person}) => isAdult(person))
    .filter(({person}) => (!person.user || person.user.email === ''))
    .length > 0;

  return <>
    <Message warning>Note: If you anticipate that you will add more people to your Membership in the near future, it's much easier to add them now!</Message>

    <Form error name="people" loading={loading} onSubmit={handleSubmit}>
      <Message error>{error}</Message>

      <Panel heading='People in your Membership Group' noSegment>
        <Message attached={true}>
          <p>Use the edit buttons to change birthdates, save phone numbers, etc. You may also <PhotoTermsModal trigger={<a href='#a'>consent to our use of photos</a>}/> of people in your group.</p>
        </Message>
        <Table attached='bottom'>
          <Table.Body>
            {activePeople.map(({person}) => (
              <Table.Row key={person.id}>
                <Table.Cell>
                  <Icon name='user'/>
                  {person.first} {person.last} <AgeOrWarning person={person}/>{' '}
                  {isAdult(person) && (!person.user || person.user.email === '') && 
                    <Label size='tiny' color='orange' content='Missing Email'/>}
                </Table.Cell>
                <Table.Cell collapsing>
                  <Button icon='pencil' size='mini' content='edit' as={Link} to={{
                    pathname: `/person/${person.id}/edit`,
                    state: { from: location }
                  }}/>
                </Table.Cell>
                <Table.Cell collapsing>
                  <PhotoConsent reload person={person}/>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Panel>
      { inactivePeople.length > 0 && <p>
        The following people are marked <Label size='mini' color='red'>inactive</Label> {inactivePeople.map(({person}) => (`${person.first} ${person.last}`)).join(', ')}. If they should be included, please let staff know!
      </p>}

      {/* inline add-person form */}
      <Modal trigger={<p><Button type='button' size='small' icon='user add' content='Add a Person'/></p>}>
        <Modal.Header>Add a Person</Modal.Header>
        <Modal.Content>
          <AddHouseholdPerson/>
        </Modal.Content>
      </Modal>

      {missingEmail && <Message warning>All adults members must have a unique email address, as we use emails to initiate background checks.</Message>}
      {missingBday && <Message warning>All members must have a date of birth on file.</Message>}
      <Button size='small' primary disabled={(!youth && !adults) || missingBday || missingEmail} content='Save &amp; Continue'/>
    </Form>
  </>
}

export default MembershipPeopleForm;

const AgeOrWarning = ({person}: {person: Person}) => {
  const displayAge = age(person.dob)
  if (displayAge === '?') {
    return <Label size='tiny' color='orange' content='Invalid Date of Birth'/>
  } else {
    return <>({displayAge})</>;
  }
}

export const countByAge = (people: HouseholdPerson[]) => {
  if (!people) {
    people = [];
  }
  let adults = 0;
  let youth = 0;

  people.filter(p => (p.role !== HouseholdPersonRole.Inactive)).forEach(({person}) => {
    const calculatedAge = Number(age(person.dob));
    if (calculatedAge >= 5 && calculatedAge < 18) {
      youth++;
    } else if (calculatedAge > 18) {
      adults++;
    }
  });

  return {
    adults,
    youth,
  };
}
