import API from 'Api'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { Profile } from 'Shared/Models';

export const useProfile = () => {
  const {id} = useParams<{id: string}>();

  const [profile, setProfile] = useState({} as Profile)
  const [profileError, setProfileError] = useState<string|undefined>();
  const [profileLoading, setLoading] = useState(true)

  useEffect(()=>{
    API.getProfile(Number(id))
      .then((profile: Profile) => {
        if (profile) {
          setProfile(profile)
        }
      })
      .catch((error) => {
        setProfileError(error);
      })
      .finally(() => {
        setLoading(false)
      })
  }, [id])
  
  return {profile, profileLoading, profileError}
}
