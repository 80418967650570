import { CardElement } from '@stripe/react-stripe-js'
import { Stripe, StripeCardElement, StripeElements } from '@stripe/stripe-js';
import API from 'Api'
import React, {  FormEvent, useCallback, useState } from 'react'
import { Button, Message } from 'semantic-ui-react'
import StripeEnabled from 'Shared/StripeEnabled'
import { StripeFormField } from 'Shared/StripeFormField'

import { useRequest } from './Hooks';

export type ChangeDefaultCardFormProps = {
  cardholder_id: number,
  onSuccess: (pid: string)=>void,
  onCancel: ()=>void
}

const Wrapped = (props: ChangeDefaultCardFormProps) =>(
  <StripeEnabled
    component={ChangeDefaultCardForm}
    {...props}/>
);

export default Wrapped;

const ChangeDefaultCardForm: React.FC<ChangeDefaultCardFormProps & { stripe: Stripe, elements: StripeElements}> = ({cardholder_id, stripe, elements, onSuccess, onCancel}) => {
  const [loading, error, run, , setError] = useRequest<any>(false);
  const [cardValid, setCardValid] = useState(false);

  const handleSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    if (!cardValid) {
      return setError('You must enter card details.')
    }

    let pid = '';
    run(
      stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement) as StripeCardElement,
      }).then((result: any) => {
        if (result.error) {
          return Promise.reject(result.error.message);
        }
        return Promise.resolve(result.paymentMethod.id);
      }).then((payment_method_id: string) => {
        pid = payment_method_id;
        return API.saveCard({
          payment_method_id,
          cardholder_id: cardholder_id,
        });
      }).then((result: {success: boolean}) => {
        if (result && result.success) {
          onSuccess(pid);
        } else {
          return Promise.reject('We encountered a mysterious error... Please contact us at hello@aldercommons.org.');
        }
      })
    )
  }, [stripe, elements, cardValid, setError, run, onSuccess, cardholder_id]);

  const handleCardChange = (e: any) => {
    if (e.error) {
      setError(e.error.message);
    }

    setCardValid(e.complete)
  }

  return <>
    {error && <Message negative>{error}</Message>}
    <StripeFormField disabled={loading} elements={elements} onChange={handleCardChange}/>
    <Button size="tiny" as='a' onClick={onCancel}>Cancel</Button>
    <Button size="tiny" disabled={loading} color='blue' onClick={handleSubmit} type="submit">Save Card</Button>
  </>
}
