import './responsive.css'

import { UserContainer } from 'Context/User'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { Button, Container, Dropdown, Menu } from 'semantic-ui-react'
import { Anonymous, HasUser, Member, Staff } from 'Shared/Roles'

// helper for semanticUI + react-router
export const MenuLink = (props: NavLinkProps) => (
  <NavLink
    {...props}
    activeClassName="active"
  />
);

const Nav = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false)
  const {user, handleLogout} = useContext(UserContainer)

  useEffect(()=>{
    setOpen(false);
  }, [location])

  const menuClass = open ? '' : 'hidden';

  return (
    <Menu stackable size="small" fixed="top" inverted>
      <Container>
        <Button id="toggler" fluid color='black' icon='sidebar' onClick={() => setOpen(!open)}/>
        <Menu.Menu className={menuClass} position="left" id="override">
          <Menu.Item icon='calendar alternate outline' as={MenuLink} exact to="/" name="Calendar" title="Calendar"/>
          <Member>
            <Menu.Item icon='id card outline' as={MenuLink} exact to="/members" name="Member Directory" title="Member Directory"/>
          </Member>
          <Staff>
            <Dropdown item text='Admin'>
              <Dropdown.Menu>
                <Dropdown.Item as={MenuLink} to='/admin/people' icon='user' text='People' />
                <Dropdown.Item as={MenuLink} to='/admin/households' icon='users' text='Groups' />
                <Dropdown.Item as={MenuLink} to='/admin/memberships' icon='star' text='Memberships' />
                <Dropdown.Item as={MenuLink} to='/admin/programs' icon='calendar outline alternate' text='Programs' />
                <Dropdown.Item as={MenuLink} to='/admin/misc' icon='settings' text='Misc' />
              </Dropdown.Menu>
            </Dropdown>
          </Staff>
        </Menu.Menu>
        <Menu.Menu className={menuClass} position="right">
          <HasUser>
            <Menu.Item icon='user' as={MenuLink} to={`/person/${user.person_id}/dash`} name="Me" title="Me"/>
            <Menu.Item icon='sign-out' link={true} onClick={handleLogout} name="Log Out" title="Log Out"/>
          </HasUser>
          <Anonymous>
            <Menu.Item icon='sign-in' as={MenuLink} exact to={{pathname:"/login", state:{from:location}}} name="Log In" title="Log In"/>
            <Menu.Item icon='star' as={MenuLink} exact to="/membership" name="Join" title="Join"/>
          </Anonymous>
        </Menu.Menu>
      </Container>
    </Menu>
  );
}

export default Nav;
