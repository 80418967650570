import API from 'Api'
import { HouseholdContainer } from 'Context/Household'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { Button, Divider, Label, List, Loader, Message } from 'semantic-ui-react'
import { Date } from 'Shared/Date'
import { FileStatusLabel } from 'Shared/FileStatuses'
import { FileTypes } from 'Shared/FileTypes'
import { useRequest } from 'Shared/Hooks'
import { HouseholdFile } from 'Shared/Models'
import { Panel } from 'Shared/Panel'
import { ListPlaceholder } from 'Shared/Placeholders'
import { Admin } from 'Shared/Roles'
import Tooltip from 'Shared/Tooltip'

import FileForm from './FileForm'

export const GroupFiles = () => {
  const {household} = useContext(HouseholdContainer);
  const [loading, error, run, files] = useRequest<HouseholdFile[]>([], {loading: true})
  const [upload, setUpload] = useState(false);

  const load = useCallback(()=>{
    run(API.getHouseholdFiles(household.id))
  }, [run, household.id])

  useEffect(load, [load])

  return <Panel icon='copy' heading='Documents'>
    <List>
      {error && <Message negative>{error}</Message>}
      {loading ? <ListPlaceholder/> : 
        files.map((file, i) => (
          <FileItem key={file.id} file={file} onSuccess={load}/>
        ))}
      {files.length === 0 && !error && !loading && 
        <List.Item>
          <Label size='tiny' color='red' content='No Documents Uploaded'/>
        </List.Item>}
    </List>

    <Admin>
      {!upload ? <Button size='tiny' onClick={()=>{setUpload(true)}} content='Admin Upload'/> : <>
        <Divider/>
        <FileForm groupID={household.id} onSuccess={load}/>
      </>}
    </Admin>
  </Panel>
}

export const FileItem = ({file, onSuccess} : {file: HouseholdFile, onSuccess?: ()=>void}) => {
  const location = useLocation();
  const {id, household_id, notes, status} = file;
  const [loading, error, run] = useRequest({})

  const approve = () => {
    run(API.updateHouseholdFile(household_id, Object.assign({}, file, {status: 1})), onSuccess)
  }
  const reject = () => {
    run(API.updateHouseholdFile(household_id, Object.assign({}, file, {status: 2})), onSuccess)
  }

  return (
    <List.Item>
      <List.Icon name='file pdf'/>
      <List.Content>
        <Downloader file={file} /> <FileStatusLabel status={status}/>
        <Admin>
          {loading && <Loader active inline size='tiny'/>}
          {error}
          {!loading && !error && <>
            {notes && <Tooltip content={notes}/>}
            <Button size='mini' icon='pencil' title='Edit' as={Link} to={{
              pathname:`/household/${household_id}/files/${id}`, 
              state: { from: location }
            }}/>
            <Button size='mini' icon='check' title='Approve' onClick={approve}/>
            <Button size='mini' icon='cancel' title='Reject' onClick={reject}/>
          </>}
        </Admin>
      </List.Content>
    </List.Item>
  );
};

const Downloader = ({file} : {file: HouseholdFile}) => {
  const {id, name, type, created_at, household_id} = file;
  const [error, setError] = useState('');

  const doDownload = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    API.getHouseholdFile(household_id, id)
      .then(({url}) => {
        download(url);
      })
      .catch(setError);
  }
  
  if (error) {
    return <>{error}</>
  }

  return <button className="a" onClick={doDownload} title={name}><Date compact ts={created_at} /> {FileTypes[type]}</button>
}

const download = (url: string) => {
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  const clickHandler = () => {
    setTimeout(() => {
      a.removeEventListener('click', clickHandler);
    }, 150);
  };

  a.addEventListener('click', clickHandler, false);
  a.click();
}
