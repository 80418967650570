import React from 'react'
import SimplePage from 'Shared/SimplePage'

import ResetForm from './ResetForm'

const Reset: React.FC = () => (
  <SimplePage centered title='Reset/Create a Password'>
    <ResetForm/>
  </SimplePage>
);

export default Reset
