import API from 'Api'
import Fuse from 'fuse.js'
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Form, Grid, List, Loader, Message, Segment } from 'semantic-ui-react'
import CreateGroup from 'Shared/CreateGroup'
import { useFields, useRequest } from 'Shared/Hooks'
import { Household } from 'Shared/Models'

const Groups = () => {
  const [loading, error, run, allGroups] = useRequest<Household[]>([], {loading: true});
  const {fields, handleChange} = useFields({
    filter: '',
    is_org: false,
    not_org: false
  });
  const [fuse, setFuse] = useState<Fuse<Household>|boolean>(false);

  const load = useCallback(()=>{
    run(API.getGroups(), (groups: Household[]) => {
      setFuse(new Fuse(groups, {
        threshold: 0.3,
        keys: ['name']
      }));
    })
  }, [run])

  useEffect(load, [load])

  if (loading) {
    return <Loader active inline='centered' />
  }

  if (error) {
    return <Message negative>{error}</Message>
  }

  const { filter, is_org, not_org } = fields;
  let groups = allGroups;
  if (fuse && fuse !== true && filter) {
    groups = fuse.search(filter).map(({item}) => (item));
  }
  if (is_org) {
    groups = groups.filter(g=>g.is_org)
  }
  if (not_org) {
    groups = groups.filter(g=>!g.is_org)
  }

  return <Grid stackable columns={2}>
    <Grid.Column>
      <Form>
        <Form.Input
          type='text'
          name='filter'
          placeholder={`Filter ${groups.length} groups`}
          value={filter}
          onChange={handleChange} />
        <Form.Group inline>
          <Form.Checkbox
            label='Is&nbsp;Org'q
            name='is_org'
            checked={is_org}
            onChange={handleChange}/>
          <Form.Checkbox
            label='Not&nbsp;Org'
            name='not_org'
            checked={not_org}
            onChange={handleChange}/>
        </Form.Group>
      </Form>

      <List>
        { groups.map(g => (
          <List.Item key={g.id}>
            <Link to={`/household/${g.id}`}>{g.name}</Link>
          </List.Item>
        )) }
      </List>
    </Grid.Column>
    <Grid.Column>
      <Segment>
        <CreateGroup onSuccess={load}/>
      </Segment>
    </Grid.Column>
  </Grid>
}

export default Groups;
