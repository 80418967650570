import { useCallback, useState } from "react"
import { Button, Form } from "semantic-ui-react";

import { ChangeHandler } from "./Hooks";
import { NumberField } from "./Number";

type DurationProps = {
  prefix?: string
  name: string
  handleChange: ChangeHandler
  defaultValue: number
  allDay?: boolean
}

export const Duration: React.FC<DurationProps> = ({prefix, name, handleChange, defaultValue, allDay}) => {
  const [hours, setHours] = useState(defaultValue ? Math.floor(defaultValue / 60) :  0);
  const [minutes, setMinutes] = useState(defaultValue ? defaultValue % 60 : 0);

  const propagate = useCallback(()=>{
    handleChange(null, {name, value: hours * 60 + minutes})
  }, [handleChange, name, hours, minutes])

  const allDayHandler = () => {
    setHours(24);
    setMinutes(0);
    propagate();
  }

  return <Form.Group style={{marginBottom: '1em'}}>
    <NumberField
      style={{maxWidth: '8em'}}
      label={`${prefix ? prefix + ' ' : ''}Hours`}
      value={hours}
      min={0}
      max={24}
      onChange={(e, {value}) => {
        setHours(Number(value) || 0)
        propagate();
      }}/>
    <NumberField
      style={{maxWidth: '8em'}}
      label='Minutes'
      value={minutes}
      min={0}
      max={60}
      onChange={(e, {value}) => {
        setMinutes(Number(value) || 0)
        propagate();
      }}/>
    {allDay && <Form.Field style={{paddingTop:'2em'}}>
      <Button size='tiny' as='a' onClick={allDayHandler}>All Day</Button>
    </Form.Field>}
  </Form.Group>
}
