import API from 'Api'
import moment from 'moment'
import React from 'react'
import { Button, ButtonProps, DropdownItemProps } from 'semantic-ui-react'

import { useRequest } from './Hooks'
import { Payment, User } from './Models'

export const filter = (payments: Payment[], year: 'all'|number) => {
  return payments.filter((p) => {
    if (p.is_donation) {
      if (!year || year === 'all' || parseInt(moment(p.created_at).format('YYYY')) === year) {
        return true
      }
    } 

    return false;
  });
}

export const optionsForPayments = (payments: Payment[]) => {
  // build dropdown
  let options: DropdownItemProps[] = [{
    key: 'all',
    value: 'all',
    text: 'Show All'
  }];

  let donationYears: Record<string, boolean>= {};
  payments.forEach(p => {
    if (p.is_donation) {
      let key = moment(p.created_at).format('YYYY');
      if (!donationYears[key]) {
        donationYears[key] = true;
        options.push({
          key: key,
          value: parseInt(key),
          text: key + ' Tax Deductible',
        });
      }
    }
  });

  return options;
}

export const allValidOptions = () => {
  let now = new Date();
  
  let options: DropdownItemProps[] = [{
    key: 'all',
    value: 'all',
    text: 'Show All'
  }];

  for(let i=now.getFullYear();i>=2019;i--) {
    options.push({
      key: i,
      value: i,
      text: i
    })
  }

  return options;
}


export const SendReceipt = ({ user, year, payments, ...rest }: { user: User, year: 'all'|number, payments: Payment[], rest?: ButtonProps }) => {
  const [loading, error, run, sent] = useRequest(false);

  const handleSend = () => {
    run(API.sendReceipt({user, year, payments: filter(payments, year)}))
  }

  if (error) {
    return <>{error}</>
  } else if (sent) {
    return <>Sent!</>
  } else if (year === 'all' || !year) {
    return <></>;
  } else {
    return <Button {...rest} loading={loading} onClick={handleSend}>
      Send Receipt
    </Button>;
  }
}
