import React, { useCallback, useEffect } from 'react'
import { Form, Grid, Segment } from 'semantic-ui-react'
import { ChangeHandler } from 'Shared/Hooks'
import { Program } from 'Shared/Models'
import { NumberField } from 'Shared/Number'
import { Panel } from 'Shared/Panel'

import { NoiseSelect } from './NoiseSelect'
import { locations } from './options'
import { ReservableSelect } from './ReservableSelect'

export const ProgramFormLocation = ({fields, handleChange}: {fields: Program, handleChange: ChangeHandler}) => {
  const {location, reservations, equipment_needs, noise_level, do_not_disturb, location_fee} = fields;

  const setLocation = useCallback((val: string) => {
    handleChange(null, {name:'location', value: val});
  }, [handleChange]);

  // remove room reservations for zoom programs
  useEffect(()=>{
    if (location === locations.zoom) {
      handleChange(null, {name:'reservations', value: []});
    }
  }, [handleChange, location])

  return <Panel heading='Location' noSegment>
    <Grid as={Segment} attached='bottom' columns={2} widths='equal' stackable>
      <Grid.Column>
        <Form.Group inline width={8}>
          <Form.Radio
            label='AC'
            checked={location === locations.ac}
            onClick={()=>{setLocation(locations.ac)}}/>
          <Form.Radio
            label="AC Zoom"
            checked={location === locations.zoom}
            onClick={()=>{setLocation(locations.zoom)}}/>
          <Form.Radio
            label='Custom'
            checked={location !== locations.zoom && location !== locations.ac}
            onClick={()=>{setLocation('')}}/>
        </Form.Group>

        <Form.Input
          style={{maxWidth: '20em'}}
          name='location'
          placeholder='at <location>'
          value={location}
          onChange={handleChange} />

        { location === locations.ac ? <>
          <ReservableSelect 
            name='reservations'
            label='Reserve Rooms'
            value={reservations}
            onChange={handleChange}/>

          <Form.Checkbox
            label='This program requires *exclusive* use of the reserved rooms'
            name='do_not_disturb'
            checked={do_not_disturb > 0}
            onChange={(_, {checked})=>{
              handleChange(null, {name:'do_not_disturb', value: checked ? 1 : 0})
            }}/>

          <NoiseSelect required noise_level={noise_level} handleChange={handleChange}/>
        </> : 
          <NumberField
            label='Location Fee'
            innerLabel={{basic: true, content:'$'}}
            name='location_fee'
            value={location_fee}
            onChange={handleChange}
          />}
        
      </Grid.Column>
      <Grid.Column>
        {location === locations.ac && 
          <Form.TextArea
            label='Equipment Needs'
            rows={4}
            placeholder="How many chairs, tables, projector, whiteboards, etc."
            name='equipment_needs'
            value={equipment_needs}
            onChange={handleChange}/>}
      </Grid.Column>
    </Grid>
  </Panel>
}

