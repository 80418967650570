import { UserContainer } from 'Context/User'
import { MenuLink } from 'Nav/Nav'
import React, {useContext} from 'react'
import { useRouteMatch } from 'react-router';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom'
import { Menu, MenuProps } from 'semantic-ui-react'

import { UserRole } from './Models';

export type TabProps = RouteProps & {
  admin?: boolean
  staff?: boolean
  hidden?: boolean
  name: string
  icon?: string
}

export type ResponsiveTabsProps = {
  items: TabProps[]
  menuProps?: MenuProps
}

const ResponsiveTabs: React.FC<ResponsiveTabsProps> = ({items, menuProps, ...rest}) => {
  const match = useRouteMatch();
  const {user} = useContext(UserContainer)

  // filter menu items based on access
  const filtered = items.filter(({admin, staff}) => {
    if (admin && user.role !== UserRole.Admin) {
      return false;
    } else if (staff && (user.role !== UserRole.Staff && user.role !== UserRole.Admin)) {
      return false;
    } else {
      return true
    }
  })

  const start = match.url + '/' + filtered[0].path;

  return (
    <>
      <Menu size='small' {...menuProps}>
        { filtered.map(({hidden, path, name, icon}) => {
          if (hidden) {
            return false;
          }
          return <Menu.Item as={MenuLink} key={path as string} icon={icon} to={`${match.url}/${path}`} name={name} />
        })}
      </Menu>
      <Switch>
        { filtered.map(({path, exact, component, render}) => {
          if (!render && component) {
            const C = component as React.ComponentType;
            render = () => <C {...rest}/>
          }
          return <Route key={path as string} exact={exact} path={`${match.path}/${path}`} render={render}/>
        })}
        <Redirect from={match.url} to={start}/>
      </Switch>
    </>
  );
};

export default ResponsiveTabs;
