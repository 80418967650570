import API from 'Api'
import { UserContainer } from 'Context/User'
import _ from 'lodash'
import { MenuLink } from 'Nav/Nav'
import React, { MouseEvent, useCallback, useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Loader, Menu, Message } from 'semantic-ui-react'
import { useRequest } from 'Shared/Hooks'
import { Program, User } from 'Shared/Models'
import { Staff, staffCheck } from 'Shared/Roles'

import { LimitedRoute, StaffRoute } from '../Helpers'
import ProgramForm from './ProgramForm'
import { ProgramImages } from './ProgramImage'
import ProgramRegistrations from './ProgramRegistrations'
import { ProgramTasks } from './ProgramTasks'
import ProgramView, { stripMarkdownLinks } from './ProgramView'

const ProgramRouter = () => {
  const {user} = useContext(UserContainer);
  const {slug_or_id} = useParams<{slug_or_id: string}>();
  const [loading, error, run, program] = useRequest<Program>({} as Program, {loading: true});

  const load = useCallback(()=> {
    run(API.getProgram(slug_or_id))
  }, [run, slug_or_id])

  useEffect(load, [load])

  const ogImg = _.find(program.images, (i)=>(i.id === program.og_image));
  if (loading) {
    return <Loader active inline='centered' />
  }

  if (error) {
    return <Message negative>{error}</Message>
  }

  return <>
    <Helmet>
      <title>{program.title}</title>
      <meta property="og:url" content={`https://app.aldercommons.org/program/${program.slug}`}/>
      <meta property="og:type" content="event" />
      { ogImg && <meta property="og:image" content={ogImg.url} />}
      { ogImg && <meta property="og:image:alt" content={ogImg.title} />}
      { ogImg && <meta property="og:image:width" content="1200" />}
      { ogImg && <meta property="og:image:height" content="630" />}
      <meta property="og:title" content={program.title + ' @ Alder Commons'} />
      <meta property="og:description" content={stripMarkdownLinks(program.description)} />
    </Helmet>

    {staffOrOrganizer(user, program) &&
      <Menu compact style={{marginBottom:'1rem'}}>
        <Menu.Item exact as={MenuLink} to={`/program/${program.slug}/`} name="View" />
        <Staff>
          <Menu.Item as={MenuLink} to={`/program/${program.id}/edit`} name="Edit" />
          <Menu.Item as={MenuLink} to={`/program/${program.id}/tasks`} name="Tasks" />
        </Staff>
        <Menu.Item as={MenuLink} to={`/program/${program.id}/images`} name="Images" />
        <Menu.Item as={MenuLink} to={`/program/${program.id}/registrations`} name="Registrations" />
        <Staff>
          <AnnounceMenuItem program={program}/>
          <CloneMenuItem program={program}/>
        </Staff>
      </Menu>}

    {/* sub-routes */}
    <Switch>
      <Route exact path={`/program/${slug_or_id}`} render={()=><ProgramView program={program} load={load}/>}/>
      <StaffRoute exact path={`/program/${program.id}/edit`} render={()=><ProgramForm program={program}/>}/>
      <StaffRoute exact path={`/program/${program.id}/tasks`} render={()=><ProgramTasks program={program}/>}/>
      <Route exact path={`/program/${program.id}/images`} render={()=><ProgramImages program={program} onSuccess={load}/>}/>
      <LimitedRoute check={u=>(staffOrOrganizer(u, program))} exact path={`/program/${program.id}/registrations`} render={()=><ProgramRegistrations program={program}/>}/>
    </Switch>
  </>
}

export default ProgramRouter;

const AnnounceMenuItem = ({program}: {program: Program}) => {
  const [loading, error, run] = useRequest({});
  const announce = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (window.confirm(`Are you sure you want to announce '${program.title}'?`)) {
      run(API.announceProgram(program.id))
    }
  }

  if (error) {
    return <Message negative>{error}</Message>;
  }

  return <Menu.Item as='a' disabled={loading} name='Announce' onClick={announce}/>
}

const CloneMenuItem = ({program}: {program: Program}) => {
  const [loading, error, run] = useRequest<Program>({} as Program);
  const [redirect, setRedirect] = useState('');

  const clone = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (window.confirm(`Are you sure you want to clone '${program.title}'?`)) {
      run(API.cloneProgram(program.id), p => {
        setRedirect(`/program/${p.id}/edit`);
      })
    }
  }

  if (error) {
    return <>{error}</>
  }

  if (redirect) {
    return <Redirect to={redirect}/>
  }

  return <Menu.Item as='a' disabled={loading} name='Clone' onClick={clone}/>
}

const staffOrOrganizer = (user: User, program: Program) => {
  if (staffCheck(user)) {
    return true;
  }

  if (user.id > 0 && program.organizers?.includes(user.id)) {
    return true;
  }

  return false;
}
