import moment from 'moment'
import { Person } from 'Shared/Models'

export const isAdult = (person: Person) => {
  return moment().diff(moment(person.dob), 'years') >= 18;
}

export const age = (dob: string) => {
  const age = moment().diff(moment(dob), 'years');
  return age < 200 ? age : '?';
}

const Age: React.FC<{person: Person}> = ({person}) => {
  return <>{age(person.dob)}</>
}

export default Age;
