import { Label, SemanticCOLORS } from "semantic-ui-react"

import { StatusOptions } from "./Models"

const statusColorMap: Record<StatusOptions, SemanticCOLORS> = {
  pending: 'yellow',
  consider: 'orange',
  clear: 'green',
  complete: 'green',
  suspended: 'red',
  dispute: 'red',
}

const altStatusMap: Record<number, {color: SemanticCOLORS, label: string}> = {
  0: {color: 'red', label: 'N/A'},
  1: {color: 'yellow', label: 'Alt Pending'},
  2: {color: 'red', label: 'Alt Failed'},
  3: {color: 'green', label: 'Alt Passed'}
}

export const CheckrStatus = ({status}: {status: StatusOptions}) => (
  <Label color={statusColorMap[status]}>
    {status}
  </Label>
)

export const AltStatus = ({status}: {status: number}) => {
  const found = altStatusMap[status];
  if (!found) {
    return <Label size='tiny' color='red'>Unrecognized Status</Label>
  }
  return <Label size='tiny' color={found.color}>
    {found.label}
  </Label>
}

export const AltStatusOptions = Object.keys(altStatusMap).map(
  (status, i) => ({
    key: status,
    value: Number(status),
    text: altStatusMap[Number(status)].label,
  })
);
