import API from 'Api'
import { PersonContainer } from 'Context/Person'
import React, { useContext, useEffect } from 'react'
import { Form, Message } from 'semantic-ui-react'
import { optionsForPayments,SendReceipt } from 'Shared/Donations'
import { useFields, useRequest } from 'Shared/Hooks'
import PaymentTable from 'Shared/PaymentTable'
import { TwoLinePlaceholder } from 'Shared/Placeholders'
 
const PaymentHistory = () => {
  const {person} = useContext(PersonContainer);
  const {user} = person;
  const [loading, error, run, payments] = useRequest([]);
  const {fields, handleChange} = useFields<{year:'all'|number}>({
    year: 'all'
  });

  useEffect(()=>{
    run(API.getPayments(user.id));
  }, [run, user])
    
  const {year} = fields;

  const options = optionsForPayments(payments);

  if (error) {
    return <Message error>{error}</Message>
  }

  if (loading) {
    return <TwoLinePlaceholder/>
  }

  return (
    <>
      <Form>
        <Form.Group inline>
          <Form.Select
            name='year'
            style={{width: '10em'}}
            options={options}
            defaultValue={year}
            onChange={handleChange}
          />
          <SendReceipt year={year} user={user} payments={payments} /> 
        </Form.Group>
      </Form>
      <PaymentTable payments={payments} year={year}/>
    </>
  );
}

export default PaymentHistory;
