import API from 'Api';
import { isArray } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react'
import { DropdownItemProps, Form, FormSelectProps } from 'semantic-ui-react'

import { ChangeHandler, useRequest } from './Hooks';
import { Tag } from './Models';

type TagChooserProps = Omit<FormSelectProps, 'value'> & {
  value: Tag[]
  interestTags?: boolean
  onChange: ChangeHandler
}

const TagChooser: React.FC<TagChooserProps> = ({onChange, value, interestTags, ...rest}) => {
  const [loading, error, run] = useRequest<Tag[]>([], {loading: true})
  const [options, setOptions] = useState<DropdownItemProps[]>([])

  const innerChange: ChangeHandler = useCallback((e, data) => {
    onChange(e, Object.assign(data, {
      value: (data.value as number[]).map(id => ({id}))
    }));
  }, [onChange]);
  
  useEffect(()=>{
    let APIcall = API.getTags;
    if (interestTags) {
      APIcall = API.getInterestTags;
    }
    run(APIcall(), tags => { 
      setOptions(tags.map(t => {
        return {
          key: t.id,
          value: t.id,
          text: t.label
        }
      }))
    })
  }, [run, interestTags])

  return (
    <Form.Select
      {...rest}
      value={isArray(value) ? value.filter(Boolean).map(t => t.id) : []}
      error={error}
      loading={loading}
      options={options}
      search
      multiple
      onChange={innerChange} />
  );
}

export default TagChooser;
