import API from 'Api'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Form, Loader, Message, Table } from 'semantic-ui-react'
import Dollars from 'Shared/Dollars'
import { allValidOptions, filter,SendReceipt } from 'Shared/Donations'
import { useFields, useRequest } from 'Shared/Hooks'
import { Payment, User } from 'Shared/Models'
import TableHeaders from 'Shared/TableHeaders'

const Donors = () => {
  const [loading, error, run, users] = useRequest<User[]>([], {loading: true});
  const [slowUsers, setSlowUsers] = useState<User[]>([]);
  const {fields, handleChange} = useFields<{year: 'all'|number}>({year: 'all'});
  const {year} = fields;

  useEffect(()=>{
    run(API.getUsers())
  }, [run])

  // add users to slowUsers
  useEffect(() => {
    if (!loading && users && users.length > 0) {
      let index = 0;
      const interval = setInterval(()=>{
        if (index === users.length-1) {
          clearInterval(interval)
        }
        setSlowUsers((u)=>{
          return u.concat(users[index]);
        });
        index += 1;
      }, 1000);

      return ()=>{
        clearInterval(interval);
      }
    }
  }, [users, loading])

  if (loading) {
    return <Loader active inline='centered' />
  }

  if (error) {
    return <Message negative>{error}</Message>
  }

  return <div>
    <Message>Note: This page intentionally takes a while to load, as we don't want to overwhelm Stripe with our asks for data...</Message>
    <Form>
      <Form.Select
        style={{width:'14em', paddingBottom:'1em'}}
        type='text'
        name='year'
        placeholder='Choose a year'
        defaultValue={year}
        options={allValidOptions()}
        onChange={handleChange}
      />
    </Form>

    <Table collapsing>
      <TableHeaders headers={['Donor', {name: 'Total', textAlign:'right'}, '']}/>
      <Table.Body>
        { slowUsers && slowUsers.map(u => (
          <Donor key={u.id} user={u} year={year}/>
        )) }
      </Table.Body>
    </Table>
  </div>
}

export default Donors;

const Donor: React.FC<{user: User, year: number|'all'}> = ({user, year}) => {
  const [loading, error, run, payments] = useRequest<Payment[]>([]);

  useEffect(()=>{
    run(API.getPayments(user.id))
  }, [run, user])


  let result = <></>;

  if (error) {
    result = <>{error}</>;
  } else if (loading) {
    result = <Loader size='tiny' inline active/>
  } else {
    const total = filter(payments, year).reduce((sum: number, p: Payment) => {
      return sum += p.amount;
    }, 0);

    if (total === 0) {
      return null;
    } else {
      result = <Dollars amount={total/100}/>
    }
  }

  return <Table.Row>
    <Table.Cell><Link to={`/person/${user.person_id}`}>{user.person.first} {user.person.last}</Link></Table.Cell>
    <Table.Cell textAlign='right'>{result}</Table.Cell>
    <Table.Cell textAlign='right'>
      <SendReceipt user={user} year={year} payments={payments}/>
    </Table.Cell>
  </Table.Row>
}
