import API from 'Api'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Button, Dimmer, Form, Header, Icon, IconProps, Label, Loader, Message, Modal, Table } from 'semantic-ui-react'
import Dollars from 'Shared/Dollars';
import { useFields, useRequest } from 'Shared/Hooks';
import { Program, Registration, RegistrationStatus, Waitlist, WaitlistStatus } from 'Shared/Models';
import { Panel } from 'Shared/Panel';
import { refundAmount } from 'Shared/PaymentTable';
import PersonLabel from 'Shared/PersonLabel';
import { Staff } from 'Shared/Roles';
import TableHeaders from 'Shared/TableHeaders';
import Tooltip from 'Shared/Tooltip';
import UserChooser from 'Shared/UserChooser';

import { EmailParticipants } from './EmailParticipants';

const ProgramRegistrations = ({program}: {program: Program}) => {
  const [loading, error, run, result] = useRequest([[],[]])

  const [registrations, waitlisters] = result;

  const load = useCallback(()=>{
    run(Promise.all([
      API.getRegistrations(program.id),
      API.getWaitlisters(program.id)
    ]))
  }, [run, program])

  useEffect(load, [load]);

  const { title } = program;
  const isEmpty = registrations.length === 0;

  return <>
    <Header style={{marginTop:'0'}} as='h1'>{title}</Header>
    {error && <Message negative>{error}</Message>}

    <Staff>
      <AddStewardModal program={program} onSuccess={load}/>  
    </Staff>

    <Dimmer.Dimmable style={{minHeight:'50px'}} dimmed={loading}>
      <Dimmer active={loading} inverted>
        <Loader/>
      </Dimmer>
      <Dimmer active={isEmpty && !loading} inverted>
        <Header as='h4'>
          No Registrations
        </Header>
      </Dimmer>
      <ProgramRegistrationsTable program={program} registrations={registrations} onSuccess={load} />
      <Earnings program={program} registrations={registrations} />
      {waitlisters.length > 0 && 
        <>
          <Header as='h4'>Waitlisters</Header>
          <WaitlisterTable waitlisters={waitlisters} onSuccess={load}/>
        </>}
    </Dimmer.Dimmable>
    {!isEmpty && !loading && 
      <EmailParticipants program={program} registrations={registrations}/>}
  </>
}

const Earnings = ({program, registrations}: {program: Program, registrations: Registration[]}) => {
  const totalRev = totalProgramRevenue(registrations);
  const profit = (totalRev/100) - program.location_fee - program.facilitator_reimbursement;

  return <Panel icon='dollar' heading='Revenue, Expenses, Profit' noSegment>
    <Table attached='bottom'>
      <Table.Body>
        <Table.Row>
          <Table.Cell style={{background: '#fafafa'}}>Total Revenue</Table.Cell>
          <Table.Cell collapsing textAlign='right'><Dollars amount={totalRev/100}/></Table.Cell>
          <Table.Cell/>
        </Table.Row>
        <Table.Row>
          <Table.Cell style={{background: '#fafafa'}}>Reimburseable Expenses</Table.Cell>
          <Table.Cell collapsing textAlign='right'><strong>-<Dollars amount={program.facilitator_reimbursement}/></strong></Table.Cell>
          <Table.Cell/>
        </Table.Row>
        <Table.Row>
          <Table.Cell style={{background: '#fafafa'}}>Location Fee</Table.Cell>
          <Table.Cell collapsing textAlign='right'>-<Dollars amount={program.location_fee}/></Table.Cell>
          <Table.Cell/>
        </Table.Row>
        <Table.Row>
          <Table.Cell style={{background: '#fafafa'}}>Profit <Tooltip content='Technically not "profit" as we are a non-profit. Revenue minus expenses.'/></Table.Cell>
          <Table.Cell collapsing textAlign='right'><Dollars amount={Math.max(0, profit)}/></Table.Cell>
          <Table.Cell/>
        </Table.Row>
        <Table.Row>
          <Table.Cell collapsing style={{background: '#fafafa'}}>Faciltator Earnings</Table.Cell>
          <Table.Cell collapsing textAlign='right'><strong><Dollars amount={Math.max(0, profit * 0.5)}/></strong></Table.Cell>
          <Table.Cell>{profit * 0.5 < program.facilitator_compensation ? <><Icon name='warning'/> Below</> : 'Above' } facilitator minimum of <Dollars amount={program.facilitator_compensation}/></Table.Cell>
        </Table.Row>
        {program.facilitator_compensation_plan && <Table.Row>
          <Table.Cell collapsing style={{background: '#fafafa'}}>Compensation Notes</Table.Cell>
          <Table.Cell colSpan={2}>{program.facilitator_compensation_plan}</Table.Cell>
        </Table.Row>}
      </Table.Body>
    </Table>
  </Panel>
}

export default ProgramRegistrations;

const WaitlisterTable  = ({waitlisters, onSuccess}: {waitlisters: Waitlist[], onSuccess: ()=>void}) => {
  return <Table compact>
    <TableHeaders headers={["Date", "Who", "Guests", "Status", "Actions"]}/>
    <Table.Body>
      { waitlisters.map(wl=>(
        <Table.Row key={wl.user_id}>
          <Table.Cell collapsing>
            {moment(wl.created_at).format('lll')}
          </Table.Cell>
          <Table.Cell>
            <div style={{marginBottom:'2px'}} key={wl.user.person.id}>
              <PersonLabel link person={wl.user.person}/>
            </div>
          </Table.Cell>
          <Table.Cell textAlign="right" collapsing>
            { wl.guests > 0 && wl.guests}
          </Table.Cell>
          <Table.Cell textAlign="right" collapsing>
            <WaitlistStatusLabel status={wl.status}/>
          </Table.Cell>
          <Table.Cell>
            <WaitlistActions waitlister={wl} onSuccess={onSuccess} />
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
}

export const totalProgramRevenue = (registrations: Registration[]) => registrations.reduce((sum, r) => {
  return sum += r.payment ? r.payment.amount - refundAmount(r.payment.refunds) : 0;
}, 0);

const ProgramRegistrationsTable = ({program, registrations, onSuccess}: {program: Program, registrations: Registration[], onSuccess: ()=>void}) => {
  const parts = registrations.reduce((sum, r) => {
    if (r.status === 1) {
      return sum;
    }
    return sum += (r.participants_full ? r.participants_full.length : 0) + r.guests;
  }, 0);

  return <Table compact>
    <TableHeaders headers={["Date", "Registrant", "Participants", "Guests", "Fee", 'Refund', 'Status', ""]}/>
    <Table.Body>
      { registrations.map(r=>(
        <Row key={r.id} r={r} onSuccess={onSuccess}/>
      ))}
    </Table.Body>

    <Table.Footer>
      <Table.Row>
        <Table.HeaderCell></Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
        <Table.HeaderCell colSpan='2' textAlign='right'>
          {parts} Participants {program.capacity > 0 && `(${program.capacity} max)`}
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="right">
          <Dollars amount={totalProgramRevenue(registrations)/100}/>
        </Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  </Table>
}

const Row = ({r, onSuccess}: {r: Registration, onSuccess: ()=>void}) => {
  const refund = r.payment ? refundAmount(r.payment.refunds) : 0;

  return <Table.Row>
    <Table.Cell collapsing>
      {moment(r.created_at).format('l')}
    </Table.Cell>
    <Table.Cell collapsing>
      <UserSwapper r={r} onSuccess={onSuccess}/>
    </Table.Cell>
    <Table.Cell>
      {r.participants_full ? r.participants_full.map(p=>(
        <div style={{marginBottom:'2px'}} key={p.id}>
          <PersonLabel link person={p}/>
        </div>
      )) : 'No Participants?!'}
    </Table.Cell>
    <Table.Cell textAlign="right" collapsing>
      {r.guests > 0 && r.guests}
    </Table.Cell>
    <Table.Cell textAlign='right'>{r.payment && <Dollars amount={r.payment.amount/100}/>}</Table.Cell>
    <Table.Cell textAlign='right'>{refund > 0 && <>-<Dollars amount={refund/100}/></>}</Table.Cell>
    <Table.Cell>
      <RegistrationStatusLabel status={r.status}/>
      {r.steward && <>{' '}<StewardIcon/></>}
    </Table.Cell>
    <Table.Cell>
      <Button size='tiny' as={Link} to={`/registrations/${r.id}`} content='Edit'/>
    </Table.Cell>
  </Table.Row>
};

const UserSwapper = ({r, onSuccess}: {r: Registration, onSuccess: ()=>void}) => {
  const [loading, error, run] = useRequest(undefined)
  const [edit, setEdit] = useState(false)

  const updateRegistrationUser = useCallback((user_id: number)=>{
    run(API.updateRegistrationUser(r.id, user_id), ()=>{
      onSuccess()
      setEdit(false)
    })
  }, [run, r, onSuccess])

  if (loading) {
    return <Loader active inline/>
  }

  if (error) {
    return <Message warning content={error}/>
  }

  if (!edit) {
    return <Button size='mini' icon='pencil' content={r.user.email} onClick={()=>{setEdit(true)}} />
  }

  return <UserChooser style={{minWidth:'10em'}} size='tiny' value={r.user.id} onChange={(e, {value})=>{
    updateRegistrationUser(Number(value));
  }}/>
}

const WaitlistActions = ({waitlister, onSuccess}: {waitlister: Waitlist, onSuccess: ()=>void}) => {
  const [loading, error, run] = useRequest(undefined)

  const invite = () => {
    run(API.inviteWaitlister(waitlister), onSuccess)
  }

  const deleteWaitlister = () => {
    run(API.deleteWaitlister(waitlister), onSuccess)
  }

  if (loading) {
    return <Loader active inline='centered'/>;
  }

  if (error) {
    return <>{error}</>
  }

  return <>
    { waitlister.status === 0 && <Button primary compact size='tiny' content='Invite' onClick={invite}/>}
    <Button compact size='tiny' content='Delete' onClick={deleteWaitlister}/>
  </>
}

export const RegistrationStatusLabel = ({status}: {status: RegistrationStatus}) => {
  if (status === RegistrationStatus.Active) {
    return <Label size='tiny' color='green' content='Active'/>
  }

  if (status === RegistrationStatus.Canceled) {
    return <Label size='tiny' color='orange' content='Canceled'/>
  }

  return <Label size='tiny' content='Unknown'/>
}

const WaitlistStatusLabel = ({status}: {status: WaitlistStatus}) => {
  if (status === WaitlistStatus.Waitlisted) {
    return <Label color='yellow' content='Waitlisted'/>
  }

  if (status === WaitlistStatus.Invited) {
    return <Label color='green' content='Invited'/>
  }

  return <Label content='Unknown'/>
}

export const StewardIcon = (props: IconProps) => (
  <Icon.Group {...props} title='Steward'>
    <Icon name='user' />
    <Icon corner name='star' />
  </Icon.Group>
)

const AddStewardModal = ({program, onSuccess}: {program: Program, onSuccess: ()=>void}) => {
  const [open, setOpen] = useState(false);

  const {fields, handleChange} = useFields({
    user: {
      id: 0
    },
    program_id: program.id,
    steward: true
  });

  const [loading, error, run] = useRequest(undefined)

  const handleSubmit = useCallback(()=>{
    run(API.createRegistration(fields), ()=>{
      setOpen(false)
      onSuccess()
    });
  }, [fields, run, onSuccess]);

  return <Modal size='mini' closeIcon='close' onClose={()=>setOpen(false)} open={open} trigger={
    <Button type='button' onClick={()=>{setOpen(true)}}>
      <StewardIcon/> Add Steward
    </Button>
  }>
    <Modal.Header>Add Steward</Modal.Header>
    <Modal.Content>
      <Form error loading={loading} onSubmit={handleSubmit}>
        <Message error content={error}/>
        <UserChooser
          label='Steward'
          name='user.id'
          onChange={handleChange}/>
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button primary content='Save' onClick={handleSubmit}/>
    </Modal.Actions>
  </Modal>
}
