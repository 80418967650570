import { Label } from "semantic-ui-react"

import { Task, TaskStatus } from "./Models"

export const TaskStatusLabel: React.FC<{task: Task}> = ({task}) => {
  switch (task.status) {
  case TaskStatus.Ready:
    return <Label size='mini' color='yellow' content='ready'/>
  case TaskStatus.Success:
    return <Label size='mini' color='green' content='success'/>
  case TaskStatus.Failure:
    return <Label size='mini' color='red' content='failure'/>
  default:
    return <Label size='mini' content='unknown'/>
  }
}
