import { CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent, StripeElements } from '@stripe/stripe-js';
import React from 'react'
import { Form, FormFieldProps } from 'semantic-ui-react'

export const StripeFormField = ({onChange, elements, ...rest}: {onChange: (card: StripeCardElementChangeEvent) => void, elements: StripeElements} & FormFieldProps) => {
  
  const configure = () => {
    const card = elements.getElement(CardElement);
    if (card) {
      card.on('change', onChange);
    }
  }

  return (
    <Form.Field style={{minHeight: '50px'}} {...rest}>
      <div className="card-wrap">
        <CardElement 
          onReady={configure}
          options={{
            iconStyle: 'solid',
            style: {
              base: {
                color: 'rgba(0,0,0,0.95)',
                fontSize: '18px',
                fontFamily: "'Averia Serif Libre', sans-serif"
              }
            }
          }}/>
      </div>
    </Form.Field>
  );
}
