import API from "Api";
import { HouseholdContainer } from "Context/Household";
import React, { useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Grid, Message } from "semantic-ui-react";
import { useRequest } from "Shared/Hooks";
import { Panel } from "Shared/Panel";
import { minimumFee } from "SlidingScale";

export const Cancel = () => {
  const { membership, household } = useContext(HouseholdContainer);
  const [loading, error, run, success] = useRequest(false);

  const handleSubmit = () => {
    run(API.cancelHouseholdMembership(household.id));
  };

  if (success) {
    return (
      <Redirect
        to={{
          pathname: `/household/${household.id}/membership`,
          state: { reload: true },
        }}
      />
    );
  }

  const canReduce =
    membership.amount + membership.extras > minimumFee(household.is_org);

  return (
    <Grid columns={2} stackable>
      <Grid.Column width={8}>
        <Panel icon="cancel" heading="Membership Cancelation">
          {canReduce && (
            <p>
              If you are considering canceling your Membership because of
              financial hardship, you may be able to{" "}
              <Link to={`/household/${household.id}/edit`}>
                reduce your Membership fees
              </Link>{" "}
              yourself.
            </p>
          )}
          <p>
            If you would like a pro-rated refund for a portion of your last
            Membership payment, please{" "}
            <a href="mailto:hello@aldercommons.org">contact us</a> to request a
            refund.
          </p>
          <p>
            Upon cancelation, you will lose access to our Slack workspace,
            Members-only email list, and OpenPath keyless entry to the building.
          </p>
          <Form onSubmit={handleSubmit} error loading={loading}>
            <Message error>{error}</Message>
            <Form.Button negative>Cancel Membership</Form.Button>
          </Form>
        </Panel>
      </Grid.Column>
    </Grid>
  );
};
