import moment from 'moment'
import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom';
import { Icon, List } from 'semantic-ui-react'
import { SemanticCOLORS, SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';

import { Person } from './Models';
import { Panel } from './Panel';
import { Admin } from './Roles';

const consentMeanings: Record<number, string> = {
  2: 'Consented',
  1: 'Did Not Consent',
  0: 'Undetermined Consent'
}

export const ProfileFields = ({person}: {person: Person}) => {
  const {user, profile} = person;
  return <Panel icon='info' heading='Personal Info'>
    <List>
      {profile && <ProfileField title='My Profile' icon='user circle' iconColor='black' content={<Link to={`/members/${person.id}`}>My Profile</Link>}/>}
      <ProfileField title='Pronouns' icon='transgender alternate' iconColor='red' content={person.pronouns}/>
      {hasDOB(person.dob) && <ProfileField title='Birthday' icon='birthday' iconColor='purple' content={moment.utc(person.dob).format('LL')}/>}
      <ProfileField title='Phone Number' icon='phone' iconColor='blue' content={formatPhoneNumber(person.phone_number)}/>
      <ProfileField title='Email' icon='mail' iconColor='green' content={user && user.email}/>
      {user.slack_id && <ProfileField title='Slack' icon='slack' iconColor='olive' content={
        <a target='_blank' href={`https://aldercommons.slack.com/team/${user.slack_id}`} rel="noreferrer">On Slack</a>
      }/>}
      {person.is_member && <ProfileField title='Is Active Member' icon='star' iconColor='yellow' content={'Member'}/>}
      <ProfileField title='Member Code' icon='barcode' content={person.is_member && person.check_code}/>

      {/* admin-only */}
      <ProfileField title='Photo Consent' admin icon='photo' content={consentMeanings[person.photo_consent]}/>
      <ProfileField title='Race / Ethnicity' admin icon='chart pie' content={person.race_ethnicity}/>
      <ProfileField title='Allergies, etc.' admin icon='stethoscope' content={person.allergies}/>
      <ProfileField title='Can Onboard' admin icon='check' content={user && user.can_onboard && 'Can Onboard'}/>
    </List>
  </Panel>
}

type ProfileFieldProps = {
  title: string,
  icon: SemanticICONS,
  iconColor?: SemanticCOLORS,
  content: ReactNode,
  admin?: boolean
}

export const ProfileField: React.FC<ProfileFieldProps>= ({title, icon, iconColor, content, admin}) => {
  if (!content) {
    return <></>;
  }

  const Wrap = admin ? Admin : React.Fragment;

  return <Wrap>
    <List.Item>
      { admin ? 
        <Icon.Group title={title}>
          <Icon name={icon} color={iconColor ? iconColor : 'black'}/>
          <Icon corner='bottom right' name='lock' color='blue'/>
        </Icon.Group>
        : <Icon title={title} name={icon} color={iconColor ? iconColor : 'black'}/>}
      <List.Content content={content}/>
    </List.Item>
  </Wrap>
}

const hasDOB = (dob: string) => {
  return dob && dob !== "0001-01-01T00:00:00Z";
}

const formatPhoneNumber = (str: string) => {
  if (!str) {
    return str;
  }

  let cleaned = str.replace(/\D/g, '');
  let match = cleaned.match(/^\+?\d?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  } else {
    return str;
  }
};
