import API from "Api"
import { useCallback } from "react"
import { Redirect } from "react-router"
import { Form, Message, Segment } from "semantic-ui-react"
import { useFields, useRequest } from "Shared/Hooks"
import { Markdown } from "Shared/Markdown"
import { Profile } from "Shared/Models"
import TagCloud from "Shared/TagCloud"
import Tooltip from "Shared/Tooltip"

const helperText = `These profile fields support **markdown**. Markdown allows simple _text formatting_ using * and _.

You can also [do a lot more with markdown](https://www.markdownguide.org/basic-syntax/), including making links like the one in this sentence.`;

export const ProfileForm = ({profile}: {profile: Profile}) => {
  const [loading, error, run, result] = useRequest<Profile|false>(false)
  const {fields, handleChange} = useFields(Object.assign({}, profile, {bio: profile.bio || helperText}))

  const handleSubmit = useCallback(()=>{
    if (fields.bio === helperText) {
      // prevent saving helperText
      fields.bio = ''
    }

    run(API.updateProfile(fields))
  }, [run, fields])

  if (result) {
    return <Redirect to={`/members/${result.person_id}`}/>
  }

  return <Form loading={loading} error onSubmit={handleSubmit}>
    <Message info header='Privacy Info' list={[
      'Information that you share in your profile will be visible to all other Members in our Member Directory.',
      'Other Members will be able to contact you, but your email address will remain private unless you choose to respond.'
    ]}/>

    <Message error>{error}</Message>

    <Form.Input
      label='Display Name'
      name='name'
      value={fields.name}
      onChange={handleChange}
      style={{maxWidth: '20em'}}
    />

    <Form.Input
      label='Pronouns'
      name='pronouns'
      value={fields.pronouns}
      onChange={handleChange}
      placeholder='they/them'
      style={{maxWidth: '20em'}}
    />

    <Form.Group widths='equal'>
      <Form.Field>
        <label>About You <Tooltip wide>Write a few sentences to describe yourself to other Members.</Tooltip></label>
        <Form.TextArea
          name='bio'
          rows={8}
          value={fields.bio}
          onChange={handleChange}
        />
      </Form.Field>
      <Form.Field>
        <label>Preview</label>
        <Segment style={{marginTop:0}}>
          <Markdown text={fields.bio} />
        </Segment>
      </Form.Field>
    </Form.Group>
  
    <Form.Group widths='equal'>
      <Form.Field>
        <label>Skillshare <Tooltip>Add any skills you'd be willing to share, formally or informally, with other Members.</Tooltip></label>
        <Form.TextArea
          name='skillshare'
          rows={5}
          value={fields.skillshare}
          onChange={handleChange}
        />
      </Form.Field>
      <Form.Field>
        <label>Preview</label>
        <Segment style={{marginTop:0}}>
          <Markdown text={fields.skillshare} />
        </Segment>
      </Form.Field>
    </Form.Group>

    <Form.Group widths='equal'>
      <Form.Field>
        <label>Services <Tooltip>Add your services here to be listed in the classifieds section of the directory.</Tooltip></label>
        <Form.TextArea
          name='services'
          rows={5}
          value={fields.services}
          onChange={handleChange}
        />
      </Form.Field>
      <Form.Field>
        <label>Preview</label>
        <Segment style={{marginTop:0}}>
          <Markdown text={fields.services} />
        </Segment>
      </Form.Field>
    </Form.Group>

    <TagCloud
      interestTags
      label='Interests'
      name='interest_tags'
      value={fields.interest_tags}
      onChange={handleChange}
    />

    <Form.Button type='submit' primary>Save Profile</Form.Button>
  </Form>
}
