import API from 'Api'
import React, { useCallback, useEffect, useState } from 'react'
import { User } from 'Shared/Models';

export const UserContainer = React.createContext({
  user: {} as User,
  setUser: (user: User) => {},
  userLoading: false,
  setLoading: (loading: boolean) => {},
  handleLogout: () => {}
});

export const WithUser: React.FC<React.PropsWithChildren> = ({children}) => {
  const [user, setUser] = useState({} as User)
  const [userLoading, setLoading] = useState(true)

  const handleLogout = useCallback(()=>{
    API.logout()
      .then(()=>{
        setUser({} as User);
      });
  }, [setUser])

  useEffect(()=>{
    API.whoami()
      .then((user: User) => {
        if (user) {
          setUser(user)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])
  
  return (
    <UserContainer.Provider value={{user, setUser, userLoading, setLoading, handleLogout}}>
      {children}
    </UserContainer.Provider>
  );
}
