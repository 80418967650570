import API from 'Api'
import Fuse from 'fuse.js'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import { RegistrationTypeOptions } from 'Routes/Program/RegistrationTypes'
import { Button, Form, Icon, Loader, Message, Table } from 'semantic-ui-react'
import { Date } from 'Shared/Date'
import Dollars from 'Shared/Dollars'
import { useFields, useRequest } from 'Shared/Hooks'
import { Program, RegistrationType } from 'Shared/Models'
import { ProgramStatusLabel, ProgramStatusOptions } from 'Shared/ProgramStatus'
import TableHeaders from 'Shared/TableHeaders'

const Programs = () => {
  const [loading, error, run, programs] = useRequest<Program[]>([], {loading: true});
  const {fields, handleChange} = useFields({
    filter: '',
    status: '',
    column: 'start_date',
    direction: 'descending',
    show_unpaid: false,
    registration_type: ''
  })

  const [fuse, setFuse] = useState<Fuse<Program>>(new Fuse([]));
  const [loadAll, setLoadAll] = useState(false);

  const setSort = useCallback((column: string) => {
    if (fields.column === column) {
      let direction = fields.direction;
      direction = direction === 'ascending' ? 'descending' : 'ascending';
      handleChange(null, {name:'direction', value: direction})
    } else {
      handleChange(null, {name:'column', value: column})
      handleChange(null, {name:'direction', value: 'descending'})
    }
  }, [fields, handleChange])

  useEffect(()=>{
    run(API.getPrograms({
      draft: 'true',
      trashed: loadAll ? 'true' : 'false',
      private: 'true',
      requested: 'true',
      participants: 'true',
      before: moment.utc().add(10, 'year').format(),
      after: loadAll ? moment.utc().subtract(100, 'year').format() : moment.utc().subtract(3, 'month').format()
    }))
  }, [run, loadAll])

  useEffect(()=>{
    setFuse(new Fuse(programs, {
      // includeScore: true,
      threshold: 0.3,
      keys: ['title', 'description']
    }));
  }, [programs])

  if (error) {
    return <Message negative>{error}</Message>
  }

  const {filter, status, column, direction, show_unpaid, registration_type} = fields;

  const showPayRequired = Number(registration_type) === RegistrationType.PaymentRequired ;

  let filteredPrograms = filter ? fuse.search(filter).map(({item}) => (item)) : programs;

  if (status !== '') {
    filteredPrograms = filteredPrograms.filter(p=>p.status === Number(status))
  }

  if (show_unpaid) {
    filteredPrograms = filteredPrograms.filter(p=> (
      moment.utc(p.start_date).isBefore(moment.utc()) && !p.facilitator_paid && (p.facilitator_compensation_plan || p.facilitator_compensation > 0 || p.facilitator_reimbursement > 0)
    ))
  }

  if (registration_type) {
    filteredPrograms = filteredPrograms.filter(p=>p.registration_type === Number(registration_type))
  }

  if (column) {
    filteredPrograms = _.sortBy(filteredPrograms, [column])
    if (direction === 'descending') {
      filteredPrograms = filteredPrograms.reverse();
    }
  }

  return <>
    <Button style={{marginBottom: '1em'}} primary as={Link} to='/new-program' icon='plus' title='Create Program'/>

    <Form>
      <Form.Group inline style={{marginBottom:0}}>
        <Form.Input
          type='text'
          name='filter'
          placeholder='Search'
          onChange={handleChange}
        />
        <Form.Select
          compact
          style={{width: '8em'}}
          name='status' 
          value={status}
          placeholder='Status'
          clearable
          options={ProgramStatusOptions}
          onChange={handleChange}
        />
        <Form.Select
          compact
          style={{width: '12em'}}
          name='registration_type'
          value={registration_type}
          placeholder='Registration Type'
          clearable
          options={RegistrationTypeOptions}
          onChange={handleChange}
        />
        <Form.Checkbox
          label='Show Unpaid'
          name='show_unpaid'
          checked={show_unpaid}
          onChange={handleChange}/>
        <Form.Checkbox
          label='Load Old & Deleted'
          name='loadAll'
          checked={loadAll}
          onChange={()=>{setLoadAll(!loadAll)}}/>
      </Form.Group>
    </Form>

    <Table sortable>
      <TableHeaders headers={[
        {
          name:"Title", 
          sorted: column === 'title' ? direction : null,
          onClick: () => setSort('title')
        }, {
          name:"Start Date",
          collapsing: true,
          sorted: column === 'start_date' ? direction : null,
          onClick: () => setSort('start_date')
        }, {
          name: "Last Updated",
          collapsing: true,
          sorted: column === 'updated_at' ? direction : null,
          onClick: () => setSort('updated_at')
        }, (showPayRequired && {
          name: "Min Fee",
          collapsing: true,
        }), {
          name: "Shortcuts",
          collapsing: true,
        }
      ].filter(h=>h)}/>
      <Table.Body>
        { loading ? <Table.Row>
          <Table.Cell colSpan={4}>
            <Loader active inline='centered' />
          </Table.Cell>
        </Table.Row> : filteredPrograms.map(p => (
          <Table.Row key={p.id}>
            <Table.Cell>
              <ProgramStatusLabel status={p.status} short /> <Link to={`/program/${p.slug}`} title={String(p.id)}>{p.title}</Link>
            </Table.Cell>
            <Table.Cell collapsing><Date full ts={p.start_date}/></Table.Cell>
            <Table.Cell collapsing><Date full ts={p.updated_at}/></Table.Cell>
            { showPayRequired && <Table.Cell collapsing textAlign='right'><Dollars amount={p.min_price}/></Table.Cell> }
            <Table.Cell collapsing>
              <Button size='tiny' icon='edit' as={Link} title='edit' to={`/program/${p.id}/edit`}/>
              <Button size='tiny' icon='images' as={Link} title='images' to={`/program/${p.id}/images`}/>
              <Button size='tiny' as={Link} title='registrations' to={`/program/${p.id}/registrations`}>
                <Icon name='users'/>
                {p.participants}{p.capacity > 0 && `/${p.capacity}`}
              </Button>
              <CloneButton program={p}/>
            </Table.Cell>
          </Table.Row>
        )) }
      </Table.Body>
    </Table>
  </>
}

export default Programs;

const CloneButton = ({program}: {program: Program}) => {
  const [loading, error, run] = useRequest<Program>({} as Program);
  const [redirect, setRedirect] = useState('');

  const clone = () => {
    run(API.cloneProgram(program.id), (p: Program) => {
      setRedirect(`/program/${p.id}/edit`);
    })
  }

  if (loading) {
    return <Loader size='tiny' active inline/>
  }

  if (error) {
    return <>{error}</>
  }

  if (redirect) {
    return <Redirect to={redirect}/>
  }

  return <Button as='a' size='tiny' title='clone' icon='clone' onClick={clone}/>
}
