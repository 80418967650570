import React, { ReactNode, useCallback, useEffect } from 'react'
import { Form } from 'semantic-ui-react'

import { ChangeHandler, useFields } from './Hooks';
import { NumberField } from './Number';

const splitValue = (value: string) => {
  if (!value) {
    value = '';
  }

  if (value !== '') {
    value = value.substring(0,10);
  }

  let parts = value.split('-');
  if (!value) {
    parts = ['','','']
  }
  
  return {
    month: Number(parts[1].replace(/^0+/, '')),
    day: Number(parts[2].replace(/^0+/, '')),
    year: Number(parts[0].replace(/^0+/, ''))
  };
}

const validateAndMerge = ({year, month, day}: {year: number, month: number, day: number}) => {
  if (!day || !year) {
    return false;
  }

  let d = new Date(1,1,1,0,0,0);
  d.setFullYear(year);
  d.setMonth(month-1);
  d.setDate(day);
  return d.toISOString();
}

const daysInMonth = (month: number, year: number) => {
  return new Date(year, month, 0).getDate();
}

type DateFieldProps = {
  name: string,
  label: ReactNode,
  defaultValue: string,
  required: boolean
  onChange: ChangeHandler
}

export const DateField: React.FC<DateFieldProps> = ({label, required, defaultValue, onChange, name: parentName}) => {
  const {fields, setFields} = useFields(splitValue(defaultValue))
  
  useEffect(()=>{
    setFields(splitValue(defaultValue))
  }, [defaultValue, setFields]);

  // send changes upstream
  useEffect(()=>{
    const result = validateAndMerge(fields);
    if (result) {
      onChange(null, {
        name: parentName,
        value: result
      });
    }
  }, [parentName, fields, onChange])

  const handleChange: ChangeHandler = useCallback((e, {name, value}) => {
    if (name === 'day' && Number(value) > daysInMonth(fields.month, fields.year)) {
      // avoid changing month by double because of overshooting max day
      setFields({...fields, 
        day: daysInMonth(fields.month, fields.year)
      })
    } else {
      setFields({...fields, [name]: value});
    }
  }, [fields, setFields]);

  const {year, month, day} = fields;
  return <Form.Group>
    <Form.Select
      label={label}
      required={required}
      placeholder='Month'
      name='month'
      value={month}
      options={MonthOptions}
      style={{minWidth:'8em'}}
      onChange={handleChange} />
    <NumberField
      label='&nbsp;'
      type='text'
      name='day'
      value={day}
      min={0}
      max={daysInMonth(month, year)}
      placeholder='DD'
      style={{maxWidth:'4em'}}
      onChange={handleChange} />
    <NumberField
      label='&nbsp;'
      type='text'
      name='year'
      value={year}
      min={0}
      max={2100}
      placeholder='YYYY'
      style={{maxWidth:'6em'}}
      onChange={handleChange} />
  </Form.Group>
}

const MonthOptions = [
  {
    key: 1,
    value: 1,
    text: 'January'
  },
  {
    key: 2,
    value: 2,
    text: 'February'
  },
  {
    key: 3,
    value: 3,
    text: 'March'
  },
  {
    key: 4,
    value: 4,
    text: 'April'
  },
  {
    key: 5,
    value: 5,
    text: 'May'
  },
  {
    key: 6,
    value: 6,
    text: 'June'
  },
  {
    key: 7,
    value: 7,
    text: 'July'
  },
  {
    key: 8,
    value: 8,
    text: 'August'
  },
  {
    key: 9,
    value: 9,
    text: 'September'
  }
  ,  {
    key: 10,
    value: 10,
    text: 'October'
  },
  {
    key: 11,
    value: 11,
    text: 'November'
  },
  {
    key: 12,
    value: 12,
    text: 'December'
  }
];
