import API from "Api";
import { HouseholdContainer } from "Context/Household";
import React, { FormEvent, useContext, useState } from "react";
import {
  Button,
  CheckboxProps,
  Form,
  Message,
  TextArea,
} from "semantic-ui-react";
import Dollars from "Shared/Dollars";
import { useFields, useRequest } from "Shared/Hooks";
import { NumberField } from "Shared/Number";

const MembershipExtrasForm = () => {
  const { household, membership, load } = useContext(HouseholdContainer);
  const [loading, error, run] = useRequest({});
  const [hasExtras, setHasExtras] = useState(membership.extras > 0);
  const { fields, handleChange } = useFields(Object.assign({}, membership));

  const handleCheck = (
    e: FormEvent<HTMLInputElement>,
    { checked }: CheckboxProps
  ) => {
    setHasExtras(!!checked);
    if (checked === false) {
      handleChange(null, { name: "extras", value: 0 });
      handleChange(null, { name: "notes", value: "" });
    }
  };

  const handleSubmit = () => {
    run(API.updateHouseholdMembership(household.id, fields, 5), load);
  };

  const { amount, extras, notes } = fields;
  const { subscription_id } = membership;
  if (subscription_id) {
    return (
      <Message>
        This account already has autopay configured. Please{" "}
        <a href="mailto:hello@aldercommons.org">contact us</a> if you need to
        make changes!
      </Message>
    );
  }

  return (
    <Form error name="extras" loading={loading} onSubmit={handleSubmit}>
      <Message error>{error}</Message>
      <Form.Field>
        <label>Extras</label>
        <Form.Checkbox
          label="I have extra needs, such as dedicated space, kitchen use, mailbox, etc."
          name="hasExtras"
          checked={hasExtras}
          onChange={handleCheck}
        />
      </Form.Field>

      {hasExtras && (
        <>
          <Message>
            If you haven't discussed your specific needs with a staff member
            yet, you should do so before submitting this form!
          </Message>
          <Form.Field>
            <label>Description of the extras that we discussed</label>
            <TextArea
              name="notes"
              onChange={handleChange}
              rows={2}
              value={notes}
            />
          </Form.Field>

          <NumberField
            style={{ maxWidth: "14em" }}
            label="Monthly Fee for Extras"
            name="extras"
            min={0}
            required
            value={extras}
            onChange={handleChange}
          />
        </>
      )}

      <Form.Field>
        <label>Total Monthly Fees</label>
        <Dollars amount={amount + extras} />
      </Form.Field>

      <Button primary type="submit">
        Save
      </Button>
    </Form>
  );
};

export default MembershipExtrasForm;
