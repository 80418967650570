import API from 'Api'
import { UserContainer } from 'Context/User'
import React, { useContext } from 'react'
import { Button, Form, Message } from 'semantic-ui-react'
import { useFields, useRequest } from 'Shared/Hooks'
import { Program, Waitlist } from 'Shared/Models'
import { NumberField } from 'Shared/Number'

const WaitlistRegister = ({program, onWaitlist}: {program: Program, onWaitlist: (wl: Waitlist)=>void}) => {
  const {user} = useContext(UserContainer);
  const [loading, error, run] = useRequest<Waitlist>({} as Waitlist)
  const {fields, handleChange} = useFields({
    user: {
      id: user.id,
      email: user.email,
      person: {
        first: user.person && user.person.first ? user.person.first : '',
        last: user.person && user.person.last ? user.person.last : '',
      }
    },
    program_id: program.id,
    guests: 0,
    newsletter: false,
  });

  const { guests, newsletter } = fields;

  const handleSubmit = () => {
    run(API.createWaitlister(fields), onWaitlist)
  }

  return (
    <Form error name="waitlist" loading={loading} onSubmit={handleSubmit} style={{maxWidth: '30em'}}>
      <Message error>{error}</Message>

      <Form.Input
        style={{maxWidth: '20em'}}
        name='user.email'
        type='email'
        required
        label='Email'
        value={fields.user.email}
        onChange={handleChange}
      />

      <Form.Checkbox
        label='I want to receive the Alder Commons newsletter'
        name='newsletter'
        checked={newsletter}
        onChange={handleChange}/>

      <Form.Group widths='equal'> 
        <Form.Input
          style={{maxWidth: '14em'}}
          name='user.person.first'
          required
          label='First Name'
          value={fields.user.person.first}
          onChange={handleChange}
        />

        <Form.Input
          style={{maxWidth: '14em'}}
          name='user.person.last'
          required
          label='Last Name'
          value={fields.user.person.last}
          onChange={handleChange}
        />
      </Form.Group>

      <NumberField
        label='Number of Additional Participants'
        style={{maxWidth:'7em'}}
        name='guests'
        value={guests}
        min={0}
        onChange={handleChange}
      />

      <Button primary type="submit">Join the Waitlist</Button>
    </Form>
  )
};

export default WaitlistRegister
