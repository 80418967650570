import './table.css'

import API from 'Api'
import { UserContainer } from 'Context/User'
import _ from 'lodash'
import moment, { Moment } from 'moment'
import qs from 'qs'
import React, { FormEvent, useCallback, useContext, useEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { Button, CheckboxProps, Form, Message } from 'semantic-ui-react'
import { useFields, useRequest } from 'Shared/Hooks'
import { Program, ProgramStatus, RegistrationType, User } from 'Shared/Models'
import { ProgramStatusOptions } from 'Shared/ProgramStatus'
import { NotStaff, Staff, staffCheck } from 'Shared/Roles'
import SimplePage from 'Shared/SimplePage'
import TagChooser from 'Shared/TagChooser'
import TagCloud from 'Shared/TagCloud'
import Tooltip from 'Shared/Tooltip'
import UserChooser from 'Shared/UserChooser'

import { labels, links, locations } from './options'
import { ProgramFormDescription } from './ProgramFormDescription'
import { ProgramFormFacilitator } from './ProgramFormFacilitator'
import { ProgramFormLocation } from './ProgramFormLocation'
import { ProgramFormRegistration } from './ProgramFormRegistration'
import { ProgramFormSchedule } from './ProgramFormSchedule'

// used onLoad but also for cloning
const fieldsForProgram = (program: Program|undefined, defaultDate: Moment|undefined, user: User, isStaff: boolean) => {
  if (program) {
    return _.clone(program);
  }

  const p: Program = Object.assign({} as Program, {
    location: locations.ac,
    start_date: defaultDate ? defaultDate : moment.utc().add(7, 'days').hours(12).minutes(0).seconds(0).format(),
    end_date: moment.utc().add(10, 'weeks').endOf('day').format(),
    duration: 60,
    waitlist: true,
    reminder: true,
    capacity: 0,
  });

  if (user.id) {
    p.organizers = [user.id];
  }

  if (isStaff) {
    p.host_text = 'Alder Commons';
    p.registration_type = RegistrationType.DonationOptional;
    p.status = ProgramStatus.Draft;
  } else {
    p.host_text = user.person.first;
    p.registration_type = RegistrationType.None;
    p.status = ProgramStatus.RequestReserved;
  };

  return p
}

export const ProgramFormWrapped = ({program}: {program: Program}) => {
  const {user} = useContext(UserContainer);
  const isStaff = staffCheck(user);
  const title = isStaff ? 'Create a Program' : 'Request a Reservation or Program';

  return <SimplePage icon='calendar plus outline' title={title}>
    <NotStaff>
      <Message>
        <Message.List items={[
          'Private reservations must be requested at least 1 week in advance',
          'Publicly listed events must be requested at least 1 month in advance'
        ]}/>
      </Message>
    </NotStaff>
    <ProgramForm program={program}/>
  </SimplePage>
}

const ProgramForm = ({program}: {program: Program}) => {
  const {user} = useContext(UserContainer);
  const isStaff = staffCheck(user);
  const action = program && program.id ? API.updateProgram.bind(API) : API.createProgram.bind(API);

  // default date for new program?
  const location = useLocation();
  const {d} = qs.parse(location.search, { ignoreQueryPrefix: true });
  const defaultDate = d ? moment.utc(d as string, 'YYYY-MM-DD') : undefined;

  const {fields, handleChange, setFields} = useFields(fieldsForProgram(program, defaultDate, user, isStaff));
  const [loading, error, run, success] = useRequest<Program>({} as Program);

  const handleScheduleChange = useCallback((scheduleFields: any) => {
    setFields(Object.assign({}, fields, scheduleFields))
  }, [fields, setFields])

  // scroll to see errors
  useEffect(()=>{
    if (error) {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }, [error])

  // adjust links and labels on location change or registration_type change
  useEffect(()=>{
    if (fields.location === locations.zoom) {
      handleChange(null, {name:'link', value: links.zoom});
      handleChange(null, {name:'link_label', value: labels.zoom});
    } else if (fields.registration_type !== RegistrationType.None) {
      handleChange(null, {name:'link', value: ''});
      handleChange(null, {name:'link_label', value: ''});
    }
  }, [fields.registration_type, fields.location, handleChange])

  // avoid misconfigured third-party links
  const validate = () => {
    if ((fields.link !== '' && fields.link_label === '') || 
        (fields.link === '' && fields.link_label !== '')) {
      return Promise.reject('Online or external programs must have a Link Label AND a Link URL specified');
    }
    return Promise.resolve();
  }

  // toggle status for nonAdmins
  const handlePublicEventToggle = (e: FormEvent<HTMLInputElement>, {checked}: CheckboxProps) => {
    handleChange(null, {name:'status', value: checked ? ProgramStatus.RequestPublic : ProgramStatus.RequestReserved});
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    let body = _.clone(fields);

    // convert empty values to zeroes
    if (!body.capacity) {
      body.capacity = 0;
    }
    if (!body.facilitator_compensation) {
      body.facilitator_compensation = 0;
    }
    if (!body.facilitator_reimbursement) {
      body.facilitator_reimbursement = 0;
    }

    run(
      validate()
        .then(() => {
          return action(body)
        })
    );
  }

  const { title, slug, organizers, status, tags, interest_tags } = fields;

  if (success && success.slug) {
    return <Redirect to={`/program/${success.slug}${program && program.id ? '' : '?fresh=true'}`}/>
  }

  return (
    <Form error name="program" loading={loading} onSubmit={handleSubmit}>
      <Message error>{error}</Message>
      <Form.Group>
        <Form.Field required>
          <label>Event Title</label>
          <Form.Input
            required
            style={{minWidth:'20em'}}
            name='title'
            value={title}
            onChange={handleChange}
          />
        </Form.Field>

        <Staff>
          <Form.Field>
            <label>Slug <Tooltip content="Leave this blank to auto-generate a slug from the program title. Don't change this if the link has already been promoted!" /></label>
            <Form.Input
              style={{minWidth:'12em'}}
              name='slug'
              value={slug}
              onChange={handleChange}/>
          </Form.Field>

          <Form.Field>
            <label>Event Admins</label>
            <UserChooser
              multiple
              style={{maxWidth: '20em'}}
              name='organizers' 
              value={organizers}
              onChange={handleChange}/>
          </Form.Field>
        </Staff>
      </Form.Group>  

      <NotStaff>
        <Form.Checkbox onChange={handlePublicEventToggle} label='List on the public calendar'/>
      </NotStaff>

      <Staff>
        <Form.Group>
          <Form.Field>
            <label>Status</label>
            <Form.Select 
              compact
              style={{width: '12em'}}
              name='status' 
              value={status}
              options={ProgramStatusOptions}
              onChange={handleChange}/>
          </Form.Field>

          <TagChooser
            interestTags
            label='Interest Tags'
            name='interest_tags'
            value={interest_tags}
            onChange={handleChange}
          />

          <TagCloud
            label='Tags'
            name='tags'
            value={tags}
            onChange={handleChange}
          />
        </Form.Group>
      </Staff>

      <ProgramFormDescription description={fields.description} handleChange={handleChange}/>

      <ProgramFormFacilitator fields={fields} handleChange={handleChange}/>

      <ProgramFormLocation fields={fields} handleChange={handleChange}/>

      <ProgramFormSchedule fields={fields} onScheduleChange={handleScheduleChange}/>
      
      <Staff>
        <ProgramFormRegistration fields={fields} handleChange={handleChange}/>
      </Staff>

      <Button primary type="submit">{isStaff ? 'Save' : 'Submit Request' }</Button>
    </Form>
  );
}

export default ProgramForm;
