export enum UserRole {
  Default = 0,
  Staff = 1,
  Admin = 2,
  Board = 3
}

export enum UserStatus {
  Disabled = -1,
  Unverified = 0,
  Active = 1
}

export type User = {
  id: number
  email: string
  pass: string
  status: UserStatus
  role: UserRole
  person_id: number
  slack_invite: string
  slack_id: string
  person: Person
  openpath_id: number
  candidate_id: string
  customer_id: string
  background_check_alt: number
  can_onboard: boolean
}

export type Person = {
  id: number
  first: string
  last: string
  nickname: string
  full_name: string
  dob: string
  pronouns: string
  race_ethnicity: string
  phone_number: string
  is_member: boolean
  alerts: boolean
  bipoc: boolean
  user: User
  photo_consent: number
  allergies: string
  check_code: string
  // computed
  profile: Profile
};

export type Profile = {
  person_id: number
  profile_image_id: number

  name: string
  pronouns: string
  bio: string
  skillshare: string
  services: string
  interest_tags: Tag[]

  // calculated
  role?: UserRole
  profile_image_url?: string
  slack_id?: string
  households?: Household[]
}

export type ProfileImage = {
  id: number
  path: string
  url: string // calculated
}

export enum ProgramStatus {
  Draft = 0,
  Public = 1,
  Canceled = 2,
  Reserved = 3,
  Deleted = 4,
  RequestReserved = 5,
  RequestPublic = 6,
}

export enum RegistrationType {
  None = 0,
  DonationOptional = 1,
  PaymentRequired = 2,
  MembersOnly = 3,
  PaymentOptional = 4,
  MembersDiscounted = 5,
}

export enum Reservable {
  Kitchen = "kitchen",
  Woodshop = "woodshop",
  Lounge = "lounge",
  MeetingRoom = "meeting_room",
  Library = "library",
  Coworking = "coworking",
  ParkingLot = "parking_lot",
  Backyard = "backyard",
  PlayRoom = "play_room",
}

export enum NoiseLevel {
  Quiet = 1,
  Medium = 2,
  Loud = 3
}

export type Program = {
  id: number
  status: ProgramStatus
  title: string
  description: string
  slug: string
  link: string
  link_label: string
  images: ProgramImage[]
  registration_type: RegistrationType
  registration_message: string
  start_date: string
  end_date: string
  rrule: string
  host_text: string
  location: string
  min_price: number
  member_price: number
  capacity: number
  participants: number
  waitlisters: number
  waitlist: boolean
  reminder: boolean
  duration: number
  og_image: number
  equipment_needs: string
  noise_level: NoiseLevel
  setup_duration: number
  cleanup_duration: number
  do_not_disturb: number
  reservations: Reservable[]
  tags: Tag[]
  interest_tags: Tag[]
  facilitator_paid: boolean
  facilitator_compensation: number
  facilitator_reimbursement: number
  facilitator_compensation_plan: string
  location_fee: number
  updated_at: string
  organizers: number[]
}

export type ProgramImage = {
  id: number
  program_id: number
  path: string
  title: string
  weight: number
  url: string // calculated
  isOG: boolean // calculated
}

export enum RegistrationStatus {
  Active = 0,
  Canceled = 1,
}

export type Registration = {
  id: number
  status: RegistrationStatus
  program: Program
  user: User
  payment: Payment
  guests: number
  steward: boolean
  participants_full: Person[]
  created_at: string
}

export type Payment = {
  is_donation: boolean
  stripe_id: string
  description: string
  amount: number
  refunds: Refund[]
  created_at: string
}

export type Refund = {
  status: string
  amount: number
}

export type Signature = {
	id: string
	household_id: number
	signer_id: number
	type: FileType
	ip: string
	signed_at: string
	created_at: string
	updated_at: string
}

export enum WaitlistStatus {
  Waitlisted = 0,
  Invited = 1,
}

export type Waitlist = {
  status: WaitlistStatus
  guests: number
  user_id: number
  user: User
  created_at: string
}

// Role Types
export enum HouseholdPersonRole {
  Dependent = 0,
  Inactive = 1,
  Admin = 10
}

export type HouseholdPerson = {
  household_id: number
  person_id: number
  role: HouseholdPersonRole
  person: Person
  household: Omit<Household, 'people'>
  updated_at: string
  created_at: string
}

export enum TaskStatus {
  Ready = 0,
  Success = 1,
  Failure = 2
}

export type Task = {
  id: number
  program_id: {
    Int64: number
  },
  data: any,
  name: string
  status: TaskStatus
  updated_at: string
}

export type Tag = {
  id: number
  label: string
  description: string
  special: boolean
}

export enum SubscriptionStatus {
  Active = 'active',
  PastDue = 'past_due',
  Unpaid = 'unpaid',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  Trialing = 'trialing'
}

export type Subscription = {
  id: string
  status: SubscriptionStatus
}

export enum HouseholdInDirectory {
  Undecided = 0,
  No = 1,
  Yes = 2
}

export type Household = {
  id: number,
  name: string,
  people: HouseholdPerson[],
  is_org: boolean,
  in_directory: HouseholdInDirectory,
};

export enum MembershipStatus {
  Inactive = 0,
  PastDue = 1,
  Canceled = 2,
  PeopleSaved = 3,
  FeeSaved = 4,
  ExtrasSaved = 5,
  DocumentsUploaded = 6,
  BackgroundChecksStarted = 7,
  UnderReview = 8,
  Active = 10,
}

export type Membership = {
  id: number
  household: Household
  status: MembershipStatus
  amount: number
  extras: number
  adults: number
  youth: number
  income: number
  days: number
  notes: string
  updated_at: string
  subscription_id: string
}

export type Invite = {
  email: string
  updated_at: string
}

export enum FileType {
  Waiver = 0,
  MutualAgreements = 1,
  TermsOfUse = 2
}

export enum FileStatus {
  Uploaded = 0,
  Approved = 1,
  Invalid = 2
}

export type HouseholdFile = {
	id: number
  src: string
	household_id: number
	uploader_id: number
	name: string
	type: FileType
	notes: string
	status: FileStatus
	created_at: string
	updated_at: string
}

export enum DocStatus {
  Hidden = 0,
  Active = 1
}

export type Doc = {
  src: string,
	name: string,
	status: DocStatus,
  created_at: string
}

// Openpath models
export type OpenpathUser = {
  id: number
  first: string
  last: string
  email: string
  remote: boolean
  status: 'A' | 'S' | 'I'
  groups: string
  user: User
  admin_link: string
  identity: {
    email: string
  }
}

// stripe models
export type Customer = {
  id: string
  name: string
  invoice_settings: {
    default_payment_method: {
      id: string
    }
  },
  subscriptions: {
    data: Sub[]
  }
};

export type Sub = {
  id: string
  plan: {
    nickname: string
  }
  quantity: number
  current_period_end: number // unix ts
}

// checkr reports
export enum ReportReviewStatus {
  NotReviewed = 0,
  ReviewedDenied = 1,
  ReviewedApproved = 2,
}

export type StatusOptions = 'pending' | 'consider' | 'clear' | 'complete' | 'suspended' | 'dispute' 

export type Report = {
  id: string
  status: StatusOptions
  review_status: ReportReviewStatus
}
