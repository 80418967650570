
import API from 'Api'
import { HouseholdContainer } from 'Context/Household'
import React, { useCallback, useContext } from 'react'
import { Loader, Message } from 'semantic-ui-react'
import { useRequest } from 'Shared/Hooks'

import SignDocuments from './SignDocuments'

const MembershipDocuments = () => {
  const {household, membership, load} = useContext(HouseholdContainer);
  const [loading, error, run] = useRequest({});

  const handleSubmit = useCallback(() => {
    run(API.updateHouseholdMembership(household.id, membership, 6), load);
  }, [run, household, membership, load]);

  if (loading) {
    return <Loader active inline/>
  }

  return <>
    <Message>When you agree to these documents, we will email a link to the other people in your Membership so they can review and sign them.</Message>
    {error && <Message warning>{error}</Message>}
    <SignDocuments household_id={household.id} onSuccess={handleSubmit} />
  </>
}

export default MembershipDocuments;
