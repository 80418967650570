import marked from "marked";

marked.setOptions({gfm: true, breaks: true});

const targetBlank = (str: string) => {
  return str.split('<a').join('<a target="_blank"');
}

export const Markdown = ({text, className, inline = false}: {text: string, className?: string, inline?: boolean}) => {
  if (inline) {
    return <span className={className} dangerouslySetInnerHTML={{ __html: targetBlank(marked.parseInline(text || '')) }}/>
  }
  return <div className={className} dangerouslySetInnerHTML={{ __html: targetBlank(marked.parse(text || '')) }}/>
}
