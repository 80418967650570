import API from 'Api'
import { HouseholdContainer } from 'Context/Household'
import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router'
import { Redirect } from 'react-router-dom'
import { Button, Form, Message } from 'semantic-ui-react'
import { isAdult } from 'Shared/Age'
import { useFields, useRequest } from 'Shared/Hooks'
import { HouseholdPersonRole, Person, User } from 'Shared/Models'
import PersonFields from 'Shared/PersonFields'
import { RoleOptions } from 'Shared/RoleOptions'
import Tooltip from 'Shared/Tooltip'

const AddHouseholdPerson = () => {
  const {household} = useContext(HouseholdContainer);
  const location = useLocation<{from: {pathname: string}}>();
  const {fields, handleChange} = useFields({
    household_id: household.id,
    person_id: 0,
    role: HouseholdPersonRole.Admin,
    person: {
      dob: '1985-01-01',
      user: {} as User
    } as Person
  });
  const [loading, error, run, success] = useRequest(false)

  useEffect(()=>{
    if (error) {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }, [error])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    run(API.createHouseholdPerson(household.id, fields));
  }
  
  const { household_id, role, person } = fields;

  if (success) {
    return <Redirect to={{
      pathname: location && location.state && location.state.from ? location.state.from.pathname : `/household/${household_id}/membership`,
      state: { reload: true }
    }}/>
  }

  return <Form error name="household" loading={loading} onSubmit={handleSubmit}>
    <Message error>{error}</Message>
    <Form.Field>
      <label>Role <Tooltip>Admins can make changes to any person in the group, while Dependents can only control their own profile.</Tooltip></label>
      <Form.Select
        style={{maxWidth: '14em'}}
        name='role'
        value={role}
        onChange={handleChange}
        options={RoleOptions}
      />
    </Form.Field>

    <Form.Input
      label='Email (required for adults)'
      required={isAdult(fields.person)}
      style={{maxWidth: '20em'}}
      name='person.user.email'
      type='email'
      value={fields.person.user.email}
      onChange={handleChange} />

    <PersonFields onChange={handleChange} person={person} strictFields={true}/>
    <Button primary type="submit">Save</Button>
  </Form>
}

export default AddHouseholdPerson;
