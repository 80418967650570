// Stripe provider
import { Elements } from '@stripe/react-stripe-js';
// Stripe consumer
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import API from 'Api';
import React from 'react'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = API.getConfig().then(config => {
  return loadStripe(config.stripe_pk);
});

const StripeEnabled = React.forwardRef(({component, ...rest}: {component: React.FC<any>, rest?: any}, ref) => {
  const Inner = component;
  return <Elements stripe={stripePromise} options={{
    fonts: [{cssSrc: 'https://fonts.googleapis.com/css?family=Averia+Sans+Libre:700|Averia+Serif+Libre:400,400i,700&display=swap'}]
  }}>
    <ElementsConsumer>
      {({elements, stripe}) => (
        <Inner {...rest} ref={ref} elements={elements} stripe={stripe}/>
      )}
    </ElementsConsumer>
  </Elements>
});

export default StripeEnabled;
