import API from 'Api'
import React, { useCallback } from 'react'
import { Button, Form, Message } from 'semantic-ui-react'
import { useFields, useRequest } from 'Shared/Hooks'
import { Person } from 'Shared/Models'
import SimplePage from 'Shared/SimplePage'

const CheckMember = () => {
  const [loading, error, run, person] = useRequest<Person>({} as Person)
  const {fields, handleChange} = useFields({
    code: ''
  })

  const handleSubmit = useCallback(() => {
    run(API.checkMember(fields))
  }, [run, fields])

  const {code} = fields;
  
  return (
    <SimplePage icon='lock' centered title='Status Checker'>
      {person && person.first && <Message success>{person.first} {person.last} is a Member!</Message>}
      <Form loading={loading} error name='check-member' onSubmit={handleSubmit}>
        <Message error>{error}</Message>
        <Form.Input
          type='text'
          name='code'
          placeholder='Member Code'
          value={code}
          onChange={handleChange}
        />
        <Button primary>Check Membership Status</Button>
      </Form>
    </SimplePage>
  )
}

export default CheckMember;
