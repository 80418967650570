import _ from 'lodash'
import { Moment } from 'moment';
import React, { ChangeEvent, FormEvent, SyntheticEvent, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Weekday } from 'rrule';
import { CheckboxProps, DropdownProps, InputOnChangeData, TextAreaProps } from 'semantic-ui-react';

export type RunFunc<T> = (promise: Promise<T>, onSuccess?: (data: T) => void, onFailure?: (error: string|undefined) => void) => void

type UseRequestOptions = {
  loading?: boolean
}

type ErrorDispatch = React.Dispatch<React.SetStateAction<string|undefined>>
type DataDispatch<T> = React.Dispatch<React.SetStateAction<T>>

export const useRequest = <T extends {}|undefined>(initData: T, options?: UseRequestOptions): [boolean, string|undefined, RunFunc<T>, T, ErrorDispatch, DataDispatch<T>] => {
  const [data, setData] = useState(initData);
  const [loading, setLoading] = useState<boolean>(options !== undefined && options.loading!!);
  const [error, setError] = useState<string|undefined>(undefined);

  // prevent setters if component is unmounted
  // this can happen if e.g. onSuccess callback causes page navigation
  const isMountedRef = useRef(true);
  useEffect(() => {
    return () => void (isMountedRef.current = false);
  }, []);

  // we could just return the promise from run(), but using onSuccess and onFailure callbacks 
  // allows us to react before the loading/errors states change - this is mostly useful if 
  // we want to redirect before the not-loading layout can show itself
  const run: RunFunc<T> = useCallback((promise, onSuccess, onFailure) => {
    setLoading(true);
    setError(undefined);

    return promise
      .then(data => {
        if (onSuccess) {
          onSuccess(data);
        }
        if (isMountedRef.current) {
          setData(data);
          setLoading(false);
        }
      })
      .catch(error => {
        if (onFailure) {
          onFailure(error);
        }
        if (isMountedRef.current) {
          setError(error);
          setLoading(false);
        }
      });
  }, [setLoading, setError, setData])

  return [loading, error, run, data, setError, setData];
}

// ChangeHandler works for Input, Select, Textarea, and Checkbox
// the onChange field for the different semantic-ui form components doesn't match, hence these wild type definitions
type Evt = SyntheticEvent<HTMLElement, Event> | ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | FormEvent<HTMLInputElement> | FormEvent<Element> | null
type Data = InputOnChangeData | TextAreaProps | CheckboxProps | DropdownProps | {name: string, value: Moment|Weekday|Weekday[]|string|number|boolean|any[]}

export type ChangeHandler = (event: Evt, data: Data) => void

type ChangeData = {
  name: string
  type: string
  value: string
  checked?: boolean
}

export const useFields = <T extends Object>(initFields: T): {fields: T, handleChange: ChangeHandler, setFields: React.Dispatch<React.SetStateAction<T>>} => {
  const [fields, setFields] = useState(initFields)

  const changeHandler = useCallback(({ name, type, value, checked }: ChangeData)=>{
    setFields(f => {
      let out = _.cloneDeep(f)
      _.set(out, name, type === 'checkbox' ? checked : value);
      return out
    });
  }, [setFields])

  const handleChange = useCallback((e: Evt, cd: Data) => {
    changeHandler(cd as ChangeData)
  }, [changeHandler])

  return {fields, handleChange, setFields};
}


// Window size detection hook
export const useMobile = (width: number = 767) => {
  const [mobile, setMobile] = useState(false);
  const mql = useRef(window.matchMedia(`(max-width: ${width}px)`));

  useLayoutEffect(() => {
    const handleResize = () => {
      setMobile(mql.current.matches);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return mobile;
}
