const Frequencies: Record<number, string> = {
  0: "How often will you come each week?",
  4: "A couple times per month",
  8: "Legacy: 2 days || ~12 hrs",
  12: "Multiple times per week",
  16: "Legacy: 4 days || ~24 hrs",
  20: "Most days of the week"
}

export const Frequency = ({days}: {days: number}) => {
  if (days && Frequencies[days]) {
    return Frequencies[days];
  }

  return "Error";
}

export const FrequencyOptions = Object.keys(Frequencies)
  .filter(k => !Frequencies[Number(k)].startsWith('Legacy'))
  .map((days, i) => {
    return {
      key: days,
      value: Number(days),
      text: Frequencies[Number(days)]
    };
  });
