import React from 'react'
import { useRouteMatch } from 'react-router'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Container } from 'semantic-ui-react'

import Program from './Program'

const ProgramRouter = () => {
  const {path} = useRouteMatch();
  return (
    <Container className='page'>
      <Switch>
        <Route path={`${path}/:slug_or_id`} component={Program}/>
        <Redirect from={path} to={`/programs/`}/>
      </Switch>
    </Container>
  );
}

export default ProgramRouter;
