import API from 'Api'
import { UserContainer } from 'Context/User'
import React, { useContext, useEffect, useState } from 'react'
import { Redirect, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Loader, Message } from 'semantic-ui-react'
import CreateGroup from 'Shared/CreateGroup'
import { useRequest } from 'Shared/Hooks';
import { HouseholdPerson } from 'Shared/Models';
import { Anonymous, LoggedIn } from 'Shared/Roles';
import SimplePage from 'Shared/SimplePage'

const Membership = () => {
  const location = useLocation();
  return (
    <SimplePage icon='star' title='Become an AC Member'>
      <Anonymous>
        <Message>You have to <Link to={{pathname: "/login", state: {from: location}}}>log in</Link> to continue setting up your membership.</Message>
        <p>Learn about <a href="https://aldercommons.org/membership">becoming a Member</a> on our website, or <a href="https://forms.gle/fT6ao72NRGRXc3J5A">join the waitlist</a>.</p>
      </Anonymous>
      <LoggedIn>
        <GroupForm/>
      </LoggedIn>
    </SimplePage>
  )
};

export default Membership;

const GroupForm = () => {
  const { state } = useLocation<{justVerified: boolean}>();
  const {user} = useContext(UserContainer)
  const [loading, error, run, householdPeople] = useRequest<HouseholdPerson[]>([]);
  const [redirect, setRedirect] = useState('');
  
  useEffect(()=>{
    const { person_id } = user;
    run(API.getPersonHouseholds(person_id))
  }, [user, run])

  useEffect(()=>{
    if (householdPeople.length === 1) {
      const id = householdPeople[0].household_id;
      setRedirect(`/household/${id}/membership`);
    }
  },[householdPeople]);

  if (loading) {
    return <Loader active inline='centered' />
  }

  if (error) {
    return <Message negative>{error}</Message>
  }

  if (redirect) {
    return <Redirect to={{pathname: redirect, state}}/>
  }

  if (householdPeople.length > 1) {
    return <>
      <p>Which household or group do you want to create a membership for?</p>
      {householdPeople.map(m => (
        <Link key={m.household_id} to={`/household/${m.household_id}/membership`}>{m.household.name}</Link>
      ))}
    </>
  }

  return <>
    <p>What do you want to call your membership group? Most people use their last name.</p>
    <CreateGroup attachUser={true} onSuccess={({id}) => {
      setRedirect(`/household/${id}/membership`)
    }}/>
  </>
}
