import API from 'Api'
import { PersonContainer } from 'Context/Person';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Form, Header, Message, Placeholder } from 'semantic-ui-react'
import { useFields, useRequest } from 'Shared/Hooks';
import { OpenpathUser } from 'Shared/Models';
import { TwoLinePlaceholder } from 'Shared/Placeholders';

type Response = {
  data?: OpenpathUser
  admin_link: string
}

const Openpath = () => {
  const {person: {user}} = useContext(PersonContainer);
  const [loading, error, run, resp] = useRequest<Response>({} as Response)
  const [success, setSuccess] = useState(false);

  useEffect(()=>{
    run(API.getOpenpathUser(user.id))
  }, [run, user])

  const createUser = () => {
    run(API.createOpenpathUser(user.id), ({data}) => {
      setSuccess(true);
    })
  }

  const openpathUser = resp.data;
  const adminLink = resp.admin_link;

  if (loading && !openpathUser) {
    return <Placeholder><Placeholder.Line length='short'/><Placeholder.Line length='short'/><Placeholder.Line length='medium'/></Placeholder>
  }

  if (error) {
    return <Message error>{error}</Message>
  }

  if (!openpathUser) {
    return <Form onSubmit={createUser} loading={loading}>
      <Button size='tiny' primary>Add to Openpath</Button>
    </Form>
  }

  return <>
    {success && <Message positive>User created, added to "Member" group, and email invite sent!</Message>}
    <p>
      ID: <a target="_blank" rel="noopener noreferrer" href={adminLink}>{openpathUser.id}</a><br/>
      Status: <Status code={openpathUser.status}/><br/>
      Email: {openpathUser.identity.email}
    </p>
    <Header as='h4'>Credentials</Header>
    <Credentials/>

    <Header as='h4'>Groups</Header>
    <Groups/>
  </>
}

export default Openpath;

const Status = ({code}: {code: string}) => {
  switch (code) {
  case 'A':
    return <span style={{color:'green'}}>Active</span>
  case 'S':
    return <span style={{color:'red'}}>Suspended</span>
  case 'I':
    return <span style={{color:'red'}}>Inactive</span>
  default:
    return <span>{code}</span>
  }
}

type CredentialResponse = {
  data?: Credential[]
}

type Credential = {
  id: number
  credentialType: {
    name: string
  },
  cardMifareCsn: {
    number: string
  }
}

const Credentials = () => {
  const {person: {user}} = useContext(PersonContainer);
  const [loading, error, run, resp] = useRequest<CredentialResponse>({} as CredentialResponse);
  

  const load = useCallback(()=>{
    run(API.getOpenpathUserCredentials(user.id))
  }, [run, user])

  useEffect(load, [load])

  const credentials = resp.data || [];

  if (loading) {
    return <TwoLinePlaceholder/>
  }

  if (error) {
    return <Message error>{error}</Message>
  }

  let list = <></>;
  if (credentials.length === 0) {
    list = <p>
      No credentials found.
    </p>
  } else {
    list = <>
      {credentials.map(c => (
        <p key={c.id}>
          {c.credentialType.name === 'Card: Openpath/MIFARE (CSN) - Fast' ? 'Keyfob' : c.credentialType.name}
          {c.cardMifareCsn && c.cardMifareCsn.number &&
            ` (${c.cardMifareCsn.number})`}
          <DeleteCredential credentialID={c.id} onSuccess={load}/>
        </p>
      ))}
    </>
  }

  return <div>
    {list}
    <AddFobForm onSuccess={load}/>
  </div>
}

const DeleteCredential = ({credentialID, onSuccess}: {credentialID: number, onSuccess: ()=>void}) => {
  const {person: {user}} = useContext(PersonContainer);
  const [loading, error, run] = useRequest({});

  const deleteCred = () => {
    run(API.deleteOpenpathCredential(user.id, credentialID), onSuccess)
  }

  if (error) {
    return <>{error}</>
  }

  return <Button size='tiny' style={{marginLeft:'1em'}} compact disabled={loading} onClick={deleteCred} icon='close'/>
}

const AddFobForm = ({onSuccess}: {onSuccess: ()=>void}) => {
  const {person: {user}} = useContext(PersonContainer);
  const [loading, error, run] = useRequest({});
  const {fields, handleChange} = useFields({
    fob: '',
  })

  const {fob} = fields;

  const addFob = () => {
    run(API.addOpenpathFob(user.id, fob), onSuccess)
  }

  return (
    <Form error name="addFob" loading={loading} onSubmit={addFob}>
      <Message error>{error}</Message>
      <Form.Group inline>
        <Form.Input
          placeholder='AB12CD34'
          name='fob'
          value={fob}
          style={{maxWidth:'10em'}}
          onChange={handleChange}/>
        <Button>Add Keyfob</Button>
      </Form.Group>
    </Form>
  );
}

type Group = {
  id: string
  name: string
}

const Groups = () => {
  const {person: {user}} = useContext(PersonContainer);
  const [loading, error, run, groups] = useRequest<Group[]>([]);

  useEffect(()=>{
    run(API.getOpenpathUserGroups(user.id))
  }, [run, user])

  if (loading) {
    return <TwoLinePlaceholder/>
  }

  if (error) {
    return <Message error>{error}</Message>
  }

  if (groups.length === 0) {
    return <p>No groups found.</p>
  } 

  return <>
    {groups.map(g => (
      <p key={g.id}>{g.name}</p>
    ))}
  </>
}
