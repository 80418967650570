import { Card } from '@stripe/stripe-js'
import API from 'Api'
import { HouseholdContainer } from 'Context/Household'
import React, { FormEvent, useCallback, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Form, Message, Segment } from 'semantic-ui-react'
import { useRequest } from 'Shared/Hooks'
import { Customer, HouseholdPersonRole, Membership, Person, Subscription, User } from 'Shared/Models'
import { ViewCard } from 'Shared/PaymentCard'
import { PersonName } from 'Shared/PersonName'
import { SingleLinePlaceholder, TwoLinePlaceholder } from 'Shared/Placeholders'
import { Admin } from 'Shared/Roles'
import { SubscriptionStatusLabel } from 'Shared/SubscriptionStatusLabel'

type GetSubscriptionResponse = {
  sub: Subscription,
  user: User
}

export const StripeDetails = () => {
  const {membership, load} = useContext(HouseholdContainer);
  const [loading, error, run, resp] = useRequest<GetSubscriptionResponse>({} as GetSubscriptionResponse, {loading: membership.subscription_id !== ''})

  const loadSub = useCallback(() => {
    if (membership.subscription_id) {
      run(API.getSubscription(membership.subscription_id))
    }
  }, [run, membership])

  const afterAutopay = useCallback(()=>{
    loadSub();
    load();
  }, [loadSub, load]);

  useEffect(loadSub, [loadSub]);

  if (loading) {
    return <SingleLinePlaceholder/>
  }

  if (error) {
    return <>{error}</>
  }

  const {sub, user} = resp;

  return <>
    <SubscriptionStatusLabel sub={sub}/>{' '} 
    {user && user.person && user.person.id && <> on <Link to={`/person/${user.person.id}/billing`}><PersonName person={user.person}/></Link></>}
    <Admin>
      {(!membership.subscription_id || (sub && sub.status === 'canceled')) && 
        <SetupAutopayForGroup membership={membership} onSuccess={afterAutopay}/>}
    </Admin>
  </>
}

const SetupAutopayForGroup = ({membership, onSuccess}: {membership: Membership, onSuccess: ()=>void}) => {
  const {household} = useContext(HouseholdContainer);
  
  const canPay = household.people && household.people.map((hp) => {
    const {person} = hp;
    if (person.user && person.user.id && person.user.customer_id &&  hp.role !== HouseholdPersonRole.Inactive) {
      return person;
    } else {
      return false
    }
  }).filter(p=>p) as Person[];

  return <>
    {canPay && canPay.map(person => {
      return <WithCustomer key={person.user.id} uid={person.user.id}>
        {(customer) => (
          <SetupAutopayIndividual key={person.user.id} membership={membership} customer={customer} uid={person.user.id} onSuccess={onSuccess} />
        )}
      </WithCustomer>
    })}
  </>
}

const WithCustomer = ({uid, children}: {uid: number, children: (customer: Customer)=>React.ReactNode}) => {
  const [loading, error, run, customer] = useRequest<Customer>({} as Customer);
  useEffect(()=>{
    run(API.getCustomer(uid))
  }, [run, uid]);

  if (loading) {
    return <SingleLinePlaceholder/>
  }

  if (error) {
    return <>{error}</>
  }

  return <>{children(customer)}</>
}

const SetupAutopayIndividual = ({membership, customer, uid, onSuccess}: {membership: Membership, customer: Customer, uid: number, onSuccess: ()=>void}) => {
  const {household} = useContext(HouseholdContainer);
  const [loading, error, run, pm] = useRequest<{card: Card}>({card: {} as Card});
  const {card} = pm;
  const [formLoading, formError, formRun] = useRequest<any>(undefined);

  const pid = customer && customer.invoice_settings
      && customer.invoice_settings.default_payment_method 
      && customer.invoice_settings.default_payment_method.id;

  useEffect(()=>{
    if (pid) {
      run(API.getCard(pid));
    }
  }, [run, pid]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    formRun(API.createAutopay(Object.assign({}, membership, {
      household: household,
      payment_method_id: pid,
      cardholder_id: uid,
    })), onSuccess)
  }

  const { amount, extras } =  membership;

  if (loading) {
    return <TwoLinePlaceholder/>
  }

  if (error) {
    return <Message negative>{error}</Message>
  }

  if (!card || !customer) {
    return <>missing data...</>;
  }

  return (
    <Segment>
      <ViewCard customer={customer} card={card}/>
      <Form error name="payment" loading={formLoading} onSubmit={handleSubmit}>
        <Message error>{formError}</Message>
        <Button type="submit">Create ${ amount + extras } Autopay</Button>
      </Form>
    </Segment>
  );
}
