import API from 'Api';
import React, { useEffect, useState } from 'react'
import { DropdownItemProps, Form, FormSelectProps } from 'semantic-ui-react'
import { User } from 'Shared/Models';

import { ChangeHandler, useRequest } from './Hooks';

type UserChooserProps = Omit<FormSelectProps, 'options'> & {onChange: ChangeHandler}

const UserChooser: React.FC<UserChooserProps>= ({onChange, ...props}) => {
  const [loading, error, run, users] = useRequest<User[]>([], {loading: true})
  const [options, setOptions] = useState<DropdownItemProps[]>([])

  useEffect(()=>{
    run(API.getUsers());
  }, [run])

  useEffect(()=>{
    setOptions(
      users.map((u: User) => {
        return {
          key: u.id,
          value: u.id,
          text: u.person.first + ' ' + u.person.last
        }
      })
    )
  }, [users])

  return (
    <Form.Select
      {...props}
      error={error}
      loading={loading}
      options={options}
      search
      onChange={onChange} />
  );
}

export default UserChooser;
