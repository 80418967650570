import API from 'Api'
import React, { useCallback, useState } from 'react'

import { User } from './Models';
import { Toggler } from './Toggler';

export const MailerliteStatus = ({user}: {user: User}) => {
  return <MailerliteToggle user={user} label='General News' groupID={11225868}/>
}

export const MailerliteMemberStatus = ({user}: {user: User}) => {
  return <MailerliteToggle user={user} label='Members-Only Updates' groupID={111100575}/>
}

export const MailerliteProgramUpdateStatus = ({user}: {user: User}) => {
  return <MailerliteToggle user={user} label="Weekly 'Upcoming Programs' Updates" groupID={111296759}/>
}

const MailerliteToggle = ({user, label, groupID}: {user: User, label: string, groupID: number}) => {
  const [unsubbed, setUnsubbed] = useState(false)

  const init = useCallback(() => {
    return API.getMailerliteSubscriber(user.id)
      .then(({subscriber, groups}) => {

        setUnsubbed(subscriber && subscriber.type === "unsubscribed")
        const inGroup = groups && groups.filter((g: {id: number})=>(g.id === groupID)).length > 0;
        return Promise.resolve(subscriber && subscriber.type === "active" && inGroup);
      })
  }, [user, groupID])

  const createUser = () => {
    return API.addMailerliteSubscriber(user.id, groupID)
  }

  const removeUser = () => {
    return API.removeMailerliteSubscriber(user.id, groupID)
  }

  if (!user.id) {
    return <></>;
  }

  if (unsubbed) {
    return <>Unsub'd</>
  }

  return <Toggler
    label={label}
    init={init}
    onCheck={createUser}
    onUncheck={removeUser}
  />
}
