import API from 'Api'
import React, { useEffect } from 'react'
import { Cell, Legend, Line, LineChart, Pie, PieChart, PieLabelRenderProps, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { Header, Loader, Message } from 'semantic-ui-react'
import { useRequest } from 'Shared/Hooks'

const COLORS = ['#804000', '#e0ac69'];

const RADIAN = Math.PI / 180;
const customLabel = ({ cx, cy, midAngle, percent, value, index }: PieLabelRenderProps) => {
  const radius = 30;
  const x = (cx as number) + radius * Math.cos(-midAngle * RADIAN);
  const y = (cy as number) + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="#fff" textAnchor={x > (cx as number) ? 'start' : 'end'} dominantBaseline="central"style={{
      filter: 'drop-shadow(0px 2px 3px rgba(0,0,0,0.5))'
    }}> 
      {`${((percent as number) * 100).toFixed(0)}% (${value})`}
    </text>
  );
};

export default function Stats() {
  const [loading, error, run, stats] = useRequest({
    timeSeries: [],
    histograms: [],
  }, {loading: true})

  useEffect(()=>{
    run(API.getStats())
  }, [run])

  if (loading) {
    return <Loader active inline='centered' />
  }

  const {timeSeries, histograms} = stats;

  return <>
    { error && <Message negative>{error}</Message> }
    <Header as='h4'>Programs and Participants</Header>
    <p>Recurring programs are counted in the month of their start date.</p>
    <SimpleChart data={timeSeries}>
      <XAxis dataKey="date" angle={45} interval={0} height={40}/>
      <Line type="monotone" dataKey="programs" stroke="#8884d8" />
      <Line type="monotone" dataKey="participants" stroke="#c28a9d" />
    </SimpleChart>

    <Message warning>Program income data is broken temporarily... had to break it as part of paid program refactoring</Message>
    <SimpleChart data={timeSeries} height={200}>
      <XAxis dataKey="date" angle={45} interval={0} height={40}/>
      <Line type="monotone" dataKey="income" stroke="#333" unit="U$D"/>
    </SimpleChart>

    <SimpleChart data={timeSeries} height={200}>
      <XAxis dataKey="date" angle={45} interval={0} height={40}/>
      <Line type="monotone" dataKey="incomePerParticipant" stroke="#333" unit="U$D / participant"/>
    </SimpleChart>

    <Header as='h4'>Membership Growth</Header>
    <p>Members are counted in the month their membership's onboarding began, even if they were added later.</p>
    <p>Similarly, Membership fees show the amount of the fees for <em>currently active</em> memberships, totaled for the month that their membership <em>started</em>.</p>
    <SimpleChart data={timeSeries}>
      <XAxis dataKey="date" angle={45} interval={0} height={40}/>
      <Line type="monotone" dataKey="memberships" stroke="#8884d8" />
      <Line type="monotone" dataKey="members" stroke="#82ca9d" />
    </SimpleChart>

    <SimpleChart data={timeSeries}>
      <XAxis dataKey="date" angle={45} interval={0} height={40}/>
      <Line type="monotone" dataKey="membershipFees" stroke="#8884d8" unit="U$D"/>
    </SimpleChart>

    <Header as='h4'>Membership Fee Distribution</Header>
    <p>What is the distribution of fees for currently active memberships.</p>
    <SimpleChart data={histograms[0]}>
      <XAxis dataKey="name" angle={45} interval={0} height={40}/>
      <Line type="monotone" dataKey="fee" stroke="#8884d8" unit=" memberships"/>
    </SimpleChart>

    <Header as='h4'>Household income distribution</Header>
    <p>What is the distribution of household income for non-org, active memberships</p>
    <SimpleChart data={histograms[1]}>
      <XAxis dataKey="name" angle={45} interval={0} height={40}/>
      <Line type="monotone" dataKey="income" stroke="#8884d8" unit=" memberships"/>
    </SimpleChart>

    <Header as='h4'>BIPOC Members</Header>
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie data={histograms[2]} dataKey="amount" nameKey="name" cx="50%" cy="50%" outerRadius={150} labelLine={false} label={customLabel} height={200}>
          {(histograms[2] as []).map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  </>
}
type SimpleChartProps = React.PropsWithChildren<{
  height?: number,
  data: any[],
}>

const SimpleChart: React.FC<SimpleChartProps> = ({height, data, children}) => {
  return <ResponsiveContainer width="100%" height={height ? height : 300}>
    <LineChart margin={{ top: 5, right: 30, bottom: 0, left: 35 }} data={data}>
      {children}
      <Legend/>
      <Tooltip />
    </LineChart>
  </ResponsiveContainer>
}
