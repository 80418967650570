import API from "Api";
import { UserContainer } from "Context/User";
import moment from "moment";
import { useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button, Message, Modal } from "semantic-ui-react";
import { useRequest } from "Shared/Hooks";
import { Program, ProgramStatus, Registration, RegistrationType, Waitlist, WaitlistStatus } from "Shared/Models";
import { Panel } from "Shared/Panel";
import { LoggedIn } from "Shared/Roles";

import ProgramRegister from "./ProgramRegister";
import WaitlistRegister from "./WaitlistRegister";

export const membershipURL = "https://aldercommons.org/membership"

export const RegistrationTrigger = ({program}: {program: Program}) => {
  const [loading, error, run, result, , setResult] = useRequest<[Registration|undefined, Waitlist|undefined]>([undefined, undefined]);
  const [registration, waitlister] = result;

  const setReg = (reg: Registration) => {
    setResult([reg, waitlister]);
  }

  const setWaitlister = (wl: Waitlist) => {
    setResult([registration, wl]);
  }

  useEffect(()=>{
    run(Promise.all([
      API.getMyRegistration(program.id), 
      API.getMyWaitlister(program.id)
    ]))
  }, [run, program])

  if (loading) {
    return <Button fluid primary disabled loading content='Register'/>
  }

  if (error) {
    return <Message negative>{error}</Message>
  }

  if (registration && registration.id) {
    if (registration.status === 1) {
      return <Message size='tiny' negative>Your registration was canceled!</Message>
    }

    return <Panel icon='checkmark box' iconColor='green' heading='You are registered!'>
      <LoggedIn>
        <p><Button size='tiny' as={Link} to={`/registrations/${registration.id}`}>Modify my registration</Button></p>
      </LoggedIn>
      {program.registration_message 
        && <p>{program.registration_message}</p>}

      {program.link && <>
        <p>If the meeting starts soon...</p>
        <a className="none" target="_blank" rel="noopener noreferrer" href={program.link}><Button fluid>{program.link_label}</Button></a>
      </>}
    </Panel>
  }

  if (waitlister && waitlister.user_id && waitlister.status !== WaitlistStatus.Invited) {
    return <Message positive>You are on the watilist for this program!</Message>
  }

  if (program.registration_type === RegistrationType.None && program.link && program.link_label) {
    return <a style={{textDecoration: 'none'}} href={program.link}>
      <Button fluid primary>{program.link_label}</Button>
    </a>
  }

  if (program.registration_type > RegistrationType.None) {
    return <RegisterModal program={program} bypass={!!(waitlister && waitlister.status === WaitlistStatus.Invited)}  onReg={setReg} onWaitlist={setWaitlister}/>
  }

  return <></>;
}

const RegisterModal = ({bypass, program, onReg, onWaitlist}: {bypass: boolean, program: Program, onReg: (r: Registration)=>void, onWaitlist: (wl: Waitlist)=>void}) => {
  const {user} = useContext(UserContainer)
  const expired = moment.utc(program.end_date).add(20, 'minutes').isBefore(moment.tz('America/Los_Angeles').utc(true));
  const canceled = program.status === ProgramStatus.Deleted;
  const full = program.capacity && program.participants + program.waitlisters >= program.capacity;
  const disabled = expired || canceled || (full && !program.waitlist) ? true : false;

  let label = 'Register';
  if (expired) {
    label = 'Registration is closed';
  } else if (canceled) {
    label = 'Program is canceled';
  } else if (full && !program.waitlist && !bypass) {
    label = 'Program is full';
  } else if (full && program.waitlist && !bypass) {
    label = 'Join the Waitlist';
  }

  // additional checks for members-only
  if (program.registration_type === RegistrationType.MembersOnly && !full) {
    if (!user.id) {
      return <LoginPrompt/>
    }
    if (!user.person || !user.person.is_member) {
      return <Message warning>You must be <a href={membershipURL}>a Member</a> to register for this program.</Message>
    }
  }

  return <>
    {!user.id && (program.registration_type === RegistrationType.PaymentRequired || program.registration_type === RegistrationType.MembersDiscounted) && !full && <LoginPrompt/>}
    <Modal closeIcon='close'
      trigger={<Button fluid primary disabled={disabled} content={label}/>}
    >
      <Modal.Header>{program.title}</Modal.Header>
      <Modal.Content>
        {full && !bypass ? <WaitlistRegister onWaitlist={onWaitlist} program={program}/> : 
          <ProgramRegister bypass={bypass} onReg={onReg} program={program}/>}
      </Modal.Content>
    </Modal>
  </>
}

const LoginPrompt = () => {
  const location = useLocation()

  return <Button fluid style={{marginBottom:'1em'}} as={Link} to={{
    pathname: '/login',
    state: { from: location }
  }}>If you're a Member, log in to register</Button>
}
