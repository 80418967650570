import API from 'Api'
import _ from 'lodash';
import React, { FormEvent, useEffect, useState } from 'react'
import { Button, Form, Icon, Label, Message, Segment} from 'semantic-ui-react'
import { useFields, useRequest } from 'Shared/Hooks';
import { Program, Registration, RegistrationStatus } from 'Shared/Models';
import { Panel } from 'Shared/Panel';
import Tooltip from 'Shared/Tooltip'

const emailsFromRegistrations = (registrations: Registration[]) => {
  const active = _.filter(registrations, reg => reg.status === RegistrationStatus.Active);
  const regOwners = _.map(active, 'user.email');
  const participants = _(active)
    .flatMap('participants_full')
    .map('user.email')
    .filter() // <- remove empties
    .value();
  
  // merge and dedupe
  return _(participants)
    .concat(regOwners)
    .uniq()
    .value()
}

export const EmailParticipants = ({program, registrations}: {program: Program, registrations: Registration[]}) => {
  const [loading, error, run, result] = useRequest<[] | {body: string, body_html: string}>([])
  const [previewed, setPreviewed] = useState(false)
  const {fields, handleChange, setFields} = useFields({
    emails: emailsFromRegistrations(registrations),
    replyTo: "hello@aldercommons.org",
    subject: `${program.title} Update`,
    message: msgTemplate(program)
  })

  useEffect(()=>{
    setPreviewed(false);
  }, [fields.message, fields.replyTo])

  const hitAPI = (preview: boolean) => {
    const body = Object.assign({
      program_id: program.id,
      preview
    }, fields);

    run(API.emailProgramParticipants(body), result => {
      if (preview) {
        setPreviewed(true)
      }
    })
  }

  const handlePreview = (e: FormEvent) => {
    e.preventDefault()
    hitAPI(true)
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    hitAPI(false)
  }

  const removeEmail = (eml: string) => {
    const filtered = fields.emails.filter(email=>(email !== eml))
    setFields({...fields, emails: filtered})
  }

  const { message, subject, emails, replyTo } = fields;

  if (_.isArray(result) && result.length) {
    return <Message positive>Queued emails to... {result.join(', ')}</Message>
  }

  if (emails.length === 0) {
    return <></>
  }

  return <Panel icon='mail forward' heading='Send Email to Registrants &amp; Participants'>
    <Form error name="email" loading={loading} onSubmit={handleSubmit}>
      <Message error>{error}</Message>

      <Form.Field>
        <label>To</label>
        {emails.map(eml => {
          return <Label key={eml}>{eml}<Icon onClick={()=>removeEmail(eml)} name='delete' /></Label>
        })}
      </Form.Field>

      <Form.Input 
        required 
        label='Subject'
        name='subject'
        value={subject}
        onChange={handleChange}
      />
      <Form.Input 
        required 
        label='Reply To'
        name='replyTo'
        value={replyTo}
        onChange={handleChange}
      />

      <Form.Field required>
        <label>Message <Tooltip content="This field supports markdown, but you can use some template placeholders..." /></label>
        <Form.TextArea
          rows={12}
          name='message'
          required
          value={message}
          onChange={handleChange}
        />
      </Form.Field>

      { !_.isArray(result) && result.body && 
        <Segment secondary dangerouslySetInnerHTML={{ __html: result.body_html }}/>}

      {!previewed ? 
        <Button onClick={handlePreview} primary>Preview</Button> :
        <Button primary type="submit">Send to {emails.length} Participants</Button>
      }
    </Form>
  </Panel>
}

const msgTemplate = (program: Program) => {
  return `Dear {{.Person.First}},

We're writing you because you are registered for *{{.Program.Title}}*.

**The next session is {{.Program.Next}}.**

` + (program.link ? `Click the following link to {{.Program.LinkLabel}}: {{.Program.Link}}

` : '') + 
`Thanks,
— Alder Commons Organizing Team

p.s. you can provide feedback here: https://aldercommons.org/feedback`
}
