import API from 'Api';
import React, { useCallback, useEffect } from 'react'
import { Button, Form, FormSelectProps, Loader, Message, Popup } from 'semantic-ui-react'

import { ChangeHandler, useRequest } from './Hooks';
import { Tag } from './Models';

type TagCloudProps = Omit<FormSelectProps, 'value'> & {
  value: Tag[] | undefined | null
  interestTags?: boolean
  onChange: ChangeHandler
}

const TagCloud: React.FC<TagCloudProps> = ({onChange, value, interestTags, ...rest}) => {
  const [loading, error, run, tags] = useRequest<Tag[]>([], {loading: true})

  // load em up
  useEffect(()=>{
    let APIcall = API.getTags;
    if (interestTags) {
      APIcall = API.getInterestTags;
    }
    run(APIcall())
  }, [run, interestTags])

  // add/remove a tag from the form value
  const toggleTag = useCallback((tag: Tag) => {
    let newValue = value ? value : [];
    if (newValue.find(t=> t.id === tag.id)) {
      // already in the list, remove it!
      newValue = newValue.filter(t => t.id !== tag.id)
    } else {
      // add it to the list
      newValue.push(tag);
    }
    
    onChange(null, {
      name: rest.name,
      value: newValue
    });
  }, [onChange, value, rest.name]);

  return <Form.Field>
    <label>{rest.label}</label>
    {loading && <Loader inline active/>}
    {error && <Message warning>{error}</Message>}
    {tags && tags.map(t => {
      const active = value && value.find(selectedTag=> selectedTag.id === t.id)
      return <span key={t.id} style={{
        display: 'inline-block',
        marginRight: '0.5em',
        marginBottom: '0.5em',
      }}>
        <Button.Group size='mini'>
          <Button
            type='button'
            color={active ? 'green' : undefined}
            onClick={()=>toggleTag(t)}
            content={t.label}
          />
          <Popup
            size='small'
            position='top center'
            content={t.description}
            trigger={<Button type='button' icon='info' />}/>
        </Button.Group>
      </span>
    })}
  </Form.Field>
}

export default TagCloud;
