import React, { useEffect, useState } from 'react'
import { Form, Icon, InputProps } from 'semantic-ui-react'

import { ChangeHandler, useFields } from './Hooks';

const PasswordField = ({name, onChange, ...rest}: {name: string, onChange: ChangeHandler} & InputProps) => {
  const [type, setType] = useState('password')
  const {fields, handleChange} = useFields({pass: ''})

  const togglePasswordType = () => {
    setType(type === 'password' ? 'text' : 'password')
  }

  useEffect(()=>{
    onChange(null, {name, value: fields.pass});
  }, [name, fields.pass, onChange]);

  return (
    <Form.Input
      {...rest}
      icon={<Icon name={type === 'password' ? 'eye slash' : 'eye'} link onClick={togglePasswordType} />}
      name={name}
      type={type}
      label="Password"
      required
      value={fields.pass}
      onChange={handleChange} />
  );
}

export default PasswordField;
