import API from 'Api'
import { PersonContainer } from 'Context/Person'
import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router'
import { Redirect } from 'react-router-dom'
import { Button, Form, Message } from 'semantic-ui-react'
import { isAdult } from 'Shared/Age'
import { useFields, useRequest } from 'Shared/Hooks'
import PersonFields from 'Shared/PersonFields'
import { Admin } from 'Shared/Roles'

const EditForm = () => {
  const location = useLocation<{from: {pathname: string}}>();
  const {person} = useContext(PersonContainer);;
  const [loading, error, run, success] = useRequest(false);
  const {fields, handleChange} = useFields({person: Object.assign({user: {email: ''}}, person)})

  useEffect(()=>{
    if (error) {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }, [error])

  const handleSubmit = () => {
    run(API.updatePerson(fields.person));
  }

  if (success) {
    return <Redirect to={{
      pathname: location && location.state && location.state.from ? location.state.from.pathname : `/person/${person.id}`,
      state: { reload: true }
    }}/>
  }

  return (
    <Form error name="Person" loading={loading} onSubmit={handleSubmit}>
      <Message error>{error}</Message>
      {person.user && person.user.email ? false : 
        <Form.Input
          label='Email (required for adults)'
          required={isAdult(person)}
          style={{maxWidth: '20em'}}
          name='person.user.email'
          type='email'
          value={fields.person.user.email}
          onChange={handleChange}
        />}
      <Admin>
        {person.user && person.user.id && <>
          <Form.Checkbox
            label='Can Onboard'
            name="person.user.can_onboard"
            checked={fields.person.user.can_onboard}
            onChange={handleChange} />
        </> }
      </Admin>
      <PersonFields onChange={handleChange} person={fields.person}/>
      <Button primary type="submit">Save</Button>
    </Form>
  );
}

export default EditForm;
