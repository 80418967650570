import API from 'Api'
import { useCallback, useState } from 'react'
import { Button } from 'semantic-ui-react';
import { User } from 'Shared/Models';
import { Toggler } from 'Shared/Toggler';

export const LibibPatron = ({user}: {user: User}) => {
  const [check, setCheck] = useState(false)
  const init = useCallback(() => {
    return API.getLibibPatron(user.id)
      .then(({barcode})=>{
        return Promise.resolve(barcode !== '')
      })
  }, [user])

  const createUser = () => {
    return API.createLibibPatron(user.id)
  }

  const removeUser = () => {
    return Promise.reject('Unsafe to delete patrons')
  }

  if (!check)  {
    return <Button size='tiny' onClick={()=>{setCheck(true)}}>Check Libib</Button>
  }

  return <>
    <Toggler
      label="libib"
      init={init}
      onCheck={createUser}
      onUncheck={removeUser}
    />
  </>
}
