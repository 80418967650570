import { Card } from '@stripe/stripe-js'
import API from 'Api'
import { capitalize } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Message } from 'semantic-ui-react'
import ChangeDefaultCardForm, { ChangeDefaultCardFormProps } from 'Shared/ChangeDefaultCardForm'

import { useRequest } from './Hooks'
import { Customer } from './Models'
import { TwoLinePlaceholder } from './Placeholders'

const PaymentCard = ({customer, onSuccess, ...rest}: {customer: Customer, onSuccess: ()=>void} & Omit<ChangeDefaultCardFormProps, 'onCancel'>) => {
  const [loading, error, run, pm] = useRequest<{card?:Card}>({});
  const [showForm, setShowForm] = useState(false);
  const { card } = pm || {};

  const getCard = useCallback((payment_method_id: string) => {
    return run(API.getCard(payment_method_id))
  }, [run]);

  useEffect(()=>{
    if (customer && customer.invoice_settings && customer.invoice_settings.default_payment_method) {
      getCard(customer.invoice_settings.default_payment_method.id)
    }
  }, [customer, getCard]);

  const toggleForm = () => {
    setShowForm(!showForm);
  }

  const handleSuccess = (pid: string) => {
    setShowForm(false);
    getCard(pid);
    if (onSuccess) {
      onSuccess();
    }
  }

  if (loading) {
    return <TwoLinePlaceholder/>
  }

  if (error) {
    return <Message error>{error}</Message>
  }

  if (showForm) {
    return <ChangeDefaultCardForm {...rest} onSuccess={handleSuccess} onCancel={toggleForm}/>
  }

  return <>
    {card !== undefined ? <ViewCard customer={customer} card={card}/> : <p>No card on file.</p>}
    <Button size="tiny" onClick={toggleForm}>{card ? 'Change' : 'Create'} Default Card</Button>
  </>
}

export default PaymentCard;

export const ViewCard = ({card, customer}: {card: Card, customer: Customer}) => (
  <div style={{marginBottom:'1em',border: '1px solid #ddd',background: 'white',maxWidth:'260px',borderRadius:'4px',padding:'0.6em 0.9em'}}>
    {customer.name}'s {capitalize(card.brand)}<br/>
    **** **** **** {card.last4}<br/>
    <i>Expires {expiry(card)}</i>
  </div>
);

const expiry = (card: Card) => pad(card.exp_month) + '/' + String(card.exp_year).slice(-2)

const pad = (n: number) => n < 10 ? '0' + n : n

