import moment from 'moment'
import React from 'react'
import { Table } from 'semantic-ui-react'
import Dollars from 'Shared/Dollars'
import { filter } from 'Shared/Donations'
import TableHeaders from 'Shared/TableHeaders'

import { Payment, Refund } from './Models'

const PaymentTable = ({ payments, year }: {payments: Payment[], year: 'all'|number}) => {

  let filtered = payments;
  if (year !== 'all') {
    filtered = filter(payments, year);
  }

  if (filtered.length === 0) {
    return <p>No payments on record.</p>
  }

  const total = filtered.reduce((sum, p) => {
    return sum += p.amount - refundAmount(p.refunds);
  }, 0);

  return (
    <Table collapsing striped>
      <TableHeaders headers={['Description', 'Date', {name:'Amount', textAlign:'right'},'']}/>
      <Table.Body>
        {filtered.map(p => {
          const refund = refundAmount(p.refunds);
          return <Table.Row key={p.stripe_id}>
            <Table.Cell>{p.description}</Table.Cell>
            <Table.Cell>{moment(p.created_at).format('LL')}</Table.Cell>
            <Table.Cell textAlign='right'><Dollars amount={p.amount/100}/></Table.Cell>
            <Table.Cell textAlign='right'>{refund ? <>-<Dollars amount={refund/100}/></> : false /*<RefundButton stripe_id={p.stripe_id}/>*/}</Table.Cell>
          </Table.Row>
        })}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan={2}>Total</Table.HeaderCell>
          <Table.HeaderCell textAlign='right'><Dollars amount={total/100}/></Table.HeaderCell>
          <Table.HeaderCell textAlign='right'></Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
}

export default PaymentTable;

export const refundAmount = (refunds: Refund[]) => (
  !refunds ? 0 : refunds.filter(r => (r.status === 'succeeded')).reduce((sum, r) => (sum += r.amount), 0)
)
