import 'react-image-crop/dist/ReactCrop.css';

import API from 'Api'
import React, { SyntheticEvent, useCallback, useRef, useState } from 'react'
import ReactCrop, { centerCrop, Crop, makeAspectCrop } from 'react-image-crop';
import { Button, Form, Grid, Header, Message, Segment } from 'semantic-ui-react'
import { useFields, useRequest } from 'Shared/Hooks';
import { Program, ProgramImage } from 'Shared/Models';
import { NumberField } from 'Shared/Number'
import Tooltip from 'Shared/Tooltip';

type ProgramImagesProps = {
  program: Program
  onSuccess?: () => void
}

export const ProgramImages: React.FC<ProgramImagesProps> = ({program, onSuccess}) => {
  const { title, images } = program;

  return <>
    <Header style={{marginTop:'0'}} as='h1'>{title}</Header>
    {images && images.map((i) => {
      i.isOG = i.id === program.og_image;
      return <Segment key={i.id}>
        <ProgramImageForm onSuccess={onSuccess} program={program} image={i} />
      </Segment>
    })}
    <Segment>
      <ProgramImageForm onSuccess={onSuccess} program={program}/>
    </Segment>
  </>
}

type ProgramImageFormProps = {
  image?: ProgramImage
  program: Program
  onSuccess?: () => void
}

type ProgramImageFields = ProgramImage & {
  type: string
}
const finalWidth = 1200;
const finalHeight = 630;
const aspectRatio = finalWidth / finalHeight;

const ProgramImageForm: React.FC<ProgramImageFormProps> = ({image, program, onSuccess}) => {
  const [loading, error, run, , setError] = useRequest({} as ProgramImageFields);
  const {fields, handleChange, setFields} = useFields({
    id: (image && image.id) || null,
    isOG: (image && image.isOG) || !program.images || program.images.length === 0,
    program_id: program.id,
    type: 'image',
    path: image && image.path ? image.path : '',
    title: image && image.title ? image.title : '',
    weight: image && image.weight ? image.weight : 0,
  })

  const [result, setResult] = useState('')
  const [src, setSrc] = useState('');

  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    width: 100,
    height: 100,
    x: 0,
    y: 0,
  })

  const fileRef = useRef<HTMLInputElement | null>(null)
  const imgRef = useRef<HTMLImageElement | null>(null)

  const handleFileChange = () => {
    if (fileRef.current && fileRef.current.files) {
      setSrc(URL.createObjectURL(fileRef.current.files[0]));
    }
  }

  const handleLoad = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    imgRef.current = e.currentTarget;
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 100,
          height: 100
        },
        aspectRatio,
        width,
        height
      ),
      width,
      height
    )
  
    handleCropComplete({} as Crop, crop)
  }

  const handleCrop = (crop: Crop) => {
    setCrop(crop);
  }

  const handleCropComplete = (crop: Crop, percentCrop: Crop) => {
    setCrop(percentCrop)

    if (fileRef.current && fileRef.current.files && fileRef.current.files[0]) {
      const { type } = fileRef.current.files[0];
      getCroppedImg(imgRef.current, percentCrop, type).then((blob: Blob | unknown) => {
        setResult(URL.createObjectURL(blob as Blob));
      });
    }
  }

  const handleUpdate = useCallback(() => {
    run(API.updateProgramImage(program.id, fields), answer => {
      setFields(answer as ProgramImageFields)
      if (onSuccess) {
        onSuccess()
      }
    })
  }, [run, program, onSuccess, fields, setFields])

  const handleCreate = useCallback(() => {
    if (!fileRef.current || !fileRef.current.files) {
      return setError('No file selected for upload.');
    }

    const { name, type } = fileRef.current.files[0];

    run(getCroppedImg(imgRef.current, crop, type)
      .then(blob => {
        return API.createProgramImage(program.id, blob as Blob, name, fields)
      }), 
    () => {
      setFields({} as ProgramImageFields)
      if (onSuccess) {
        onSuccess();
      }
    }
    )
  }, [run, program, onSuccess, fields, crop, setError, setFields]);

  const handleSubmit = useCallback(() => {
    if (image) {
      handleUpdate();
    } else {
      handleCreate();
    }
  }, [handleUpdate, handleCreate, image]);

  const handleDelete = useCallback(() => {
    run(API.deleteProgramImage(program.id, (image as ProgramImage).id), onSuccess)
  }, [program, image, onSuccess, run])
  
  const { url } = image || {};
  const { title, weight, isOG } = fields;

  return <Form error name="program_image" loading={loading} onSubmit={handleSubmit}>
    <Message error>{error}</Message>
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column>
          { url ? '' : <>
            <Form.Field required>
              <label>Add a PNG or JPEG <Tooltip content="Will be cropped to 1200px&nbsp;&times;&nbsp;630px"/></label>
              <input type='file' accept="image/png,image/jpeg" onChange={handleFileChange} ref={fileRef}/>
            </Form.Field>

            {src && <ReactCrop
              aspect={aspectRatio}
              crop={crop}
              keepSelection={true}
              onChange={handleCrop} 
              onComplete={handleCropComplete} >
              {src && <img alt='crop' src={src} onLoad={handleLoad}/>}
            </ReactCrop>}
          </>}

          <Form.Input 
            error={title && title.length > 128 ? 'Maximum length for captions is 128 characters' : false}
            label='Caption'
            name='title'
            required
            defaultValue={title}
            onChange={handleChange}
          />
          <NumberField
            style={{maxWidth: '5em'}}
            label='Sort Index'
            name='weight'
            defaultValue={weight}
            onChange={handleChange}
          />

          {/* only show primary image toggle for subsquent uploads */}
          {!program.images || program.images.length === 0 ? false : 
            <Form.Checkbox
              label='Primary Image'
              name='isOG'
              checked={isOG}
              onChange={handleChange}/> }

          {url ? 
            <>
              <Button primary>Save</Button>
              <Button type='button' onClick={handleDelete}>Delete</Button>
            </> : 
            <Button primary>Upload</Button>
          }
        </Grid.Column>
        <Grid.Column>
          { result && <img style={{maxWidth:'100%'}} alt='Crop Result' src={result}/>}
          { url && <img alt={title} title={title} src={url}/> }
        </Grid.Column>
      </Grid.Row>
    </Grid>
    
  </Form>
}

function getCroppedImg(image: unknown, crop: Crop, type: string) {
  const img = image as HTMLImageElement;
  const canvas = document.createElement('canvas');
  const browserScaleFactor = finalWidth / img.width;
  const scaleX = img.naturalWidth / img.width;
  const scaleY = img.naturalHeight / img.height;
  canvas.width = finalWidth;
  canvas.height = finalHeight;
  const cropWidth = (crop.width/100) * img.width || 100;
  const cropHeight = (crop.height/100) * img.height || 100;
  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(
      img,
      (crop.x/100 || 0) * img.width * scaleX,
      (crop.y/100 || 0) * img.height * scaleY,
      cropWidth * scaleX,
      cropHeight * scaleY,
      0,
      0,
      cropWidth * browserScaleFactor * (img.width / cropWidth),
      cropHeight * browserScaleFactor * (img.width / cropWidth),
    );
  }

  return new Promise((resolve, reject) => {
    canvas.toBlob(resolve, type);
  });
}
