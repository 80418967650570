import API from 'Api'
import Fuse from 'fuse.js'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Form, Icon, Loader, Message, Table } from 'semantic-ui-react'
import { ChangeHandler, useFields, useRequest } from 'Shared/Hooks'
import { Person, UserRole, UserStatus } from 'Shared/Models'
import { PersonName } from 'Shared/PersonName'
import TableHeaders from 'Shared/TableHeaders'
import Tooltip from 'Shared/Tooltip'

type Filter = {
  filter: string
  alertable: boolean
  admin: boolean
  staff: boolean
  member: boolean
  duplicate: boolean
  not_member: boolean
  not_verified: boolean
  photo_consent: boolean
  has_email: boolean
  has_allergy: boolean
  race_ethnicity: boolean
}

const People = () => {
  const [loading, error, run] = useRequest<Person[]>([], {loading: true})
  const {fields, handleChange} = useFields<Filter>({} as Filter)
  const [fuse, setFuse] = useState<Fuse<Person> | null>(null);
  const [loadedAdmin, setLoadedAdmin] = useState(false);
  const [people, setPeople] = useState<Person[]>([]);

  const shouldLoadAdmin = fields.admin || fields.staff || fields.member || fields.not_member || fields.not_verified || fields.has_email;

  // initial load
  useEffect(()=>{
    run(API.getPeople(), (ppl) => {
      setPeople(ppl.map(p => {
        p.full_name = `${p.first} ${p.nickname} ${p.last}`
        return p;
      }))
    })
  }, [run]);

  // if we're filtering by something on p.user, we need to do a more expensive fetch
  useEffect(()=>{
    if (shouldLoadAdmin && !loadedAdmin) {
      run(API.getPeopleAdmin(), (ppl) => {
        setPeople(ppl.map(p => {
          p.full_name = `${p.first} ${p.nickname} ${p.last}`
          return p;
        }));

        setLoadedAdmin(true);
      })
    }
  }, [run, shouldLoadAdmin, loadedAdmin])

  // update the fuse after load
  useEffect(()=>{
    setFuse(new Fuse(people, {
      threshold: 0.3,
      keys: ['full_name', 'user.email']
    }));
  }, [people])

  if (loading) {
    return <Loader active inline='centered' />
  }

  const filteredPeople = filterPeople(people, fuse, fields);

  const tableHeaders = [
    'Name',
    fields.has_email && 'Email',
    fields.has_allergy && 'Allergy',
    fields.race_ethnicity && 'Race/Ethnicity',
    fields.race_ethnicity && 'BIPOC',
    'Edit'
  ].filter(x=>x);

  return <>
    { error && <Message negative>{error}</Message>}
    <Filters loadedAdmin={loadedAdmin} fields={fields} handleChange={handleChange} filteredPeople={filteredPeople}/>

    <Table compact unstackable>
      <TableHeaders headers={tableHeaders}/>
      <Table.Body>
        { filteredPeople.map(p => (
          <Table.Row key={p.id}>
            <Table.Cell>
              <Link to={`/person/${p.id}`}><PersonName person={p}/></Link>
            </Table.Cell>
            {fields.has_email && <Table.Cell>
              {p.user.email}
            </Table.Cell>}
            {fields.has_allergy && <Table.Cell>
              {p.allergies}
            </Table.Cell>}
            {fields.race_ethnicity && <Table.Cell collapsing>
              {p.race_ethnicity}
            </Table.Cell>}
            {fields.race_ethnicity && <Table.Cell collapsing>
              {p.bipoc && <Icon name='check' color='green'/> }
            </Table.Cell>}
            <Table.Cell collapsing>
              <Link to={`/person/${p.id}/edit`}><Button icon='pencil' size='mini'/></Link>
            </Table.Cell>
          </Table.Row>
        )) }
      </Table.Body>
    </Table>
  </>
}
type FiltersProps = {
  loadedAdmin: boolean
  fields: Filter
  handleChange: ChangeHandler
  filteredPeople: Person[]
}

const Filters: React.FC<FiltersProps> = ({loadedAdmin, fields, handleChange, filteredPeople}) => (
  <Form>
    <Form.Group style={{marginBottom:'1em'}}>
      <Form.Input
        style={{width:'13em'}}
        type='text'
        name='filter'
        placeholder={`Filter ${filteredPeople.length} by name${loadedAdmin ? ' or email' : ''}`}
        value={fields.filter}
        onChange={handleChange}
      />
      {!loadedAdmin && <div style={{marginRight: '0.5em', height: '2.5em', display: 'flex', alignItems:'center'}}>
        <Tooltip content='You must use the "Has Email" checkbox to filter by email'/>
      </div>}
      <Link to='/admin/misc/person'>
        <Form.Button><Icon name='plus'/>New Person</Form.Button>
      </Link>
    </Form.Group>
    <Form.Group>
      <Form.Checkbox
        label='Is&nbsp;Admin'
        name='admin'
        checked={fields.admin}
        onChange={handleChange}/>
      <Form.Checkbox
        label='Is&nbsp;Staff'
        name='staff'
        checked={fields.staff}
        onChange={handleChange}/>
      <Form.Checkbox
        label='Is&nbsp;Member'
        name='member'
        checked={fields.member}
        onChange={handleChange}/>
      <Form.Checkbox
        label='Is&nbsp;Not&nbsp;Member'
        name='not_member'
        checked={fields.not_member}
        onChange={handleChange}/>
      <Form.Checkbox
        label='Duplicate?'
        name='duplicate'
        checked={fields.duplicate}
        onChange={handleChange}/>
    </Form.Group>
    <Form.Group>
      <Form.Checkbox
        label='Gets&nbsp;Text&nbsp;Alerts'
        name='alertable'
        checked={fields.alertable}
        onChange={handleChange}/>
      <Form.Checkbox
        label='Unverified&nbsp;Email'
        name='not_verified'
        checked={fields.not_verified}
        onChange={handleChange}/>
      <Form.Checkbox
        label='Has Email'
        name='has_email'
        checked={fields.has_email}
        onChange={handleChange}/>
      <Form.Checkbox
        label='Has&nbsp;Allergy'
        name='has_allergy'
        checked={fields.has_allergy}
        onChange={handleChange}/>
      <Form.Checkbox
        label='Photo&nbsp;Consent'
        name='photo_consent'
        checked={fields.photo_consent}
        onChange={handleChange}/>
      <Form.Checkbox
        label='Race/Ethnicity'
        name='race_ethnicity'
        checked={fields.race_ethnicity}
        onChange={handleChange}/>
    </Form.Group>
  </Form>
)

const filterPeople = (ogPeople: Person[], fuse: Fuse<Person> | null, fields: Filter) => {
  const { filter, alertable, admin, staff, member, not_member, duplicate, not_verified, photo_consent, has_email, has_allergy, race_ethnicity } = fields;

  let people = filter && fuse ? fuse.search(filter).map(({item}) => (item)) : ogPeople;
  if (alertable) {
    people = people.filter(p=>(p.alerts))
  }
  if (admin) {
    people = people.filter(p=>(p.user && p.user.role === UserRole.Admin))
  }
  if (staff) {
    people = people.filter(p=>(p.user && p.user.role === UserRole.Staff))
  }
  if (member) {
    people = people.filter(p=>(p.is_member))
  }
  if (not_member) {
    people = people.filter(p=>(!p.is_member))
  }
  if (not_verified) {
    people = people.filter(p=>(p.user && p.user.status === UserStatus.Unverified))
  }
  if (photo_consent) {
    people = people.filter(p=>(p.photo_consent === 2))
  }
  if (has_email) {
    people = people.filter(p=>(p.user && p.user.email))
  }
  if (has_allergy) {
    people = people.filter(p=>(p.allergies && !['none', 'no', 'n/a', 'na'].includes(p.allergies.toLowerCase()) ))
  }
  if (race_ethnicity) {
    people = people.filter(p=>(p.race_ethnicity))
  }

  if (duplicate) {
    people = (_(people)
      .groupBy(x=>x.first.toLowerCase() + ' ' + x.last.toLowerCase())
      .pickBy(x => x.length > 1)
      .flatMap()
      .flatten()
      .value());
  }

  return people;
}

export default People;
