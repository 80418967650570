import { WithUser } from 'Context/User'
import { createBrowserHistory } from "history";
import { Helmet } from 'react-helmet'
import { Router } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'

import Embeds from './Embeds/Embeds'
import Routes from './Routes/Routes'

const customHistory = createBrowserHistory();

const App = () => (
  <WithUser>
    <Helmet
      defaultTitle="Alder Commons"
      titleTemplate="%s | Alder Commons"
    >
      <meta content="Alder Commons" property="og:site_name" />
      <meta content="https://aldercommons.org/img/alder_commons_og.png" property="og:image" />
    </Helmet>
    <Router history={customHistory}>
      <Switch>
        <Route path='/embed' component={Embeds}/>
        <Routes/>
      </Switch>
    </Router>
  </WithUser>
)

export default App;
