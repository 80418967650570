import moment from 'moment'
import React, { useCallback } from 'react'
import { Form } from 'semantic-ui-react'

import { ChangeHandler, useFields } from './Hooks';

const defaultState = (value?: string) => {
  if (value && value !== "0001-01-01T00:00:00Z") {
    let m = moment.utc(value);
    return {
      date: m.format('YYYY-MM-DD'),
      time: m.format('HH:mm'),
    };
  } else {
    return {
      date: '',
      time: '',
    }
  }
}

export const DateTimePicker = ({name: parentName, value, onChange}: {name: string, value: string, onChange: ChangeHandler}) => {
  const {fields, setFields} = useFields(defaultState(value))

  const innerChange: ChangeHandler = useCallback((e, {name, value}) => {
    const newValue = {...fields, [name]: value};
    setFields(newValue);
    onChange(null, {
      name: parentName,
      value: newValue.date + 'T' + newValue.time + ':00Z'
    });
  }, [parentName, fields, onChange, setFields]);

  const { date, time } = fields;

  return (
    <Form.Group inline>
      <Form.Input
        style={{maxWidth: '11em'}}
        name="date"
        type="date"
        required
        value={date}
        onChange={innerChange}
      />
      <Form.Input
        style={{maxWidth: '11em'}}
        name="time"
        type="time"
        required
        value={time}
        onChange={innerChange}
      />
    </Form.Group>
  );
}
