import API from 'Api'
import { PersonContainer } from 'Context/Person'
import React, { useCallback, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Message } from 'semantic-ui-react'
import { Date } from 'Shared/Date'
import { useRequest } from 'Shared/Hooks'
import { Program } from 'Shared/Models'
import { Panel } from 'Shared/Panel'
import { ListPlaceholder } from 'Shared/Placeholders'
import { ProgramStatusLabel } from 'Shared/ProgramStatus'
import { TimeRange } from 'Shared/Time'

export const MyEvents = () => {
  const {person} = useContext(PersonContainer);
  const [loading, error, run, programs] = useRequest<Program[]>([])

  const load = useCallback(()=>{
    run(API.getUserPrograms(person.user.id))
  }, [person, run])

  useEffect(load, [load]);

  return <Panel icon='calendar alternate outline' heading='My Reservations'>
    {error && <Message negative>{error}</Message>}
    {!loading && programs.length > 0 &&
      <p>Upcoming programs, room reservations, and reservation requests for which you are the host.</p>}
    {loading ? <ListPlaceholder/> :
      programs.map(p => {
        return <div key={p.id} style={{marginBottom:'0.5em'}}>
          <Link to={`/program/${p.slug}`}>
            {p.title}
          </Link>&nbsp;<ProgramStatusLabel size='tiny' status={p.status} style={{padding: '0.2em 0.4em'}} /><br/>
          {p.rrule !== '' ?
            <>
              <Date ts={p.start_date} short/> &mdash; <Date ts={p.end_date} short/>
            </> :
            <Date ts={p.start_date} short/>} <TimeRange program={p} style={{color: '#777',marginLeft: '0.5em'}} noTZ/>
        </div>
      })}
    <Button
      icon='calendar plus outline'
      size='tiny'
      as={Link}
      to='/new-program'
      title='Request a Reservation or Host a Program'
      content='Reserve Space'/>
  </Panel>
}

