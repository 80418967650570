import React from 'react'
import { Form, SelectProps } from 'semantic-ui-react'
import { ChangeHandler } from 'Shared/Hooks'
import { NoiseLevel } from 'Shared/Models'

type NoiseSelectProps = {
  noise_level: NoiseLevel
  handleChange: ChangeHandler
} & Omit<SelectProps, 'options'>

export const NoiseSelect: React.FC<NoiseSelectProps> = ({noise_level, handleChange, ...rest}) => (
  <Form.Select
    {...rest}
    label='Noise Level'
    value={noise_level}
    name='noise_level'
    onChange={handleChange}
    options={Object.keys(NoiseLevels).map(Number).map((key: NoiseLevel)=>({
      key: key,
      value: key,
      text: NoiseLevels[key]
    }))}/>
)

export const NoiseLevels: Record<NoiseLevel, string> = {
  [NoiseLevel.Quiet]: 'Quiet (e.g. small meeting)',
  [NoiseLevel.Medium]: 'Medium (e.g. large group with chatter)',
  [NoiseLevel.Loud]: 'Loud (e.g. amplified sound, hammering)'
};
