import API from "Api"
import { useCallback } from "react"
import { Button, Label, Loader, Message, SemanticCOLORS } from "semantic-ui-react"
import { useRequest } from "Shared/Hooks"
import { Report, ReportReviewStatus } from "Shared/Models"

const reviewStatusMap: Record<ReportReviewStatus, {name: string, color: SemanticCOLORS}> = {
  [ReportReviewStatus.NotReviewed]: {name: 'Not Reviewed', color: 'grey'},
  [ReportReviewStatus.ReviewedDenied]: {name: 'Reviewed & Denied', color: 'red'},
  [ReportReviewStatus.ReviewedApproved]: {name: 'Reviewed & Approved', color: 'green'}
}

export const ReportReview = ({report, loadReports}: {report: Report, loadReports: ()=>void}) => {
  const [loading, error, run] = useRequest({});

  const doUpdate = useCallback((status: number)=>{
    run(API.createReportReview({report_id: report.id, status}), loadReports)
  }, [run, report.id, loadReports])

  if (loading) {
    return <Loader active inline/>
  }

  if (error) {
    return <Message negative>{error}</Message>
  }

  const label = reviewStatusMap[report.review_status];

  return <>
    <Label color={label.color}>
      {label.name}
    </Label>
    {report.review_status === 0 && <>&nbsp;
      <Button onClick={()=>{doUpdate(2)}} title='Approve' color='green' icon='check' size='tiny'/>
      <Button onClick={()=>{doUpdate(1)}} title='Reject' color='red' icon='cancel' size='tiny'/>
    </>}
  </>
}
